import React, { Component } from 'react';
import './brand.css';
import {
  CommandBarButton,
  IButtonStyles,
  IImageProps,
  Image,
  ImageFit,
  OverflowSet,
  Text,
} from 'office-ui-fabric-react';
import { withRouter } from 'react-router-dom';
import { ProfileDropdown } from '../profileDropdown/profileDropdown';
import history from '../../history';
import { uPrinceTheme } from '../../../theme';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  getCuDetails,
  getOrganizationDetails,
  getThemeColor,
} from '../../reducers/uprinceReducer';
import { getProjectDefinition } from '../../reducers/projectReducer';
import {
  EXTERNAL_DATA_SYNC_SOURCE_TYPE,
  getContractingUnit,
  getProject,
} from '../util';
import ENV from '../../../env.json';
import { BrandNavBar } from '../navBar/brandNavBar';
import _ from 'lodash';
import { SyncStatusDropdown } from '../syncStatusDropdown/syncStatusDropdown';

const onRenderOverflowButton = (
  overflowItems: any[] | undefined
): JSX.Element => {
  const buttonStyles: Partial<IButtonStyles> = {
    root: {
      minWidth: 0,
      padding: '0 4px',
      alignSelf: 'stretch',
      height: 40,
      width: 40,
      fontSize: 40,
      background: uPrinceTheme.palette.themePrimary,
    },
    menuIcon: {
      fontSize: 25,
      color: '#ffffff',
    },
  };
  return (
    <CommandBarButton
      ariaLabel="More items"
      role="menuitem"
      styles={buttonStyles}
      menuIconProps={{ iconName: 'Settings' }}
      menuProps={{ items: overflowItems! }}
    />
  );
};

const styles = {
  hoverText: {
    alignSelf: 'center',
    backgroundColor: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
    textDecoration: 'none',
    opacity: '0.5',
    transition: 'all 0.2s',
    '&:hover': {
      background: uPrinceTheme.palette.themePrimary,
    },
  },
};

interface Props extends WithTranslation {
  getOrganizationDetails: any;
  getProjectDefinition: any;
  getCuDetails: any;
  organization: any;
  selectedProject: any;
  selectedCu: any;
  getThemeColor: any;
  match: any;
  cancelRequest: any;
}

interface State {
  hover: boolean;
  selectedProject: any;
  selectedCu: any;
}

class Brand extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hover: false,
      selectedProject: null,
      selectedCu: null,
    };
  }

  componentDidMount() {
    //this.props.getOrganizationDetails();
    const paths = window.location.pathname.split('/');
    if (paths && _.isArray(paths) && paths.length > 2 && paths[1] === '') {
      this.props.getThemeColor();
    }

    if (getContractingUnit()) {
      this.props.getCuDetails(getContractingUnit());
      this.setState({
        selectedCu: this.props.selectedCu,
      });
    } else {
      this.setState({
        selectedCu: null,
      });
    }

    if (getProject()) {
      if (this.props.selectedProject !== getProject()) {
        this.props.getProjectDefinition(getProject());
      } else {
        this.setState({
          selectedProject: this.props.selectedProject,
        });
      }
    } else {
      this.setState({
        selectedProject: null,
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.selectedProject !== this.props.selectedProject) {
      this.setState({
        selectedProject: this.props.selectedProject,
      });
    }
    if (prevProps.selectedCu !== this.props.selectedCu) {
      this.setState({
        selectedCu: this.props.selectedCu,
      });
    }
    if (this.props.match.url !== prevProps.match.url) {
      if (getContractingUnit()) {
        this.props.getCuDetails(getContractingUnit());
        this.setState({
          selectedCu: this.props.selectedCu,
        });
      } else {
        this.setState({
          selectedCu: null,
        });
      }
      if (getProject()) {
        //this.props.getProjectDefinition(getProject())
        if (
          this.props.selectedProject &&
          this.props.selectedProject?.sequenceCode !== getProject()
        ) {
          this.props.getProjectDefinition(getProject());
        } else {
          this.setState({
            selectedProject: this.props.selectedProject,
          });
        }
      }
    }
  }

  renderRedirect = () => {};

  organisationClicked = () => {
    history.push('/organisation/profile');
  };

  projectRedirect = () => {
    history.push('/project/');
  };

  QRCodeRedirect = () => {
    history.push('/qr/new');
  };
  timeClockRedirect = () => {
    history.push('/time_clock/');
  };

  render() {
    const { t } = this.props;
    const imageProps: Partial<IImageProps> = {
      imageFit: ImageFit.centerContain,
      width: 40,
      height: 40,
    };
    return (
      <header
        className="brand"
        style={{ backgroundColor: uPrinceTheme.palette.themePrimary }}
      >
        {/* <Icon iconName="Waffle" className="brandicon" /> */}
        <Image
          {...imageProps}
          className="brandicon"
          src={
            localStorage.getItem('logoUrl')
              ? localStorage.getItem('logoUrl')!!
              : ''
          }
          onMouseEnter={() => {
            this.setState({ hover: true });
          }}
          onMouseLeave={() => {
            this.setState({ hover: false });
          }}
          style={{
            ...(this.state.hover ? styles.hoverText : null),
          }}
          alt='Example of the image fit value "centerContain" on an image wider than the frame.'
          onClick={() => {
            history.push({
              pathname: '/',
              state: { isRoot: true },
            });
          }}
        />
        <label className="brandName">
          {this.props.organization ? this.props.organization : ''}
          {this.state.selectedCu ? ' > ' + this.state.selectedCu : ''}
          {this.state.selectedProject?.title
            ? ' > ' + this.state.selectedProject.title
            : ''}{' '}
        </label>

        <div className="meNavBar">
          {this.state?.selectedProject?.externalDataSyncSourceType ===
            EXTERNAL_DATA_SYNC_SOURCE_TYPE.ULTRANETTER && (
            <SyncStatusDropdown />
          )}
          <BrandNavBar />
        </div>

        <OverflowSet
          aria-label="Custom Example"
          role="menubar"
          className="settingicon"
          overflowItems={[
            // {
            //   key: 'organisation',
            //   name: t('organisation'),
            //   icon: 'MoveToFolder',
            //   onClick: this.organisationClicked,
            // },

            {
              key: 'buildNo',
              name:
                t('Build No') +
                `${ENV.BUILDMAJOR}.${ENV.BUILDMINOR}.${ENV.BUILDREVISION}`,
              icon: 'MoveToFolder',
              onRender: (
                item: any,
                dismissMenu: (ev?: any, dismissAll?: boolean) => void
              ) => {
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={{ marginTop: 2, marginLeft: 25 }}>
                        Version : {ENV.BUILDMAJOR}.{ENV.BUILDMINOR}.
                        {ENV.BUILDREVISION}
                      </Text>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={{ marginTop: 5, marginLeft: 25 }}>
                        Date : {ENV.RELEASEDATE}
                      </Text>
                    </div>
                  </div>
                );
              },
            },

            // {
            //   key: 'screenResolution',
            //   name:
            //     t('Screen Resolution') +
            //     ':' +
            //     screen.width +
            //     '*' +
            //     screen.height,
            //   icon: 'MoveToFolder',
            // },
          ]}
          onRenderOverflowButton={onRenderOverflowButton}
          onRenderItem={() => {}}
        />
        <ProfileDropdown />
      </header>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    organization: state.uprince.organization,
    selectedCu: state.uprince.selectedCu,
    selectedProject: state.project.selectedProject,
  };
};

const mapDispatchToProps = {
  getOrganizationDetails,
  getProjectDefinition,
  getCuDetails,
  getThemeColor,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Brand))
);
