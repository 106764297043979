import React from 'react';
import ProgressBar from '../../progressBar/progressBar';
import TitlePane from '../../titlePane/titlePane';
import {
  getTheme,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  Stack,
} from '@fluentui/react';
const theme = getTheme();
const addIcon: IIconProps = {
  iconName: 'Add',
  styles: { root: { fontSize: 11 } },
};
const iconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
    cursor: 'pointer',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const ListPaneSection = (props: {
  activeClass: string;
  titlePaneHeader: string;
  showProgress: boolean;
  renderCommandBar: any;
  renderShotcutPane: any;
  children: any;
  addUser?:boolean
}) => {
  const addUser = props?.addUser

  function addWorkerClick(){
    
  }

  return (
    <Stack horizontal style={{height: '100%'}}>
      {props.renderShotcutPane()}

      <div className={props.activeClass} style={{height: '100%'}}>
        <Stack
          className="project-list-header right-panel"
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            paddingRight: 0,
            // height: '100%'
          }}
          styles={{ root: { width: '100%', paddingRight: 0 } }}
        >
          <TitlePane
            headerTitle={props.titlePaneHeader}
            addUser={addUser}
            addWorkerClick={addWorkerClick()}
          />
          {props.renderCommandBar()}
          <ProgressBar show={props.showProgress}/>
        </Stack>

        <Stack style={{ height: 'calc(100% - 71px' }}>
          {props.children}
        </Stack>
      </div>
   
    </Stack>
  );
};

export default ListPaneSection;
