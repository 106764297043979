import {getTheme, ITheme, Panel, PanelType, Stack} from '@fluentui/react';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import SplitPane, {Pane} from 'react-split-pane';
import history from '../../history';
import {messageService} from '../../services/messageService';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import {getContractingUnit, getProject} from '../../shared/util';
import '../style/style.css';
import CommandBarLeft from './commandBar/commandBarLeft';
import withMainUI from '../../shared/mainUI/withMainUI';
import ResourceListPane from './resourceListPane/container';
import {
    addWorkersAndVehicleToPmol,
    clearFormData,
    readByDayPlanningId,
    readDayPlanningDropDownData,
    readDayPlanningShortCutPaneData,
    saveDayPlanningStateAttr,
} from '../../reducers/dayPlanningReducer';
import {
    DayPlanning,
    DayPlanningListItem,
    DayPlanningListPaneFilter,
    DayPlanningShortCutPaneItem,
    DocumentPaneType,
    formData,
} from '../../types/dayPlanning';
import TitlePane from '../../shared/titlePane/titlePane';
import ProgressBar from '../../shared/progressBar/progressBar';
import {PMOLShortcutPane} from "./shortCutPane/pmolShortcutPane/component";
import PmolListPaneComponent from './pmolListPane/component';
import {readPMOLDropDownData} from '../../reducers/pmolReducer';
import ResourceShortcutPane from './shortCutPane/resourceShortcutPane/component';
import _ from 'lodash';

export interface State {
    selectedShortCutItem: DayPlanningShortCutPaneItem;
    filter: DayPlanningListPaneFilter;
    formData: DayPlanning;
    loading: boolean;
    showPanel: boolean;
    width: number;
    activeClass: string;
    bookmarkDivClass: string;
    showDetails: boolean;
    autoSave: boolean;
    isEdit: boolean;
    isDisableNewButton: boolean;
    isDrag: boolean;
    resetLayout: boolean;
    collapseBookmarkPane: boolean;
    collapseBottomBookmarkPane: boolean;
    topReSizer: number;
    bottomReSizer: number;
    horizontalReSizer: number;
    projectId: null | string
    featureId: null | string
    documentPaneType: DocumentPaneType | null;
    isMinimize: boolean
    divClass: any
}

interface Props extends WithTranslation {
    match: any;
    resetFormData: () => void;
    showProgressBar: boolean;
    shortCutPaneFilters: DayPlanningShortCutPaneItem[];
    formData: DayPlanning;
    setUid: () => void;
    clearUid: () => void;
    resetIsChange: () => void;
    uid: string | null;
    autoSave: boolean;
    isChange: boolean;
    isEdit: boolean;
    isDataLoaded?: boolean;
    loadMsg: string;
    location: any;
    loading: boolean;
    handleShowDocumentPane: (collapseBookmarkPane?: boolean) => void;
    reSizer: number;
    showDetails: boolean;
    showPanel: boolean;
    handleScroll: () => void;
    currentActiveSection: string;
    animation: any;
    readDayPlanningShortCutPaneData: () => void;
    readDayPlanningDropDownData: () => void;
    readByDayPlanningId: (id: string) => void;
    clearFormData: () => void;
    shortcutPaneData: DayPlanningShortCutPaneItem[];

    dplanList: DayPlanningListItem[];
    isDPLANListLoaded: boolean;
    dplanDropDowns: any;
    reloadDPListPane: boolean;
    collapseBookmarkPane: boolean;
    addWorkersAndVehicleToPmol: any
    saveDayPlanningStateAttr: any
    readPMOLDropDownData: any
}

class DayPlanningMainLayout extends React.Component<Props, State> {
    _isMounted = false;
    theme: ITheme = getTheme();
    subscription: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            filter: new DayPlanningListPaneFilter(),
            selectedShortCutItem: {
                key: null,
                id: null,
                name: null,
                type: null,
                value: null,
            },
            isEdit: false,
            autoSave: false,
            loading: false,
            width: window.innerWidth,
            showPanel: false,
            activeClass: 'grid-righter ',
            bookmarkDivClass: 'bookmark-panel parent',
            showDetails: false,
            formData: formData,
            isDisableNewButton: false,
            isDrag: false,
            resetLayout: false,
            collapseBookmarkPane: false,
            collapseBottomBookmarkPane: false,
            topReSizer: screen.width,
            bottomReSizer: screen.width,
            projectId: null,
            horizontalReSizer: screen.height - 340,
            documentPaneType: null,
            featureId: null,
            isMinimize: true,
            divClass: 'filter-panel-left',
        };
    }

    componentDidMount() {
        this.handleUrlChange();
        this.props.readPMOLDropDownData();

        this.props.saveDayPlanningStateAttr('dayPlanningDate', new Date());
        this.props.saveDayPlanningStateAttr('dayPlanningWeek', {});
        // if (
        //   location.pathname.split('/').pop() === 'new' &&
        //   this.state.formData &&
        //   this.state.formData.id === null
        // ) {
        //     this.createNew();
        // }
        this.setState(
          {
              formData: this.props.formData,
          },
          this.props.handleShowDocumentPane,
        );
        this.subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.hideDocumentPane) {
                    this.hideDocumentPane();
                }

                // if (data.data.isNewDPLAN) {
                //     this.createNew();
                // }

                if (data.data.collapseDocumentPane) {
                    this.setState({
                        topReSizer: data.data.documentPaneType === 1 ? this.state.topReSizer === 300 ? screen.width / 2 : 300 : this.state.topReSizer,
                        bottomReSizer: data.data.documentPaneType === 2 ? this.state.bottomReSizer === 300 ? screen.width / 2 : 300 : this.state.bottomReSizer,
                        isDrag: !this.state.isDrag,
                        collapseBookmarkPane: data.data.documentPaneType === 1 ? !this.state.collapseBookmarkPane : this.state.collapseBookmarkPane,
                        collapseBottomBookmarkPane: data.data.documentPaneType === 2 ? !this.state.collapseBottomBookmarkPane : this.state.collapseBottomBookmarkPane,
                        resetLayout: !this.state.resetLayout,
                    });
                }

                if (data.data.minimizeBottomPane) {
                    this.setState({
                        horizontalReSizer: data.data.isMinimize ? screen.height - 330 : screen.height / 2 - 140,
                        isMinimize: data.data.isMinimize,
                    });
                }

                if (
                  data &&
                  data.data &&
                  data.data.hasOwnProperty('isLoadingPMOLData') &&
                  data.data.isLoadingPMOLData
                ) {
                    this.setState({isDisableNewButton: true});
                } else if (
                  data.data.hasOwnProperty('isLoadingPMOLData') &&
                  !data.data.isLoadingPMOLData
                ) {
                    this.setState({isDisableNewButton: false});
                }

                if (data.data.dndItem) {
                    this.addWorkersAndVehicleToPmol(data.data.dndItem);
                }
                if (data.data.fullScreen) {
                    this.props.saveDayPlanningStateAttr('dPlanFullScreen', true);
                }
            }
        });

        if (this.state.divClass === 'filter-panel-left') {
            if (window.innerWidth > 1300) {
                messageService.sendMessage({shortCutPaneExpanded: true});
            }
        } else {
            messageService.sendMessage({shortCutPaneExpanded: false});
        }

        if (_.isNull(getContractingUnit())) {
            this.props.saveDayPlanningStateAttr('isMyEnv', true);
        } else {
            this.props.saveDayPlanningStateAttr('isMyEnv', false);
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.reloadDPListPane !== prevProps.reloadDPListPane && this.props.reloadDPListPane) {
            this.reloadListPaneItem();
        }
        if (this.props.match.url !== prevProps.match.url) {
            this.handleUrlChange(prevState.collapseBookmarkPane);
        }
        if (this.props.formData !== prevProps.formData) {
            this.setState({
                formData: this.props.formData,
            });
        }
        if (
          this.props.location &&
          this.props.location.state &&
          this.state.projectId != this.props.location.state.selectedProject
        ) {
            if (this.props.location.state.selectedProject) {
                this.setState(
                  {
                      projectId: this.props.location.state.selectedProject,
                  },
                );
            }
        }
    }

    handleShowDocumentPane = (collapseBookmarkPane?: boolean) => {
        if (this.state.documentPaneType === DocumentPaneType.CU_HR || this.state.documentPaneType === DocumentPaneType.CPC) {
            this.setState({
                bottomReSizer: this.state.isEdit ? screen.width / 2 : screen.width,
                topReSizer: screen.width,
            });
        } else if (this.state.documentPaneType === DocumentPaneType.PMOL) {
            this.setState({
                topReSizer: this.state.isEdit ? screen.width / 2 : screen.width,
                bottomReSizer: screen.width,
            });
        }
        this.props.handleShowDocumentPane(collapseBookmarkPane);
    };

    handleUrlChange(collapseBookmarkPane?: boolean) {
        let id = this.props.match.params.featureId ? this.props.match.params.featureId : null;
        let isNew = location.pathname.split('/').pop() === 'new';
        if (id && id !== 'new') {
            this.setState(
              {
                  isEdit: !!this.props.match.params.featureId,
                  isDisableNewButton: !!id,
                  documentPaneType: this.getDocumentPaneType(),
                  featureId: id,
              }, () => {
                  this.handleShowDocumentPane(collapseBookmarkPane);
              });
            // this.props.readByDayPlanningId(id)
        } else {
            this.setState(
              {
                  isEdit: false,
                  isDisableNewButton: false,
              }, () => {
                  this.props.handleShowDocumentPane();
              });
        }
    }


    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    getDocumentPaneType = () => {
        if (window.location.href.indexOf('/pmol/') > -1) {
            return DocumentPaneType.PMOL;
        } else if (window.location.href.indexOf('/hr/') > -1) {
            return DocumentPaneType.CU_HR;
        } else if (window.location.href.indexOf('/cpc/') > -1) {
            return DocumentPaneType.CPC;
        } else {
            return null;
        }
    };

    createNew = (): void => {
        this.props.clearFormData();
        //this.props.setUid();
        let formData: any = {
            title: null,
            // ....
        };
        this.setState({
            formData: formData,
        });
        if (getProject()) {
            history.push(`/CU/${getContractingUnit()}/project/${getProject()}/day-planning`);
        } else {
            history.push(`/CU/${getContractingUnit()}/day-planning`);
        }
    };

    getListPaneHeight = (isTopPane?: boolean): number => {
        if (screen.width <= 1920) {
            return screen.height - (this.state.horizontalReSizer + 285);
        } else if (screen.width > 1920 && screen.width <= 2048) {
            return screen.height - (this.state.horizontalReSizer + 150);
        } else if (screen.width > 2048) {
            return screen.height - (this.state.horizontalReSizer + 200);
        } else {
            return screen.height - (this.state.horizontalReSizer + 285);
        }
    };

    reloadListPaneItem = () => {
        //
        //this.props.resetIsChange();
        setTimeout(() => {

        }, 100);
    };

    renderShortCutPane = (isFirst: boolean, isTopPane: boolean) => {
        if (isTopPane) {
            return <PMOLShortcutPane
              divClass={this.state.divClass}
              horizontalReSizer={this.state.horizontalReSizer}
            />
        } else {
            return <ResourceShortcutPane/>
        }
    };

    getDocumentPaneView = (isTopPane?: boolean) => {
        if (isTopPane) {
            return <div></div>
        }
    };

    private getListPaneView = (isTopPane?: boolean) => {
        if (isTopPane) {
            return (
              <div>
                  <TitlePane
                    headerTitle={"All"}
                    type={1}
                    isFullScreen={true}
                  />
                  <div style={{
                      height: isTopPane ? '100vh' : screen.height - (this.state.horizontalReSizer - 10),
                      overflow: 'overlay',
                  }}>
                      <Stack horizontal>
                          {this.renderShortCutPane(true, true)}

                          <div className={this.state.activeClass}>
                              <Stack
                                className="project-list-header right-panel"
                                style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingRight: 0,
                                }}
                                styles={{root: {width: '100%', paddingRight: 0}}}
                              >

                                  <CommandBarLeft isMyCalendarTop={true}/>
                                  <ProgressBar show={this.props.showProgressBar}/>
                              </Stack>

                              <Stack style={{alignSelf: 'center'}}>
                                  <PmolListPaneComponent/>
                              </Stack>
                          </div>
                      </Stack>
                  </div>
              </div>
            );
        } else {
            return (
              <div>
                  <TitlePane
                    headerTitle={'All'}
                    minimize={true}
                    isMinimize={this.state.isMinimize}
                    type={2}
                  />
                  <div style={{height: (this.getListPaneHeight() - 30), overflow: 'overlay'}}>
                      <Stack horizontal>
                          {this.renderShortCutPane(false, false)}

                          <div className={this.state.activeClass}>
                              <Stack
                                className="project-list-header right-panel"
                                style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingRight: 0,
                                }}
                                styles={{root: {width: '100%', paddingRight: 0}}}
                              >

                                  <CommandBarLeft isMyCalendarTop={false}/>
                                  <ProgressBar show={this.props.showProgressBar}/>
                              </Stack>

                              <Stack style={{alignSelf: 'center'}}>
                                  <ResourceListPane
                                    reSizer={this.state.bottomReSizer}
                                  />
                              </Stack>
                          </div>
                      </Stack>
                  </div>
              </div>
            );
        }

    };

    handleFilter = (filter: DayPlanningListPaneFilter) => {
        if ((filter.title && filter.title.length >= 3) || filter.title === null) {
            this.setState({
                filter: filter,
            }, this.reloadListPaneItem);
        } else {
            this.setState({
                filter: filter,
            });
        }
    };

    handelShortCutPaneFilter = (selectedItem: DayPlanningShortCutPaneItem) => {
        this.setState({
            selectedShortCutItem: selectedItem,
        });
    };

    addWorkersAndVehicleToPmol = (data: any) => {
        this.props.addWorkersAndVehicleToPmol({
            pmolId: data?.dropItem?.id,
            coperateProductCatalogId: data?.dragItem?.coperateProductCatalogId ? data?.dragItem?.coperateProductCatalogId : null,
            cabPersonId: data?.dragItem?.id ? data?.dragItem?.id : null,
        });
    };

    render() {
        const {t} = this.props;
        return (
          <div>
                  <SplitPane defaultSize={screen.height - 310} onChange={(size: any) => {
                      this.setState({horizontalReSizer: size});
                  }} maxSize={screen.height - 310} minSize={40} style={{position: 'inherit'}} split="horizontal"
                             size={this.state.horizontalReSizer}>
                      <div style={{width: '100%', height: '100%'}} className={'ms-Grid'}>
                          <SplitPane className={'SplitPane'}
                                     minSize={(screen.width / 3)}
                                     maxSize={(screen.width / 3) * 2}
                                     defaultSize={
                                         this.props.showDetails && this.state.documentPaneType === DocumentPaneType.PMOL ? screen.width / 2 : screen.width
                                     }
                                     split="vertical"
                                     onChange={(size: any) => {
                                         this.setState({topReSizer: size});
                                     }}
                                     size={this.state.topReSizer}
                          >
                              <Pane className={''} style={{
                                  width: '100%',
                                  height: `${(this.state.horizontalReSizer - 10)}px`,
                              }}>{this.getListPaneView(true)}</Pane>
                              <Pane className={''} style={{
                                  width: '100%',
                                  height: '100%',
                              }}> {this.state.documentPaneType === DocumentPaneType.PMOL && this.getDocumentPaneView(true)}</Pane>
                          </SplitPane>
                          <div></div>
                      </div>
                      <div style={{width: '100%'}} className={'ms-Grid'}>
                          <div id={'bottomPane'} style={{height: this.getListPaneHeight()}}>
                              <SplitPane
                                className={'SplitPane'}
                                minSize={(screen.width / 3)}
                                maxSize={(screen.width / 3) * 2}
                                defaultSize={
                                    this.props.showDetails && (this.state.documentPaneType === DocumentPaneType.CU_HR || this.state.documentPaneType === DocumentPaneType.CPC) ? screen.width / 2 : screen.width
                                }
                                split="vertical"
                                onChange={(size: any) => {
                                    this.setState({bottomReSizer: size});
                                }}
                                size={this.state.bottomReSizer}
                              >
                                  <Pane className={''}
                                        style={{width: '100%', height: '100%'}}>{this.getListPaneView()}</Pane>
                                  <Pane className={''} style={{width: '100%', height: '100%'}}>
                                      {this.getDocumentPaneView()}
                                  </Pane>
                              </SplitPane>
                          </div>
                      </div>

                  </SplitPane>
              <Panel
                isOpen={this.props.showPanel}
                type={PanelType.smallFixedFar}
                onDismiss={this._hidePanel}
                isLightDismiss={true}
                headerText={t('corporateProductCatalogue')}
                className="custom-detail-panel"
                closeButtonAriaLabel={t('close')}
              >
                  {this.getDocumentPaneView()}
              </Panel>

              <ConfirmationDialog
                hidden={true}
                title={t('areyousure')}
                subText={t('youwonTbeabletorevertthis')}
                onClickConfirmDialog={() => {
                }}
                onClickCancelDialog={() => {
                }}
                cancelButtonText={t('noIwonT')}
                confirmButtonText={t('yesDeleteit')}
              />
          </div>
        );
    }

    private _hidePanel = () => {
        this.setState({showPanel: false, showDetails: false});
        this.setState({topReSizer: screen.width});
        this.setState({bottomReSizer: screen.width});
        if (getProject()) {
            history.push(`/CU/${getContractingUnit()}/project/${getProject()}/day-planning`);
        } else {
            history.push(`/CU/${getContractingUnit()}/day-planning`);
        }
    };

    private hideDocumentPane = () => {
        this.setState({topReSizer: screen.width});
        this.setState({bottomReSizer: screen.width});
        if (getProject()) {
            history.push(`/CU/${getContractingUnit()}/project/${getProject()}/day-planning`);
        } else {
            history.push(`/CU/${getContractingUnit()}/day-planning`);
        }
    };
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        shortcutPaneData: state.dplan?.dplanShortCutPaneData,
        isDPLANListLoaded: state.dplan.isDPLANListLoaded,
        dplanList: state.dplan.dplanList,
        dplanDropDowns: state.dplan.dplanDropDowns,
        reloadDPListPane: state.dplan.reloadDPListPane,
        formData: state.dplan.formData,
        isEdit: state.dplan.isEdit,
        showProgressBar: state.dplan.showProgressBar,
    };
};

const mapDispatchToProps = {
    readDayPlanningShortCutPaneData,
    readDayPlanningDropDownData,
    readByDayPlanningId,
    clearFormData,
    addWorkersAndVehicleToPmol,
    saveDayPlanningStateAttr,
    readPMOLDropDownData,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(withMainUI((DayPlanningMainLayout))),
  ));
