import { FC, useEffect, useRef, useState, FormEvent } from 'react';
import {
  DatePicker,
  FontWeights,
  ChoiceGroup,
  getTheme,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  IChoiceGroupOption,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { cancelIconButtonStyles } from '../wbsCreate/documentPane/WbsCreateDocument';
import ProgressBar from '../../shared/progressBar/progressBar';
import XLSX from 'xlsx';
import Axios from 'axios';
import ENV from '../../../env.json';
import {
  CREATE_INSTRUCTION_EP,
  CREATE_LOCATION_EP,
  CREATE_TEAMS_FOLDER_FROM_CREATE_PBS,
  PBS_SAVE,
  SAVE_PBS_INSTRUCTION_EP,
  UPLOAD_DOCUMENTS_TO_TEAMS_EP,
  UPLOAD_PRODUCT_EXCEL,
} from '../../shared/endpoints';
import { getContractingUnit, getProject, addAllItem } from '../../shared/util';
import { Form } from 'react-final-form';
import {
  Dropdown,
  IDropdownOption,
  mergeStyles,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { analyzeCustomDocument } from '../../services/formRecognizerService';
import { useHistory } from 'react-router-dom';
import { Input } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid4 } from 'uuid';
import { saveVPStateAttr } from '../../reducers/visualPlanReducer';
import { messageService } from '../../services/messageService';
import {
  saveFormData,
  createTeamsFolder,
  readCbcToPbs,
} from '../../reducers/projectBreakdownReducer';
import { PENDING_DEVELOPMENT_STATUS_ID } from '../../types/projectMolecule';
import { projectInitialFormData } from '../../types/project';
import { PRODUCT_ITEM_TYPE_REG_CONTRACT_ID } from '../../types/projectBreakdownStructure';
import client from '../../api';
import moment from 'moment';
import i18n from '../../../i18n';
import { file } from 'jszip';
import { calFrenchDate, convertDateStringToYYYYMMDD, convertToDateString } from './utils';
  type TransWord = {'Titre'?:string ,'Référence'?:string , 'Date de début'?:string , 'Date de fin'?:string}

const theme = getTheme();
const dutchTrans:TransWord = {
  'Titre':'title',
  'Référence':'reference',
  'Date de début':'startDate',
  'Date de fin':'endDate'
}
interface RowData {
  [key: string]: any; // Allows access by column name
}
type htmType = 'title&ref' | 'start&end' | 'comments' | 'cbc' | 'cpc';
type ImportType = 'syndi' | 'vicky' | 'fluvius' | 'brutélé';
interface OptionSubject<T> {
  key?: string;
  text?: string;
  data?: { value: T };
}
interface ProjectModalProps {
  isOpen: boolean;
  onCloseModel: any;
  optionType: OptionSubject<ImportType>;
  from: 'product' | 'worker';
}
const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIconWhite: IIconProps = {
  iconName: 'Add',
  styles: addWhiteIconButtonStyles,
};
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const addIcon: IIconProps = { iconName: 'Add', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: {
    maxWidth: 400,
  },
  textContent: {
    padding: '15px 10px',
  },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: {
    width: '100%',
  },
});

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '0px 24px 14px 24px',
      textAlign: 'end',
    },
  ],
  body: {
    flex: '4 4 auto',
    display: 'flex',
    padding: '0 24px 0px 24px',
    overflowY: 'hidden',
    minWidth: '80vw',
    minHeight: '80vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
      preview: {
        width: '50%',
      },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const stackStyles: IStackStyles = {
  root: {
    padding: 0,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    paddingBottom: 10,
    flexGrow: 1,
    width: 0,
  },
};

const deleteIcon: IIconProps = { iconName: 'Delete' };

const modalStyles = {
  main: {
    height: '500px', // Customize the height
    width: '600px', // You can also adjust the width
  },
};

const dragAndDropStyles = mergeStyleSets({
  button: {
    height: '29px',
    marginLeft: '2px',
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    background: 'black',
  },
});

type ExcelData = (string | number)[][];
type fromType = 'product' | 'worker';
const AddCustomModal: FC<ProjectModalProps> = ({
  isOpen,
  onCloseModel,
  from,
  optionType,
}) => {
  // Define your colors
  const bg = 'white'; // Replace with your actual grey color
  const fill = 'rgb(242, 116, 34)'; // Replace with your actual primary color

  // Define the styles using mergeStyles
  const { t } = useTranslation();

  const styles = {
    wrapper: mergeStyles({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 15,
      marginBottom: 13,
      // height: '100vh',
      // backgroundColor: bg,
    }),
    hourglass: mergeStyles({
      display: 'block',
      // background:bg,
      // margin: '3em auto',
      width: '2em', // Increased width for better visibility
      height: '4em', // Increased height for better visibility
      marginRight: 8,
      animation: 'hourglass 1s linear infinite',
      // Adding animation styles here
    }),
    outer: mergeStyles({
      fill: fill,
    }),
    middle: mergeStyles({
      fill: bg,
    }),
  };

  const keyframes = `
  @keyframes hourglass {
      0% {
          transform: rotate(0deg);
          box-shadow: inset ${fill} 0 -0em 0 0, inset ${bg} 0 -2em 0 0, inset ${fill} 0 -4em 0 0;
      }
      80% {
          transform: rotate(0deg);
          box-shadow: inset ${fill} 0 -2em 0 0, inset ${bg} 0 -2em 0 0, inset ${fill} 0 -2em 0 0;
      }
      100% {
          transform: rotate(180deg);
          box-shadow: inset ${fill} 0 -2em 0 0, inset ${bg} 0 -2em 0 0, inset ${fill} 0 -2em 0 0;
      }
  }`;

  const lotOptions: IChoiceGroupOption[] = [
    { key: '0', text: t('TPA') },
    {
      key: '1',
      text: t('TPS'),
      styles: { field: { marginLeft: '15px' } },
    },
  ];
  const excelColumnNames = {
    Hoofdgemeente: 'Hoofdgemeente',
    PC: 'PC',
    Straat: 'Straat',
    Huisnr: 'Huisnr.',
    Busnr: 'Busnr.',
    RefnrE: 'Ref.nr E',
    RefnrG: 'Ref.nr G',
    RefnrKabelTV: 'Ref.nr Kabel TV',
  };
  const fromType: fromType = from;
  const addProductOptions: IDropdownOption[] = [
    { key: '0', text: t('Syndi'), data: { value: 'syndi' } },
    { key: '1', text: t('Vicky'), data: { value: 'vicky' } },
    { key: '2', text: t('Fluvius'), data: { value: 'fluvius' } },
    { key: '3', text: t(`Brutélé `), data: { value: 'brutélé' } },
  ];
  const addWorkerOptions: IDropdownOption[] = [
    {
      key: '0',
      text: t('Vario'),
    },
  ];
  const dispatch = useDispatch();
  const currentUser = useSelector<any, any>(
    (state) => state.uprince.currentUser
  );
  const [excelFileName, setExcelFileName] = useState<string | null>('');
  const [pdfFileName, setPdfFileName] = useState<string | null>('');
  const [htmFileName, setHtmFileName] = useState<string | null>('');
  const [excelFile, setExcelFile] = useState<any>(null);
  const [isProgress, setIsProgress] = useState(false);
  const [numberOfRecords, setNumberOfRecords] = useState('');
  const [data, setData] = useState<ExcelData>([]);
  const [dataObject, setDataObject] = useState({});
  const [pdf, setPdf] = useState(null);
  // const [toImport, setToImport] = useState<any>(importType);
  const [isHtm, setIsHtm] = useState(false);
  const [option, setOption] = useState<OptionSubject<ImportType>>(optionType);
  const [fileUrl, setFileUrl] = useState<string | null>('');
  const [mainTitle, setMainTitle] = useState<any>('');
  const [contractNr, setContractNr] = useState<any>('');
  const [finishedCount, setFinishedCount] = useState(0);
  const [loadCount, setLoadCount] = useState(0);
  const [showHourGlass, setShowHourGlass] = useState(false);
  const [excelData, setExcelData] = useState<RowData[]>([]);
  const [isPdf, setIsPdf] = useState(false);
  const [products, setProducts] = useState<any>({});
  const [lotId, setLotId] = useState('');
  const [selectedLot, setSelectedLot] = useState('0');
  const [cbcItems, setCbcItems] = useState<any>();
  const [cpcItems, setCpcItems] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [mandatoryFiles, setMandatoryFiles] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [htmData, setHtmData] = useState(null);
  //get categories for Lotid
  const [categories,setCategories] = useState(null)
  useEffect(() => {
    JSON.stringify(dataObject, null, 2);
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(keyframes));
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [data, dataObject]);

  useEffect(() => {
    setExcelFileName('');
    setPdfFileName('');
    setHtmFileName('');
    setNumberOfRecords('');
    setFileUrl(null);
    setIsPdf(false);
    setProducts({});
    setIsHtm(false);
    setHtmData(null);
  }, []);

  useEffect(() => {
    if (lotId) {
      if (lotId.includes('TPA')) {
        setSelectedLot('0');
      } else setSelectedLot('1');
    }
  }, [lotId]);

  useEffect(() => {
    if(cpcItems.length){

    }
  },[cpcItems])

  useEffect(() => {
    console.log(products);
  }, [products]);

  // uploadAPI calls when the formType is product and importType is syndhi
  const uploadToAPI = async (file: any) => {
    const formData = new FormData(); // Create a FormData object
    formData.append('file', file);
    const response = await Axios({
      method: 'POST',
      url: `${ENV.BASE_URL}/${UPLOAD_PRODUCT_EXCEL}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
        Cu: getContractingUnit(),
        Project: getProject(),
        'Content-Type': 'multipart/form-data',
        'X-API-Key': 'ae43693a-1006-45c6-b48a-d0747240d2ed',
        ProcessingNotificationEmail: currentUser.user.email,
      },
    });
    setIsProgress(false);
    if (response.status === 200) {
      const { result } = response.data;
      setNumberOfRecords(result.numberOfRecords);
    }
  };

  const onWorkerUpload = async () => {};

  const handleDropExcel = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        setExcelFileName(file.name);
        handleExcelFileChange(file, true);
      } else {
        alert('Please upload a valid Excel file.');
      }
    }
  };

  const handleDropHtm = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
    const files = event.dataTransfer.files;
    console.log()
    if (files.length > 0) {
      const file = files[0];
      if (file.type === "text/html" || file.name.endsWith('.html')) {
        setHtmFileName(file.name);
        handleHtmFileChange(file);
      } else {
        alert('Please upload a valid Html file.');
      }
    }
  };

  const handleDropPdf = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type === 'application/pdf') {
        setPdfFileName(file.name);
        handlePdfFileChange(file, true);
      } else {
        alert('Please upload a valid Excel file.');
      }
    }
  };

  const handleDropAttachements = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles: any) => [...prevFiles, ...droppedFiles]);
  };

  const handlePdfFileChange = (file: any, isAddproduct: boolean = true) => {
    setPdfFileName(file.name);
    setIsPdf(true);
    setMandatoryFiles((prevFiles: any) => [...prevFiles, file]);
    const uploadedFile = file;
    if (uploadedFile && uploadedFile.type === 'application/pdf') {
      setPdf(uploadedFile);
      const url = URL.createObjectURL(uploadedFile);
      setFileUrl(url);
    } else {
      alert('Please upload a valid PDF file.');
    }
    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        try {
          const response: any = await analyzeCustomDocument(arrayBuffer);
          isAddproduct && constructTheField(response.documents[0].fields);
        } catch (error) {
          console.error('Error during document analysis:', error);
        } finally {
        }
      };
    }
  };

  const constructTheField = (data: any) => {
    const keys = Object.keys(data);

    setProducts(data);
  };

  const handleExcelFileChange = (file: any, isAddproduct: boolean = true) => {
    setIsPdf(false);
    setIsProgress(true);
    setMandatoryFiles((prevFiles: any) => [...prevFiles, file]);
    if (isAddproduct) {
      setExcelFileName(file.name);
      setExcelFile(file);
    }
    const reader = new FileReader();
    let jsonData: any;
    reader.onload = async (event) => {
      try {
        const binaryStr = event.target?.result; // Optional chaining to avoid null reference
        const workbook = XLSX.read(binaryStr, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        if (from === 'product') {
          jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          }) as ExcelData;
        } else {
          jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: null,
          }) as ExcelData;
        }
        console.log(jsonData);
        const getValueFromJasonData = jsonData.map((arr: any, index: any) => {
          if (!arr[2]) return;
          return arr;
        });
        setData(jsonData);
        if (isAddproduct && from === 'product') {
          if (option?.data?.value === 'syndi' && fromType === 'product') {
            //call only in Sindhi and add product
            await uploadToAPI(file);
          } else {
            await onWorkerUpload();
          }
          setLotId(jsonData[1][0]);
          let tempArray: any[] = [];
          let createCbcItem: any[] = [];
          const transformedData = jsonData.reduce((acc: any, row: any) => {
            if (row.length >= 34) {
              tempArray.push(row);
            }
            // Example: Group rows by a "Category" column
            const category = row.Category || 'Uncategorized';
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push(row);
            return acc;
          }, {});
          tempArray.shift();
          tempArray.map((strArr) => {
            const obj: any = {};
            obj['realArticleNo'] = strArr[0];
            obj['lotId'] = selectedLot === '0' ? 'TPA' : 'TPS';
            obj['pbsId'] = null;
            obj['quantity'] = strArr[30].replace(',', '.');
            createCbcItem.push(obj);
          });
          setCbcItems(createCbcItem);
          setDataObject(transformedData);
        } else {
          const userArray: any[] = [];
          // 1 hardCoded because in index 1 only we will get the header array
          const headers = getValueFromJasonData[1];
          //Calculate the how many users are present neglecting the empty spaces
          const userRows = getValueFromJasonData.filter((str: any) => str);
          // here intializing with 2 because index 0 is full of empty and index 1 is header thats why we are starting from 2
          for (let i = 2; i < userRows.length; i++) {
            const row = getValueFromJasonData[i];
            const user: any = {};
            for (let j = 0; j < headers.length; j++) {
              user[headers[j]] = row[j] || '';
            }
            userArray.push(user);
          }
          setUsers(userArray);
        }
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
      setIsProgress(false);
    };
    if (file instanceof Blob) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsBinaryString(file?.target?.files[0]);
    }

    // for excel iterate & create product
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryStr = e.target?.result;
        if (binaryStr) {
          // Read workbook
          const workbook = XLSX.read(binaryStr, { type: 'binary' });

          // Use the first sheet
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          // Convert sheet to JSON format with column headers as keys
          const jsonData = XLSX.utils.sheet_to_json<RowData>(sheet, {
            header: 1,
          });

          // Extract headers and data separately
          const headers = jsonData[0];
          const rows = jsonData.slice(1);

          // Map rows to objects with headers as keys
          const rowData = rows.map((row) =>
            headers.reduce((acc: any, header: any, idx: any) => {
              acc[header as string] = row[idx];
              return acc;
            }, {} as RowData)
          );

          setExcelData(rowData);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  const handleHtmFileChange = (file: any) => {
    setIsHtm(true);
    setHtmFileName(file.name);
    setHtmData(file);
    setMandatoryFiles((prevFiles: any) => [...prevFiles, file]);
    if (file && file.type === 'text/html') {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        console.log(e)
        const htmlContent = e.target.result;
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        console.log(doc);
        const iframe: any = document.getElementById('previewFrame');
        iframe.srcdoc = e.target.result; // Load the HTML content into the iframe
        // Use querySelector to find the nested element within Header1Div
        const total_elements = doc.querySelectorAll('.OT_Body');
        // OT_body1
        const ot_container_1 = total_elements[0].querySelector('.OT_Container');
        const table_ot_container_1 = ot_container_1?.querySelectorAll('table');
        // getTitleAndReference
        const titleAndRefTableRows =
          table_ot_container_1 &&
          table_ot_container_1[0]?.querySelectorAll('tr');
        const titlEAndRefobj = getKeyValueFromElement(
          titleAndRefTableRows,
          'title&ref'
        );
        console.log(titlEAndRefobj);
        // getStartDateAndEndDate
        const startDateAndEndDateRows =
          table_ot_container_1 &&
          table_ot_container_1[1]?.querySelectorAll('tr');
        const startAndEndDateObj = getKeyValueFromElement(
          startDateAndEndDateRows,
          'start&end'
        );
        console.log(startAndEndDateObj);
        // OT_body2
        const ot_container = total_elements[1].querySelector('.OT_Container');
        const table_ot_container_2 = ot_container?.querySelector('table');
        //getComments
        const comments =
          table_ot_container_2 && table_ot_container_2.querySelectorAll('td');
        const commentsObj = getKeyValueFromElement(comments, 'comments');
        console.log(commentsObj);
        console.log({
          ...titlEAndRefobj,
          ...startAndEndDateObj,
          ...commentsObj,
        });
        //getCBCItems OT_body_2
        const ot_container_3 = total_elements[2].querySelector('.OT_Container');
        const table_ot_container_3 = ot_container_3?.querySelector('table');
        const tbody = table_ot_container_3?.querySelector('tbody');
        const tr = tbody?.querySelectorAll('tr');
        const getCPC = getKeyValueFromElement(tr, 'cpc');
        const getCBC = getKeyValueFromElement(tr, 'cbc',getCPC[0].category);
        setProducts((prev: any) => ({
          ...prev,
          ...titlEAndRefobj,
          ...startAndEndDateObj,
          ...commentsObj,
          cpc: getCPC,
          cbc: getCBC,
        }));
      };

      reader.readAsText(file); // Read the file as text
    } else {
      alert('Please upload a valid HTML file.');
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const getKeyValueFromElement = (ele: any, type?: htmType,category?:string) => {
    let tempObj: any = {};
    let value: string;
    let prevKey: string;
    if (type && ['title&ref', 'start&end'].includes(type)) {
      [...ele].map((tr: any, i: number) => {
        [...tr.querySelectorAll('td')].map((td: any, j: number) => {
          // this is used only for getting title and ref (i<2)
          if (i < 2) {
            if (!j) {
              value = td.querySelector('label').textContent;
              const title = value.trim().split('');
              title.pop();
              const strTitle = title.join('').trim();
              prevKey = strTitle;
              tempObj[strTitle] = null;
            } else {
              value = td.querySelector('span').textContent;
              if (type === 'start&end') {
                const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
                const date = new Date(normalizedValue);
                value = date.toLocaleDateString('fr-FR', {
                  day: '2-digit', 
                  month: '2-digit',
                  year: 'numeric',
                })
              }
              prevKey && (tempObj[prevKey] = value);
            }
          }
        });
      });
    } else if (type === 'comments') {
      [...ele].map((td: any, i: number) => {
        if (!i) {
          value = td.textContent;
          console.log(value);
          const title = value.trim().split('');
          title.pop();
          const strTitle = title.join('').trim();
          prevKey = strTitle;
          tempObj[strTitle] = null;
        } else {
          let headHtmlEle = td.querySelector('.dijitTitlePane');
          prevKey && (tempObj[prevKey] = headHtmlEle.innerHTML);
        }
      });
    } else if (type === 'cbc') {
      const tempArray: any[] = [];
      Array.from(ele).map((tr: any, i: number) => {
        Array.from(tr.querySelectorAll('.SectionEnd')).map(
          (td: any, j: number) => {
            const tempObj: any = {};
            const title = td.querySelector('.OT_PM_SectionTitle_1 span');
            const quantity = td.querySelectorAll(
              '.OT_PM_SectionTitle_2 span'
            )[2]?.textContent;
            const split_title = title.textContent.split(' ');
            const realArticleNumber = split_title[0];
            tempObj['realArticleNo'] = `${category}${realArticleNumber}.`;
            tempObj['lotId'] = null;
            tempObj['pbsId'] = null;
            tempObj['quantity'] = quantity;
            tempArray.push(tempObj);
          }
        );
      });
      setCbcItems(tempArray);
      return tempArray;
    } else if (type === 'cpc') {
      const tempArray: any[] = [];
      Array.from(ele).map((tr: any, i: number) => {
        const tempObj: any = {};
        const title = tr.querySelectorAll('.xspColumn span')[0]?.textContent;
        const category = tr.querySelectorAll('.xspColumn span')[1]?.textContent;
        const vendorId = tr.querySelectorAll('.xspColumn span')[2]?.textContent;
        const plannedQuantity = tr.querySelectorAll('.xspColumn span')[3]?.textContent;
        tempObj['resourceId'] = null;
        tempObj['pbsProductId'] = null;
        tempObj['coperateProductCatalogId'] = null;
        tempObj['quantity'] = plannedQuantity;
        tempObj['sequenceCode'] = null;
        tempObj['cpcText'] = null;
        tempObj['cpcKey'] = null;
        tempObj['resourceFamilyTitle'] = null;
        tempObj['unit'] = null;
        tempObj['cpcTitle'] = title;
        tempObj['environment'] = 'cu';
        tempObj['consolidatedQuantity'] = null;
        tempObj['resourceNo'] = vendorId;
        tempObj['category'] = category
        tempArray.push(tempObj);
      });
      setCpcItems(tempArray);
      return tempArray;
    }
    return tempObj;
  };

  const modalClose = () => {
    // setToImport('0');
    setExcelFileName('');
    setPdfFileName('');
    setNumberOfRecords('');
    setFileUrl(null);
    setIsPdf(false);
    onCloseModel(option);
    setData([]);
  };

  const onProductChange = (e: any, key: any, from: 'htm' | 'pdf') => {
    const tempProducts = { ...products };
    from === 'pdf'
      ? (tempProducts[key].value = e.target.value)
      : (tempProducts[key] = e.target.value);
    console.log(tempProducts);
    setProducts((prev: any) => ({ ...prev, ...tempProducts }));
  };

  const onCardClick = (type: string, file: any) => {
    console.log(file, type);
    type === 'pdf' ? setIsPdf(true) : setIsPdf(false);
    type === 'pdf' && handlePdfFileChange(file);
    type === 'excel' && handleExcelFileChange(file);
  };

  const onImportChange = (
    event: React.FormEvent<HTMLDivElement>,
    option: any
  ) => {
    setOption(option);
    setProducts({})
    setIsPdf(false)
    setIsHtm(false)
    setData([])
    setExcelFileName('')
    setPdfFileName('')
    setHtmFileName('')
    // setToImport(option.key);
  };

  const deleteExcel = () => {
    findAndDeleteFile('excel');
    setExcelFileName('');
    setExcelFile([]);
    setNumberOfRecords('');
    setData([]);
  };

  const deleteHtmlFile = () => {
    setHtmFileName('');
    setIsHtm(false);
    setHtmData(null);
    setProducts({});
    setMandatoryFiles([])
  };

  const findAndDeleteFile = (type: string) => {
    console.log(mandatoryFiles);
    const remainingFiles = mandatoryFiles.filter((obj: any, index: number) => {
      const isExcel =
        (obj?.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          obj?.type === 'application/vnd.ms-excel' ||
          obj?.name.endsWith('.xlsx') ||
          obj?.name.endsWith('.xls')) &&
        type === 'excel';

      const isPDF =
        (obj?.type === 'application/pdf' || obj?.name.endsWith('.pdf')) &&
        type === 'pdf';
      return !(isExcel || isPDF);
    });
    setMandatoryFiles(remainingFiles);
  };

  const deletePdf = () => {
    findAndDeleteFile('pdf');
    setPdfFileName('');
    setPdf(null);
  };

  const deleteFile = () => {
    setFiles([]);
  };

  const dateToString = (date: any) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const stringToDate = (dateString: any) => {
    if(dateString){
    const date = dateString.split(' ');
    if (date.length > 1) {
      date.pop();
      const [day, month, year] = date[0]?.split('/');
      return new Date(year, month - 1, day);
    }
    const [day, month, year] = dateString?.split('/');
    return new Date(year, month - 1, day);}
  };

  const newstringToDate = (dateString: any) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day);
  };

  const handleDateChange = (date:any,product:any) => {
    const tempProducts = { ...products };
    tempProducts[product] = convertDateStringToYYYYMMDD(date)
    setProducts((prev: any) => ({ ...prev, ...tempProducts }));
  }

  const addReviewer = async (productId: string) => {
    const payLoad = {
      id: productId,
      qualityReviewer:
        products['necessaire'].content === 'Oui'
          ? 'Sabri Atay'
          : 'Stessy Delguste',
      qualityReviewerId:
        products['necessaire'].content === 'Oui'
          ? '73b34e18-23a6-47d1-86b8-17ac052c6017'
          : '73b34e18-23a6-47d1-86b8-17ac052c6018',
    };
    try {
      const response = await Axios({
        method: 'PATCH',
        url: `${ENV.BASE_URL}/Pbs/UpdateProductSW`,
        data: payLoad,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
          Cu: getContractingUnit(),
          Project: getProject(),
          'Content-Type': 'application/json',
          lang: 'en-US',
          'X-API-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6',
        },
      });
      return response?.data?.result;
    } catch (err) {
      console.log('error getting project details');
    }
  };

  const onSubmit = async () => {
    if (option?.data?.value) {
      const projectId = getProject();
      if (projectId === null || projectId === undefined || projectId === '') {
        return;
      }
      setShowHourGlass(true);
      if (pdf && data.length) {
        const address = `${products['Commune']?.value} , ${products['code postal'].value}`;
        let addressRes;
        try {
          addressRes = await getPositions(address);
        } catch (err) {
          console.log('Cant able to get positions');
        }
        let addressPayload;
        try {
          addressPayload = mapLocationPayload(addressRes);
        } catch (err) {
          console.log('Cant able to get map location');
        }
        let addressDetails;
        try {
          addressDetails = await client.post(
            CREATE_LOCATION_EP,
            addressPayload
          );
        } catch (err) {
          console.log(err);
        }

        const payLoadData = {
          id: uuid4(),
          name: `${decodeURIComponent(products['Ordre de travail'].value)} - ${
            products['Type Dossier'].value
              ? decodeURIComponent(products['Type Dossier'].value)
              : ''
          } - ${decodeURIComponent(
            products['Commune'].value ?? ''
          )} , ${decodeURIComponent(products['code postal'].value ?? '')}`,
          startDate: calculateDate(
            products['Date de transfert'].value,
            products['necessaire'].content,
            'start'
          ),
          endDate: calculateDate(
            products['Date de transfert'].value,
            products['necessaire'].content,
            'end'
          ),
          contract: products['Ordre de travail'].value,
          productPurpose: createProductPurpose(products),
          productDerivation: createProductComposition(products),
          productFormatPresentation: createProductFormatPresentation(products),
          pbsProductStatusId: 'd60aad0b-2e84-482b-ad25-618d80d49477',
          pbsToleranceStateId: '004eb795-8bba-47e8-9049-d14774ab0b18',
          pbsToleranceState: 'Within Tolerance',
          pbsProductStatus: 'Pending Development',
          pbsLocation: addressDetails?.data?.result,
          qualityApproverId: null,
          qualityApprover: null,
          qualityProducerId: null,
          qualityProducer: null,
          // qualityReviewer: 'Harisankaran (CBT)',
          // qualityReviewerId: '6a74ddf9-b92f-4d4e-a65b-7ec38ed571c2',
          // qualityReviewer: products['necessaire'].content === 'Oui' ?  "Harisankaran (CBT)":"Stessy Delguste",
          // qualityReviewerId:products['necessaire'].content === 'Oui' ?  "6a74ddf9-b92f-4d4e-a65b-7ec38ed571c2" :  "73b34e18-23a6-47d1-86b8-17ac052c6018",
          projectId: getProject(),
          pbsType: 'regular',
        };

        try {
          const createProduct = await createProductApi(projectId, payLoadData);
          const productId = createProduct.id;
          const pbsId = createProduct.productId;
          try {
            await addReviewer(productId);
          } catch (err) {
            console.log(err);
          }
          try {
            await addCbcToProduct(productId, 'excel');
          } catch (err) {
            console.log('Error While adding CBC to product');
          }
          const instructionDetails:any = await createSharePointAndInstruction(payLoadData,projectId,createProduct.title)
          console.log(instructionDetails)
          if (instructionDetails.id) {
            try {
              const linkToPbs = await linkInstruction(
                productId,
                instructionDetails.id
              );
              if (linkToPbs.message === 'Ok') {
                modalClose();
                setTimeout(() => {
                  dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                  dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
                  dispatch(saveVPStateAttr('slectedPBSForBor', null));
                  dispatch(saveVPStateAttr('selectedPBS', pbsId));
                  dispatch(readCbcToPbs(productId));
                }, 1500);
              }
            } catch (err) {
              console.log('Error While Linking Instruction');
            }
          }
        } catch (err) {
          console.log('Some of the Api fails', err);
        } finally {
          setShowHourGlass(false);
        }
      } else if (isHtm) {
        const address = `${products['Titre']}`;
        let addressRes;
        try {
          addressRes = await getPositions(`${address  },Belgium`);
        } catch (err) {
          console.log('Cant able to get positions');
        }
        let addressPayload;
        try {
          addressPayload =  mapLocationPayload(addressRes);
        } catch (err) {
          console.log('Cant able to get map location');
        }
        let addressDetails;
        try {
          addressDetails = await client.post(
            CREATE_LOCATION_EP,
            addressPayload
          );
        } catch (err) {
          console.log(err);
        }
        const payload = {
          id: uuid4(),
          name: `${products['Référence']} - ${products['Titre']}`,
          contract: `${products['Référence']}`,
          startDate: convertToDateString(`${products['Date de début']}`),
          endDate: convertToDateString(`${products['Date de fin']}`),
          productPurpose: `${products['Commentaire']}`,
          pbsLocation: addressDetails?.data?.result,
          projectId: getProject(),
          pbsType: 'regular',
          pbsProductStatusId: 'd60aad0b-2e84-482b-ad25-618d80d49477',
          pbsToleranceStateId: '004eb795-8bba-47e8-9049-d14774ab0b18',
          pbsToleranceState: 'Within Tolerance',
          pbsProductStatus: 'Pending Development',
        };
        try {
          const createProduct = await createProductApi(projectId, payload);
          const productId = createProduct.id;
          const pbsId = createProduct.productId;
          try {
            await addCbcToProduct(productId, 'html');
          } catch (err) {
            console.log(err);
          }
          try {
            await addCpcToProduct(productId);
          } catch (err) {
            console.log(err);
          }
          const instructionDetails:any = await createSharePointAndInstruction(payload,projectId,createProduct.title)
          console.log(instructionDetails)
          if (instructionDetails.id) {
            try {
              const linkToPbs = await linkInstruction(
                productId,
                instructionDetails.id
              );
              if (linkToPbs.message === 'Ok') {
                modalClose();
                setTimeout(() => {
                  dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
                  dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
                  dispatch(saveVPStateAttr('slectedPBSForBor', null));
                  dispatch(saveVPStateAttr('selectedPBS', pbsId));
                  dispatch(readCbcToPbs(productId));
                }, 1500);
              }
            } catch (err) {
              console.log('Error While Linking Instruction');
            }
          }
        } catch (err) {
          console.log('error calling api');
        }
      } else {
        return alert('Please upload the documents');
      }
    } else {
    }
  };

  const createSharePointAndInstruction = async(payLoadData:any,projectId:any,title:any) => {
    let drive_Id_from_project_def, parentFolderDriveItemId;
    try {
      const projectDefREs = await projectDefinition();
      drive_Id_from_project_def = projectDefREs.driveId;
      parentFolderDriveItemId = projectDefREs.projectFolderDriveItemId;
    } catch (err) {
      console.log('Error on calling project definition');
    }
    let parentId, driveId;
    try {
      const createTeamsFolder = await createTeamFolder(
        payLoadData.name,
        drive_Id_from_project_def,
        parentFolderDriveItemId
      );
      ({ id: parentId, driveId } = createTeamsFolder.folderDetails);
    } catch (error) {
      console.error('Error creating teams folder:', error);
    }
    try {
      await moveFilesToTeamsFolder(parentId, driveId);
    } catch (err) {
      console.log('Error Occured while moving files to folder');
    }
    let creatreSharable;
    try {
      creatreSharable = await createSharableLink(
        projectId,
        driveId,
        parentId
      );
    } catch (error) {
      console.error('Error creating sharable link:', error);
    }
    let instructionDetails;
    try {
      instructionDetails = await createInstrution(
        creatreSharable?.link?.webUrl,
        title
      );
    } catch (err) {
      console.error('Error creating instruction details:', err);
    }
    return instructionDetails
  }

  const moveFilesToTeamsFolder = async (parentId: string, driveId: string) => {
    const Totalfiles = [...mandatoryFiles, ...files];
    Totalfiles.forEach(async (file: any, index: any) => {
      const formData = new FormData();
      formData.append('files', file);
      let config: any = {
        method: 'post',
        maxBodyLength: Infinity,
        url: UPLOAD_DOCUMENTS_TO_TEAMS_EP,
        headers: {
          DriveId: driveId,
          RootFolderDriveId: parentId,
          'X-API-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6',
        },
        data: formData,
      };
      await client
        .request(config)
        .then((res) => console.log('res', res))
        .catch((err) => console.log('err', err));
    });
  };

  async function projectDefinition() {
    const projectDetails = {
      project: getProject(),
    };
    try {
      const response = await Axios({
        method: 'GET',
        url: `${ENV.BASE_URL}/ProjectDefinition/ReadById/${getProject()}`,
        data: projectDetails,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
          Cu: getContractingUnit(),
          Project: getProject(),
          'Content-Type': 'application/json',
          lang: 'en-US',
        },
      });
      console.log(response);
      return response?.data?.result;
    } catch (err) {
      console.log('error getting project details');
    }
  }

  const getPositions = async (address: any) => {
    try {
      const fetchAddressUrl = 'https://atlas.microsoft.com/search/address/json';
      const response = await fetch(
        `${fetchAddressUrl}?api-version=1.0&subscription-key=${
          ENV.MAP_KEY
        }&query=${encodeURIComponent(address)}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('Positions can`t be get');
    }
    return null;
  };

  async function addCbcToProduct(productId: any, from: 'html' | 'excel') {
    if (cbcItems) {
      cbcItems.forEach((obj: any) => {
        obj['pbsId'] = productId;
        obj['lotId'] =
          from === 'html' ? null : selectedLot === '0' ? 'TPA' : 'TPS';
      });
      console.log(cbcItems);
      const dataObj = {
        isPdfUpload: true,
        pbsCbcResources: cbcItems,
      };
      try {
        const response = await Axios({
          method: 'POST',
          url: `${ENV.BASE_URL}/Pbs/AddPbsCbcResource`,
          data: dataObj,
          headers: {
            Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
            Cu: getContractingUnit(),
            Project: getProject(),
          },
        });
      } catch (err) {
        console.log('Can`t able to add CBC Item', err);
      }
    }
  }

  async function addCpcToProduct(productId:any) {
    const data: any[] =  cpcItems.map((obj:any) => {
      obj['pbsProductId'] = productId
      return obj
    });
    // let obj = data[0]
    // obj['resourceNo']='024003'
    try {
      const response = await Axios({
        method: 'POST',
        url: `${ENV.BASE_URL}/PbsResource/CreatePbsMaterial`,
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
          Cu: getContractingUnit(),
          Project: getProject(),
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  async function linkInstruction(productId: string, instructionsId: string) {
    const payload = {
      id: uuid4(),
      pbsProductId: productId,
      instructionsId: instructionsId,
    };
    try {
      const response = await client.post(SAVE_PBS_INSTRUCTION_EP, payload);
      return response?.data;
    } catch (error) {
      console.log('linkInstructionToPbs is failed ', error);
    }
    return null;
  }

  async function createTeamFolder(
    folderName: any,
    drive_Id: any,
    parentFolderDriveItemId: any
  ) {
    const data = JSON.stringify({});
    const name = folderName.split('-');
    const number = name.shift();
    const num = number.replace('/', '-');
    const structuredText = num + name.join('-');

    let config: any = {
      method: 'post',
      maxBodyLength: Infinity,
      url: CREATE_TEAMS_FOLDER_FROM_CREATE_PBS,
      headers: {
        'X-API-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6',
        // 'Project': getProject(),
        FolderName: structuredText,
        ProductGuid: uuid4(),
        InstructionType: 'text',
        DriveId: drive_Id,
        // DriveId: 'b!f0Uk5osDokCvD_htqaHZIiH7EwNEz65Bud7whpl8S7YvDjg9y5tcRKXMCh1I1SKI',
        ParentFolderDriveItemId: parentFolderDriveItemId,
        // ParentFolderDriveItemId: '01HPB2YBFTOSCEMNSM4RF3HJOO3H2R6VM3',
        'Content-Type': 'application/json',
      },
      data: data,
    };
    try {
      const response = await client.request(config);
      return response?.data.result;
    } catch (error) {
      console.log('createTeamsFolder is failed ', error);
    }
    return null;
  }

  const createProductComposition = (products: any) => {
    // if (products) {
    //   return `<div>
    //   <div>${i18n.t('Remarques sur le chantier')}</div>
    //   <div>${products['Remarques sur chantier'].value}</div>
    //   </div>`;
    // } else return `<></>`;
    return `<></>`;
  };

  const createProductFormatPresentation = (products: any) => {
    const tempArray = ['Demandeur', 'GSM', 'mail'];
    if (products) {
      return tempArray
        .map(
          (str: any) =>
            `<p><strong>${str}:</strong> ${products[str]?.value ?? 'N/A'}</p>`
        )
        .join('');
    } else return `<></>`;
  };

  const createProductPurpose = (products: any) => {
    const tempArray = [
      'Powalco Id',
      'Date de transfert',
      'Type Dossier',
      'Type Client',
      'Type Batiment',
      'Type Reseau',
      'Type de flux chantier',
      'priorite',
      'necessaire',
      'Remarques sur chantier',
    ];
    if (products) {
      return tempArray
        .map(
          (str: any) =>
            `<p><strong>${str}:</strong> ${products[str]?.value ?? 'N/A'}</p>`
        )
        .join('');
    } else return `<></>`;
  };

  const calculateDate = (date: any, type: string, dateType: String) => {
    const tempDate = date.split(' ');
    let daysToAdd;
    let initialDate;
    if (tempDate.length > 1) {
      tempDate.pop();
      initialDate = moment(tempDate[0], 'DD/MM/YYYY');
    } else {
      initialDate = moment(tempDate, 'DD/MM/YYYY');
    }
    if (dateType === 'start') {
      daysToAdd = type === 'Oui' ? 15 : 5;
    } else {
      daysToAdd = type === 'Oui' ? 25 : 10;
    }
    // Number of working days to add
    let currentDate = initialDate.clone();
    // Add days, skipping weekends
    while (daysToAdd > 0) {
      currentDate.add(1, 'days'); // Move to the next day
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        // Decrement the counter only if it's a working day
        daysToAdd--;
      }
    }
    console.log(currentDate);
    return currentDate.format('YYYY-MM-DD');
  };

  const createTeamsFolder = async (folderName: string) => {
    const data = JSON.stringify({});
    let config: any = {
      method: 'post',
      maxBodyLength: Infinity,
      url: CREATE_TEAMS_FOLDER_FROM_CREATE_PBS,
      headers: {
        'X-API-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6',
        // 'Project': getProject(),
        FolderName: decodeURIComponent(folderName),
        ProductGuid: uuid4(),
        InstructionType: 'text',
        DriveId: '',
        ParentFolderDriveItemId: '',
        'Content-Type': 'application/json',
      },
      data: data,
    };

    try {
      const response = await client.request(config);
      return response?.data?.result;
    } catch (error) {
      console.log('createTeamsFolder is failed', error);
    }
    return null;
  };

  const createSharableLink = async (
    projectId: string,
    driveId: string,
    driveItemId: string
  ) => {
    try {
      const response = await client.get('Wbs/GetLinkFromTeams', {
        headers: {
          'X-API-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6',
          DriveId: driveId,
          DriveItemId: driveItemId,
          Type: 'edit',
        },
      });
      return response?.data?.result;
    } catch (error) {
      console.error('createSharableLink is failed ', error);
    }

    return null;
  };

  const createInstrution = async (
    sharableLink: string,
    instructionName: string
  ) => {
    const payload = {
      headerTitle: null,
      // id: instructionId,
      instructionType: '100',
      instructionsDetails: sharableLink,
      isDeleted: false,
      isSaved: false,
      name: instructionName,
      pbsInstructionFamilyId: '626253e2-981c-4dcd-b084-a801adfdab1e',
      pbsInstructionLink: [
        {
          id: null,
          title: 'Folder Link',
          link: sharableLink,
          type: 'Website',
          value: sharableLink,
        },
      ],
      sequenceCode: null,
      title: null,
    };
    try {
      const response = await client.post(CREATE_INSTRUCTION_EP, payload);
      return response?.data?.result;
    } catch (error) {
      console.log('createInstrution is failed ', error);
    }
    return null;
  };

  const linkInstructionToPbs = async (
    productId: string,
    instructionsId: string
  ) => {
    const payload = {
      id: uuid4(),
      pbsProductId: productId,
      instructionsId: instructionsId,
    };
    try {
      const response = await client.post(SAVE_PBS_INSTRUCTION_EP, payload);
      return response?.data?.result;
    } catch (error) {
      console.log('linkInstructionToPbs is failed ', error);
    }
    return null;
  };

  const mapLocationPayload = (mapLocation: any) => {
    const mapData = {
      type: 'Country',
      id: mapLocation?.results[0]?.id,
      score: '1',
      address: mapLocation?.results[0]?.address,
      position: {
        lat: mapLocation?.results[0]?.position?.lon,
        lon: mapLocation?.results[0]?.position?.lat,
      },
      viewport: null,
      boundingBox: {
        northEast:
          mapLocation?.results[0]?.viewport?.topLeftPoint?.lon +
          ',' +
          mapLocation?.results[0]?.viewport?.topLeftPoint?.lat,
        southWest:
          mapLocation?.results[0]?.viewport?.btmRightPoint?.lon +
          ',' +
          mapLocation?.results[0]?.viewport?.btmRightPoint?.lat,
        entity: 'position',
      },
    };
    return mapData;
  };

  const getAddressDetails = async (address: string) => {
    try {
      const fetchAddressUrl = 'https://atlas.microsoft.com/search/address/json';
      const response = await fetch(
        `${fetchAddressUrl}?api-version=1.0&subscription-key=${
          ENV.MAP_KEY
        }&query=${encodeURIComponent(address)}`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      alert('Something went wrong while fetching location details');
    }
    return null;
  };

  const createPayLoad = (
    pbsName: string,
    pbsContract: string,
    projectId: string,
    pbsParentId?: string,
    address?: string,
    pbsLocation?: string
  ) => {
    const parentPayload = {
      id: uuid4(),
      name: pbsName,
      pbsLocation: pbsLocation ? pbsLocation : '',
      pbsProductItemTypeId: PRODUCT_ITEM_TYPE_REG_CONTRACT_ID,
      pbsProductStatusId: PENDING_DEVELOPMENT_STATUS_ID,
      pbsToleranceStateId: projectInitialFormData.projectToleranceStateId,
      pbsType: 'regular',
      projectId: projectId,
      startDate: new Date(),
      endDate: new Date(),
      contract: pbsContract,
      parentId: pbsParentId && pbsParentId !== '' ? pbsParentId : null,
    };
    return parentPayload;
  };

  const createProductApi = async (projectId: string, data: any) => {
    try {
      const response = await client.post(PBS_SAVE, data, {
        headers: { 'Content-Type': 'application/json', project: projectId },
      });
      return response?.data?.result;
    } catch (error) {
      console.error('Create Pbs API failed ', error);
    }
    return null;
  };

  const onChangeRadioButtons = (
    ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined,
    option?: IChoiceGroupOption | undefined
  ) => {
    if (option) {
      setSelectedLot(option.key);
    }
  };

  const createProductForExcelData = async () => {
    const projectId = getProject();
    if (projectId === null || projectId === undefined || projectId === '') {
      return;
    }

    setIsProgress(true);
    setLoadCount(excelData.length + 1);
    setShowHourGlass(true);

    try {
      const parentPayload = createPayLoad(mainTitle, contractNr, projectId);
      const parentData = await createProductApi(projectId, parentPayload);
      const folderData = await createTeamsFolder(mainTitle);
      if (parentData?.productId) {
        const linkDetails = await createSharableLink(
          projectId,
          folderData?.folderDetails?.driveId,
          folderData?.folderDetails?.id
        );
        console.log('linkDetails ', linkDetails?.link?.webUrl);
        const instructionDetails = await createInstrution(
          linkDetails?.link?.webUrl,
          mainTitle
        );
        if (instructionDetails?.id) {
          await linkInstructionToPbs(parentData?.id, instructionDetails.id);
        }
        for (let index = 0; excelData.length > index; index++) {
          let title = '';
          let address = '';
          let isPcAvailable = false;
          setFinishedCount(index + 1);
          const rowdata = excelData[index];
          const electricity = rowdata[excelColumnNames.RefnrE];
          const gas = rowdata[excelColumnNames.RefnrG];

          // if both electricity & gas is available then create 3 product
          if (electricity && electricity !== '' && gas && gas !== '') {
            title = contractNr;

            if (
              rowdata[excelColumnNames.PC] &&
              rowdata[excelColumnNames.PC] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.PC];
              isPcAvailable = true;
            }

            if (
              rowdata[excelColumnNames.Hoofdgemeente] &&
              rowdata[excelColumnNames.Hoofdgemeente] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Hoofdgemeente];
              address = rowdata[excelColumnNames.Hoofdgemeente] + ', ';
            }

            if (isPcAvailable) {
              address = address + rowdata[excelColumnNames.PC] + ' ';
            }

            if (
              rowdata[excelColumnNames.Straat] &&
              rowdata[excelColumnNames.Straat] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Straat];
              address = address + rowdata[excelColumnNames.Straat] + ' ';
            }

            if (
              rowdata[excelColumnNames.Huisnr] &&
              rowdata[excelColumnNames.Huisnr] !== ''
            ) {
              title = title + ' ' + rowdata[excelColumnNames.Huisnr];
              address = address + rowdata[excelColumnNames.Huisnr];
            }

            if (
              rowdata[excelColumnNames.Busnr] &&
              rowdata[excelColumnNames.Busnr] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Busnr];
            }

            const locationDetails = await getAddressDetails(address);
            const addressPayload = mapLocationPayload(locationDetails);
            const addressDetails = await client.post(
              CREATE_LOCATION_EP,
              addressPayload
            );

            if (addressDetails?.data?.result) {
              // make sure the address is available
              const innerParentPayload = createPayLoad(
                title,
                contractNr,
                projectId,
                parentData.productId,
                address,
                addressDetails?.data?.result
              );
              const innerParentData = await createProductApi(
                projectId,
                innerParentPayload
              );

              const innerInstructionDetails = await createInstrution(
                linkDetails?.link?.webUrl,
                mainTitle
              ); // create instrutions and add link
              if (innerInstructionDetails?.id && innerParentData?.id) {
                await linkInstructionToPbs(
                  innerParentData.id,
                  innerInstructionDetails.id
                );
              }

              if (innerParentData?.productId) {
                const childPbsElecticTitle =
                  title + ' - ' + 'ELEK - ' + rowdata[excelColumnNames.RefnrE];
                const childPbsElecticPayload = createPayLoad(
                  childPbsElecticTitle,
                  contractNr,
                  projectId,
                  innerParentData.productId,
                  address,
                  addressDetails?.data?.result
                );
                const elekProductDetails = await createProductApi(
                  projectId,
                  childPbsElecticPayload
                );

                const elekInstructionDetails = await createInstrution(
                  linkDetails?.link?.webUrl,
                  mainTitle
                ); // create instrutions and add link
                if (elekInstructionDetails?.id && elekProductDetails?.id) {
                  await linkInstructionToPbs(
                    elekProductDetails.id,
                    elekInstructionDetails.id
                  );
                }

                const childPbsGasTitle =
                  title + ' - ' + 'GAS - ' + rowdata[excelColumnNames.RefnrG];
                const childPbsGasPayload = createPayLoad(
                  childPbsGasTitle,
                  contractNr,
                  projectId,
                  innerParentData.productId,
                  address,
                  addressDetails?.data?.result
                );
                const gasProductDetails = await createProductApi(
                  projectId,
                  childPbsGasPayload
                );

                const gasInstructionDetails = await createInstrution(
                  linkDetails?.link?.webUrl,
                  mainTitle
                ); // create instrutions and add link
                if (gasInstructionDetails?.id && gasProductDetails?.id) {
                  await linkInstructionToPbs(
                    gasProductDetails.id,
                    gasInstructionDetails.id
                  );
                }
              }
            }
          } else if (electricity && electricity !== '') {
            //if electricity data only available
            title = contractNr;

            if (
              rowdata[excelColumnNames.PC] &&
              rowdata[excelColumnNames.PC] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.PC];
              isPcAvailable = true;
            }

            if (
              rowdata[excelColumnNames.Hoofdgemeente] &&
              rowdata[excelColumnNames.Hoofdgemeente] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Hoofdgemeente];
              address = rowdata[excelColumnNames.Hoofdgemeente] + ', ';
            }

            if (isPcAvailable) {
              address = address + rowdata[excelColumnNames.PC] + ' ';
            }

            if (
              rowdata[excelColumnNames.Straat] &&
              rowdata[excelColumnNames.Straat] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Straat];
              address = address + rowdata[excelColumnNames.Straat] + ' ';
            }

            if (
              rowdata[excelColumnNames.Huisnr] &&
              rowdata[excelColumnNames.Huisnr] !== ''
            ) {
              title = title + ' ' + rowdata[excelColumnNames.Huisnr];
              address = address + rowdata[excelColumnNames.Huisnr];
            }

            if (
              rowdata[excelColumnNames.Busnr] &&
              rowdata[excelColumnNames.Busnr] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Busnr];
            }

            title =
              title + ' - ' + 'ELEK - ' + rowdata[excelColumnNames.RefnrE];

            const locationDetails = await getAddressDetails(address);
            const addressPayload = mapLocationPayload(locationDetails);
            const addressDetails = await client.post(
              CREATE_LOCATION_EP,
              addressPayload
            );

            if (addressDetails?.data?.result) {
              // make sure the address is available
              const childPbsElecticPayload = createPayLoad(
                title,
                contractNr,
                projectId,
                parentData.productId,
                address,
                addressDetails?.data?.result
              );
              const elekProductDetails = await createProductApi(
                projectId,
                childPbsElecticPayload
              );

              const elekInstructionDetails = await createInstrution(
                linkDetails?.link?.webUrl,
                mainTitle
              ); // create instrutions and add link
              if (elekInstructionDetails?.id && elekProductDetails?.id) {
                await linkInstructionToPbs(
                  elekProductDetails.id,
                  elekInstructionDetails.id
                );
              }
            }
          } else if (gas && gas !== '') {
            //if gas data only available
            title = contractNr;

            if (
              rowdata[excelColumnNames.PC] &&
              rowdata[excelColumnNames.PC] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.PC];
              isPcAvailable = true;
            }

            if (
              rowdata[excelColumnNames.Hoofdgemeente] &&
              rowdata[excelColumnNames.Hoofdgemeente] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Hoofdgemeente];
              address = rowdata[excelColumnNames.Hoofdgemeente] + ', ';
            }

            if (isPcAvailable) {
              address = address + rowdata[excelColumnNames.PC] + ' ';
            }

            if (
              rowdata[excelColumnNames.Straat] &&
              rowdata[excelColumnNames.Straat] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Straat];
              address = address + rowdata[excelColumnNames.Straat] + ' ';
            }

            if (
              rowdata[excelColumnNames.Huisnr] &&
              rowdata[excelColumnNames.Huisnr] !== ''
            ) {
              title = title + ' ' + rowdata[excelColumnNames.Huisnr];
              address = address + rowdata[excelColumnNames.Huisnr];
            }

            if (
              rowdata[excelColumnNames.Busnr] &&
              rowdata[excelColumnNames.Busnr] !== ''
            ) {
              title = title + ' - ' + rowdata[excelColumnNames.Busnr];
            }

            title = title + ' - ' + 'GAS - ' + rowdata[excelColumnNames.RefnrG];

            const locationDetails = await getAddressDetails(address);
            const addressPayload = mapLocationPayload(locationDetails);
            const addressDetails = await client.post(
              CREATE_LOCATION_EP,
              addressPayload
            );

            if (addressDetails?.data?.result) {
              // make sure the address is available
              const childPbsGasPayload = createPayLoad(
                title,
                contractNr,
                projectId,
                parentData.productId,
                address,
                addressDetails?.data?.result
              );
              const gasProductDetails = await createProductApi(
                projectId,
                childPbsGasPayload
              );

              const gasInstructionDetails = await createInstrution(
                linkDetails?.link?.webUrl,
                mainTitle
              ); // create instrutions and add link
              if (gasInstructionDetails?.id && gasProductDetails?.id) {
                await linkInstructionToPbs(
                  gasProductDetails.id,
                  gasInstructionDetails.id
                );
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Can't create product by using excel ", error);
    }

    setIsProgress(false);
    setShowHourGlass(false);
    setLoadCount(0);
    setFinishedCount(0);
    onCloseModel();
  };

  const handleFileChange = (event: any) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        render={({ form }) => {
          return (
            <form>
              <Modal
                titleAriaId={from === 'product' ? 'Add Product' : 'Add Worker'}
                isOpen={isOpen}
                scrollableContentClassName="body"
                isBlocking={true}
                dragOptions={undefined}
                // styles={modalStyles}
              >
                <div className={contentStyles.header}>
                  <span>
                    {from === 'product' ? t('addProduct') : t('addWorker')}
                  </span>
                  <IconButton
                    styles={cancelIconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => {
                      onCloseModel();
                      modalClose();
                    }}
                  />
                </div>
                <ProgressBar show={isProgress} />
                <div className={contentStyles.body} style={{ height: '50vh' }}>
                  <div style={{ flexBasis: '70%' }} className="preview">
                    {isPdf ? (
                      <>
                        {pdf && (
                          <div>
                            <h2>Preview:</h2>
                            {fileUrl && (
                              <div style={{ marginTop: '20px' }}>
                                <iframe
                                  src={fileUrl}
                                  width={`100%`}
                                  height={'600px'}
                                  title="PDF Preview"
                                  style={{
                                    border: '1px solid #ccc',
                                    backgroundColor: 'white',
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : isHtm ? (
                      <>
                        <iframe
                          className="common-commandbar qrListPane"
                          id="previewFrame"
                        ></iframe>
                      </>
                    ) : data.length ? (
                      <div>
                        {data.length > 0 && (
                          <table
                            style={{
                              borderCollapse: 'collapse',
                              width: '100%',
                            }}
                          >
                            <thead>
                              <tr>
                                {data[0].map((header: any, index: any) => (
                                  <th
                                    key={index}
                                    style={{
                                      border: '1px solid #000',
                                      padding: '8px',
                                    }}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {data.slice(1).map(
                                (row, rowIndex) =>
                                  row && (
                                    <tr key={rowIndex}>
                                      {row.map((cell: any, cellIndex: any) => (
                                        <td
                                          style={{
                                            border: '1px solid #000',
                                            padding: '8px',
                                          }}
                                          key={cellIndex}
                                        >
                                          {cell}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexBasis: '30%',
                      borderLeft: '1px solid #ddd',
                      padding: '0px 0px 0px 20px',
                      overflow: 'auto',
                    }}
                    className="upload"
                  >
                    <div style={{ width: '100%' }}>
                      <Stack>
                        <Stack.Item grow={6}>
                          <div className={`${classNames.fullWidth}`}>
                            <Dropdown
                              label={t('import')}
                              options={
                                fromType === 'product'
                                  ? addProductOptions
                                  : addWorkerOptions
                              }
                              onChange={onImportChange}
                              selectedKey={option?.key}
                            />
                          </div>
                        </Stack.Item>
                      </Stack>

                      {option?.data?.value === 'brutélé' && (
                        <>
                          <Label>{t('attachment')}</Label>
                          <div className={`${classNames.fullWidth}`}>
                            <div>
                              {!Object.keys(products).length ? (
                                <>
                                  <div
                                    onDragOver={handleDragOver}
                                    onDrop={handleDropHtm}
                                    style={{
                                      border: '2px dashed #ccc',
                                      padding: '40px',
                                      textAlign: 'center',
                                      marginBottom: '20px',
                                    }}
                                  >
                                    <p>
                                      {t(`drag and drop your Htm file here`)}
                                    </p>
                                  </div>
                                  <button
                                    className="uploadButton"
                                    onClick={() => {
                                      document
                                        .getElementById('fileInput')
                                        ?.click();
                                    }}
                                  >
                                    {t('upload File')}
                                  </button>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    accept={'.htm'}
                                    onChange={(e) => {
                                      const file = e.target.files?.[0];
                                      if (file) {
                                        handleHtmFileChange(file);
                                      }
                                    }}
                                    style={{
                                      display: 'none',
                                    }}
                                  />
                                </>
                              ) : (
                                <div className="afterUploaded">
                                  <div
                                    className="card"
                                    // onClick={() => onCardClick('excel',excelFile)}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <h3>{t('uploaded File')}</h3>
                                    </div>

                                    <div>
                                      <div className="uploadedExcel">
                                        <div
                                          style={{
                                            wordBreak: 'break-all',
                                            textAlign: 'center',
                                          }}
                                     
                                        >
                                          {htmFileName}
                                        </div>
                                        <IconButton
                                          className="excelPlaceHolder"
                                          iconProps={deleteIcon}
                                          onClick={deleteHtmlFile}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <h3>Details</h3>
                                  {[
                                    'Titre',
                                    'Référence',
                                    'Date de début',
                                    'Date de fin',
                                    // 'Commentaire',
                                  ].map((str,i) => (
                                    <div key={i} className="card-footer">
                                      <div>{t(`${dutchTrans[str as keyof TransWord]}`)}</div>
                                      {['Titre', 'Référence'].includes(str) ? (
                                        <Input
                                          key={str}
                                          className={`input_border ${classNames.fullWidth}`}
                                          value={products[str]}
                                          onChange={(e) =>
                                            onProductChange(e, str, 'htm')
                                          }
                                        />
                                      ) : (
                                        <>
                                            <DatePicker
                                          placeholder={t('selectadate')}
                                          ariaLabel={t('projectCompletionDate')}
                                          value={stringToDate(products[str])}
                                          formatDate={dateToString}
                                          onSelectDate={(e) => handleDateChange(e,str)}
                                        />
                                        </>
                                    
                                      )}
                                    </div>
                                  ))}
                                  <div></div>
                                </div>
                              )}
                            </div>
                            <div style={{ marginTop: '26px' }}>
                            <Label>{t('attachment')}</Label>
                            <div className={`${classNames.fullWidth}`}>
                              <div>
                                {!files.length ? (
                                  <>
                                    <div
                                      onDragOver={handleDragOver}
                                      onDrop={handleDropAttachements}
                                      style={{
                                        border: '2px dashed #ccc',
                                        padding: '40px',
                                        textAlign: 'center',
                                        marginBottom: '20px',
                                      }}
                                    >
                                      <p>
                                        {t('drag and drop your Files here')}
                                      </p>
                                    </div>
                                    <button
                                      className="uploadButton"
                                      onClick={() => {
                                        document
                                          .getElementById('fileAttachInput')
                                          ?.click();
                                      }}
                                    >
                                      {t('upload File')}
                                    </button>
                                    <input
                                      type="file"
                                      multiple
                                      id="fileAttachInput"
                                      accept="file"
                                      onChange={handleFileChange}
                                      style={{
                                        display: 'none',
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div className="afterUploaded">
                                      <div className="card">
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <h3>{t('uploaded File')}</h3>
                                          <div>{`${t('no of files')} : ${
                                            files.length
                                          }`}</div>
                                        </div>

                                        <div>
                                          <div className="uploadedExcel">
                                            <div
                                              style={{
                                                wordBreak: 'break-all',
                                                textAlign: 'center',
                                              }}
                                            >
                                              {t('attachement Files')}
                                            </div>
                                            <IconButton
                                              className="excelPlaceHolder"
                                              iconProps={deleteIcon}
                                              onClick={deleteFile}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          </div>
                        </>
                      )}

                      {option?.data?.value &&
                        ['syndi', 'vicky', 'fluvius'].includes(
                          option?.data?.value
                        ) && (
                          <>
                            <Label>{t('attachment')}</Label>
                            <div className={`${classNames.fullWidth}`}>
                              <div>
                                {!excelFileName ? (
                                  <>
                                    <div
                                      onDragOver={handleDragOver}
                                      onDrop={handleDropExcel}
                                      style={{
                                        border: '2px dashed #ccc',
                                        padding: '40px',
                                        textAlign: 'center',
                                        marginBottom: '20px',
                                      }}
                                    >
                                      <p>
                                        {t(
                                          `drag and drop your ${'Excel'}  file here`
                                        )}
                                      </p>
                                    </div>
                                    <button
                                      className="uploadButton"
                                      onClick={() => {
                                        document
                                          .getElementById('fileInput')
                                          ?.click();
                                      }}
                                    >
                                      {t('upload File')}
                                    </button>
                                    <input
                                      type="file"
                                      id="fileInput"
                                      accept={'.xlsx, .xls'}
                                      onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                          handleExcelFileChange(file);
                                        }
                                      }}
                                      style={{
                                        display: 'none',
                                      }}
                                    />
                                  </>
                                ) : (
                                  <div className="afterUploaded">
                                    <div
                                      className="card"
                                      // onClick={() => onCardClick('excel',excelFile)}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <h3>{t('uploaded File')}</h3>
                                        {numberOfRecords && (
                                          <h3>
                                            Number Of Records :{' '}
                                            {numberOfRecords}
                                          </h3>
                                        )}
                                      </div>

                                      <div>
                                        <div className="uploadedExcel">
                                          <div
                                            style={{
                                              wordBreak: 'break-all',
                                              textAlign: 'center',
                                            }}
                                            onClick={() =>
                                              onCardClick('excel', excelFile)
                                            }
                                          >
                                            {excelFileName}
                                          </div>
                                          <IconButton
                                            className="excelPlaceHolder"
                                            iconProps={deleteIcon}
                                            onClick={deleteExcel}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                      {option?.data?.value === 'vicky' && (
                        <div>
                          <div
                            className={`${classNames.fullWidth}`}
                            style={{ paddingTop: 26 }}
                          >
                            <ChoiceGroup
                              styles={{
                                flexContainer: {
                                  display: 'flex',
                                },
                              }}
                              selectedKey={selectedLot}
                              options={lotOptions}
                              onChange={onChangeRadioButtons}
                            />
                          </div>
                        </div>
                      )}

                      {option?.data?.value === 'vicky' && ( //Here toImport is converted to number and then checked for toImport key value , if toImport '0' is converted to number and that is falsy so during toImport 1 this wont render
                        <div>
                          <div style={{ marginTop: '26px' }}>
                            <Label>{t('attachment')}</Label>
                            <div className={`${classNames.fullWidth}`}>
                              <div>
                                {!pdfFileName ? (
                                  <>
                                    <div
                                      onDragOver={handleDragOver}
                                      onDrop={handleDropPdf}
                                      style={{
                                        border: '2px dashed #ccc',
                                        padding: '40px',
                                        textAlign: 'center',
                                        marginBottom: '20px',
                                      }}
                                    >
                                      <p>
                                        {t('drag and drop your PDF file here')}
                                      </p>
                                    </div>
                                    <button
                                      className="uploadButton"
                                      onClick={() => {
                                        document
                                          .getElementById('filePdfInput')
                                          ?.click();
                                      }}
                                    >
                                      {t('upload File')}
                                    </button>
                                    <input
                                      type="file"
                                      id="filePdfInput"
                                      accept=".pdf"
                                      onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                          handlePdfFileChange(file);
                                        }
                                      }}
                                      style={{
                                        display: 'none',
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div className="afterUploaded">
                                      <div
                                        className="card"
                                        // onClick={() => onCardClick('pdf', pdf)}
                                      >
                                        <h3>{t('uploaded File')}</h3>
                                        <div>
                                          <div className="uploadedExcel">
                                            <div
                                              style={{
                                                wordBreak: 'break-all',
                                                textAlign: 'center',
                                              }}
                                              onClick={() =>
                                                onCardClick('pdf', pdf)
                                              }
                                            >
                                              {pdfFileName}
                                            </div>
                                            <IconButton
                                              className="excelPlaceHolder"
                                              iconProps={deleteIcon}
                                              onClick={deletePdf}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="sideScroll">
                                      {Object.keys(products).length ? (
                                        Object.keys(products).map((key,i) => (
                                          <div key={i} style={{ padding: '10px 0px' }}>
                                            <div>{key}</div>
                                            {key === 'Date de transfert' ? (
                                              <DatePicker
                                                placeholder={t('selectadate')}
                                                ariaLabel={t(
                                                  'projectCompletionDate'
                                                )}
                                                value={stringToDate(
                                                  products[key].value
                                                )}
                                                formatDate={dateToString}
                                                onSelectDate={(e) => products[key].value = convertDateStringToYYYYMMDD(e)}
                                              />
                                            ) : (
                                              <Input
                                                key={key}
                                                className={`${classNames.fullWidth}`}
                                                style={{
                                                  border: '1px solid #000',
                                                  color: '#000',
                                                  padding: '4px 8px',
                                                  marginTop: '10px',
                                                }}
                                                value={products[key].value}
                                                onChange={(e) =>
                                                  onProductChange(e, key, 'pdf')
                                                }
                                              />
                                            )}
                                          </div>
                                        ))
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div style={{ marginTop: '26px' }}>
                            <Label>{t('attachment')}</Label>
                            <div className={`${classNames.fullWidth}`}>
                              <div>
                                {!files.length ? (
                                  <>
                                    <div
                                      onDragOver={handleDragOver}
                                      onDrop={handleDropAttachements}
                                      style={{
                                        border: '2px dashed #ccc',
                                        padding: '40px',
                                        textAlign: 'center',
                                        marginBottom: '20px',
                                      }}
                                    >
                                      <p>
                                        {t('drag and drop your Files here')}
                                      </p>
                                    </div>
                                    <button
                                      className="uploadButton"
                                      onClick={() => {
                                        document
                                          .getElementById('fileAttachInput')
                                          ?.click();
                                      }}
                                    >
                                      {t('upload File')}
                                    </button>
                                    <input
                                      type="file"
                                      multiple
                                      id="fileAttachInput"
                                      accept="file"
                                      onChange={handleFileChange}
                                      style={{
                                        display: 'none',
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div className="afterUploaded">
                                      <div className="card">
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <h3>{t('uploaded File')}</h3>
                                          <div>{`${t('no of files')} : ${
                                            files.length
                                          }`}</div>
                                        </div>

                                        <div>
                                          <div className="uploadedExcel">
                                            <div
                                              style={{
                                                wordBreak: 'break-all',
                                                textAlign: 'center',
                                              }}
                                            >
                                              {t('attachement Files')}
                                            </div>
                                            <IconButton
                                              className="excelPlaceHolder"
                                              iconProps={deleteIcon}
                                              onClick={deleteFile}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {option?.data?.value === 'fluvius' && (
                        <>
                          <div>
                            <TextField
                              label={t('Main Title')}
                              value={mainTitle}
                              onChange={(event, value) => {
                                setMainTitle(value);
                              }}
                            />
                          </div>
                          <div>
                            <TextField
                              label={t('Contract nr')}
                              value={contractNr}
                              onChange={(event, value) => {
                                setContractNr(value);
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignSelf: 'end',
                        gap: 20,
                      }}
                    >
                      {showHourGlass && (
                        <div className={styles.wrapper}>
                          <svg
                            className={styles.hourglass}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 120 206"
                            preserveAspectRatio="none"
                          >
                            <path
                              className={styles.middle}
                              d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
                            />
                            <path
                              className={styles.outer}
                              d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
                            />
                          </svg>
                          {finishedCount}/{loadCount}
                        </div>
                      )}
                      {option?.data?.value === 'fluvius' && (
                        <div className="save_btn">
                          <PrimaryButton
                            iconProps={addIconWhite}
                            text={t('import')}
                            type="submit"
                            disabled={mainTitle === '' || contractNr === ''}
                            onClick={() => {
                              createProductForExcelData();
                            }}
                            style={{
                              marginTop: 20,
                              marginBottom: 30,
                              marginRight: 20,
                            }}
                          />
                        </div>
                      )}
                      {option?.data?.value &&
                        ['vicky', 'brutélé'].includes(option?.data?.value) && (
                          <div className="save_btn">
                            <PrimaryButton
                              iconProps={addIconWhite}
                              text={t('import')}
                              type="submit"
                              disabled={!(((excelFile && pdf) || htmData) && products)}
                              onClick={onSubmit}
                              style={{
                                marginTop: 20,
                                marginBottom: 30,
                                marginRight: 20,
                              }}
                            />
                          </div>
                        )}
                      {fromType === 'worker' &&
                      option?.data?.value === 'vicky' ? (
                        <>
                          <div className="save_btn">
                            <PrimaryButton
                              text={t('close')}
                              type="submit"
                              disabled={true}
                              onClick={() => {
                                fromType === 'worker'
                                  ? onCloseModel()
                                  : createProductForExcelData();
                              }}
                              style={{
                                marginTop: 20,
                                marginBottom: 30,
                                marginRight: 20,
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div></div>
              </Modal>
            </form>
          );
        }}
      ></Form>
    </div>
  );
};

export default AddCustomModal;
