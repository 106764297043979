import {
    getTheme,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    PrimaryButton,
    Stack,
    TextField,
} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    getFlatDataFromTree,
    getTreeFromFlatData,
    SortableTreeWithoutDndContext as SortableTree,
    toggleExpandedForAll
} from '@nosferatu500/react-sortable-tree';
import i18n from '../../../../../i18n';
// import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import FileExplorerTheme from 'react-sortable-tree-theme-minimal';
// import FileExplorerTheme from 'react-sortable-tree-theme-solverboard';
// react-sortable-tree-list-view.css
import '../../../../shared/sortableTreeListView/react-sortable-tree-list-view.css';

const stackStyles: IStackStyles = {root: {padding: 0}};

const stackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        height: 50,
    },
};

const stackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

interface Props {
    treeData: any;
    customNodeRender?: any;
    customNodeLevel?: any;
    treeHeader?: any;
    handleTitleClick?: any;
    horizontalReSizer: any;
}

const MyCalSortableTree = (props: Props) => {
    const [treeData, setTreeData]: any = useState([]);
    const [, setFlatTreeData]: any = useState([]);
    const [searchString, setSearchString] = useState<string>('');
    const [searchFocusIndex, setSearchFocusIndex] = useState<any>(0);
    const [searchFoundCount, setSearchFoundCount] = useState<any>(null);


    useEffect(() => {
        const tData = getTreeFromFlatData({
            flatData: props.treeData,
            getKey: (node: any) => node.id,
            getParentKey: (node: any) => node.parentId,
            // @ts-ignore
            rootKey: null,
        });
        setTreeData(toggleExpandedForAll({
            treeData: tData[0]?.children[0]?.children, // Removes the first two root nodes
            expanded: true,
        }));
        // setSelectedItem(selectItemId);
    }, [props.treeData]);


    useEffect(() => {
        const fData = getFlatDataFromTree({
            treeData: treeData,
            getNodeKey: (node: any) => node.id,

        });
        setFlatTreeData(fData);
    }, [treeData]);

    useEffect(() => {
        if (props.horizontalReSizer) {
            document.documentElement.style.setProperty('--horizontal-resizer', `${props.horizontalReSizer}px`);
        }
    }, [props.horizontalReSizer]);

    const expand = (expanded: any) => {
        setTreeData(
          toggleExpandedForAll({
              treeData: treeData,
              expanded,
          }),
        );
    };

    const expandAll = () => {
        expand(true);
    };

    const collapseAll = () => {
        setSearchString('');
        expand(false);
    };

    // Case insensitive search of `node.title`
    const customSearchMethod = ({node, searchQuery}: any) => {
        return searchQuery &&
          node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
    };

    const handleTreeOnSearch = (searchString: string) => {
        setSearchString(searchString);
    };

    const selectPrevMatch = () => {
        let searchFoundCountVal = 0;
        let searchFocusIndexValue = 0;
        if (typeof searchFoundCount === 'number') {
            searchFoundCountVal = searchFoundCount;
        }
        if (typeof searchFocusIndex === 'number') {
            searchFocusIndexValue = searchFocusIndex;
        }

        setSearchFocusIndex(
          searchFocusIndex !== null
            ? (searchFoundCountVal + searchFocusIndexValue - 1) %
            searchFoundCountVal
            : searchFoundCountVal - 1,
        );
    };


    const selectNextMatch = () => {
        let searchFoundCountVal = 0;
        if (typeof searchFoundCount === 'number') {
            searchFoundCountVal = searchFoundCount;
        }

        setSearchFocusIndex(
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCountVal
            : 0,
        );
    };

    // model////////
    const {t} = useTranslation();
    const theme = getTheme();

    const onRenderTitle = (rowInfo: any) => {
        const {path, node} = rowInfo;
        if (Array.isArray(props.customNodeLevel) && rowInfo.node && props.customNodeLevel.includes(rowInfo.node?.displayOrder) && props.customNodeRender) {
            return props.customNodeRender(node);
        } else {
            return (rowInfo.node && rowInfo.node?.displayOrder === props.customNodeLevel && props.customNodeRender) ? props.customNodeRender(node) :
              <Label onClick={() => {
                  props?.handleTitleClick ? props.handleTitleClick(node) : () => {
                  };
              }}>{(!node?.children) ? <li className={'taxonomyDot'}>{node.title}</li> : node.title}</Label>
        }
    }

    return (
      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 taxonomy-wrapper">
          <div className="row">
              <Stack horizontal styles={stackStyles} tokens={stackTokens}>
                  <Stack.Item grow={1} styles={stackItemStyles}>
                      <PrimaryButton onClick={expandAll}>
                          {t('expandAll')}
                      </PrimaryButton>
                  </Stack.Item>
                  <Stack.Item grow={1} styles={stackItemStyles}>
                      <PrimaryButton onClick={collapseAll}>
                          {t('collapseAll')}
                      </PrimaryButton>
                  </Stack.Item>
                  <Stack.Item grow={3} styles={stackItemStyles}>
                      <TextField
                        value={searchString}
                        placeholder={i18n.t('search')}
                        onChange={(event, value) => {
                            if (value) {
                                handleTreeOnSearch(value);
                            } else {
                                handleTreeOnSearch('');
                            }
                        }}
                      />
                  </Stack.Item>
                  <Stack.Item grow={1} styles={stackItemStyles}>
                      <PrimaryButton
                        style={{minWidth: 25}}
                        disabled={!searchFoundCount}
                        onClick={selectPrevMatch}
                      >
                          {' '}
                          &lt;
                      </PrimaryButton>
                  </Stack.Item>
                  <Stack.Item grow={1} styles={stackItemStyles}>
                      <PrimaryButton
                        style={{minWidth: 25}}
                        disabled={!searchFoundCount}
                        onClick={selectNextMatch}
                      >
                          &gt;
                      </PrimaryButton>
                  </Stack.Item>
                  <Stack.Item grow={1} styles={stackItemStyles}>
                        <span style={{marginTop: 6}}>
              &nbsp;
                            {searchFoundCount
                              ? searchFoundCount > 0
                                ? searchFocusIndex + 1
                                : 0
                              : 0}
                            &nbsp;/&nbsp;
                            {searchFoundCount || 0}
                        </span>
                  </Stack.Item>
              </Stack>
          </div>
          <div className="row">
              {props.treeHeader && <div style={{width: '100%'}} className={'treeHeader'}>
                  {props.treeHeader}
              </div>}
          </div>

          <label htmlFor="find-box"></label>
          <SortableTree
            canDrag={({}) => false}
            canDrop={() => false}
            searchQuery={searchString}
            searchMethod={customSearchMethod}
            searchFocusOffset={searchFocusIndex}
            searchFinishCallback={(matches) => {
                setSearchFoundCount(matches.length);
                setSearchFocusIndex(
                  matches.length > 0 ? searchFocusIndex % matches.length : 0,
                );
            }}
            // isVirtualized={true}
            treeData={treeData}
            onChange={(treeData) => {
                setTreeData(treeData);
            }}
            onlyExpandSearchedNodes={true}
            theme={FileExplorerTheme}
            rowHeight={41}
            generateNodeProps={(rowInfo: any) => {
                const {path, node} = rowInfo;
                return {
                    buttons: [
                        <div>
                            { /* {(rowInfo.node &&rowInfo.node?.level==='5')?<div className="lastNode"></div>:false}*/}
                            { /* <Label> {(rowInfo.node &&rowInfo.node.parentId!=null && rowInfo.node.children && rowInfo.node.children.length<=0)?"last node":rowInfo.node.parentId}</Label>*/}
                        </div>,
                    ],
                    title: [
                        <div style={{paddingLeft: 5}}>
                            {onRenderTitle(rowInfo)}
                            { /* {(rowInfo.node &&rowInfo.node?.level==='5')?<div className="lastNode"></div>:false}*/}
                            { /* <Label> {(rowInfo.node &&rowInfo.node.parentId!=null && rowInfo.node.children && rowInfo.node.children.length<=0)?"last node":rowInfo.node.parentId}</Label>*/}
                        </div>,
                    ],
                };
            }}
          />
      </div>
    );
};

export default MyCalSortableTree;
