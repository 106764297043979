import React, { useEffect, useRef } from 'react';
import { ViewMode } from '../../../shared/ganttv2';
import { Label } from 'office-ui-fabric-react';
import { ViewModeContextLTP } from '../productTaxonomyListPane/component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectTeamCapacityData } from '../../../reducers/visualPlanReducer';
import {
  DirectionalHint,
  FontIcon,
  IComboBoxOption,
  ITooltipHostStyles,
  mergeStyleSets,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react';
import moment from 'moment/moment';
import { ViewSwitcher } from './view-switcher';
import { TableExpandProvider } from '../../../hooks/useTableExpandContext';
import i18next from 'i18next';;

const ROW_HEIGHT = 36;

const weekendColor = '#4f7fef33';

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const classnames = mergeStyleSets({
  tooltipUl: {
    margin: 2, 
    padding: 0, 
    fontSize: 14
  },
  tableRowTr: {
    height: ROW_HEIGHT, 
    border: '1px solid #BFC9CA', 
    backgroundColor: '#F3F2F1'
  },
  tableRowTh: {
    minWidth: 60,
    height: ROW_HEIGHT,
    borderRight: '1px solid #BFC9CA',
    padding: 0,
  },
  tableRowExpandIconWithTitle: {
    width: 'calc(100% - 25px)', 
    display: 'flex', 
    flexDirection: 'row', 
    marginLeft: 25, 
    padding: 0, 
    height: 'inherit', 
    borderRight: '1px solid #BFC9CA'
  },
  expandIcon: {
    marginLeft: 20, 
    marginTop: 10, 
    cursor: 'pointer'
  },
  tableRowLabel: {
    marginTop: 3,
    marginLeft: 10, 
    fontSize: 14
  },
  tableRowTd: {
    minWidth: 60,
    height: ROW_HEIGHT,
    textAlignLast: 'end',
    paddingRight: 10,
    border: '1px solid #BFC9CA',
    fontFamily: 'Segoe UI',
    fontSize: 14,
  },
  tableRowChildrendTd: {
    textAlignLast: 'end',
    paddingRight: 10,
    maxWidth: '0.1vw',
    border: '1px solid #BFC9CA',
    maxHeight: ROW_HEIGHT,
  },
  tableRowChildrendTdLabel: {
    height: ROW_HEIGHT, 
    padding: 0, 
    marginLeft: 5, 
    fontSize: 12, 
    overflow: 'hidden'
  }
})

const getToolTipContent = (projectName: string[] | undefined): React.ReactElement | undefined => {
  if (projectName && projectName.length > 0) {
    return (
      <ul className={classnames.tooltipUl}>
        {projectName.map((project, index) => (
          <li key={index}>{project}</li>
        ))}
      </ul>
    );
  }
  return undefined;
};

function TableRowChildTeam({ index, personTitle, mainValues, weeks }: any) {
  return (
    <>
      <tr
        className={classnames.tableRowTr}
        key={index}
      >
        <th 
          className={classnames.tableRowTh}
          style={{
            backgroundColor: index % 2 === 0 ? '#F3F2F1' : 'white',
          }}
        >
          <div className={classnames.tableRowExpandIconWithTitle}>
            <Label className={classnames.tableRowLabel}>{personTitle}</Label>
          </div>
        </th>
        <>
          {mainValues?.project?.map((projectName: any, index: number) => {
              return (
                <td
                  key={index}
                  className={classnames.tableRowChildrendTd}
                  style={{ background: (index + 1) % 7 === 0 || (index + 2) % 7 === 0 ? weekendColor : undefined }}
                >
                  <TooltipHost
                    delay={TooltipDelay.zero}
                    directionalHint={DirectionalHint.topCenter}
                    styles={hostStyles}
                    content={getToolTipContent(projectName)}
                  >
                    <Label className={classnames.tableRowChildrendTdLabel}>
                      {projectName.length === 1 ? (
                        projectName[0]
                      ) : projectName.length > 0 ? (
                        projectName.length + ' ' + i18next.t('projects')
                      ) : undefined}
                    </Label>
                  </TooltipHost>

                </td>
              );
            },
          )}
        </>
      </tr>
    </>
  );
}

function ProjectTeamCapacityTable(props: { matrixType: string, height: number }) {
  const projectTeamCapacityData: any = useSelector((state: any) => state.vp.projectTeamCapacityData);
  const [isChecked, setIsChecked] = React.useState(true);
  const [showGanttChart, setShowGanttChart] = React.useState(true);
  const [view, setView] = React.useState<ViewMode>(ViewMode.Day);
  const [weeks, setWeeks] = React.useState<any[]>([]);
  const [resourceData, setResourceData] = React.useState<any>([]);
  const [weeklyGroupedDatesArray, setWeeklyGroupedDatesArray] = React.useState<any>([]);
  const {
    selectedBuInCuProjectPlanning,
    projectLevelReloadRequired,
    productTaxonomyReloadRequired,
  } = useSelector((state: any) => state.vp);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const matrixTableRef = useRef(null);

  const optionsForMePP: IComboBoxOption[] = [
    {
      key: ViewMode.Day,
      text: t('day'),
    },
    {
      key: ViewMode.Week,
      text: t('week'),
    },
    {
      key: ViewMode.Month,
      text: t('month'),
    },
  ];

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  const lastWeekStartDate = moment().subtract(1, 'weeks').startOf('week').format('MM/DD/YYYY');
  const nextTwoWeeksLastDay = moment().add(2, 'weeks').endOf('week').format('MM/DD/YYYY');
  const lastMonthStartDate = moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY');
  const nextTwoMonthsLastDay = moment().add(2, 'months').endOf('month').format('MM/DD/YYYY');

  const datePayload = {
    startDate: view == ViewMode.Month ? lastMonthStartDate : lastWeekStartDate,
    endDate: view == ViewMode.Month ? nextTwoMonthsLastDay : nextTwoWeeksLastDay,
    type: view,
  };

  useEffect(() => {
    if (props.matrixType === '500') {
      dispatch(getProjectTeamCapacityData(datePayload));
    }

    return () => {
    };
  }, [props.matrixType, view, selectedBuInCuProjectPlanning, projectLevelReloadRequired, productTaxonomyReloadRequired]);

  useEffect(() => {
    if (projectTeamCapacityData) {

      let { persons, days, week } = projectTeamCapacityData?.result;

      // Week and Month data comes with null days array
      if (!days) {
        days = week;
      }
      const sortedPersons = persons?.sort((a: any, b: any) => a?.personName?.localeCompare(b?.personName));
      setResourceData(sortedPersons);
      const groupedArray = [];
      for (let i = 0; i < week?.length; i++) {
        const startIdx = i * 7;
        const endIdx = startIdx + 7;
        const weekDays = days?.slice(startIdx, endIdx);

        groupedArray.push({
          week: week[i],
          days: weekDays,
        });
      }

      setWeeklyGroupedDatesArray(groupedArray);
      setWeeks(week ?? days);
    }
  }, [projectTeamCapacityData]);

  return (
    <div
      className={'matrixTable'}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ViewModeContextLTP.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
          onShowGanttChartChangeLTP: (showGanttChart: boolean) => {},
          showGanttChartLTP: showGanttChart
        }}
      >
        <TableExpandProvider>
          <ViewSwitcher
            onViewModeChange={(viewMode: ViewMode) => {
              onViewModeChange(viewMode);
            }}
            onViewListChange={setIsChecked}
            isChecked={isChecked}
            renderFilterComponent={() => <Label></Label>}
            option={optionsForMePP}
          />

          <div ref={matrixTableRef} id={'matrix-table-table'}
               style={{ width: '89vw', height: props.height - 96, overflow: 'overlay' }}>
            <table
              style={{
                borderCollapse: 'collapse',
                width: 'min-content',
                padding: 20,
                tableLayout: 'auto',
              }}
            >
              <thead>
              {view == ViewMode.Day && <tr style={{position: 'sticky', top: 0, zIndex: 10}}>
                <th style={{ minWidth: '28vw', height: ROW_HEIGHT, padding: 0 }}>
                  <div id={'matrix-table-table-header'} style={{
                    width: '100%',
                    height: 'inherit',
                    background: 'white',
                    borderRight: '1px solid #BFC9CA',
                    marginTop: -1,
                  }}></div>
                </th>
                {weeks && weeks?.map((weekNumber: any) => {
                  return <th colSpan={7} style={{
                    background: 'white',
                    border: '1px solid #BFC9CA',
                    verticalAlign: 'middle',
                  }}>
                    <Label style={{ fontSize: 14 }}>
                      {weekNumber}
                    </Label>
                  </th>;
                })}
              </tr>}

              <tr style={{
                position: 'sticky',
                top: view == ViewMode.Day ? ROW_HEIGHT : undefined,
                height: 2,
                border: '1px solid #BFC9CA',
                zIndex: 10,
              }}>
                <th style={{ minWidth: '28vw', height: ROW_HEIGHT, padding: 0 }}>
                  <div id={'matrix-table-table-header'} style={{ width: '100%', height: 'inherit', background: 'white', borderRight: '1px solid #BFC9CA' }}></div>
                </th>
                {
                  weeklyGroupedDatesArray && weeklyGroupedDatesArray?.map((e: any, index: number) => {
                    return (
                      <th key={index} colSpan={7} style={{
                        verticalAlign: 'middle',
                        padding: 0,
                        height: 'inherit'
                      }}>
                        {Object.entries(e.days).map(([key, dateAndName], index) => (
                          <th
                            key={index}
                            style={{
                              background: index === 5 || index === 6 ? weekendColor : 'white',
                              minWidth: view == ViewMode.Day ? '3.8vw' : '14.3vw',
                              width: '8vw',
                              verticalAlign: 'middle',
                              border: '1px solid #BFC9CA',
                              padding: 0,
                              height: ROW_HEIGHT,
                            }}
                          >
                            {view == ViewMode.Day ?
                              <>
                                <Label style={{ fontSize: 12, padding: 0 }}>
                                  {dateAndName && moment(dateAndName, 'ddd-MMM DD').format('ddd')}
                                </Label>
                                <Label style={{ fontSize: 12, padding: 0 }}>
                                  {dateAndName && moment(dateAndName).format('MMM DD')}
                                </Label>
                              </> :
                              <Label style={{ fontSize: 12 }}>
                                {dateAndName}
                              </Label>}
                          </th>
                        ))}

                      </th>
                    );
                  })
                }

              </tr>
              </thead>
              <tbody>
              {/*Data table*/}

              {resourceData && resourceData?.map((person: any, index: number) => {
                return <TableRowChildTeam 
                          mainValues={person} 
                          key={index} 
                          index={index} 
                          weeks={view == ViewMode.Day ? weeks : { weeks }} 
                          personTitle={person.personName}
                        />
              })}
              </tbody>
            </table>
          </div>
        </TableExpandProvider>
      </ViewModeContextLTP.Provider>
    </div>
  );
}

export default ProjectTeamCapacityTable;
