import React, { Component } from 'react';
import { connect } from 'react-redux';
import Brand from '../../shared/brand/brand';
// import ProjectBar from '../../shared/projectBar/projectBar';
import NavBar from '../../shared/navBar/navBar';
import LanguageMainLayout from './dropdownTranslationMainLayout';
import { getContractingUnit } from '../../shared/util';
import ProjectBarComponent from '../../shared/projectBar/ProjectBarComponent';


interface Props {
  currentUser: any;
  organization: any
}

interface State {
  isContractUnit: boolean
}


export class dropdownTranslation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isContractUnit: getContractingUnit() ? true : false };
  }


  render() {
    // const hasOrganisationPermission = hasPermissions(this.props.currentUser?.groups, this.props.organization).hasOrganisationPermission;
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        {/* <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/> */}
        <ProjectBarComponent contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        <NavBar selectedKey={'/dropdown-translations-dashboard'} currentUser={this.props.currentUser}/>
        <LanguageMainLayout/>
      </div>
    );
  }
}


const mapStateToProps = (state: any) => {
  return { organization: state.uprince.organization };
};


const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(dropdownTranslation);
