import React, { useEffect, useState } from 'react';
import SortableTreeListViewWithoutDnd from '../../../shared/sortableTreeListView/sortableTreeListViewWithoutDnd';
import {
  OrganisationSettingListPaneFilter,
  OSListPaneItem,
} from '../../../types/organisationSetting';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectPlanResourceType } from '../../../types/myDayPlanning';
import DragBox from '../../../shared/dnd/dragBox';
import { getOrgTeamTaxonomyList } from '../../../reducers/projectDayPlanningReducer';

const OrgTeams = () => {
  const dispatch = useDispatch();
  const { horizontalReSizer, orgTeamsTaxonomy } = useSelector(
    (state: any) => state.projectDPlan
  );
  const [listPaneItems, setListPaneItems]: any =
    useState<OSListPaneItem[]>(orgTeamsTaxonomy);

  useEffect(() => {
    setListPaneItems(orgTeamsTaxonomy);
  }, [orgTeamsTaxonomy]);

  useEffect(() => {
    getTeamsTaxonomy();
  }, []);

  const getTeamsTaxonomy = () => {
    let filter = new OrganisationSettingListPaneFilter();
    // filter.organizationTaxonomyLevelId=ORG_TEAM_ID
    dispatch(getOrgTeamTaxonomyList(filter));
  };

  const handleTitleClick = (node: any) => {
    // messageService.sendMessage({ selectedOrgSetting: node });
  };

  const customNodeRender = (node: any) => {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleTitleClick(node);
        }}
        id={node.id}
        key={node.id}
        className={'lastNode'}
      >
        <DragBox
          name={`${node?.title}`}
          item={node}
          type={ProjectPlanResourceType.ORG_TEAMS}
          style={{ fontSize: 13 }}
          droppedLabel={`${node?.title}`}
        />
      </div>
    );
  };

  return (
    <div className={'planboard'} style={{ height: '100%' }}>
      <div className={`org wrapper-holder`} style={{height: '100%'}}>
        <SortableTreeListViewWithoutDnd
          treeData={listPaneItems}
          customNodeLevel={6}
          customNodeRender={(node: any) => customNodeRender(node)}
          handleTitleClick={(node: any) => {
            handleTitleClick(node);
          }}
        />
      </div>
    </div>
  );
};

export default OrgTeams;
