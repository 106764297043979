import React from 'react';
import { Gantt } from './../../../shared/ganttv2/components/gantt/gantt';
import { getFlatDataFromTree, walk } from '@nosferatu500/react-sortable-tree';
import { Task, ViewMode } from '../../../shared/ganttv2';
import { initTasks } from './helper';
import { ViewSwitcher } from './view-switcher';
import _ from 'lodash';
import { Label, Text } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useDebouncedCallback } from 'use-debounce';
import {
  formatVpProductBarDataWithoutRefresh,
  saveVPStateAttr,
  updatePMOLLabourTeam,
  updatePMOLToolsPool,
  // updateProductTaxonomyTreeIndex,
  updateProjectItemsDate,
} from '../../../reducers/visualPlanReducer';
import { useTranslation } from 'react-i18next';
import { RESOURCE_TYPE_IDS } from '../../../shared/util';
import ProgressBar from '../../../shared/progressBar/progressBar';
import moment from 'moment';
import { ChartType } from '../../../shared/ganttv2/types/public-types';
import { saveBORStateAttr } from '../../../reducers/billOfResourcesReducer';
import { savePbsStateAttr } from '../../../reducers/projectBreakdownReducer';
import { savePMOLStateAttr } from '../../../reducers/pmolReducer';
import { ViewModeContext } from '../productTaxonomyListPane/component';
import { ExpandProvider } from '../../../hooks/expandContext';
// import useMoreMenu from '../../../hooks/useMoreMenu';
import PmolModal from './pmolModal';

// Init
const ListPaneComponent = (props: {
  msDropDowns: any;
  vpList: any[];
  isDataLoaded: boolean;
  vpBarList: any[];
  handleBarTaskItemClick: (id: any) => void;
}) => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.Week);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  const [showGanttChart, setShowGanttChart] = React.useState(true);
  const [labourPerson, setLabourPerson] = React.useState<any>([]);
  const [toolResource, setToolResource] = React.useState<any>([]);
  const [searchText, setSearchText] = React.useState<any>();
  const [tree, setTree] = React.useState<any>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const { isPmolCreate, setPmolCreate, product } = useMoreMenu();
  // const store = useStore();

  const reduxBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpProjectBarList
  );

  const listTasks: any[] = useSelector((state: any) => state.vp.vpProjectList);

  // const {
  //   selectedProjectItemType,
  //   selectedStartDateProjectItem,
  //   selectedEndDateProjectItem,
  //   prCreatefromBor,
  //   prCreatedBorId,
  // }: any = useSelector((state: any) => state.vp);

  // const { showProgressBar, pbsCreated }: any = useSelector(
  //   (state: any) => state.projectBreakdown
  // );

  const interactBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpProjectInteractBarList
  );

  // const isLabourTeamAssigned: any[] = useSelector(
  //   (state: any) => state.vp.isLabourTeamAssignedProject
  // );

  // const isToolPoolAssigned: any[] = useSelector(
  //   (state: any) => state.vp.isToolPoolAssignedProject
  // );

  const selectedResourceType: string = useSelector(
    (state: any) => state.vp.selectedResourceTypeOrgProject
  );

  // useEffect(() => {
  //   if (isLabourTeamAssigned || isToolPoolAssigned) {
  //     dispatch(
  //         readProjectVPData({
  //             requestType: [selectedProjectItemType],
  //             startDate: new Date(selectedStartDateProjectItem),
  //             endDate: new Date(selectedEndDateProjectItem)
  //         })
  //     );
  //   }
  //   return() => {
  //     dispatch(saveVPStateAttr('siblingCount', 0))
  //   }
  // }, []);

  // useEffect(() => {
  //   if(isPlanning() && pbsCreated){
  //     dispatch(readProjectVPDataWithOutRefreshing({
  //       type: ['4'],
  //       startDate: formatDateTimeToISOString(store.getState().vp.selectedStartDateProjectItem),
  //       endDate: formatDateTimeToISOString(store.getState().vp.selectedEndDateProjectItem),
  //     }))
  //   }
  // },[pbsCreated]);

  const debounced = useDebouncedCallback(
    (value) => {
      if (selectedResourceType === RESOURCE_TYPE_IDS.LABOURS) {
        const payload = {
          id: uuidv4(),
          coperateProductCatalogId: null,
          requiredQuantity: 0,
          consumedQuantity: 0,
          cpcBasicUnitofMeasureId: null,
          pmolId: value.pmolSequenceId,
          type: null,
          personId: labourPerson.personId,
          pmolLabourId: value.id,
          roleId: null,
          startDate: value.startDate,
          endDate: value.endDate,
        };

        dispatch(updatePMOLLabourTeam(payload));
      } else {
        const payload = {
          id: uuidv4(),
          coperateProductCatalogId: toolResource.cpcId,
          requiredQuantity: 0,
          consumedQuantity: 0,
          cpcBasicUnitofMeasureId: null,
          pmolId: value.pmolSequenceId,
          type: null,
          personId: null,
          pmolLabourId: value.id,
          roleId: null,
          startDate: value.startDate,
          endDate: value.endDate,
        };
        dispatch(updatePMOLToolsPool(payload));
      }
    },
    // delay in ms
    1000
  );

  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.QuaterYear) {
    columnWidth = 350;
  }

  const handleTaskChange = (task: any) => {
    let type = null;
    let sequnceId = null;
    const startDate =
      moment(task.start).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate =
      moment(task.end).subtract(1, 'days').format('YYYY-MM-DD') +
      'T00:00:00.000Z';
    if (task.type === 'pmol' || task.pmolSequenceId) {
      type = 'PMOL';
      sequnceId = task.pmolSequenceId;
    } else if (
      task.type === 'bor' ||
      (task.borSequenceId && !task.pmolSequenceId)
    ) {
      type = 'BOR';
      sequnceId = task.borSequenceId;
    } else if (
      task.type === 'pbs' ||
      task.type === 'product' ||
      task.type === 'parentProduct'
    ) {
      type = 'PBS';
      sequnceId = task.pbsSequenceId;
      dispatch(saveVPStateAttr('selectedPBS', task.pbsSequenceId));
    } else if (task.type === 'milestone') {
      type = 'MILESTONE';
      sequnceId = task.milestoneSequanceId;
    }

    const ffData: any[] = getFlatDataFromTree({
      treeData: tree,
      getNodeKey: (node: any) => node.pbsSequenceId,
      ignoreCollapsed: false,
    });

    const finalresult = ffData.map((item: any) => {
      return item?.node;
    });

    let modifiedIndex = -1;
    if (type === 'PBS') {
      modifiedIndex = finalresult.findIndex(
        (t) => t.pbsSequenceId === task.pbsSequenceId
      );
    } else if (type === 'PMOL') {
      modifiedIndex = finalresult.findIndex(
        (t) => t.pmolSequenceId === task.pmolSequenceId
      );
    } else if (type === 'BOR') {
      modifiedIndex = finalresult.findIndex(
        (t) => t.borSequenceId === task.borSequenceId
      );
    }

    if (modifiedIndex >= 0) {
      finalresult[modifiedIndex] = {
        ...finalresult[modifiedIndex],
        start: task.start,
        end: task.end,
        startDate: task.start.toISOString().replace('Z', ''),
        endDate: task.end.toISOString().replace('Z', ''),
      };
      dispatch(saveVPStateAttr('vpProjectList', [...finalresult]));
    }

    if (type) {
      const payloadUpdate = {
        type: type,
        sequenceId: sequnceId,
        startDate: startDate,
        endDate: endDate,
      };
      dispatch(updateProjectItemsDate(payloadUpdate));
    }
  };

  const handleTaskDelete = (task: Task) => {};

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: Task) => {
    dispatch(saveVPStateAttr('selectedVpProjectBarType', task.type));
    dispatch(saveVPStateAttr('slectedPBSForBor', null));
    if (task.type.toString() === 'pbs') {
      dispatch(savePbsStateAttr('showProgressBar', true));
      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
      dispatch(saveVPStateAttr('selectedPBS', task.pbsSequenceId));
    }

    if (task.type.toString() === 'pmol') {
      dispatch(savePMOLStateAttr('showProgressBar', true));
      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
      dispatch(saveVPStateAttr('selectedPMOL', task.pmolSequenceId));
    }

    if (task.type.toString() === 'bor') {
      dispatch(saveBORStateAttr('showProgressBar', true));
      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
      dispatch(saveVPStateAttr('selectedBOR', task.borSequenceId));
      setSearchText(task.borSequenceId);
    }

    if (task.type.toString() === 'milestone') {
      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
      dispatch(saveVPStateAttr('selectedMileStone', task.milestoneSequanceId));
    }
  };

  const handleRightClick = (task: Task) => {};

  const handleSelect = (task: Task, isSelected: boolean) => {
    console.log(
      task.name + ' has ' + (isSelected ? 'selected' : 'unselected'),
      task
    );
    // props.handleBarTaskItemClick(task.poId);
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const stringToArr = (string1: any) => {
    if (string1) {
      const x = string1.trim().split(',');
      x.shift();
      return x;
    }
    return [];
  };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  const sumNodeValues = (treeData: any) => {
    let indexss = '';
    const callback = ({ node }: any) => {
      indexss += ',' + node.id;
    };
    walk({
      treeData,
      getNodeKey,
      callback,
      ignoreCollapsed: true,
    });
    let v = stringToArr(indexss);
    return indexss;
  };

  const onVisibilityChanged = (data: any) => {
    const treeTotal1 = sumNodeValues(data);
    if (stringToArr(treeTotal1).length <= interactBarTasks.length) {
      const newTasks = interactBarTasks.filter((item) =>
        stringToArr(treeTotal1).includes(item.id)
      );
      dispatch(saveVPStateAttr('vpProjectList', [...data]));
      dispatch(saveVPStateAttr('vpProjectInteractBarList', newTasks));
    } else {
      const newTasks = reduxBarTasks.filter((item) =>
        stringToArr(treeTotal1).includes(item.id)
      );
      dispatch(saveVPStateAttr('vpProjectList', [...data]));
      dispatch(saveVPStateAttr('vpProjectInteractBarList', newTasks));
    }
  };

  const onvisibilityToggled = (data: any) => {};

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  // const getTreeIndexList = (treeData: any[]) => {
  //   let total = 0;
  //   let modifiedArray: any[] = [];
  //   const callback = (props: any) => {
  //     modifiedArray.push({
  //       pbsProductId: props.node?.pbsSequenceId,
  //       treeIndex: props.treeIndex,
  //     });
  //     total += props.node.value;
  //   };

  //   walk({
  //     treeData,
  //     getNodeKey,
  //     callback,
  //     ignoreCollapsed: false,
  //   });
  //   dispatch(updateProductTaxonomyTreeIndex({ pbsTreeIndex: modifiedArray }));
  //   return total;
  // };

  return (
    <div style={{ height: '100%' }}>
      <ViewModeContext.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
          onShowGanttChartChange: (showGanttChart: boolean) => {},
          showGanttChart: showGanttChart,
        }}
      >
        <ExpandProvider>
          <ViewSwitcher
            onViewModeChange={(viewMode: React.SetStateAction<ViewMode>) =>
              setView(viewMode)
            }
            onViewListChange={setIsChecked}
            isChecked={isChecked}
            renderFilterComponent={() => <Label></Label>}
            msDropDowns={props.msDropDowns}
          />

          {!props.isDataLoaded && <ProgressBar show={true} />}
          {props.isDataLoaded && _.isEmpty(interactBarTasks) && (
            <Text style={{ marginLeft: 20 }}>{t('noData')}</Text>
          )}

          {props.isDataLoaded && !_.isEmpty(interactBarTasks) && (
            <Gantt
              tasks={
                listTasks &&
                _.isArray(listTasks) &&
                !_.isEmpty(listTasks) &&
                props.isDataLoaded &&
                !_.isEmpty(interactBarTasks) &&
                typeof interactBarTasks[0].start !== 'string'
                  ? interactBarTasks
                  : tasks
              }
              // tasks={ tasks }
              listTasks={
                listTasks &&
                _.isArray(listTasks) &&
                !_.isEmpty(listTasks) &&
                props.isDataLoaded
                  ? listTasks
                  : tasks
              }
              searchQuery={searchText}
              selectedScreen={''}
              searchMethod={() => {}}
              searchFocusOffset={0}
              searchFinishCallback={(matches) => {}}
              onlyExpandSearchedNodes={true}
              viewMode={view}
              onDateChange={handleTaskChange}
              onDelete={handleTaskDelete}
              onProgressChange={handleProgressChange}
              onDoubleClick={handleDblClick}
              onRightClick={handleRightClick}
              onSelect={handleSelect}
              onExpanderClick={handleExpanderClick}
              listCellWidth={isChecked ? '155px' : ''}
              columnWidth={columnWidth}
              onVisibilityChanged={onVisibilityChanged}
              onVisibilityToggle={onvisibilityToggled}
              ganttHeight={560}
              isInitialCollaps={true}
              canDrag={({ node, path, treeIndex }: any) => {
                return false;
              }}
              canDrop={({ node, nextParent }: any) => {
                let canDrop = false;
                if (
                  nextParent &&
                  (nextParent.type === 'LabourItem' ||
                    nextParent.type === 'Tool')
                ) {
                  canDrop = true;
                  if (node.personId && node.type === 'labour') {
                    debounced(nextParent);
                  } else if (node.cpcId && node.type === 'Tools') {
                    debounced(nextParent);
                  } else {
                    canDrop = false;
                  }
                } else {
                  canDrop = false;
                }
                return canDrop;
              }}
              onMoveNode={(path, node) => {
                if (node.personId) {
                  setLabourPerson(node);
                } else if (node.type === 'Tools') {
                  setToolResource(node);
                }
              }}
              chartType={ChartType.PBS}
              onTreeDataChange={(treeData) => {
                if (
                  treeData &&
                  Array.isArray(treeData) &&
                  treeData[0]?.id !== '0000'
                ) {
                  //getTreeIndexList(treeData)
                  setTree(treeData);
                }
                dispatch(
                  saveVPStateAttr('vpProjectBarList', [
                    ...formatVpProductBarDataWithoutRefresh(treeData)[0],
                  ])
                );
                dispatch(
                  saveVPStateAttr('vpProjectInteractBarList', [
                    ...formatVpProductBarDataWithoutRefresh(treeData)[1],
                  ])
                );
              }}
            />
          )}
          <PmolModal />
        </ExpandProvider>
      </ViewModeContext.Provider>
    </div>
  );
};

export default ListPaneComponent;
