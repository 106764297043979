// DragAndDropFileUpload.tsx
import { Icon, mergeStyleSets } from '@fluentui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../theme';

interface Props {
  multiple: boolean;
  getUploadedFile: (files: File[]) => void;
  disabled: boolean;
  buttonLabel?: string | undefined;
  iconName?: string | undefined
}

const classnames = mergeStyleSets({
  labelContainer: {
    height: '35px',
    marginLeft: '2px',
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    background: uPrinceTheme.palette.themePrimary,
  },
  label: {
    textAlign: 'center',
    padding: '0 4px'
  },
  uploadIcon: {
    padding: '0 4px'
  }
})

const styles = {
  iconStyle: { color: '#ffffff' }
}

const DragAndDropFileUpload: React.FC<Props> = ({
  multiple,
  getUploadedFile,
  disabled,
  buttonLabel,
  iconName
}) => {
  const { t } = useTranslation();
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
    setDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    updateFiles(droppedFiles);
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const filesArray = Array.from(selectedFiles);
      updateFiles(filesArray);
    }
  };

  const updateFiles = (newFiles: File[]) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles];
    setFiles(updatedFiles);
    getUploadedFile(updatedFiles);
  };

  // const handleRemoveFile = (fileToRemove: File) => {
  //   const filteredFiles = files.filter((file: File) => file !== fileToRemove);
  //   setFiles(filteredFiles);
  //   getUploadedFile(filteredFiles);
  // };

  return (
    <div
      className={`dropzone ${dragging ? 'dragging' : ''}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        type="file"
        id="file-input"
        multiple={multiple}
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        disabled={disabled}
      />

      <label
        htmlFor="file-input"
        className="file-input-label"
        style={{ color: 'white' }}
      >
        <div className={classnames.labelContainer}>
          <div className={classnames.uploadIcon}>
            <Icon
              iconName={iconName ? iconName : "CloudUpload"}
              style={styles.iconStyle}
            />
          </div>
          <div className={classnames.label}>
            {buttonLabel ? buttonLabel : t('dragAndDrop')}
          </div>
        </div>
      </label>
    </div>
  );
};

export default DragAndDropFileUpload;
