import i18n from '../../i18n';
import { v4 as uuidv4 } from 'uuid';
import {
  CLEAR_CPC_DATA,
  CLEAR_PBS_FORM_DATA,
  CLEAR_PBS_UID,
  CREATE_COMPETENCE,
  CREATE_COMPETENCE_FAIL,
  CREATE_COMPETENCE_SUCCESS,
  CREATE_CONSUMABLE,
  CREATE_CONSUMABLE_FAIL,
  CREATE_CONSUMABLE_SUCCESS,
  CREATE_LABOUR,
  CREATE_LABOUR_FAIL,
  CREATE_LABOUR_SUCCESS,
  CREATE_QUALITY,
  CREATE_QUALITY_FAIL,
  CREATE_QUALITY_SUCCESS,
  CREATE_RESOURCE,
  CREATE_RESOURCE_FAIL,
  CREATE_RESOURCE_SUCCESS,
  CREATE_RISK,
  CREATE_RISK_FAIL,
  CREATE_RISK_SUCCESS,
  CREATE_TECH_INSTRUCTION,
  CREATE_TECH_INSTRUCTION_FAIL,
  CREATE_TECH_INSTRUCTION_SUCCESS,
  CREATE_TOOLS,
  CREATE_TOOLS_FAIL,
  CREATE_TOOLS_SUCCESS,
  DELETE_BOR,
  DELETE_BOR_FAIL,
  DELETE_BOR_SUCCESS,
  DELETE_COMPETENCE,
  DELETE_COMPETENCE_FAIL,
  DELETE_COMPETENCE_SUCCESS,
  DELETE_CONSUMABLE,
  DELETE_CONSUMABLE_FAIL,
  DELETE_CONSUMABLE_SUCCESS,
  DELETE_LABOURS,
  DELETE_LABOURS_FAIL,
  DELETE_LABOURS_SUCCESS,
  DELETE_MATERIALS,
  DELETE_MATERIALS_FAIL,
  DELETE_MATERIALS_SUCCESS,
  DELETE_QUALITY,
  DELETE_QUALITY_FAIL,
  DELETE_QUALITY_SUCCESS,
  DELETE_RISK,
  DELETE_RISK_FAIL,
  DELETE_RISK_SUCCESS,
  DELETE_TECH_INSTRUCTION,
  DELETE_TECH_INSTRUCTION_FAIL,
  DELETE_TECH_INSTRUCTION_SUCCESS,
  DELETE_TOOLS,
  DELETE_TOOLS_FAIL,
  DELETE_TOOLS_SUCCESS,
  GET_BOR_BY_ID,
  GET_BOR_BY_ID_FAIL,
  GET_BOR_BY_ID_SUCCESS,
  GET_CPC_BY_ID,
  GET_CPC_BY_ID_FAIL,
  GET_CPC_BY_ID_SUCCESS,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_BY_ID_FAIL,
  GET_PRODUCT_BY_ID_SUCCESS,
  LOAD_COMPETENCE,
  LOAD_COMPETENCE_DROPDOWN,
  LOAD_COMPETENCE_DROPDOWN_FAIL,
  LOAD_COMPETENCE_DROPDOWN_SUCCESS,
  LOAD_COMPETENCE_FAIL,
  LOAD_COMPETENCE_SUCCESS,
  LOAD_DROPDOWN_DATA,
  LOAD_DROPDOWN_DATA_FAIL,
  LOAD_DROPDOWN_DATA_SUCCESS,
  LOAD_INSTRUCTION_DROPDOWN,
  LOAD_INSTRUCTION_DROPDOWN_FAIL,
  LOAD_INSTRUCTION_DROPDOWN_SUCCESS,
  LOAD_LIST_PANE_LIST,
  LOAD_LIST_PANE_LIST_FAIL,
  LOAD_LIST_PANE_LIST_SUCCESS,
  LOAD_MATERIALS,
  LOAD_MATERIALS_FAIL,
  LOAD_MATERIALS_SUCCESS,
  LOAD_PBS_MATERIALS,
  LOAD_PBS_MATERIALS_FAIL,
  LOAD_PBS_MATERIALS_SUCCESS,
  LOAD_QUALITY,
  LOAD_QUALITY_FAIL,
  LOAD_QUALITY_SUCCESS,
  LOAD_RESOURCE_LIST_PANE_LIST,
  LOAD_RESOURCE_LIST_PANE_LIST_FAIL,
  LOAD_RESOURCE_LIST_PANE_LIST_SUCCESS,
  LOAD_RISK_DROPDOWN,
  LOAD_RISK_DROPDOWN_FAIL,
  LOAD_RISK_DROPDOWN_SUCCESS,
  LOAD_SHORTCUT_PANE_FILTER_LIST,
  LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL,
  LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS,
  LOAD_TAXONOMY,
  LOAD_TAXONOMY_FAIL,
  LOAD_TAXONOMY_SUCCESS,
  LOAD_TECH_INSTRUCTION,
  LOAD_TECH_INSTRUCTION_FAIL,
  LOAD_TECH_INSTRUCTION_SUCCESS,
  READ_CONSUMABLE,
  READ_CONSUMABLE_FAIL,
  READ_CONSUMABLE_SUCCESS,
  READ_LABOURS,
  READ_LABOURS_FAIL,
  READ_LABOURS_SUCCESS,
  READ_PBS_CONSUMABLE,
  READ_PBS_CONSUMABLE_FAIL,
  READ_PBS_CONSUMABLE_SUCCESS,
  READ_PBS_LABOURS,
  READ_PBS_LABOURS_FAIL,
  READ_PBS_LABOURS_SUCCESS,
  READ_PBS_TOOLS,
  READ_PBS_TOOLS_FAIL,
  READ_PBS_TOOLS_SUCCESS,
  READ_RISK,
  READ_RISK_FAIL,
  READ_RISK_SUCCESS,
  READ_TOOLS,
  READ_TOOLS_FAIL,
  READ_TOOLS_SUCCESS,
  RESET_BOR_FORM_DATA,
  RESET_FORM_DATA,
  RESET_IS_CHANGE,
  RESET_RISK_GRID_LIST,
  SAVE_BOR,
  SAVE_BOR_FAIL,
  SAVE_BOR_STATE_ATTR,
  SAVE_BOR_SUCCESS,
  SAVE_PBS_QUALITY,
  SAVE_PBS_QUALITY_FAIL,
  SAVE_PBS_QUALITY_SUCCESS,
  SAVE_PBS_RISK,
  SAVE_PBS_RISK_FAIL,
  SAVE_PBS_RISK_SUCCESS,
  SEND_BOR_RETURN_RES,
  SET_PBS_UID,
  UPDATE_BOR_CONSUMABLE,
  UPDATE_BOR_CONSUMABLE_FAIL,
  UPDATE_BOR_CONSUMABLE_SUCCESS,
  UPDATE_BOR_LABOURS,
  UPDATE_BOR_LABOURS_FAIL,
  UPDATE_BOR_LABOURS_SUCCESS,
  UPDATE_BOR_MATERIAL,
  UPDATE_BOR_MATERIAL_FAIL,
  UPDATE_BOR_MATERIAL_SUCCESS,
  UPDATE_BOR_STATUS,
  UPDATE_BOR_STATUS_FAIL,
  UPDATE_BOR_STATUS_SUCCESS,
  UPDATE_BOR_TOOLS,
  UPDATE_BOR_TOOLS_FAIL,
  UPDATE_BOR_TOOLS_SUCCESS,
  UPLOAD_INSTRUCTION_DOC,
  UPLOAD_INSTRUCTION_DOC_FAIL,
  UPLOAD_INSTRUCTION_DOC_SUCCESS,
} from '../actionTypes/billOfResourcesType';
import {
  BOR_READ_LIST_PANE_DATA,
  BOR_READ_RESOURCE_LIST_PANE_DATA,
  BOR_READ_SHORTCUT_PANE_DATA,
  BOR_SAVE,
  COMPETENCE_FILTER_EP,
  CPC_BY_ID_EP,
  CREATE_COMPETENCE_EP,
  CREATE_CONSUMABLE_EP,
  CREATE_INSTRUCTION_EP,
  CREATE_LABOUR_EP,
  CREATE_MATERIALS_EP,
  CREATE_TOOLS_EP,
  DELETE_BOR_CONSUMABLE_EP,
  DELETE_BOR_EP,
  DELETE_BOR_LABOURS_EP,
  DELETE_BOR_MATERIALS_EP,
  DELETE_BOR_TOOLS_EP,
  DELETE_COMPETENCE_EP,
  DELETE_TECH_INSTRUCTION_EP,
  getConsumableByProductIdURL,
  getLabourByProductIdURL,
  getMaterialByProductIdURL,
  getPbsInstructionByType,
  getToolsByProductIdURL,
  PBS_BOR_BY_ID,
  PBS_CONSUMABLE_EP,
  PBS_LABOURS_EP,
  PBS_MATERIALS_EP,
  PBS_PRODUCT_BY_ID,
  PBS_TOOLS_EP,
  QUALITY_FILTER_EP,
  READ_BOR_DROPDOWN_DATA_EP,
  READ_COMPETENCE_DROPDOWN_EP,
  READ_INSTRUCTION_DROPDOWN_EP,
  READ_TAXONOMY_DATA,
  RISK_DROPDWON_EP,
  UPDATE_BOR_CONSUMABLE_EP,
  UPDATE_BOR_LABOURS_EP,
  UPDATE_BOR_MATERIAL_EP,
  UPDATE_BOR_STATUS_EP,
  UPDATE_BOR_TOOLS_EP,
  UPLOAD_INSTRUCTION_DOC_EP,
} from '../shared/endpoints';
import UprinceLogger from '../shared/Logger/logger';
import {
  BillOfResource,
  BORResourceUpdateRequest,
  BORStatusUpdateRequest,
  Competence,
  FilterItem,
  Resource,
  ResourceFilterItem,
} from '../types/billOfResources';


const defaultState: any = {
  formData: [],
  productData: null,
  cpcData: null,
  shortCutPaneFilters: [],
  borListPaneItem: [],
  resourceListPaneItem: [],
  toleranceStates: [],
  productStates: [],
  borStatus: [],
  borType: [],
  itemTypes: [],
  toleranceStatesFilter: [],
  productStatesFilter: [],
  itemTypesFilter: [],
  uid: null,
  loadMsgType: '',
  loadMsg: '',
  alertMsg: '',
  alertMsgType: '',
  saveErrorMsg: '',
  loading: false,
  isDataLoaded: false,
  isDetailsLoaded: true,
  isChange: false,
  autoSave: false,
  dropdowns: null,
  createRiskStatus: false,
  riskList: [],
  readRiskStatus: false,
  deleteRiskStatus: false,
  qualityList: [],
  readQualityStatus: false,
  createQualityStatus: false,
  deleteQualityStatus: false,
  isNew: false,
  isEdit: false,
  readMaterialsStatus: false,
  materials: [],
  createMaterialsStatus: false,
  newRiskId: null,
  saveRiskStatus: false,
  newQualityId: null,
  saveQualityStatus: false,
  tools: [],
  readToolsStatus: false,
  createToolsStatus: false,
  deleteMaterialsStatus: false,
  deleteToolsStatus: false,
  readLabourStatus: false,
  labours: [],
  createLabourStatus: false,
  deleteLabourStatus: false,
  readConsumableStatus: false,
  consumables: [],
  createConsumableStatus: false,
  deleteConsumableStatus: false,
  instructionDropDowns: [],
  readTechInstructionStatus: false,
  techInstructions: [],
  instructionUploadStatus: false,
  uploadedInstructionLink: null,
  locationTaxonomy: [],
  utilityTaxonomy: [],
  createTechInstructionStatus: false,
  competenceList: [],
  readCompetenceStatus: false,
  saveCompetenceStatus: false,
  pbsSkills: [],
  pbsExperience: [],
  deleteCompetenceStatus: false,
  deleteTechInstructionStatus: false,
  showProgressBar: false,
  returnResources: null,
  borCreated: false,
  isNewBor: false,
  createdVPBOR:null,
  borCreatedFromVP: false,
  isBOROpenFromVP: true
};

const billOfResourcesReducer = (state = defaultState, action: any) => {
  switch (action.type) {

    case RESET_FORM_DATA:
      return { ...state, formData: [], isEdit: false };
    case RESET_BOR_FORM_DATA:
      return { ...state, formData: [], isEdit: false };
    case LOAD_SHORTCUT_PANE_FILTER_LIST:
      return { ...state, loading: true };
    case LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shortCutPaneFilters: action.payload.data.result,
      };
    case LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_LIST_PANE_LIST:
      return {
        ...state,
        loading: true,
        borListPaneItem: [],
        isDataLoaded: false,
        loadMsg: '',
      };
    case LOAD_LIST_PANE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isChange: false,
        borListPaneItem: action.payload.data.status ? action.payload.data.result : [],
        loadMsg: !action.payload.data.status ? action.payload.data.message : '',
        isDataLoaded: true,
      };
    case LOAD_LIST_PANE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        isChange: false,
        error: 'Error while fetching Time Clock',
        loadMsg: action.error.response.data.message,
        borListPaneItem: [],
        isDataLoaded: true,
      };

    case LOAD_RESOURCE_LIST_PANE_LIST:
      return {
        ...state,
        loading: true,
        resourceListPaneItem: [],
        isDataLoaded: false,
        loadMsg: '',
      };
    case LOAD_RESOURCE_LIST_PANE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isChange: false,
        resourceListPaneItem: action.payload.data.status ? action.payload.data.result : [],
        loadMsg: !action.payload.data.status ? action.payload.data.message : '',
        isDataLoaded: true,
      };
    case LOAD_RESOURCE_LIST_PANE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        isChange: false,
        error: 'Error while fetching Time Clock',
        loadMsg: action.error.response.data.message,
        resourceListPaneItem: [],
        isDataLoaded: true,
      };

    case LOAD_DROPDOWN_DATA:
      return { ...state, loading: true };

    case LOAD_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        borStatus: formattedPayloadToDropdown(
          action.payload.data.result.borStatus,
        ),
        borType: action.payload.data.result.borType,
        borDropdownList: formattedPayloadToDropdownList(action.payload.data.result),
      };

    case LOAD_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: 'Error while fetching Time Clock',
      };

    case LOAD_RISK_DROPDOWN:
      UprinceLogger.log('LOAD_RISK_DROPDOWN');
      return { ...state };
    case LOAD_RISK_DROPDOWN_SUCCESS:
      UprinceLogger.log(
        'LOAD_RISK_DROPDOWN_SUCCESS',
        action.payload.data.result,
      );
      return {
        ...state,
        dropdowns: action.payload.data.result,
      };
    case LOAD_RISK_DROPDOWN_FAIL:
      UprinceLogger.log('LOAD_RISK_DROPDOWN');
      return {
        ...state,
      };

    case SAVE_BOR:
      return { ...state, showProgressBar: true, borCreated: false };
    case SAVE_BOR_SUCCESS:
      return {
        ...state,
        isChange: true,
        isNew: false,
        showProgressBar: false,
        borCreated: true,
      };
    case SAVE_BOR_FAIL:
      return {
        ...state,
        showProgressBar: false,
        saveErrorMsg: action?.error?.response?.data?.message,
      };

    case GET_BOR_BY_ID:
      return { ...state, formData: [], showProgressBar: true };
    case GET_BOR_BY_ID_SUCCESS:
      return {
        ...state,
        isEdit: true,
        formData: action.payload.data.result,
        showProgressBar: false,
        createdVPBOR: action.payload.data.result,
      };
    case GET_BOR_BY_ID_FAIL:
      return {
        ...state,
        showProgressBar: false,
      };

    case GET_PRODUCT_BY_ID:
      return { ...state, productData: null };
    case GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        productData: action.payload.data.result,
      };
    case GET_PRODUCT_BY_ID_FAIL:
      return {
        ...state,
      };


    case GET_CPC_BY_ID:
      return { ...state, cpcData: null };
    case GET_CPC_BY_ID_SUCCESS:
      return {
        ...state,
        cpcData: action.payload.data.result,
      };
    case GET_CPC_BY_ID_FAIL:
      return {
        ...state,
      };


    case CREATE_RISK:
      UprinceLogger.log('CREATE_RISK');
      return { ...state, createRiskStatus: false };
    case CREATE_RISK_SUCCESS:
      UprinceLogger.log('CREATE_RISK_SUCCESS', action.payload.data.result);
      return {
        ...state,
        createRiskStatus: true,
        saveRiskStatus: false,
        newRiskId: action.payload.data.result
          ? action.payload.data.result.id
          : null,
      };
    case CREATE_RISK_FAIL:
      UprinceLogger.log('CREATE_RISK_FAIL');
      return {
        ...state,
        createRiskStatus: false,
      };
    case SAVE_PBS_RISK:
      UprinceLogger.log('CREATE_RISK');
      return { ...state, createRiskStatus: false };
    case SAVE_PBS_RISK_SUCCESS:
      UprinceLogger.log('CREATE_RISK_SUCCESS', action.payload.data.result);
      return {
        ...state,
        createRiskStatus: false,
        saveRiskStatus: true,
        newRiskId: null,
      };
    case SAVE_PBS_RISK_FAIL:
      UprinceLogger.log('CREATE_RISK_FAIL');
      return {
        ...state,
        createRiskStatus: false,
        saveRiskStatus: false,
      };
    case READ_RISK:
      UprinceLogger.log('READ_RISK');
      return { ...state, readRiskStatus: true };
    case READ_RISK_SUCCESS:
      UprinceLogger.log('READ_RISK_SUCCESS', action.payload.data.result);
      return {
        ...state,
        riskList: action.payload.data.result,
        readRiskStatus: false,
        saveRiskStatus: false,
      };
    case READ_RISK_FAIL:
      UprinceLogger.log('READ_RISK_FAIL');
      return {
        ...state,
        readRiskStatus: false,
      };
    case DELETE_RISK:
      UprinceLogger.log('DELETE_RISK');
      return { ...state, deleteRiskStatus: false };
    case DELETE_RISK_SUCCESS:
      UprinceLogger.log('DELETE_RISK_SUCCESS', action.payload.data.result);
      return {
        ...state,
        deleteRiskStatus: true,
      };
    case DELETE_RISK_FAIL:
      UprinceLogger.log('DELETE_RISK_FAIL');
      return {
        ...state,
        readRiskStatus: false,
      };
    case LOAD_QUALITY:
      UprinceLogger.log('LOAD_QUALITY');
      return { ...state, readQualityStatus: true };
    case LOAD_QUALITY_SUCCESS:
      UprinceLogger.log('LOAD_QUALITY_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readQualityStatus: false,
        qualityList: action.payload.data.result,
      };
    case LOAD_QUALITY_FAIL:
      UprinceLogger.log('LOAD_QUALITY_FAIL');
      return {
        ...state,
        readQualityStatus: false,
      };
    case CREATE_QUALITY:
      UprinceLogger.log('CREATE_QUALITY');
      return { ...state, createQualityStatus: false };
    case CREATE_QUALITY_SUCCESS:
      UprinceLogger.log('CREATE_QUALITY_SUCCESS', action.payload.data.result);
      return {
        ...state,
        createQualityStatus: true,
        saveQualityStatus: false,
        newQualityId: action.payload.data.result
          ? action.payload.data.result.id
          : null,
      };
    case CREATE_QUALITY_FAIL:
      UprinceLogger.log('CREATE_QUALITY_FAIL');
      return {
        ...state,
        createQualityStatus: false,
      };

    case SAVE_PBS_QUALITY:
      UprinceLogger.log('CREATE_RISK');
      return { ...state, createQualityStatus: false };
    case SAVE_PBS_QUALITY_SUCCESS:
      UprinceLogger.log('CREATE_RISK_SUCCESS', action.payload.data.result);
      return {
        ...state,
        createQualityStatus: false,
        saveQualityStatus: true,
        newQualityId: null,
      };
    case SAVE_PBS_QUALITY_FAIL:
      UprinceLogger.log('CREATE_RISK_FAIL');
      return {
        ...state,
        createQualityStatus: false,
        saveQualityStatus: false,
      };

    case DELETE_QUALITY:
      UprinceLogger.log('DELETE_QUALITY');
      return { ...state, deleteQualityStatus: false };
    case DELETE_QUALITY_SUCCESS:
      UprinceLogger.log('DELETE_QUALITY_SUCCESS', action.payload.data.result);
      return {
        ...state,
        deleteQualityStatus: action.payload.data.result,
      };
    case DELETE_QUALITY_FAIL:
      UprinceLogger.log('DELETE_QUALITY_FAIL');
      return {
        ...state,
        deleteQualityStatus: false,
      };

    case SET_PBS_UID:
      return {
        ...state,
        isEdit: false,
        uid: uuidv4(),
      };
    case CLEAR_PBS_UID:
      return {
        ...state,
        uid: null,
        isChange: false,
      };
    case CLEAR_CPC_DATA:
      return {
        ...state,
        cpcData: null,
      };
    case CLEAR_PBS_FORM_DATA:
      return {
        ...state,
        uid: null,
        isNew: true,
        isChange: false,
        formData: [],
      };
    case RESET_IS_CHANGE:
      return {
        ...state,
        isChange: false,
      };
    case RESET_RISK_GRID_LIST:
      return {
        ...state,
        riskList: [],
      };

    case LOAD_MATERIALS:
      UprinceLogger.log('LOAD_MATERIALS');
      return { ...state, readMaterialsStatus: true, materials: [] };
    case LOAD_MATERIALS_SUCCESS:
      UprinceLogger.log('LOAD_MATERIALS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readMaterialsStatus: false,
        materials: action.payload.data.result,
      };
    case LOAD_MATERIALS_FAIL:
      UprinceLogger.log('LOAD_MATERIALS_FAIL');
      return {
        ...state,
        readMaterialsStatus: false,
      };


    case LOAD_PBS_MATERIALS:
      UprinceLogger.log('LOAD_MATERIALS');
      return { ...state, readMaterialsStatus: true, materials: [] };
    case LOAD_PBS_MATERIALS_SUCCESS:
      UprinceLogger.log('LOAD_MATERIALS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readMaterialsStatus: false,
        materials: action.payload.data.result,
      };
    case LOAD_PBS_MATERIALS_FAIL:
      UprinceLogger.log('LOAD_MATERIALS_FAIL');
      return {
        ...state,
        readMaterialsStatus: false,
      };

    case CREATE_RESOURCE:
      UprinceLogger.log('CREATE_RESOURCE');
      return { ...state, createMaterialsStatus: false };
    case CREATE_RESOURCE_SUCCESS:
      UprinceLogger.log('CREATE_RESOURCE_SUCCESS', action.payload.data.result);
      return {
        ...state,
        createMaterialsStatus: true,
      };
    case CREATE_RESOURCE_FAIL:
      UprinceLogger.log('CREATE_RESOURCE_FAIL');
      return {
        ...state,
        createMaterialsStatus: false,
      };

    case READ_TOOLS:
      UprinceLogger.log('READ_TOOLS');
      return { ...state, readToolsStatus: true, tools: [] };
    case READ_TOOLS_SUCCESS:
      UprinceLogger.log('READ_TOOLS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readToolsStatus: false,
        tools: action.payload.data.result,
      };
    case READ_TOOLS_FAIL:
      UprinceLogger.log('READ_TOOLS_FAIL');
      return {
        ...state,
        readToolsStatus: false,
      };

    case READ_PBS_TOOLS:
      UprinceLogger.log('READ_TOOLS');
      return { ...state, readToolsStatus: true, tools: [] };
    case READ_PBS_TOOLS_SUCCESS:
      UprinceLogger.log('READ_TOOLS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readToolsStatus: false,
        tools: action.payload.data.result.map((item: any) => {
          return {
            ...item,
            date: new Date(),
          };
        }),
      };
    case READ_PBS_TOOLS_FAIL:
      UprinceLogger.log('READ_TOOLS_FAIL');
      return {
        ...state,
        readToolsStatus: false,
      };


    case CREATE_TOOLS:
      UprinceLogger.log('CREATE_TOOLS');
      return { ...state, createToolsStatus: false };
    case CREATE_TOOLS_SUCCESS:
      UprinceLogger.log('CREATE_TOOLS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        createToolsStatus: true,
      };
    case CREATE_TOOLS_FAIL:
      UprinceLogger.log('CREATE_TOOLS_FAIL');
      return {
        ...state,
        createToolsStatus: false,
      };

    case DELETE_MATERIALS:
      UprinceLogger.log('DELETE_MATERIALS');
      return { ...state, deleteMaterialsStatus: false };
    case DELETE_MATERIALS_SUCCESS:
      UprinceLogger.log('DELETE_MATERIALS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        deleteMaterialsStatus: true,
      };
    case DELETE_MATERIALS_FAIL:
      UprinceLogger.log('DELETE_MATERIALS_FAIL');
      return {
        ...state,
        deleteMaterialsStatus: false,
      };

    case DELETE_TOOLS:
      UprinceLogger.log('DELETE_TOOLS');
      return { ...state, deleteToolsStatus: false };
    case DELETE_TOOLS_SUCCESS:
      UprinceLogger.log('DELETE_TOOLS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        deleteToolsStatus: true,
      };
    case DELETE_TOOLS_FAIL:
      UprinceLogger.log('DELETE_TOOLS_FAIL');
      return {
        ...state,
        deleteToolsStatus: false,
      };

    case READ_LABOURS:
      UprinceLogger.log('READ_LABOURS');
      return { ...state, readLabourStatus: true, labours: [] };
    case READ_LABOURS_SUCCESS:
      UprinceLogger.log('READ_LABOURS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readLabourStatus: false,
        labours: action.payload.data.result,
      };
    case READ_LABOURS_FAIL:
      UprinceLogger.log('READ_LABOURS_FAIL');
      return {
        ...state,
        labours: [],
        readLabourStatus: false,
      };

    case READ_PBS_LABOURS:
      UprinceLogger.log('READ_LABOURS');
      return { ...state, readLabourStatus: true, labours: [] };
    case READ_PBS_LABOURS_SUCCESS:
      UprinceLogger.log('READ_LABOURS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readLabourStatus: false,
        labours: action.payload.data.result,
      };
    case READ_PBS_LABOURS_FAIL:
      UprinceLogger.log('READ_LABOURS_FAIL');
      return {
        ...state,
        labours: [],
        readLabourStatus: false,
      };

    case CREATE_LABOUR:
      UprinceLogger.log('CREATE_LABOUR');
      return { ...state, createLabourStatus: false };
    case CREATE_LABOUR_SUCCESS:
      UprinceLogger.log('CREATE_LABOUR_SUCCESS', action.payload.data.result);
      return {
        ...state,
        createLabourStatus: true,
      };
    case CREATE_LABOUR_FAIL:
      UprinceLogger.log('CREATE_LABOUR_FAIL');
      return {
        ...state,
        createLabourStatus: false,
      };
    case DELETE_LABOURS:
      UprinceLogger.log('DELETE_LABOURS');
      return { ...state, deleteLabourStatus: false };
    case DELETE_LABOURS_SUCCESS:
      UprinceLogger.log('DELETE_LABOURS_SUCCESS', action.payload.data.result);
      return {
        ...state,
        deleteLabourStatus: true,
      };
    case DELETE_LABOURS_FAIL:
      UprinceLogger.log('DELETE_LABOURS_FAIL');
      return {
        ...state,
        deleteLabourStatus: false,
      };

    case READ_CONSUMABLE:
      UprinceLogger.log('READ_CONSUMABLE');
      return { ...state, readConsumableStatus: true, consumables: [] };
    case READ_CONSUMABLE_SUCCESS:
      UprinceLogger.log('READ_CONSUMABLE_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readConsumableStatus: false,
        consumables: action.payload.data.result,
      };
    case READ_CONSUMABLE_FAIL:
      UprinceLogger.log('READ_CONSUMABLE_FAIL');
      return {
        ...state,
        consumables: [],
        readConsumableStatus: false,
      };

    case READ_PBS_CONSUMABLE:
      UprinceLogger.log('READ_CONSUMABLE');
      return { ...state, readConsumableStatus: true, consumables: [] };
    case READ_PBS_CONSUMABLE_SUCCESS:
      UprinceLogger.log('READ_CONSUMABLE_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readConsumableStatus: false,
        consumables: action.payload.data.result,
      };
    case READ_PBS_CONSUMABLE_FAIL:
      UprinceLogger.log('READ_CONSUMABLE_FAIL');
      return {
        ...state,
        consumables: [],
        readConsumableStatus: false,
      };

    case CREATE_CONSUMABLE:
      UprinceLogger.log('CREATE_CONSUMABLE');
      return { ...state, createConsumableStatus: false };
    case CREATE_CONSUMABLE_SUCCESS:
      UprinceLogger.log(
        'CREATE_CONSUMABLE_SUCCESS',
        action.payload.data.result,
      );
      return {
        ...state,
        createConsumableStatus: true,
      };
    case CREATE_CONSUMABLE_FAIL:
      UprinceLogger.log('CREATE_CONSUMABLE_FAIL');
      return {
        ...state,
        createConsumableStatus: false,
      };
    case DELETE_CONSUMABLE:
      UprinceLogger.log('DELETE_CONSUMABLE');
      return { ...state, deleteConsumableStatus: false };
    case DELETE_CONSUMABLE_SUCCESS:
      UprinceLogger.log('DELETE_CONSUMABLE_SUCCESS', action.payload.data.result);
      return {
        ...state,
        deleteConsumableStatus: true,
      };
    case DELETE_CONSUMABLE_FAIL:
      UprinceLogger.log('DELETE_CONSUMABLE_FAIL');
      return {
        ...state,
        deleteConsumableStatus: false,
      };
    case LOAD_INSTRUCTION_DROPDOWN:
      UprinceLogger.log('LOAD_INSTRUCTION_DROPDOWN');
      return { ...state, instructionDropDowns: [] };
    case LOAD_INSTRUCTION_DROPDOWN_SUCCESS:
      UprinceLogger.log('LOAD_INSTRUCTION_DROPDOWN_SUCCESS', action.payload.data.result);
      return {
        ...state,
        instructionDropDowns: action.payload.data.result ? action.payload.data.result.pbsInstructionFamily : [],
      };
    case LOAD_INSTRUCTION_DROPDOWN_FAIL:
      UprinceLogger.log('LOAD_INSTRUCTION_DROPDOWN_FAIL');
      return {
        ...state,
        instructionDropDowns: [],
      };
    case LOAD_TECH_INSTRUCTION:
      UprinceLogger.log('LOAD_TECH_INSTRUCTION');
      return { ...state, techInstructions: [], readTechInstructionStatus: true };
    case LOAD_TECH_INSTRUCTION_SUCCESS:
      UprinceLogger.log('LOAD_TECH_INSTRUCTION_SUCCESS', action.payload.data.result);
      return {
        ...state,
        readTechInstructionStatus: false,
        techInstructions: action.payload.data.result,
      };
    case LOAD_TECH_INSTRUCTION_FAIL:
      UprinceLogger.log('LOAD_TECH_INSTRUCTION_FAIL');
      return {
        ...state,
        readTechInstructionStatus: false,
        techInstructions: [],
      };
    case UPLOAD_INSTRUCTION_DOC:
      UprinceLogger.log('UPLOAD_INSTRUCTION_DOC');
      return { ...state, uploadedInstructionLink: null, instructionUploadStatus: false };
    case UPLOAD_INSTRUCTION_DOC_SUCCESS:
      UprinceLogger.log('UPLOAD_INSTRUCTION_DOC_SUCCESS', action.payload.data.result);
      return {
        ...state,
        instructionUploadStatus: true,
        uploadedInstructionLink: action.payload.data.result,
      };
    case UPLOAD_INSTRUCTION_DOC_FAIL:
      UprinceLogger.log('UPLOAD_INSTRUCTION_DOC_FAIL');
      return {
        ...state,
        instructionUploadStatus: false,
        uploadedInstructionLink: null,
      };
    case LOAD_TAXONOMY:
      return { ...state };
    case LOAD_TAXONOMY_SUCCESS:
      return {
        ...state,
        locationTaxonomy: action.payload.data.result.locationTaxonomy,
        utilityTaxonomy: action.payload.data.result.utilityTaxonomy,
      };
    case LOAD_TAXONOMY_FAIL:
      return {
        ...state,
      };
    case CREATE_TECH_INSTRUCTION:
      return { ...state, createTechInstructionStatus: false };
    case CREATE_TECH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        createTechInstructionStatus: true,
      };
    case CREATE_TECH_INSTRUCTION_FAIL:
      return {
        ...state, createTechInstructionStatus: false,
      };

    case LOAD_COMPETENCE:
      return { ...state, readCompetenceStatus: true };
    case LOAD_COMPETENCE_SUCCESS:
      return {
        ...state,
        competenceList: action.payload.data.result,
        readCompetenceStatus: false,
        saveCompetenceStatus: false,
      };
    case LOAD_COMPETENCE_FAIL:
      return {
        ...state,
        readCompetenceStatus: false,
      };
    case LOAD_COMPETENCE_DROPDOWN:
      return { ...state, pbsSkills: [], pbsExperience: [] };
    case LOAD_COMPETENCE_DROPDOWN_SUCCESS:
      return {
        ...state,
        pbsSkills: action.payload.data.result ? action.payload.data.result.pbsSkills : [],
        pbsExperience: action.payload.data.result ? action.payload.data.result.pbsExperience : [],
      };
    case LOAD_COMPETENCE_DROPDOWN_FAIL:
      return {
        ...state,
        pbsSkills: [],
        pbsExperience: [],
      };

    case CREATE_COMPETENCE:
      return { ...state, saveCompetenceStatus: false };
    case CREATE_COMPETENCE_SUCCESS:
      return {
        ...state,
        saveCompetenceStatus: true,
      };
    case CREATE_COMPETENCE_FAIL:
      return {
        ...state, saveCompetenceStatus: false,
      };

    case DELETE_COMPETENCE:
      return { ...state, deleteCompetenceStatus: false };
    case DELETE_COMPETENCE_SUCCESS:
      return {
        ...state,
        deleteCompetenceStatus: true,
      };
    case DELETE_COMPETENCE_FAIL:
      return {
        ...state, deleteCompetenceStatus: false,
      };
    case DELETE_TECH_INSTRUCTION:
      return { ...state, deleteTechInstructionStatus: false };
    case DELETE_TECH_INSTRUCTION_SUCCESS:
      return {
        ...state,
        deleteTechInstructionStatus: true,
      };
    case DELETE_TECH_INSTRUCTION_FAIL:
      return {
        ...state, deleteTechInstructionStatus: false,
      };

    case DELETE_BOR:
      return { ...state, loading: true };
    case DELETE_BOR_SUCCESS:
      return {
        ...state,
        isChange: true,
        loading: false,
        alertMsg: '',
        alertMsgType: 'success',
      };
    case DELETE_BOR_FAIL:
      return {
        ...state,
        loadMsg: action.error.response.data.message,
        loadMsgType: 'error',
        loading: false,
        error: 'Error while fetching CPC',
      };
    case UPDATE_BOR_STATUS:
      return { ...state };
    case UPDATE_BOR_STATUS_SUCCESS:
      UprinceLogger.log('UPDATE_BOR_STATUS_SUCCESS');
      return {
        ...state,
      };
    case UPDATE_BOR_STATUS_FAIL:
      return {
        ...state,
      };

    case UPDATE_BOR_MATERIAL:
      return { ...state };
    case UPDATE_BOR_MATERIAL_SUCCESS:
      UprinceLogger.log('UPDATE_BOR_STATUS_SUCCESS');
      return {
        ...state,
      };
    case UPDATE_BOR_MATERIAL_FAIL:
      return {
        ...state,
      };

    case UPDATE_BOR_TOOLS:
      return { ...state };
    case UPDATE_BOR_TOOLS_SUCCESS:
      UprinceLogger.log('UPDATE_BOR_STATUS_SUCCESS');
      return {
        ...state,
      };
    case UPDATE_BOR_TOOLS_FAIL:
      return {
        ...state,
      };

    case UPDATE_BOR_CONSUMABLE:
      return { ...state };
    case UPDATE_BOR_CONSUMABLE_SUCCESS:
      UprinceLogger.log('UPDATE_BOR_STATUS_SUCCESS');
      return {
        ...state,
      };
    case UPDATE_BOR_CONSUMABLE_FAIL:
      return {
        ...state,
      };

    case UPDATE_BOR_LABOURS:
      return { ...state };
    case UPDATE_BOR_LABOURS_SUCCESS:
      UprinceLogger.log('UPDATE_BOR_STATUS_SUCCESS');
      return {
        ...state,
      };
    case UPDATE_BOR_LABOURS_FAIL:
      return {
        ...state,
      };
    case SEND_BOR_RETURN_RES:
      return {
        ...state,
        returnResources: action.payload,
      };
    case SAVE_BOR_STATE_ATTR:
      const attr = Object.keys(action)[1];
      const value = Object.values(action)[1];
      return {
        ...state,
        [attr]: value,
      };

    default:
      return state;
  }
};

export const getShortcutPaneFilterList = () => {
  return {
    types: [
      LOAD_SHORTCUT_PANE_FILTER_LIST,
      LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS,
      LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL,
    ],
    payload: {
      request: {
        url: BOR_READ_SHORTCUT_PANE_DATA,
        method: 'GET',
      },
    },
  };
};

export function saveBORStateAttr(attr: string, value: any) {
  return {
    type: SAVE_BOR_STATE_ATTR,
    [attr]: value,
  };
}

export const getById = (id: string) => {
  return {
    types: [GET_BOR_BY_ID, GET_BOR_BY_ID_SUCCESS, GET_BOR_BY_ID_FAIL],
    payload: {
      request: {
        url: PBS_BOR_BY_ID + id,
        method: 'GET',
      },
    },
  };
};

export const getProductById = (id: string) => {
  return {
    types: [GET_PRODUCT_BY_ID, GET_PRODUCT_BY_ID_SUCCESS, GET_PRODUCT_BY_ID_FAIL],
    payload: {
      request: {
        url: PBS_PRODUCT_BY_ID + id,
        method: 'GET',
      },
    },
  };
};

export const getCpcById = (cpc: any) => {
  let id = cpc.value;
  let environment = cpc.environment;
  return {
    types: [GET_CPC_BY_ID, GET_CPC_BY_ID_SUCCESS, GET_CPC_BY_ID_FAIL],
    payload: {
      request: {
        url: CPC_BY_ID_EP + id,
        method: 'GET',
        headers: { environment: environment },
      },
    },
  };
};
export const clearCpcData = () => {
  return {
    type: CLEAR_CPC_DATA,
  };
};

export const setUid = () => {
  return {
    type: SET_PBS_UID,
  };
};

export const clearUid = () => {
  return {
    type: CLEAR_PBS_UID,
  };
};
export const clearFormData = () => {
  return {
    type: CLEAR_PBS_FORM_DATA,
  };
};

export const resetBorFormDate = () => {
  return {
    type: RESET_BOR_FORM_DATA,
  };
};

export const resetIsChange = () => {
  return {
    type: RESET_IS_CHANGE,
  };
};
export const resetRiskGridList = () => {
  return {
    type: RESET_RISK_GRID_LIST,
  };
};

export const saveFormData = (billOfResource: BillOfResource) => {
  return {
    types: [SAVE_BOR, SAVE_BOR_SUCCESS, SAVE_BOR_FAIL],
    payload: {
      request: {
        url: BOR_SAVE,
        method: 'POST',
        data: billOfResource,
      },
    },
  };
};

export const getListPaneData = (filterItem: FilterItem) => {
  return {
    types: [
      LOAD_LIST_PANE_LIST,
      LOAD_LIST_PANE_LIST_SUCCESS,
      LOAD_LIST_PANE_LIST_FAIL,
    ],
    payload: {
      request: {
        url: BOR_READ_LIST_PANE_DATA,
        method: 'POST',
        data: filterItem,
      },
    },
  };
};

export const getResourcesListPaneData = (filterItem: ResourceFilterItem) => {
  return {
    types: [
      LOAD_RESOURCE_LIST_PANE_LIST,
      LOAD_RESOURCE_LIST_PANE_LIST_SUCCESS,
      LOAD_RESOURCE_LIST_PANE_LIST_FAIL,
    ],
    payload: {
      request: {
        url: BOR_READ_RESOURCE_LIST_PANE_DATA,
        method: 'POST',
        data: filterItem,
      },
    },
  };
};

export const getRiskDropDownsList = () => {
  return {
    types: [
      LOAD_RISK_DROPDOWN,
      LOAD_RISK_DROPDOWN_SUCCESS,
      LOAD_RISK_DROPDOWN_FAIL,
    ],
    payload: {
      request: {
        url: RISK_DROPDWON_EP,
      },
    },
  };
};


export const readDropdownData = () => {
  return {
    types: [
      LOAD_DROPDOWN_DATA,
      LOAD_DROPDOWN_DATA_SUCCESS,
      LOAD_DROPDOWN_DATA_FAIL,
    ],
    payload: {
      request: {
        url: READ_BOR_DROPDOWN_DATA_EP,
        method: 'GET',
      },
    },
  };
};

export const borDropdownData = () => {
  return {
    types: [
      LOAD_DROPDOWN_DATA,
      LOAD_DROPDOWN_DATA_SUCCESS,
      LOAD_DROPDOWN_DATA_FAIL,
    ],
    payload: {
      request: {
        url: READ_BOR_DROPDOWN_DATA_EP,
        method: 'GET',
      },
    },
  };
};

export const readTaxonomyData = () => {
  return {
    types: [
      LOAD_TAXONOMY,
      LOAD_TAXONOMY_SUCCESS,
      LOAD_TAXONOMY_FAIL,
    ],
    payload: {
      request: {
        url: READ_TAXONOMY_DATA,
        method: 'GET',
      },
    },
  };
};

export const filterQualityList = (id: string) => {
  return {
    types: [LOAD_QUALITY, LOAD_QUALITY_SUCCESS, LOAD_QUALITY_FAIL],
    payload: {
      request: {
        url: QUALITY_FILTER_EP + id,
        method: 'GET',
        //data: new QualityFilter(),
      },
    },
  };
};

export const readMaterialsData = (id: string) => {
  return {
    types: [LOAD_MATERIALS, LOAD_MATERIALS_SUCCESS, LOAD_MATERIALS_FAIL],
    payload: {
      request: {
        url: getMaterialByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};

export const readPbsMaterialsData = (id: string) => {
  return {
    types: [LOAD_PBS_MATERIALS, LOAD_PBS_MATERIALS_SUCCESS, LOAD_PBS_MATERIALS_FAIL],
    payload: {
      request: {
        url: PBS_MATERIALS_EP + id,
        method: 'GET',
      },
    },
  };
};

export const createMaterials = (data: Resource) => {
  return {
    types: [CREATE_RESOURCE, CREATE_RESOURCE_SUCCESS, CREATE_RESOURCE_FAIL],
    payload: {
      request: {
        url: CREATE_MATERIALS_EP,
        method: 'POST',
        data: [data],
      },
    },
  };
};

export const readToolsData = (id: string) => {

  return {
    types: [READ_TOOLS, READ_TOOLS_SUCCESS, READ_TOOLS_FAIL],
    payload: {
      request: {
        url: getToolsByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};
export const readPbsToolsData = (id: string) => {

  return {
    types: [READ_PBS_TOOLS, READ_PBS_TOOLS_SUCCESS, READ_PBS_TOOLS_FAIL],
    payload: {
      request: {
        url: PBS_TOOLS_EP + id,
        method: 'GET',
      },
    },
  };
};

export const createTools = (data: Resource) => {
  return {
    types: [CREATE_TOOLS, CREATE_TOOLS_SUCCESS, CREATE_TOOLS_FAIL],
    payload: {
      request: {
        url: CREATE_TOOLS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteMaterials = (data: string[]) => {
  return {
    types: [DELETE_MATERIALS, DELETE_MATERIALS_SUCCESS, DELETE_MATERIALS_FAIL],
    payload: {
      request: {
        url: DELETE_BOR_MATERIALS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteTools = (data: string[]) => {
  return {
    types: [DELETE_TOOLS, DELETE_TOOLS_SUCCESS, DELETE_TOOLS_FAIL],
    payload: {
      request: {
        url: DELETE_BOR_TOOLS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readLabourData = (id: string) => {

  return {
    types: [READ_LABOURS, READ_LABOURS_SUCCESS, READ_LABOURS_FAIL],
    payload: {
      request: {
        url: getLabourByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};

export const readPbsLabourData = (id: string) => {

  return {
    types: [READ_PBS_LABOURS, READ_PBS_LABOURS_SUCCESS, READ_PBS_LABOURS_FAIL],
    payload: {
      request: {
        url: PBS_LABOURS_EP + id,
        method: 'GET',
      },
    },
  };
};

export const createLabour = (data: Resource) => {
  return {
    types: [CREATE_LABOUR, CREATE_LABOUR_SUCCESS, CREATE_LABOUR_FAIL],
    payload: {
      request: {
        url: CREATE_LABOUR_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteLabours = (data: string[]) => {
  return {
    types: [DELETE_LABOURS, DELETE_LABOURS_SUCCESS, DELETE_LABOURS_FAIL],
    payload: {
      request: {
        url: DELETE_BOR_LABOURS_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readConsumableData = (id: string) => {
  return {
    types: [READ_CONSUMABLE, READ_CONSUMABLE_SUCCESS, READ_CONSUMABLE_FAIL],
    payload: {
      request: {
        url: getConsumableByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};

export const readPbsConsumableData = (id: string) => {
  return {
    types: [READ_PBS_CONSUMABLE, READ_PBS_CONSUMABLE_SUCCESS, READ_PBS_CONSUMABLE_FAIL],
    payload: {
      request: {
        url: PBS_CONSUMABLE_EP + id,
        method: 'GET',
      },
    },
  };
};

export const createConsumable = (data: Resource) => {
  return {
    types: [CREATE_CONSUMABLE, CREATE_CONSUMABLE_SUCCESS, CREATE_CONSUMABLE_FAIL],
    payload: {
      request: {
        url: CREATE_CONSUMABLE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const deleteConsumable = (data: string[]) => {
  return {
    types: [DELETE_CONSUMABLE, DELETE_CONSUMABLE_SUCCESS, DELETE_CONSUMABLE_FAIL],
    payload: {
      request: {
        url: DELETE_BOR_CONSUMABLE_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const readInstructionDropDown = () => {
  return {
    types: [LOAD_INSTRUCTION_DROPDOWN, LOAD_INSTRUCTION_DROPDOWN_SUCCESS, LOAD_INSTRUCTION_DROPDOWN_FAIL],
    payload: {
      request: {
        url: READ_INSTRUCTION_DROPDOWN_EP,
      },
    },
  };
};

export const readTechInstruction = (pbsId: string, type: string) => {
  return {
    types: [LOAD_TECH_INSTRUCTION, LOAD_TECH_INSTRUCTION_SUCCESS, LOAD_TECH_INSTRUCTION_FAIL],
    payload: {
      request: {
        url: getPbsInstructionByType(pbsId, type),
      },
    },
  };
};

export const uploadInstructionDoc = (formData: any) => {
  return {
    types: [UPLOAD_INSTRUCTION_DOC, UPLOAD_INSTRUCTION_DOC_SUCCESS, UPLOAD_INSTRUCTION_DOC_FAIL],
    payload: {
      request: {
        url: UPLOAD_INSTRUCTION_DOC_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const createTechInstruction = (instruction: any) => {
  return {
    types: [CREATE_TECH_INSTRUCTION, CREATE_TECH_INSTRUCTION_SUCCESS, CREATE_TECH_INSTRUCTION_FAIL],
    payload: {
      request: {
        url: CREATE_INSTRUCTION_EP,
        method: 'POST',
        data: instruction,
      },
    },
  };
};


///api/Competence/ReadCompetenceList
export const filterCompetenceList = (id: string) => {
  return {
    types: [LOAD_COMPETENCE, LOAD_COMPETENCE_SUCCESS, LOAD_COMPETENCE_FAIL],
    payload: {
      request: {
        url: COMPETENCE_FILTER_EP + id,
        method: 'GET',
      },
    },
  };
};

export const readCompetenceDropDown = () => {
  return {
    types: [LOAD_COMPETENCE_DROPDOWN, LOAD_COMPETENCE_DROPDOWN_SUCCESS, LOAD_COMPETENCE_DROPDOWN_FAIL],
    payload: {
      request: {
        url: READ_COMPETENCE_DROPDOWN_EP,
      },
    },
  };
};


export const createCompetence = (competence: Competence) => {
  return {
    types: [CREATE_COMPETENCE, CREATE_COMPETENCE_SUCCESS, CREATE_COMPETENCE_FAIL],
    payload: {
      request: {
        url: CREATE_COMPETENCE_EP,
        method: 'POST',
        data: competence,
      },
    },
  };
};


export const deleteCompetence = (data: string[]) => {
  return {
    types: [DELETE_COMPETENCE, DELETE_COMPETENCE_SUCCESS, DELETE_COMPETENCE_FAIL],
    payload: {
      request: {
        url: DELETE_COMPETENCE_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};


export const deleteProduct = (data: string[]) => {
  return {
    types: [DELETE_BOR, DELETE_BOR_SUCCESS, DELETE_BOR_FAIL],
    payload: {
      request: {
        url: DELETE_BOR_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const deleteTechInstruction = (data: string[]) => {
  return {
    types: [DELETE_TECH_INSTRUCTION, DELETE_TECH_INSTRUCTION_SUCCESS, DELETE_TECH_INSTRUCTION_FAIL],
    payload: {
      request: {
        url: DELETE_TECH_INSTRUCTION_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const updateBORStatus = (data: BORStatusUpdateRequest) => {
  return {
    types: [UPDATE_BOR_STATUS, UPDATE_BOR_STATUS_SUCCESS, UPDATE_BOR_STATUS_FAIL],
    payload: {
      request: {
        url: UPDATE_BOR_STATUS_EP,
        method: 'PUT',
        data: data,
      },
    },
  };
};

export const updateBORMaterial = (data: BORResourceUpdateRequest) => {
  return {
    types: [UPDATE_BOR_MATERIAL, UPDATE_BOR_MATERIAL_SUCCESS, UPDATE_BOR_MATERIAL_FAIL],
    payload: {
      request: {
        url: UPDATE_BOR_MATERIAL_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateBORTool = (data: BORResourceUpdateRequest) => {
  return {
    types: [UPDATE_BOR_TOOLS, UPDATE_BOR_TOOLS_SUCCESS, UPDATE_BOR_TOOLS_FAIL],
    payload: {
      request: {
        url: UPDATE_BOR_TOOLS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateBORConsumable = (data: BORResourceUpdateRequest) => {
  return {
    types: [UPDATE_BOR_CONSUMABLE, UPDATE_BOR_CONSUMABLE_SUCCESS, UPDATE_BOR_CONSUMABLE_FAIL],
    payload: {
      request: {
        url: UPDATE_BOR_CONSUMABLE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateBORLabour = (data: BORResourceUpdateRequest) => {
  return {
    types: [UPDATE_BOR_LABOURS, UPDATE_BOR_LABOURS_SUCCESS, UPDATE_BOR_LABOURS_FAIL],
    payload: {
      request: {
        url: UPDATE_BOR_LABOURS_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const setReturnBorResources = (resources: any) => {
  return {
    type: SEND_BOR_RETURN_RES,
    payload: resources,
  };
};

export const resetFormData = () => {
  return {
    type: RESET_FORM_DATA,
  };
};

const formattedPayloadToListPaneTable = (response: any) => {
  if (response.data.result && response.data.result.length > 0) {
    let data = response.data.result.map((item: any) => {
      return {
        key: item.productId,
        id: item.productId,
        productId: item.productId ? item.productId : '-',
        name: item.name ? item.name : '-',
        title: item.title ? item.title : '-',
        headerTitle: item.headerTitle ? item.headerTitle : '-',
        pbsProductItemTypeId: item.pbsProductItemTypeId
          ? item.pbsProductItemTypeId
          : null,
        pbsProductItemType: item.pbsProductItemType
          ? item.pbsProductItemType
          : '-',
        pbsProductStatusId: item.pbsProductStatusId
          ? item.pbsProductStatusId
          : null,
        pbsProductStatus: item.pbsProductStatus
          ? item.pbsProductStatus
          : '-',
        pbsToleranceStateId: item.pbsToleranceStateId
          ? item.pbsToleranceStateId
          : null,
        pbsToleranceState: item.pbsToleranceState
          ? item.pbsToleranceState
          : '-',
      };
    });
    return data;
  } else {
    return [];
  }
};

const formattedPayloadToDropdown = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    return data;
  } else {
    return [];
  }
};

const formattedPayloadToDropdownList = (response: any) => {
  if (response) {
    let keys = Object.keys(response);
    let data = keys.map((item: any) => {
      return {
        key: item,
        text: item,

      };
    });
    return data;
  } else {
    return [];
  }
};

const formattedPayloadToFilterDropdown = (response: any) => {
  if (response) {
    let data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    let defualtOption = [
      {
        key: '0',
        text: i18n.t('all'),
        isDefault: true,
      },
    ];
    Array.prototype.push.apply(defualtOption, data);
    return defualtOption;
  } else {
    return [];
  }
};

export default billOfResourcesReducer;
