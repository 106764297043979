import React from 'react';
import { useTranslation } from 'react-i18next';
import { DirectionalHint, Icon, IconButton, Label, Link, mergeStyleSets, Stack, Text, TooltipHost } from '@fluentui/react';
import { Depths } from '@fluentui/theme';
import { uPrinceTheme } from '../../../../theme';
import { PMOL_TYPE } from '../../../types/projectMolecule';

interface BookMarkPaneProps {
  // divClass: string;
  isShowBookmark: boolean;
  currentActiveSection: string;
  toggleBookmark: () => void;
  toggleBookmarkOut: () => void;
  isEdit: boolean;
  pmolType: PMOL_TYPE;
}

const PmolBookMarkPaneComponent: React.FC<BookMarkPaneProps> = ({
  // divClass,
  isShowBookmark,
  currentActiveSection,
  toggleBookmark,
  toggleBookmarkOut,
  isEdit,
  pmolType,
}) => {
  const { t } = useTranslation();

  // Function to collapse the document pane based on screen size
  const collapseDocumentPane = () => {
    toggleBookmarkOut();
  };

  const GlobalNavButton = () => <IconButton onClick={toggleBookmark} iconProps={{ iconName: 'GlobalNavButton' }} title={t('openBookmark')} ariaLabel={t('openBookmark')} style={{width: 32, height: 32}} />;

  // Define styles for reusable parts
  const classStyles = mergeStyleSets({
    topHeaderStyle: {
      padding: '1.5px 0 !important',
      fontWeight: 600,
      borderLeft: '0px !important',
      color: uPrinceTheme.palette.themePrimary
    },
    container: {
      height: 'calc(100% - 12px)',
      width: 'calc(100% - 12px)',
      padding: 6,
      overflow: 'auto'
    },
    regularFont: {
      fontWeight: 'bold',
      fontSize: 18
    },
    bookmarkTitleBar: {
      display: 'flex', 
      justifyContent: 'space-between', 
      padding: '0 8px'
    },
    bookmarkContent: {
      height: '100%', 
      overflow: 'auto'
    },
    bookmarkWith: {
      height: '100%',
      width: '220px'
    },
    bookmarkWithout: {
      height: '100%',
      width: '28px'
    },
    containerSub: {
      width: '100%', 
      height: '100%', 
      margin: '0 auto', 
      padding: '4px 0'
    }
  });

  // A reusable function for rendering links
  const renderLink = (section: string, label: string, subSectionId: string = '', isSub: boolean) => (
    <>
      <Link
        className={`${classStyles.topHeaderStyle} ${currentActiveSection === section ? 'selected' : ''}`}
        style={{
          borderLeft: currentActiveSection === section ? `4px solid ${uPrinceTheme.palette.themePrimary}` : '',
          fontWeight: !isSub ? 600 : 'normal',
        }}
        href={subSectionId}
      >
        {t(label)}
      </Link><br />
    </>
  );

  // Component for regular bookmarks
  const BookMarkReg = () => (
    <Stack
      className={`collapse`}
      verticalFill
      gap={30}
      // styles={{ root: { selectors: { '@media(max-width: 1440px)': { boxShadow: Depths.depth8 } } } }}
    >
      <div className={classStyles.bookmarkTitleBar}>
        <Text className={classStyles.regularFont}>{t('bookmark')}</Text>
        <i title="Close" className="ms-Icon ms-Icon--ChromeClose" aria-hidden="true" onClick={collapseDocumentPane}></i>
      </div>
      <div
        className={`inner-container inner-container-pmol ${classStyles.bookmarkContent}`}
      >
        {renderLink('1', `1. ${t('pMOLHeader')}`, '#project-moleculer-header', false)}
        {renderLink('2', `2. ${t('startHandshake')}`, '#pmol-start-handshake', false)}
        {renderLink('3', `2.1. ${t('pMOLAddress')}`, '#start-handshake-id-2-1', true)}
        {renderLink('4', `2.2. ${t('instructions')}`, '#start-handshake-id-2-2', true)}
        {renderLink('5', `2.3. ${t('risks')}`, '#start-handshake-id-2-3', true)}
        {renderLink('6', `2.4. ${t('quality')}`, '#start-handshake-id-2-4', true)}
        {renderLink('23', `3. ${t('cbc')}`, '#pmol-cbc', false)}
        {renderLink('23', `3.1. ${t('contractor')}`, '#pmol-cbc-3-1', true)}
        {renderLink('23', `3.2. ${t('cbc')}`, '#pmol-cbc-3-2', true)}
        {renderLink('7', `4. ${t('plannedwork')}`, '#planned-work', false)}
        {renderLink('8', `4.1. ${t('material')}`, '#planned-work-id-3-1', true)}
        {renderLink('9', `4.2. ${t('tools')}`, '#planned-work-id-3-2', true)}
        {renderLink('10', `4.3. ${t('consumables')}`, '#planned-work-id-3-3', true)}
        {renderLink('11', `4.4. ${t('labour')}`, '#planned-work-id-3-4', true)}
        {renderLink('12', `5. ${t('extraWork')}`, '#extra-work', false)}
        {renderLink('13', `5.1. ${t('description')}`, '#extra-work-id-4-1', true)}
        {renderLink('14', `5.2. ${t('material')}`, '#extra-work-id-4-2', true)}
        {renderLink('15', `5.3. ${t('tools')}`, '#extra-work-id-4-3', true)}
        {renderLink('16', `5.4. ${t('consumables')}`, '#extra-work-id-4-4', true)}
        {renderLink('17', `5.5. ${t('labour')}`, '#extra-work-id-4-5', true)}
        {renderLink('18', `6. ${t('pMOLJournal')}`, '#journal-5-1', false)}
        {renderLink('20', `7. ${t('stopHandshake')}`, '#stop-handshake-6-1', false)}
        {isEdit && renderLink('22', `8. ${t('history')}`, '#history', false)}
      </div>
    </Stack>
  );

  // Component for sub-type bookmarks
  const BookMarkSub = () => (
    <Stack
      className={`collapse`}
      verticalFill
      gap={30}
      styles={{ root: { selectors: { '@media(max-width: 1440px)': { boxShadow: Depths.depth8 } } } }}
    >
      <div className={classStyles.bookmarkTitleBar}>
        <Text className={classStyles.regularFont}>{t('bookmark')}</Text>
        <i title="Close" className="ms-Icon ms-Icon--ChromeClose" aria-hidden="true" onClick={collapseDocumentPane}></i>
      </div>
      <div className={`inner-container ${classStyles.bookmarkContent}`}>
        {renderLink('1', `1. ${t('pMOLHeader')}`, '#project-moleculer-header', false)}
        {renderLink('3', `2.1. ${t('pMOLAddress')}`, '#start-handshake-id-2-1', true)}
        {renderLink('4', `2.2. ${t('instructions')}`, '#start-handshake-id-2-2', true)}
        {renderLink('5', `2.3. ${t('risks')}`, '#start-handshake-id-2-3', true)}
        {renderLink('6', `2.4. ${t('quality')}`, '#start-handshake-id-2-4', true)}
        {renderLink('8', `4. ${t('service')}`, '#service', false)}
        {renderLink('19', `5. ${t('pMOLJournal')}`, '#journal-5-1', false)}
        {renderLink('21', `6. ${t('stopHandshake')}`, '#stop-handshake-6-1', false)}
        {isEdit && renderLink('22', `7. ${t('history')}`, '#history', false)}
      </div>
    </Stack>
  );

  // Render the correct bookmark pane based on pmolType
  return (
    <div className={isShowBookmark ? classStyles.bookmarkWith : classStyles.bookmarkWithout} style={{width: isShowBookmark ? '220px' : '28px', height: '100%'}}>
      {isShowBookmark ? 
        <div className={classStyles.container}>
          {pmolType === PMOL_TYPE.REGULAR && <BookMarkReg />}
          {pmolType === PMOL_TYPE.SUB && <BookMarkSub />}
          <Stack
            className="bookmark-panel-responsive"
            verticalFill
            onClick={toggleBookmark}
            gap={30}
            styles={{ root: { boxShadow: Depths.depth8 } }}
          >
            <TooltipHost
              content={t('bookmark')}
              id="bmark"
              calloutProps={{ gapSpace: 0 }}
              directionalHint={DirectionalHint.rightCenter}
            >
              <i className="ms-Icon ms-Icon--SearchBookmark" aria-labelledby="bmark" aria-hidden="true"></i>
            </TooltipHost>
          </Stack>
        </div>
      : (
        <div className={classStyles.containerSub}>
          <GlobalNavButton />
        </div>
      )}
    </div>
  );
};

export default PmolBookMarkPaneComponent;
