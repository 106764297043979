import { useTranslation } from 'react-i18next';
import {
  FontWeights,
  getTheme,
  Icon,
  IconButton,
  IIconProps,
  IPersonaProps,
  Label,
  mergeStyles,
  mergeStyleSets,
  Modal,
  NormalPeoplePicker,
  Persona,
  PrimaryButton,
  TextField,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  getHtmlPrintData,
  saveProjectDayPlanningStateAttr,
} from '../../../../reducers/projectDayPlanningReducer';
import { Form } from 'react-final-form';
import ProgressBar from '../../../../shared/progressBar/progressBar';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import {
  formatDateTimeToISOString,
  isMyEnvironment,
  removeHtmlContents,
} from '../../../../shared/util';
import {
  Document,
  Page,
  pdf,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import graphApiServices from '../../../../services/graph.service';
import { CABFilter } from '../../../../types/addressBookItem';
import { getAddressBookList } from '../../../../reducers/addressBookReducer';
import DragAndDropFileUpload from '../../../../shared/Dropzone/FileDropZone';

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIconWhite: IIconProps = {
  iconName: 'Print',
  styles: addWhiteIconButtonStyles,
};
const mailIcon: IIconProps = {
  iconName: 'Mail',
  styles: addWhiteIconButtonStyles,
};

const innerStyles = {
  sendButton: {
    marginTop: 10,
    marginBottom: 10,
    marginRight: 25,
  },
};

const styles = StyleSheet.create({
  table: {
    display: 'table',
    margin: 10,
    width: 'auto',
    fontSize: 11,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '14.2%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#dddddd',
    textAlign: 'left',
  },
  tableData: {
    width: '14.2%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#dddddd',
    textAlign: 'left',
    fontWeight: 'light',
  },
  tableCell: {
    margin: 5,
    fontSize: 9,
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    fontWeight: 'bold',
  },
});

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 26px 2px 2px',
      textAlign: 'end',
      borderTop: '1px solid #dddddd',
      background: 'white',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflow: 'auto',
    minWidth: '20vw',
    minHeight: '10vh',
    maxWidth: '50vw',
    maxHeight: '50vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
  modalContainer: {
    width: '30%',
  },

});

const tableStyle = mergeStyleSets({
  table: {
    marginTop: 10,
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 11,
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
    verticalAlign: 'top',
  },
  tdProjectName: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
    verticalAlign: 'top',
    width: 120,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    textAlignLast: 'center',
    padding: 8,
  },
  thHeading: {
    // border: '1px solid #dddddd',
    textAlign: 'center',
    padding: 8,
    fontWeight: 'bold',
  },
  even: {
    backgroundColor: '#dddddd',
  },
});

const sendMailStyles = mergeStyleSets({
  fields: {
    display: 'flex',
    gap: '8px',
    marginTop: '8px',
  },
  formLabel: {
    width: 75
  },
  formField: {
    width: 'calc(100% - 75px)'
  },
  formContainer: {
    padding: 12
  },
  footerForSendMailPopup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
    gap: 6,
  },
  cancelIcon: {
    borderRadius: '50%',
    position: 'absolute',
    right: '-6px',
    top: '-8px',
    background: '#000',
    color: '#fff',
    padding: '2px',
    fontSize: '12px',
    width: '15px',
    height: '15px',
    TextAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  attachmentContainer: {
    border: '1px solid #bebebe', 
    width: 100, 
    height: 100, 
    position: 'relative', 
    padding: 4
  },
  attachmentContentContainer: {
    width: '100%', 
    height: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column', 
    justifyContent: 'space-between'
  },
  pdfIcon: {
    fontSize: 32, 
    display: 'flex', 
    justifyContent: 'center'
  },
  attachmentName: {
    color: uPrinceTheme.palette.themePrimary, 
    textAlign: 'center', 
    fontSize: 12, 
    margin: 0
  }
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const PrintModal = () => {
  // constants 
  const dispatch = useDispatch();
  const graphApi = new graphApiServices();
  const { t } = useTranslation();
  const titleId = useId('title');
  const componentRef = React.useRef<any>(null);

  // Redux states
  const { isPrintModelOpen } = useSelector((state: any) => state.projectDPlan);
  const { selectedDate } = useSelector((state: any) => state.mydPlan);
  const { htmlPrintData } = useSelector((state: any) => state.projectDPlan);
  const { showProgressBar } = useSelector((state: any) => state.projectDPlan);
  const { selectedBu } = useSelector((state: any) => state.projectDPlan);
  const { selectedCu } = useSelector((state: any) => state.projectDPlan);
  const { selectedProject } = useSelector((state: any) => state.project);
  const { userInfo } = useSelector((state: any) => state.uprince);

  // Component states
  const [emailIds, setEmailIds] = useState<any>([]);
  const [sendMailVisibility, setSendMailVisibility] = useState<boolean>(false);
  const [allCabPerson, setAllCabPerson] = useState<any[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [parsedPrintData, setParsedPrintData] = useState<any>([]);
  const [to, setTo] = useState<any>([]);
  const [cc, setcc] = useState<any>([]);
  const [mailSubject, setMailSubject] = useState<string>('');
  const [mailBody, setMailBody] = useState<string>('');
  const [uploadedAttchments, setUploadedAttachments] = useState<any[]>([]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <PrimaryButton
        iconProps={addIconWhite}
        text={t('print')}
        disabled={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    );
  }, []);

  useEffect(() => {
    if (isPrintModelOpen) {
      dispatch(
        getHtmlPrintData({
          date: formatDateTimeToISOString(selectedDate),
          buId: isMyEnvironment()
            ? selectedBu
            : selectedProject?.projectClassification
                ?.projectClassificationBuisnessUnit,
          cu: selectedCu?.sequenceCode,
        })
      );
    }
  }, [isPrintModelOpen]);

  useEffect(() => {
    let cabFilter = new CABFilter();
    cabFilter.isSaved === true;
    fetchAllCabDetails(cabFilter);
    const subject =
      'Potteau dagplanning (' +
      moment(selectedDate).format('dddd MMMM D') +
      ')';
    setMailSubject(subject);
  }, []);

  const fetchAllCabDetails = async (filter: CABFilter) => {
    try {
      const response: any = await dispatch(getAddressBookList(filter));
      if (response?.payload.status === 200) {
        const cabData = response?.payload.data?.result || [];

        // Filter saved entries and map to options
        const options = cabData
          // .filter((item: any) => item.isSaved)
          .map(({ person, personCompany }: any) => ({
            text: person?.fullName,
            key: personCompany?.email,
          }))
          .filter(({ key }: any) => key);
        setAllCabPerson(options);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (htmlPrintData) {
      const emailId = Array.from(
        new Set(
          htmlPrintData
            .flatMap((row: any) => row.names.map((name: any) => name.emailId))
            .filter((emailId: any) => emailId)
        )
      );
      setEmailIds(emailId);
    }

    const removeMatchingItems = (data: any) => {
      const matched: any[] = [];
      const unmatched: any[] = [];

      // Create a Set of ids for quick lookup
      const ids = new Set(data?.map((item: any) => item.projectMoleculeId));

      data?.forEach((item: any) => {
        if (ids.has(item.travelProjectMolecularId)) {
          matched.push(item);
        } else {
          unmatched.push(item);
        }
      });

      const matchedIds = new Set(
        matched.map((item) => item.travelProjectMolecularId)
      );

      const filteredUnmatched = unmatched?.filter(
        (item) => !matchedIds.has(item.projectMoleculeId)
      );
      const mergedItems = [...matched, ...filteredUnmatched];
      return mergedItems;
    };

    // Usage
    const result = removeMatchingItems(htmlPrintData);
    setParsedPrintData(result);
  }, [htmlPrintData]);

  const onSubmit = async (values: any) => {
    // const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    // await sleep(300);
    //
    // Promise.all([
    //   // dispatch(updatePlanBoardsPmolTitle(values))
    // ]).then(() => {
    //   onModalClose();
    // });
  };

  const onModalClose = () => {
    dispatch(saveProjectDayPlanningStateAttr('isPrintModelOpen', false));
  };

  const sortByForeman = (row: any) => {
    const foremanRole = 'Foreman';
    const sort = row?.names?.sort((a: any, b: any) => {
      if (a.role === foremanRole) {
        return -1;
      }
      if (b.role === foremanRole) {
        return 1;
      }
      if (a.companyName === null && b.companyName !== null) {
        return -1;
      }
      if (a.companyName !== null && b.companyName === null) {
        return 1;
      }
      if (a.companyName < b.companyName) {
        return -1;
      }
      if (a.companyName > b.companyName) {
        return 1;
      }

      return row?.names?.indexOf(a) - row?.names?.indexOf(b);
    });

    return sort;
  };

  const mailContent = () => {
    return (
      <Document>
        <Page size="A4" orientation="landscape">
          <Text style={{ textAlign: 'center', padding: 8, fontSize: 10 }}>
            {moment(selectedDate).format('dddd MMMM D')}
          </Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.header]}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t('address')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t('project Molecule')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t('hour')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t('vehicles')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t('names')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t('comments')}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{t('siteManager')}</Text>
              </View>
            </View>
            {parsedPrintData?.map((row: any, index: any) => (
              <View key={index} style={styles.tableRow}>
                {/* ADDRESS */}
                <View style={styles.tableData}>
                  <Text style={styles.tableCell}>{row.address}</Text>
                </View>

                {/* PMOL */}
                <View style={styles.tableData}>
                  <Text style={styles.tableCell}>
                    {removeResourceOrIds(row.projectMolecule)}
                  </Text>
                </View>

                {/* HOUR */}
                {row?.travelProjectMolecularId ? (
                  <View>
                    {row?.departureStartTime &&
                    row?.yardStartTime &&
                    row?.yardEndTime ? (
                      <View style={styles.tableData}>
                        <Text style={styles.tableCell}>
                          {row?.departureStartTime} - {row?.yardStartTime}
                        </Text>
                        <Text style={styles.tableCell}>
                          {row?.yardStartTime} - {row?.yardEndTime}
                        </Text>
                      </View>
                    ) : row?.departureStartTime &&
                      row?.yardStartTime &&
                      !row?.yardEndTime ? (
                      <View style={styles.tableData}>
                        <Text style={styles.tableCell}>
                          {row?.departureStartTime} - {row?.yardStartTime}
                        </Text>
                      </View>
                    ) : !row?.departureStartTime &&
                      row?.yardStartTime &&
                      row?.yardEndTime ? (
                      <View style={styles.tableData}>
                        <Text style={styles.tableCell}>
                          {row?.yardStartTime} - {row?.yardEndTime}
                        </Text>
                      </View>
                    ) : row?.departureStartTime &&
                      !row?.yardStartTime &&
                      row?.yardEndTime ? (
                      <View style={styles.tableData}>
                        <Text style={styles.tableCell}>
                          {row?.executionStartTime} - {row?.executionEndTime}
                        </Text>
                      </View>
                    ) : (
                      <View style={styles.tableData}>
                        <Text style={styles.tableCell}>
                          {row?.executionStartTime} - {row?.executionEndTime}
                        </Text>
                      </View>
                    )}
                  </View>
                ) : (
                  <View style={styles.tableData}>
                    <Text style={styles.tableCell}>
                      {row?.executionStartTime} - {row?.executionEndTime}
                    </Text>
                  </View>
                )}

                {/* VEHICLES */}
                <View style={styles.tableData}>
                  {row.vehicle?.map((vehicle: any, i: any) => (
                    <Text key={i} style={styles.tableCell}>
                      {removeResourceOrIds(vehicle)}
                    </Text>
                  ))}
                </View>

                {/* NAMES */}
                <View style={styles.tableData}>
                  {sortByForeman(row)?.map((name: any, i: any) => (
                    <Text key={i} style={styles.tableCell}>
                      {name?.fullName}{' '}
                      {name?.companyName && `(${name?.companyName})`}
                    </Text>
                  ))}
                </View>

                {/* COMMENTS */}
                <View style={styles.tableData}>
                  <Text style={styles.tableCell}>
                    {row?.comment?.[0]
                      ? removeHtmlContents(row?.comment?.[0])
                      : ''}
                  </Text>
                </View>

                {/* SITE MANAGER */}
                <View style={styles.tableData}>
                  <Text style={styles.tableCell}>{row.siteManager}</Text>
                  <Text style={styles.tableCell}>
                    {row?.siteManagerNumber && row?.siteManagerNumber}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );
  };

  const sendMailToWorkerByGraphAPI = async () => {
    setIsLoading(true);
    const toRecipients: string[] = to?.map(
      (e: { key: string; text: string }) => {
        return e.key;
      }
    );
    const ccRecipients: string[] = cc?.map(
      (e: { key: string; text: string }) => {
        return e.key;
      }
    );
    try {
      const accessToken = await graphApi.getToken();
      const blobPdf = await pdf(mailContent()).toBlob();
      // Convert blob to base64 encoded string
      const reader = new FileReader();
      reader.readAsDataURL(blobPdf);
      reader.onloadend = async () => {
        const base64data = reader.result?.toString().split(',')[1];
        if (base64data && emailIds) {
          // Call the Graph API method to send email
          await graphApi.sendEmailWithPdfAttachment(
            mailSubject,
            toRecipients,
            base64data,
            accessToken,
            userInfo?.oid,
            ccRecipients,
            mailBody,
            uploadedAttchments
          );
          onModalClose();
          onCloseSendMailPopup();
        }
      };
    } catch (err) {
      console.error('Error generating or sending PDF:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const removeResourceOrIds = (value: any) => {
    if (value) {
      let parts = value.split(' - ');
      let lastValue = parts[parts.length - 1];
      return lastValue;
    }
  };

  // Send Mail Popup
  const onCloseSendMailPopup = () => {
    setSendMailVisibility(false);
    setTo([]);
    setcc([]);
    setMailSubject('');
    setMailBody('');
    setUploadedAttachments([])
  };

  const handleInputChange = (val: any) => {
    setMailBody(val);
  };

  const handleSubjectChange = (event: any) => {
    setMailSubject(event.target.value);
  };

  const onFilterPersonChanged = (
    filter: string,
    selectedItems?: IPersonaProps[] | undefined
  ) => {
    if (!filter) {
      return [];
    }
    const selectedIds = new Set(to?.map((item: any) => item.text)); // Assuming `id` is a unique identifier

    return (
      allCabPerson?.filter(
        (item: any) =>
          item.text.toLowerCase().includes(filter.toLowerCase()) &&
          !selectedIds.has(item.text)
      ) || []
    );
  };

  const onFilterPersonChangedCC = (
    filter: string,
    selectedItems?: IPersonaProps[] | undefined
  ) => {
    if (!filter) {
      return [];
    }
    const selectedIds = new Set(cc?.map((item: any) => item.text)); // Assuming `id` is a unique identifier

    return (
      allCabPerson?.filter(
        (item: any) =>
          item.text.toLowerCase().includes(filter.toLowerCase()) &&
          !selectedIds.has(item.text)
      ) || []
    );
  };

  const existingItem = mergeStyles({ padding: '10px' });
  const onRenderSuggestionsItem = (props: any, itemProps: any): JSX.Element => {
    return (
      <div className={existingItem} key={props.key}>
        <Persona {...props} size={1} hidePersonaDetails={false} />
      </div>
    );
  };

  const getProjectMoleculeStructuredName = (value: any) => {
    let isPBSPresent = false;
    let str = '';
    const sepratedArray = value.split(' - ');
    sepratedArray.map((str: string) => {
      if (str.includes('PBS')) {
        isPBSPresent = true;
      }
    });
    //isPBSPresent and num Present
    if (sepratedArray.length === 4) {
      str += `${sepratedArray[2]} - ${sepratedArray[3]}`;
    }
    if (sepratedArray.length > 4) {
      str += `${sepratedArray[2]} - ${sepratedArray[3]}`;
      sepratedArray.map((string: string, i: number) => {
        if (i > 3) {
          str += ` ${string}`;
        }
      });
    }
    if (isPBSPresent && sepratedArray.length === 3) {
      str += `${sepratedArray[2]}`;
    } else if (!isPBSPresent && sepratedArray.length === 3) {
      str += `${sepratedArray[1]} - ${sepratedArray[2]}`;
    }
    return str;
  };

  const onUpload = (files: File[]) => {
    const fileData = new FormData();
    const nonImageFiles = files.filter(
      (file) => !file.type.startsWith('image/')
    );
    if (nonImageFiles.length > 0) {
      const uploadedFile = nonImageFiles[0];
      fileData.append('file', uploadedFile);
      // for (let [key, value] of fileData.entries()) {
      //   console.log('FormData entry:', key, value); // Logs the key-value pair for each file
      // }
      const reader = new FileReader();
      reader.onload = function (event: any) {
        const base64data = event.target.result;
        const base64String = base64data.split(',')[1];
        const payload = {
          '@odata.type': '#microsoft.graph.fileAttachment',
          name: `${uploadedFile.name}`,
          contentBytes: base64String,
          contentType: uploadedFile.type,
        };
        setUploadedAttachments((prev: any) => [...prev, payload]);
      };
      reader.readAsDataURL(uploadedFile);
    } else {
      alert('The uploaded file is an image. Please upload a non-image file.');
    }
  };

  const onDeleteAttachments = (index: number) => {
    const newArr = [...uploadedAttchments];
    newArr.splice(index, 1);
  
    setUploadedAttachments(newArr);
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={() => {}}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Modal
                  titleAriaId={titleId}
                  isOpen={isPrintModelOpen}
                  onDismiss={() => {
                    onModalClose();
                  }}
                  // scrollableContentClassName='body'
                  isBlocking={false}
                  dragOptions={undefined}
                >
                  <div className={contentStyles.header}>
                    <span id={titleId}>{t('print')}</span>
                    <IconButton
                      styles={cancelIconButtonStyles}
                      iconProps={cancelIcon}
                      ariaLabel="Close popup modal"
                      onClick={() => {
                        onModalClose();
                      }}
                    />
                  </div>
                  <ProgressBar show={showProgressBar} />

                  <div className={contentStyles.body}>
                    <div className="proj-detail-content inner">
                      <div className="ms-Grid-row">
                        <div
                          id="print-area-2"
                          className="print-area"
                          ref={componentRef}
                        >
                          <table className={tableStyle.table}>
                            <thead>
                              <tr>
                                <td
                                  colSpan={8}
                                  className={tableStyle.thHeading}
                                >
                                  {moment(selectedDate).format('dddd MMMM D')}
                                </td>
                              </tr>
                              <tr>
                                {/* <th className={tableStyle.th}>{t('project')}</th> */}
                                <th className={tableStyle.th}>
                                  {t('address')}
                                </th>
                                <th className={tableStyle.th}>
                                  {t('project Molecule')}
                                </th>
                                <th
                                  className={tableStyle.th}
                                  style={{ width: 80 }}
                                >
                                  {t('hour')}
                                </th>
                                <th className={tableStyle.th}>
                                  {t('vehicles')}
                                </th>
                                <th className={tableStyle.th}>{t('names')}</th>
                                <th className={tableStyle.th}>
                                  {t('comments')}
                                </th>
                                <th className={tableStyle.th}>
                                  {t('siteManager')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {parsedPrintData?.map((row: any) => (
                                <tr>
                                  {/* ADDRESS */}
                                  <td className={tableStyle.td}>
                                    {row?.address}
                                  </td>

                                  {/* PMOL */}
                                  <td className={tableStyle.td}>
                                    {getProjectMoleculeStructuredName(
                                      row?.projectMolecule
                                    )}
                                  </td>

                                  {/* HOUR */}
                                  {row?.travelProjectMolecularId ? (
                                    <>
                                      {row?.departureStartTime &&
                                      row?.yardStartTime &&
                                      row?.yardEndTime ? (
                                        <td className={tableStyle.td}>
                                          {row?.departureStartTime} -{' '}
                                          {row?.yardStartTime}
                                          <br />
                                          {row?.yardStartTime} -{' '}
                                          {row?.yardEndTime}
                                        </td>
                                      ) : row?.departureStartTime &&
                                        row?.yardStartTime &&
                                        !row?.yardEndTime ? (
                                        <td className={tableStyle.td}>
                                          {row?.departureStartTime} -{' '}
                                          {row?.yardStartTime}
                                        </td>
                                      ) : !row?.departureStartTime &&
                                        row?.yardStartTime &&
                                        row?.yardEndTime ? (
                                        <td className={tableStyle.td}>
                                          {row?.yardStartTime} -{' '}
                                          {row?.yardEndTime}
                                        </td>
                                      ) : row?.departureStartTime &&
                                        !row?.yardStartTime &&
                                        row?.yardEndTime ? (
                                        <td className={tableStyle.td}>
                                          {row?.executionStartTime} -{' '}
                                          {row?.executionEndTime}
                                        </td>
                                      ) : (
                                        <td className={tableStyle.td}>
                                          {row?.executionStartTime} -{' '}
                                          {row?.executionEndTime}
                                        </td>
                                      )}
                                    </>
                                  ) : (
                                    <td className={tableStyle.td}>
                                      {row?.executionStartTime} -{' '}
                                      {row?.executionEndTime}
                                    </td>
                                  )}

                                  {/* VEHICLES */}
                                  <td className={tableStyle.td}>
                                    {row.vehicle?.map(
                                      (vehicle: any, i: any) => (
                                        <div key={i}>
                                          {removeResourceOrIds(vehicle)}
                                        </div>
                                      )
                                    )}
                                  </td>

                                  {/* NAME */}
                                  <td className={tableStyle.td}>
                                    {sortByForeman(row)?.map(
                                      (name: any, i: any) => (
                                        <div key={i}>
                                          {name?.fullName}{' '}
                                          {name?.companyName &&
                                            `(${name?.companyName})`}
                                        </div>
                                      )
                                    )}
                                  </td>

                                  {/* COMMENTS */}
                                  <td className={tableStyle.td}>
                                    <div>
                                      {row?.comment?.[0]
                                        ? removeHtmlContents(row?.comment?.[0])
                                        : ''}
                                    </div>
                                  </td>

                                  {/* SITE MANAGER */}
                                  <td className={tableStyle.td}>
                                    {row?.siteManager}
                                    <br />
                                    {row?.siteManagerNumber &&
                                      row?.siteManagerNumber}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={contentStyles.footer}>
                    <PrimaryButton
                      iconProps={mailIcon}
                      text={t('send')}
                      disabled={htmlPrintData ? false : true}
                      style={innerStyles.sendButton}
                      onClick={() => {
                        setSendMailVisibility(true);
                      }}
                    />

                    <ReactToPrint
                      trigger={reactToPrintTrigger}
                      content={reactToPrintContent}
                      documentTitle={
                        t('projectPlanboard') +
                        ' ' +
                        moment(selectedDate).format('DD-MM-YYYY')
                      }
                      copyStyles={true}
                    />
                  </div>
                </Modal>
              </div>
            </form>
          );
        }}
      />

      {/* Send Mail Popup Modal  */}
      <Modal
        titleAriaId={titleId}
        isOpen={sendMailVisibility}
        onDismiss={() => {
          onCloseSendMailPopup();
        }}
        containerClassName={contentStyles.modalContainer}
        isBlocking={false}
        dragOptions={undefined}
      >
        <div className={contentStyles.header}>
          <span id={titleId}>{t('sendDailyPlanning')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              onCloseSendMailPopup();
            }}
          />
        </div>
        <ProgressBar show={isLoading} />
        {/* Content  */}
        <div className={sendMailStyles.formContainer}>
          {/* TO  */}
          <div style={{ display: 'flex', gap: '8px' }}>
            <Label className={sendMailStyles.formLabel}>{t('to')}:</Label>
            <NormalPeoplePicker
              selectedItems={to}
              disabled={false}
              onResolveSuggestions={onFilterPersonChanged}
              onChange={(items) => {
                setTo(items);
                return items ? items : [];
              }}
              className={sendMailStyles.formField}
              onRenderSuggestionsItem={onRenderSuggestionsItem}
            />
          </div>

          {/* CC  */}
          <div className={sendMailStyles.fields}>
            <Label className={sendMailStyles.formLabel}>{t('cc')}:</Label>
            <NormalPeoplePicker
              selectedItems={cc}
              disabled={false}
              onResolveSuggestions={onFilterPersonChangedCC}
              onChange={(items) => {
                setcc(items);
                return items ? items : [];
              }}
              className={sendMailStyles.formField}
              onRenderSuggestionsItem={onRenderSuggestionsItem}
            />
          </div>

          {/* Subject  */}
          <div className={sendMailStyles.fields}>
            <Label className={sendMailStyles.formLabel}>{t('subject')}:</Label>
            <TextField
              className={sendMailStyles.formField}
              value={mailSubject}
              noValidate
              onChange={(event) => handleSubjectChange(event)}
            />
          </div>

          {/* Body  */}
          <div className={sendMailStyles.fields}>
            <Label className={sendMailStyles.formLabel}>{t('body')}:</Label>
            <TextField
              className={sendMailStyles.formField}
              value={mailBody}
              noValidate
              onChange={(event, newVal: string | undefined) =>
                handleInputChange(newVal)
              }
              multiline={true}
            />
          </div>

          {/* Uploaded files */}
          {uploadedAttchments?.length > 0 ? 
            <div className={sendMailStyles.fields}>
              {uploadedAttchments?.map((files, index) => {
                return (
                  <div
                    className={sendMailStyles.attachmentContainer}
                    key={files?.name + index}
                  >
                    <Icon iconName='Cancel' className={sendMailStyles.cancelIcon} onClick={() => onDeleteAttachments(index)}/>
                    <div className={sendMailStyles.attachmentContentContainer}>
                      <Icon iconName="PDF" className={sendMailStyles.pdfIcon}/>
                      <p className={sendMailStyles.attachmentName}>{files.name}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          : null}

          {/* Mail send action  */}
          <div className={sendMailStyles.footerForSendMailPopup}>
            {/* Upload */}
            <DragAndDropFileUpload
              multiple={false}
              getUploadedFile={(files) => onUpload(files)}
              disabled={false}
              buttonLabel={t('attachments')}
              iconName='Attach'
            />

            <PrimaryButton
              iconProps={mailIcon}
              text={t('send')}
              disabled={to?.length === 0 ? true : false}
              onClick={() => {
                sendMailToWorkerByGraphAPI();
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PrintModal;
