import React from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { saveProjectStateAttr } from '../../../reducers/projectReducer';
import { useDispatch, useSelector } from 'react-redux';
import { projectInitialFormData } from '../../../types/project';
import {saveCIAWStateAttr} from "../../../reducers/ciawReducer";
import { addProjectConfiguration } from '../../../reducers/cuProjectReducer';

const CommandBarRight = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newClick = () => {
    messageService.sendMessage({ isNewPROJECT: true, resetListPaneSelection: true });
    dispatch(saveProjectStateAttr('isNewProject', true));
    dispatch(saveProjectStateAttr('isClear', true));
    dispatch(saveProjectStateAttr('selectedProject', projectInitialFormData));
    // props.createNew(true);
  };
  const saveFormData = () => {
    messageService.sendMessage({ savePROJECTData: true });
  };
  const handleRemove = () => {
    // props.handleRemove();
  };

  const {isProjectSqModelOpen} = useSelector(
    (state: any) => state.project
  );

  const { selectedProject } = useSelector(
    (state: any) => state.project,
  );

  const squareMeters=()=>{
    dispatch( saveProjectStateAttr('isProjectSqModelOpen',!isProjectSqModelOpen))
    !isProjectSqModelOpen ? dispatch(addProjectConfiguration({ projectId: selectedProject?.id, isRequestingData: true })) : null;
  };

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
        disabled: false,
      },
      {
        key: 'save',
        text: t('save'),
        iconProps: { iconName: 'Save' },
        onClick: () => {
          saveFormData();
        },
        disabled: false,
      },
      {
        key: 'tempDelivery',
        text: t('tempDelivery'),
        iconProps: { iconName: 'DeliveryTruck' },
        onClick: () => {
        },
        disabled: true,
      },
      {
        key: 'definitiveDelivery',
        onClick: () => {
        },
        name: t('deffinitiveDelivery'),
        iconProps: { iconName: 'DeliveryTruck' },
        disabled: true,
      },
      {
        key: 'ciaw',
        onClick: () => {
          dispatch(saveCIAWStateAttr('isCiawSiteCodeModelOpen', true));
        },
        name: t('ciaw'),
        iconProps: { iconName: 'connectContacts' },
        disabled: false,
      },
      {
        key: 'SquareMeters',
        onClick: () => {
          squareMeters();
        },
        name: '€/m²',
        iconProps: { iconName: 'Section' },
        disabled: true,
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
    </div>
  );
};

export default CommandBarRight;
