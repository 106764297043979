import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  filterCiawListPaneData,
  readCabCertificationCIAW,
  readCIAWById,
  saveCIAWStateAttr,
} from '../../../reducers/ciawReducer';
import { useTranslation } from 'react-i18next';
import {
  DetailsListLayoutMode,
  DetailsRow,
  DirectionalHint,
  Dropdown,
  ChoiceGroup,
  FontIcon,
  IColumn,
  Icon,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles,
  ITooltipHostStyles,
  registerIcons,
  ScrollablePane,
  ScrollbarVisibility,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  Text,
  TextField,
  TooltipDelay,
  TooltipHost,
  Check,
} from '@fluentui/react';
import { Field, Radio, RadioGroup } from '@fluentui/react-components';
import { SvgIcons } from '../../../shared/svgIcons/svgIcons';
import { classNames } from '../../contract/listPane/component';
import CustomDatePicker from '../../../shared/customDatePicker/customDatePicker';
import { CiawFilterData, CiawListItem } from '../../../types/ciaw';
import moment from 'moment';
import _, { uniqBy } from 'lodash';
import { getTomorrow } from '../../../shared/util';
import { useId } from '@uifabric/react-hooks';
import { ICheckStyles } from 'office-ui-fabric-react';
import '../../../components/style/style.css'
// import Certification from '../../certification/certification';

registerIcons(SvgIcons);
const hostStyles: Partial<ITooltipHostStyles> = {
  root: { display: 'inline-block' },
};

const ListPaneComponent = () => {
  const dispatch = useDispatch();
  // const store = useStore();
  const { t } = useTranslation();
  const tooltipId = useId('tooltip');

  const eligibilityOptions = [
    // {key: '0', text: t('all'), data: {color: '#000000'}},
    { key: '1', text: t('2'), data: { color: '#07AA6B' } }, // green
    // {key: '3', text: t('3'), data: {color: '#eb9557'}},
    { key: '2', text: t('1'), data: { color: '#EB5757' } }, // red
  ];

  const limOptions = [
    { key: 'all', text: t('all'), data: { color: '#000000' } },
    { key: '0', text: t('0'), data: { color: '#EB5757' } },
    { key: '1', text: t('1'), data: { color: '#eb9557' } }, //orange
    { key: '3', text: t('3'), data: { color: '#07AA6B' } }, //green
  ];

  // const limOptions1 = [
  //   {key: '0', text: t('all'), data: {color: '#EB5757'}},
  //   // {key: 0, text: t('0'), data: {color: '#EB5757'}}, //red
  //   {key: 2, text: t('2'), data: {color: '#eb9557'}}, //orange
  //   {key: 3, text: t('3'), data: {color: '#07AA6B'}}, //green
  // ];

  // const A1Options = [
  //   {key: '0', text: t('all'), data: {color: '#EB5757'}},
  //   // {key: 0, text: t('0'), data: {color: '#EB5757'}}, //red
  //   {key: 1, text: t('1'), data: {color: '#eb9557'}}, //orange
  //   {key: 3, text: t('3'), data: {color: '#07AA6B'}}, //green
  // ];
  const platformOptions = [
    { key: 'all', text: t('all') },
    { key: 'SOCIALSECURITYWEBSITE', text: t('Social Security Website') },
    { key: 'CUSTOMERPORTAL', text: t('Customer Portal') },
  ];

  let columns: IColumn[] = [
    // {
    //   key: 'column',
    //   name: '',
    //   fieldName: '',
    //   minWidth: 60,
    //   maxWidth: 80,
    //   isResizable: true,
    //   isSorted: false,
    //   onColumnClick: _onColumnClick,
    // },
    {
      key: 'column0',
      name: t('date'),
      fieldName: 'date',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      isSorted: false,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column1',
      name: t('employee'),
      fieldName: 'cabPersonName',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      isSorted: false,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column7',
      name: t('Organisation'),
      fieldName: 'organisation',
      minWidth: 60,
      maxWidth: 150,
      isResizable: true,
      isSorted: false,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column2',
      name: t('projectTitle'),
      fieldName: 'projectTitle',
      minWidth: 60,
      maxWidth: 300,
      isResizable: true,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column12',
      name: t('productTitle'),
      fieldName: 'productTitle',
      minWidth: 60,
      maxWidth: 300,
      isResizable: true,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column3',
      name: t('eligibility'),
      fieldName: 'cpCiawRegistrationStatus',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        // item?.ciawRegistrationStatus
        // item?.isCiawEligible
        // item?.cpCiawRegistrationStatus
        if (item?.isCustomerPortal === true) {
          if (item?.cpCiawRegistrationStatus === 'Send') {
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#07AA6B',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );
          } else if (item?.cpCiawRegistrationStatus === 'Not Send') {
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#EB5757',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );
          }
        } else if (item?.isCustomerPortal === false) {
          if (item?.isCiawEligible === '1') {
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#07AA6B',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );
          } else if (item?.isCiawEligible === '2') {
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#EB5757',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );
          } else if (item?.isCiawEligible === '3') {
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#eb9557',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );
          }
        } else {
          return (
            <div>
              <Dropdown
                // placeholder={t('all')}
                options={eligibilityOptions}
                // selectedKey={filter?.isCiawEligible ? filter?.isCiawEligible : null}
                onChange={(event, value) => {
                  setFilter((prevState: any) => ({
                    ...prevState,
                    cpCiawRegistrationStatus: value?.key,
                    // ciawRegistrationStatus: value?.key
                    // isCiawEligible: value?.key
                  }));
                }}
                onRenderOption={onRenderOption}
                onRenderTitle={onRenderTitle}
              />
            </div>
          );
        }
      },
    },
    {
      key: 'column4',
      name: t('warning'),
      fieldName: 'remark',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        if (item?.remark && item?.remark.length > 0) {
          return (
            <div
              style={{
                margin: 5,
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <TooltipHost
                delay={TooltipDelay.medium}
                id={tooltipId}
                directionalHint={DirectionalHint.bottomCenter}
                styles={hostStyles}
                content={item?.remark?.map((item: any) => {
                  return <li>{item}</li>;
                })}
              >
                <FontIcon aria-label="Warning" iconName="Warning" />
              </TooltipHost>
            </div>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      key: 'column5',
      name: t('ciawStatus'),
      fieldName: 'ciawStatus',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column6',
      name: t('platform'),
      fieldName: 'platform',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: _onColumnClick,
    },
    {
      key: 'column8',
      name: t('ID'),
      fieldName: 'certificationStatuses',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        if (item?.SSNI > 4) {
          return (
            <span className="ciaw-eligibility-list-column">
              <Icon
                style={{
                  fontSize: 8,
                  color: '#07AA6B',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                iconName="CircleFill"
              />
            </span>
          );
        }

        switch (item?.SSNI) {
          case 0:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#EB5757',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />
              </span>
            );
          case 1:
          case 2:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#eb9557',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />
              </span>
            );
          case 3:
          case 4:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#07AA6B',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />
              </span>
            );
          default:
            return (
              <div>
                <Dropdown
                  // placeholder={t('all')}
                  options={limOptions}
                  // selectedKey={filter?.isCiawEligible ? filter?.isCiawEligible : null}
                  onChange={(event, value) => {
                    setFilter((prevState: any) => ({
                      ...prevState,

                      certificationStatuses:
                        value?.key == 'all'
                          ? []
                          : [
                              {
                                title: 'SSNI',
                                weeks: value?.key == 'all' ? null : value?.key,
                              },
                            ],
                    }));
                  }}
                  onRenderOption={onRenderlimOptions}
                  onRenderTitle={onRenderlimTitle}
                />
              </div>
            );
        }
      },
    },
    {
      key: 'column9',
      name: t('A1'),
      fieldName: 'certificationStatuses',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        if (item?.A1 > 4) {
          return (
            <span className="ciaw-eligibility-list-column">
              <Icon
                style={{
                  fontSize: 8,
                  color: '#07AA6B',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                iconName="CircleFill"
              />
            </span>
          );
        }
        switch (item?.A1) {
          case 0:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#EB5757',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );
          case 1:
          case 2:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#eb9557',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );

          case 3:
          case 4:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#07AA6B',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );

          default:
            return (
              <div>
                <Dropdown
                  // placeholder={t('all')}
                  options={limOptions}
                  // selectedKey={filter?.isCiawEligible ? filter?.isCiawEligible : null}
                  onChange={(event, value) => {
                    setFilter((prevState: any) => ({
                      ...prevState,
                      certificationStatuses:
                        value?.key == 'all'
                          ? []
                          : [
                              {
                                title: 'A1',
                                weeks: value?.key == 'all' ? null : value?.key,
                              },
                            ],
                    }));
                  }}
                  onRenderOption={onRenderlimOptions}
                  onRenderTitle={onRenderlimTitle}
                />
              </div>
            );
        }
      },
    },
    {
      key: 'column10',
      name: t('LIM'),
      fieldName: 'certificationStatuses',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        if (item?.LIMOSA > 4) {
          return (
            <span className="ciaw-eligibility-list-column">
              <Icon
                style={{
                  fontSize: 8,
                  color: '#07AA6B',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                iconName="CircleFill"
              />
            </span>
          );
        }
        switch (item?.LIMOSA) {
          case 0:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#EB5757',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );
          case 1:
          case 2:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#eb9557',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );

          case 3:
          case 4:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#07AA6B',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );

          default:
            return (
              <div>
                <Dropdown
                  // placeholder={t('all')}
                  options={limOptions}
                  // selectedKey={filter?.isCiawEligible ? filter?.isCiawEligible : null}
                  onChange={(event, value) => {
                    setFilter((prevState: any) => ({
                      ...prevState,
                      certificationStatuses:
                        value?.key == 'all'
                          ? []
                          : [
                              {
                                title: 'LIMOSA',
                                weeks: value?.key == 'all' ? null : value?.key,
                              },
                            ],
                    }));
                  }}
                  onRenderOption={onRenderlimOptions}
                  onRenderTitle={onRenderlimTitle}
                />
              </div>
            );
        }
      },
    },
    {
      key: 'column11',
      name: t('VCA'),
      fieldName: 'certificationStatuses',
      minWidth: 60,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        if (item?.VCA > 4) {
          return (
            <span className="ciaw-eligibility-list-column">
              <Icon
                style={{
                  fontSize: 8,
                  color: '#07AA6B',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                iconName="CircleFill"
              />
            </span>
          );
        }
        switch (item?.VCA) {
          case 0:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#EB5757',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );
          case 1:
          case 2:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#eb9557',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );

          case 3:
          case 4:
            return (
              <span className="ciaw-eligibility-list-column">
                <Icon
                  style={{
                    fontSize: 8,
                    color: '#07AA6B',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  iconName="CircleFill"
                />{' '}
              </span>
            );

          default:
            return (
              <div>
                <Dropdown
                  // placeholder={t('all')}
                  options={limOptions}
                  // selectedKey={filter?.isCiawEligible ? filter?.isCiawEligible : null}
                  onChange={(event, value) => {
                    setFilter((prevState: any) => ({
                      ...prevState,
                      certificationStatuses:
                        value?.key == 'all'
                          ? []
                          : [
                              {
                                title: 'VCA',
                                weeks: value?.key == 'all' ? null : value?.key,
                              },
                            ],
                    }));
                  }}
                  onRenderOption={onRenderlimOptions}
                  onRenderTitle={onRenderTitle}
                />
              </div>
            );
        }
      },
    },
  ];

  const onRenderlimOptions = (limOptions: any): JSX.Element => {
    return (
      <div>
        {limOptions.key && (
          <Icon
            style={{ paddingLeft: 23, color: limOptions.data.color }}
            iconName={limOptions.key == 'all' ? 'CircleRing' : 'CircleFill'}
            aria-hidden="true"
          />
        )}
      </div>
    );
  };

  const onRenderlimTitle = (limOptions: any): JSX.Element => {
    const limOption = limOptions[0];
    return (
      <div>
        {limOption.key && (
          <Icon
            style={{ paddingLeft: 23, color: limOption.data.color }}
            iconName={limOption.key == 'all' ? 'CircleRing' : 'CircleFill'}
            aria-hidden="true"
          />
        )}
      </div>
    );
  };

  const onRenderOption = (eligibilityOptions: any): JSX.Element => {
    return (
      <div>
        {eligibilityOptions.key && (
          <Icon
            style={{ paddingLeft: 23, color: eligibilityOptions.data.color }}
            iconName={
              eligibilityOptions.key == '0' ? 'CircleRing' : 'CircleFill'
            }
            aria-hidden="true"
          />
        )}
      </div>
    );
  };

  const onRenderTitle = (eligibilityOptions: any): JSX.Element => {
    const eligibilityOption = eligibilityOptions[0];
    return (
      <div>
        {eligibilityOption.key && (
          <Icon
            style={{ paddingLeft: 23, color: eligibilityOption.data.color }}
            iconName={
              eligibilityOption.key == '0' ? 'CircleRing' : 'CircleFill'
            }
            aria-hidden="true"
          />
        )}
      </div>
    );
  };

  function _onColumnClick(
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void {
    sortedColumns.map((item: any) => {
      if (item.key === column.key) {
        item.isSorted = true;
        item.isSortedDescending = !item.isSortedDescending;

        let sortBy = item.isSortedDescending ? 'desc' : 'asc';
        setFilter((prevState: any) => ({
          ...prevState,
          sorter: {
            attribute: item.fieldName ? item.fieldName : null,
            order: sortBy ? sortBy : null,
          },
        }));
      } else {
        item.isSorted = false;
        item.isSortedDescending = true;
      }
    });
    setSortedColumns(sortedColumns);
  }
  const [filter, setFilter] = useState<any>(new CiawFilterData());
  const [selectionDetails, setSelectionDetails]: any =
    React.useState<any>(null);
  const [sortedColumns, setSortedColumns] = useState<any>(columns);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [selectedData,setSelectedData] = useState<any>({})
  const [isradioCheck,setIsRadioCheck] = useState(false)
  const {
    ciawListData,
    ciawDropdownData,
    timeClockSelectedDate,
    ciawFilterData,
    ciawSendResult,
    ciawCancelResult,
  } = useSelector((state: any) => state.ciaw);

  const [filteredData, setFilteredData] = useState<any>(ciawListData);
  
  const _gridSelection = new Selection({
    onSelectionChanged: () => {
      console.log(_gridSelection.getSelection())
      setSelectionDetails(_gridSelection.getSelection());
      // dispatch(
      //   saveCIAWStateAttr(
      //     'selectedCIAWListItems',
      //     _gridSelection.getSelection()
      //   )
      // );
    },
  });

  useEffect(() => {
    if(Object.keys(selectedData).length){
      const selectedCIAWITtems = []
      for(let obj in selectedData){
        selectedCIAWITtems.push(selectedData[obj])
      }
      dispatch(
        saveCIAWStateAttr(
          'selectedCIAWListItems',
          selectedCIAWITtems
        )
      );
    }else {
      dispatch(
        saveCIAWStateAttr(
          'selectedCIAWListItems',
          []
        )
      );
    }
  },[selectedData])

  const handleCheckboxChange = (id: string) => {
    setSelectedItems((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(id)) {
        newState.delete(id); 
      } else {
        newState.add(id); 
      }
      return newState;
    });
    const data = [...filteredData]
    const tempObj:any = {}
     data.forEach((obj) => {
      if(obj?.id == id) {
         if(!selectedData.id){
          tempObj[id] = obj
         }
      }
    })
    setSelectedData((prev:any) => {
      const updatedData = {...prev}
      if(updatedData[id]){
        delete updatedData[id]
      } else {
      const obj = data.find((obj) => obj?.id === id)
      if(obj){
        updatedData[id] = obj
      }
      }
      return updatedData
    })
  };

  useEffect(() =>{
    if(selectedItems.size !== filteredData.length){
      setIsRadioCheck(false)
    } 
    if(selectedItems.size && (selectedItems.size === filteredData.length) ){
       setIsRadioCheck(true)
    }
  },[selectedItems])

  useEffect(() => {
    // setFilter(new CiawFilterData()); NOTE: this isn't necessary, but don't remove this line
    dispatch(
      saveCIAWStateAttr(
        'timeClockSelectedDate',
        moment(getTomorrow()).format('YYYY-MM-DD')
      )
    );

    return () => {
      dispatch(saveCIAWStateAttr('ciawListData', []));
    };
  }, []);

  useEffect(() => {
    setFilteredData(ciawListData);
  }, [ciawListData]);

  useEffect(() => {
    if (selectionDetails) {
      dispatch(saveCIAWStateAttr('selectedCiaw', selectionDetails));
    }
  }, [selectionDetails]);

  useEffect(() => {
    if (ciawSendResult || ciawCancelResult) {
      Promise.all([dispatch(filterCiawListPaneData(filter))]).then(() => {
        dispatch(saveCIAWStateAttr('ciawSendResult', null));
        dispatch(saveCIAWStateAttr('ciawCancelResult', null));
      });
    }
  }, [ciawSendResult, ciawCancelResult]);

  useEffect(() => {
    dispatch(filterCiawListPaneData(filter)); //get ListPane filters
    dispatch(saveCIAWStateAttr('ciawFilterDataexport', ciawListData));
  }, [filter]);

  useEffect(() => {
    setFilter((prevState: any) => ({
      ...prevState,
      date: timeClockSelectedDate
        ? moment(timeClockSelectedDate).format('YYYY-MM-DD')
        : undefined,
      ciawStatus: ciawFilterData.ciawStatus,
    }));
  }, [timeClockSelectedDate, ciawFilterData]);

  // const getValuesForOrganisationDropdown = () => {
  //   const organisationDropdownData: any = uniqBy(ciawListData, 'organisation');
  //   const updatedData = organisationDropdownData.map((each: any) => ({
  //     key: each.organisation,
  //     text: t(each.organisation) // Assuming t() is a translation function
  //   }));

  //   return [{key: 'all', text: t('All')}, ...updatedData]
  // }

  const _renderFilterItemColumn = (
    item?: CiawListItem,
    index?: number,
    column?: IColumn
  ) => {
    if (column?.key === 'column0') {
      {
        return (
          <div>
            <CustomDatePicker
              setValue={
                timeClockSelectedDate ? new Date(timeClockSelectedDate) : null
              }
              getValue={(date: Date) => {
                if (date) {
                  setFilter((prevState: any) => ({
                    ...prevState,
                    date: date ? moment(date).format('YYYY-MM-DD') : null,
                  }));
                  dispatch(saveCIAWStateAttr('timeClockSelectedDate', date));
                }
              }}
              isClearable={true}
              onDateClear={() => {
                setFilter((prevState: any) => ({
                  ...prevState,
                  date: null,
                }));
                dispatch(saveCIAWStateAttr('timeClockSelectedDate', null));
              }}
            />
          </div>
        );
      }
    } else if (column?.key === 'column1') {
      {
        return (
          <div>
            <TextField
              value={filter?.cabPerson ? filter?.cabPerson : null}
              onChange={(event, value) => {
                setFilter((prevState: any) => ({
                  ...prevState,
                  cabPerson: value ?? null,
                }));
              }}
            />
          </div>
        );
      }
    } else if (column?.key === 'column2') {
      {
        return (
          <div>
            <TextField
              value={filter?.project ? filter?.project : null}
              onChange={(event, value) => {
                setFilter((prevState: any) => ({
                  ...prevState,
                  project: value ?? null,
                }));
              }}
            />
          </div>
        );
      }
    } else if (column?.key === 'column5') {
      {
        return (
          <div>
            <Dropdown
              placeholder={t('all')}
              options={formatToDropDownList(ciawDropdownData?.status)}
              selectedKey={filter?.ciawStatus ? filter?.ciawStatus : null}
              onChange={(event, value) => {
                setFilter((prevState: any) => ({
                  ...prevState,
                  ciawStatus: value?.key ?? null,
                }));
              }}
            />
          </div>
        );
      }
    } else if (column?.key === 'column6') {
      {
        return (
          <div>
            <Dropdown
              placeholder={t('all')}
              options={platformOptions}
              // selectedKey={filter?.isCiawEligible ? filter?.isCiawEligible : null}
              onChange={(event, value) => {
                setFilter((prevState: any) => ({
                  ...prevState,
                  platform: value?.key ? value.key : null,
                }));
              }}
            />
          </div>
        );
      }
    } else if (column?.key === 'column7') {
      {
        return (
          <div>
            <TextField
              // value={filter?.project ? filter?.project : null}
              onChange={(event, value) => {
                const data = ciawListData;
                if (value?.length && value?.length === 0) {
                  setFilteredData(data);
                } else {
                  const filtered = ciawListData.filter((item: any) =>
                    item.organisation
                      .toLowerCase()
                      .includes(value?.toLowerCase())
                  );
                  setFilteredData(filtered);
                }
              }}
            />
          </div>
        );
      }
    } else if (column?.key === 'column12') {
      {
        return (
          <div>
            <TextField
              // value={filter?.project ? filter?.project : null}
              onChange={(event, value) => {
                const data = ciawListData;
                if (value?.length && value?.length === 0) {
                  setFilteredData(data);
                } else if (value === '') {
                  setFilteredData(data);
                } else {
                  const filtered = ciawListData?.filter((item: any) =>
                    item?.productTitle
                      ?.toLowerCase()
                      .includes(value?.toLowerCase())
                  );
                  setFilteredData(filtered);
                }
              }}
            />
          </div>
        );
      }
    }
  };

  const formatToDropDownList = (dropdown: any) => {
    const allCol = [{ key: 0, text: 'All' }];
    if (dropdown) {
      return allCol.concat(dropdown);
    }
    return [];
  };

  const onRadioClick = (e:any,row:any) => {
    console.log(row)
    e.stopPropagation();
    handleCheckboxChange(row?.item?.id)
    const bodyDetails = [...sortedColumns]
    setSortedColumns(bodyDetails)
  }

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    // const customStyles: Partial<IDetailsRowStyles> = {};
    const customRowStyles: Partial<IDetailsRowStyles> = {
      root: {
        textDecoration: 'none',
        selectors: {
          ':hover': {
            backgroundColor: '#f3f3f3',
          },
          
        },
      },
    };
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // dispatch(readCIAWById(row?.item?.id))
            dispatch(readCIAWById(row?.item?.id, row?.item?.pbsProductId));
            dispatch(
              readCabCertificationCIAW({
                personId: row?.item?.cabPersonId,
                date: timeClockSelectedDate
                  ? moment(timeClockSelectedDate).format('YYYY/MM/DD')
                  : moment().format('YYYY/MM/DD'),
              })
            );
          }}
        >
          {/* <DetailsRow {...row} styles={customStyles} /> */}
          <DetailsRow
            {...row}
            styles={customRowStyles}
            onRenderCheck={(checkProps) => (
              <div
              style={{display:'flex',alignItems:'center',height:'100%',marginLeft:'10px'}}
                onClick={(e) => {
                  onRadioClick(e,row)
                  // console.log('clickedś');
                 ;
                }}
              >
                <Check
                  {...checkProps}
                  checked={selectedItems.has(row?.item?.id)}
                  styles={
                    {
                      root: {
                        selectors: {
                          ':hover': {
                            backgroundColor: 'transparent',
                          },
                        },
                      },
                    } as Partial<ICheckStyles>
                  }
                />
              </div>
            )}
          />
        </span>
      );
    }
    return null;
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };

  const _renderItemColumn = (item: any, index?: number, column?: IColumn) => {
    // if (column && column.fieldName && column.fieldName === 'title') {
    //     return <Link to={'#'} onClick={(e) => {
    //         e.preventDefault();
    //         dispatch(readCIAWById(item?.id));
    //         dispatch(readCabCertificationCIAW({
    //             personId: item?.cabPersonId,
    //             date: timeClockSelectedDate ? moment(timeClockSelectedDate).format('YYYY/MM/DD') : moment().format('YYYY/MM/DD')
    //         }));
    //         setSelectionDetails([item]);
    //         // dispatch(saveCIAWStateAttr('selectedCiaw', [item]));
    //     }}>{item[column.fieldName]}</Link>;
    // } else
    if (column && column.fieldName) {
      const fieldContent = item[column.fieldName];
      return (
        <span
          style={{ paddingLeft: 9 }}
          onClick={(e) => {
            e.preventDefault();
            setSelectionDetails([item]);
          }}
        >
          {fieldContent}
        </span>
      );
    } else {
      return <span style={{ paddingLeft: 9 }}>-</span>;
    }
  };

  const onheaderRadioClick = (e:any) => {
    setIsRadioCheck(!isradioCheck)
    const data = [...filteredData]
    const dataObj:any = {}
     data.forEach((obj) => {
      dataObj[obj.id] = obj
    })
    if(!isradioCheck){
      setSelectedItems(new Set())
      e.stopPropagation()
      const selectableSet:any = new Set()
      filteredData.forEach((obj:any) => {
        selectableSet.add(obj.id)
      })
      setSelectedItems(selectableSet)
      setFilteredData(data)
      dispatch(
        saveCIAWStateAttr(
          'selectedCIAWListItems',
          [...data]
        )
      );
      setSelectedData(dataObj)
    }
   else {
    setSelectedItems(new Set())
    setFilteredData(data)
    dispatch(
      saveCIAWStateAttr(
        'selectedCIAWListItems',
        []
      )
    );
    }

  }

  const renderListPane = () => {
    return (
      <div style={{width:`${window.outerWidth + 200}px`}} className={`wrapper-holder ciaw-wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            // items={ciawListData}
            items={filteredData}
            compact={true}
            columns={sortedColumns}
            setKey="set"
            enableShimmer={false}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            selection={_gridSelection}
            selectionMode={SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderItemColumn={(item: any, index?: number, column?: IColumn) =>
              _renderItemColumn(item, index, column)
            }
            // onRenderDetailsHeader={(headerProps, defaultRender) => {
            //   if (defaultRender !== undefined) {
            //     return (
            //       <Sticky
            //         stickyPosition={StickyPositionType.Header}
            //         isScrollSynced={true}
            //         stickyBackgroundColor="transparent"
            //       >
            //         <div>{defaultRender(headerProps)}</div>
            //       </Sticky>
            //     );
            //   } else {
            //     return <span></span>;
            //   }
            // }}
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div style={{position:'relative'}}>
                      {defaultRender(headerProps)}
                      {/* Header radio button */}
                      <div
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' , position:'absolute',top:'1%',bottom:'1%',marginLeft:'10px' }}
                        onClick={(e) => {
                          onheaderRadioClick(e)
                        }}
                      >
                        <Check
                          checked={isradioCheck}  // Header radio button is bound to isAllSelected state
                          styles={{
                            root: {
                              selectors: {
                                ':hover': {
                                  backgroundColor: 'transparent',
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Sticky>
                );
              } else {
                return <span></span>;
              }
            }}   
          />
          {ciawListData && _.isEmpty(ciawListData) && (
            <div style={{ paddingTop: 28, paddingLeft: 20 }}>
              <Text>{`${t('noCiawDataAvailable')}`}</Text>
            </div>
          )}
        </ScrollablePane>
      </div>
    );
  };

  return (
    <div>
      <div className={'pdp'} style={{ width: '100%', height: '100%' }}>
        <div
          className={'project-day_planing'}
          style={{ width: '100%', height: screen.height, overflow: 'overlay' }}
        >
          {' '}
          {renderListPane()}
        </div>
      </div>
    </div>
  );
};

export default ListPaneComponent;
