import _ from 'lodash';
import i18n from '../../i18n';
import UprinceLogger from '../shared/Logger/logger';
import {
  PROJECT_DEFINITION_READ_EP,
  PROJECT_HEADER_DETAILS_READ_EP,
  getLabourByProductIdURL
} from '../shared/endpoints';
import axios from 'axios';
import { projectInitialFormData } from '../types/project';
import { isCuProjectPlanning, isPlanning } from '../shared/util';
import { READ_LABOURS, READ_LABOURS_SUCCESS, READ_LABOURS_FAIL } from '../actionTypes/projectBreakdownType';

export const LOAD_PROJECT_MANAGEMENT_LEVEL =
  'uprince/LOAD_PROJECT_MANAGEMENT_LEVEL';
export const LOAD_PROJECT_MANAGEMENT_LEVEL_SUCCSESS =
  'uprince/LOAD_PROJECT_MANAGEMENT_LEVEL_SUCCESS';
export const LOAD_PROJECT_MANAGEMENT_LEVEL_FAIL =
  'uprice/LOAD_PROJECT_MANAGEMENT_LEVEL_FAIL';

export const LOAD_PROJECT_TOLERANCE_STATE =
  'uprince/LOAD_PROJECT_TOLERANCE_STATE';
export const LOAD_PROJECT_TOLERANCE_STATE_SUCCESS =
  'uprince/LOAD_PROJECT_TOLERANCE_STATE_SUCCESS';
export const LOAD_PROJECT_TOLERANCE_STATE_FAIL =
  'uprince/LOAD_PROJECT_TOLERANCE_STATE_FAIL';

export const LOAD_PROJECT_CURRENCY = 'uprince/LOAD_PROJECT_CURRENCY';
export const LOAD_PROJECT_CURRENCY_SUCCESS =
  'uprince/LOAD_PROJECT_CURRENCY_SUCCESS';
export const LOAD_PROJECT_CURRENCY_FAIL = 'uprince/LOAD_PROJECT_CURRENCY_FAIL';

export const LOAD_PROJECT_STATE = 'uprince/LOAD_PROJECT_STATE';
export const LOAD_PROJECT_STATE_SUCCESS = 'uprince/LOAD_PROJECT_STATE_SUCCESS';
export const LOAD_PROJECT_STATE_FAIL = 'uprince/LOAD_PROJECT_STATE_FAIL';

export const CRATE_TEAM_SITE = 'uprince/CRATE_TEAM_SITE';
export const CRATE_TEAM_SITE_SUCCESS = 'uprince/CRATE_TEAM_SITE_SUCCESS';
export const CRATE_TEAM_SITE_FAIL = 'uprince/CRATE_TEAM_SITE_FAIL';

export const GET_TEAM_INFORMATON = 'uprince/CRATE_TEAM_SITE';
export const GET_TEAM_INFORMATON_SUCCESS = 'uprince/CRATE_TEAM_SITE_SUCCESS';
export const GET_TEAM_INFORMATON_FAIL = 'uprince/CRATE_TEAM_SITE_FAIL';

export const LOAD_PROJECT_TYPE = 'uprince/LOAD_PROJECT_TYPE';
export const LOAD_PROJECT_TYPE_SUCCESS = 'uprince/LOAD_PROJECT_TYPE_SUCCESS';
export const LOAD_PROJECT_TYPE_FAIL = 'uprince/LOAD_PROJECT_TYPE_FAIL';

export const LOAD_PROJECT_TEMPLATE = 'uprince/LOAD_PROJECT_TEMPLATE';
export const LOAD_PROJECT_TEMPLATE_SUCCESS =
  'uprince/LOAD_PROJECT_TEMPLATE_SUCCESS';
export const LOAD_PROJECT_TEMPLATE_FAIL = 'uprince/LOAD_PROJECT_TEMPLATE_FAIL';

export const LOAD_PROJECT_DEFINITION = 'uprince/LOAD_PROJECT_DEFINITION';
export const LOAD_PROJECT_DEFINITION_SUCCESS =
  'uprince/LOAD_PROJECT_DEFINITION_SUCCESS';
export const LOAD_PROJECT_DEFINITION_FAIL =
  'uprice/LOAD_PROJECT_DEFINITION_FAIL';

export const LOAD_PROJECT_FILTER = 'uprince/LOAD_PROJECT_FILTER';
export const LOAD_PROJECT_FILTER_SUCCESS =
  'uprince/LOAD_PROJECT_FILTER_SUCCESS';
export const LOAD_PROJECT_FILTER_FAIL = 'uprice/LOAD_PROJECT_FILTER_FAIL';

export const LOAD_PROJECT_DEFINITION_BY_ID =
  'uprince/LOAD_PROJECT_DEFINITION_BY_ID';
export const LOAD_PROJECT_DEFINITION_BY_ID_SUCCESS =
  'uprince/LOAD_PROJECT_DEFINITION_BY_ID_SUCCESS';
export const LOAD_PROJECT_DEFINITION_FAIL_BY_ID =
  'uprice/LOAD_PROJECT_DEFINITION_FAIL_BY_ID';

export const SAVE_PROJECT_DEFINITION = 'uprince/SAVE_PROJECT_DEFINITION';
export const SAVE_PROJECT_DEFINITION_SUCCESS =
  'uprince/SAVE_PROJECT_DEFINITION_SUCCESS';
export const SAVE_PROJECT_DEFINITION_FAIL =
  'uprince/SAVE_PROJECT_DEFINITION_FAIL';

export const UPDATE_PROJECT_DEFINITION = 'uprince/UPDATE_PROJECT_DEFINITION';
export const UPDATE_PROJECT_DEFINITION_SUCCESS =
  'uprince/UPDATE_PROJECT_DEFINITION_SUCCESS';
export const UPDATE_PROJECT_DEFINITION_FAIL =
  'uprince/UPDATE_PROJECT_DEFINITION_FAIL';

export const LOAD_PROJECT_DROPDOWNS = 'uprince/LOAD_PROJECT_DROPDOWNS';
export const LOAD_PROJECT_DROPDOWNS_SUCCESS =
  'uprince/LOAD_PROJECT_DROPDOWNS_SUCCESS';
export const LOAD_PROJECT_DROPDOWNS_FAIL = 'uprice/LOAD_PROJECT_DROPDOWNS_FAIL';

export const LOAD_PROJECT_WAYPOINTS = 'uprince/LOAD_PROJECT_WAYPOINTS';
export const LOAD_PROJECT_WAYPOINTS_SUCCESS =
  'uprince/LOAD_PROJECT_WAYPOINTS_SUCCESS';
export const LOAD_PROJECT_WAYPOINTS_FAIL =
  'uprince/LOAD_PROJECT_WAYPOINTS_FAIL';

export const LOAD_USER_ROLES = 'uprince/LOAD_USER_ROLES';
export const LOAD_USER_ROLES_SUCCESS = 'uprince/LOAD_USER_ROLES_SUCCESS';
export const LOAD_USER_ROLES_FAIL = 'uprince/LOAD_USER_ROLES_FAIL';

export const DELETE_USER_ROLES = 'uprince/DELETE_USER_ROLES';
export const DELETE_USER_ROLES_SUCCESS = 'uprince/DELETE_USER_ROLES_SUCCESS';
export const DELETE_USER_ROLES_FAIL = 'uprince/DELETE_USER_ROLES_FAIL';

export const LOAD_PROJECT_HEADER_DETAILS =
  'uprince/LOAD_PROJECT_HEADER_DETAILS';
export const LOAD_PROJECT_HEADER_DETAILS_SUCCESS =
  'uprince/LOAD_PROJECT_HEADER_DETAILS_SUCCESS';
export const LOAD_PROJECT_HEADER_DETAILS_FAIL =
  'uprince/LOAD_PROJECT_HEADER_DETAILS_FAIL';

export const CLEAR_PROJECT_FORM_DATA = 'uprince/CLEAR_PROJECT_FORM_DATA';

export const CREATE_PROJECT_DEFINITION = 'uprince/CREATE_PROJECT_DEFINITION';

// export const READ_LABOURS = 'uprince/READ_LABOURS';
// export const READ_LABOURS_SUCCESS = 'uprince/READ_LABOURS_SUCCESS';
// export const READ_LABOURS_FAIL = 'uprice/READ_LABOURS_FAIL';

export const CREATE_PROJECT_DEFINITION_SUCCESS =
  'uprince/CREATE_PROJECT_DEFINITION_SUCCESS';
export const CREATE_PROJECT_DEFINITION_FAIL =
  'uprince/CREATE_PROJECT_DEFINITION_FAIL';

export const REQUEST_ACCESS_ALL_PROJECTS =
  'uprince/REQUEST_ACCESS_ALL_PROJECTS';
export const REQUEST_ACCESS_ALL_PROJECTS_SUCCESS =
  'uprince/REQUEST_ACCESS_ALL_PROJECTS_SUCCESS';
export const REQUEST_ACCESS_ALL_PROJECTS_FAIL =
  'uprice/REQUEST_ACCESS_ALL_PROJECTS_FAIL';

export const LOAD_UPDATE_DETAILS_IS_LOADED =
  'uprince/LOAD_UPDATE_DETAILS_IS_LOADED';
export const SAVE_PROJECT_STATE_ATTR = 'uprince/SAVE_PROJECT_STATE_ATTR';

const defaultState: any = {
  projectManagementLevel: [],
  projectToleranceState: [],
  projectScopeStatus: [],
  projectFinanceStatus: [],
  projectCurrency: [],
  projectState: [],
  projectType: [],
  projectTemplate: [],
  allProjects: [],
  nonFilterProjects: [],
  wayPoints: [],
  selectedProject: [],
  customerteamlist:[],
  updateProjectList: false,
  saveMsgType: '',
  saveMsg: '',
  loadMsg: '',
  alertMsg: '',
  alertMsgType: '',
  userRoles: [],
  filterProjectManagementLevel: [],
  filterProjectToleranceState: [],
  filterProjectCurrency: [],
  filterProjectState: [],
  filterProjectType: [],
  filterProjectTemplate: [],
  isDataLoaded: false,
  isDetailsLoaded: false,
  deleteTeamState: false,
  projectHeader: [],
  ledgerDropDowns: [],
  reloadListPane: false,
  projectID: null,
  isNewProject: false,
  isClear: false,
  isProjectLoaded: false,
  isEdit: false,
  projectClassificationSize: [],
  projectClassificationSector: [],
  projectClassificationConstructionType: [],
  projectClassificationBuisnessUnit: [],
  requestAllProjectAccessSuccess: false,
  isProjectSqModelOpen: false,
  updatedProjectFromPP: null,
};

const source = axios.CancelToken.source();

const projectReducer = (state = defaultState, action: any) => {
  if (action) {
    switch (action.type) {
      case LOAD_PROJECT_MANAGEMENT_LEVEL:
        return { ...state, loading: true };
      case LOAD_PROJECT_MANAGEMENT_LEVEL_SUCCSESS:
        return {
          ...state,
          loading: false,
          projectManagementLevel: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_MANAGEMENT_LEVEL_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectManagementLevel',
        };

      // ///tolerance case////////
      case LOAD_PROJECT_TOLERANCE_STATE:
        return { ...state, loading: true };
      case LOAD_PROJECT_TOLERANCE_STATE_SUCCESS:
        return {
          ...state,
          loading: false,
          projectToleranceState: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_TOLERANCE_STATE_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectToleranceState',
        };

      // ///State case////////
      case LOAD_PROJECT_STATE:
        return { ...state, loading: true };
      case LOAD_PROJECT_STATE_SUCCESS:
        return {
          ...state,
          loading: false,
          projectState: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_STATE_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectState',
        };

      // ///Type case////////
      case LOAD_PROJECT_TYPE:
        return { ...state, loading: true };
      case LOAD_PROJECT_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          projectType: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectType',
        };

      // ///TEMPLATE case////////
      case LOAD_PROJECT_TEMPLATE:
        return { ...state, loading: true };
      case LOAD_PROJECT_TEMPLATE_SUCCESS:
        return {
          ...state,
          loading: false,
          projectTemplate: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_TEMPLATE_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectTemplate',
        };

      // ///CURRENCY case////////
      case LOAD_PROJECT_CURRENCY:
        return { ...state, loading: true };
      case LOAD_PROJECT_CURRENCY_SUCCESS:
        return {
          ...state,
          loading: false,
          projectCurrency: formattedPayloadToDropdown(action.payload),
        };
      case LOAD_PROJECT_CURRENCY_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectCurrency',
        };

      // ///Project Dropdowns case////////
      case LOAD_PROJECT_DROPDOWNS:
        return { ...state, loading: true };
      case LOAD_PROJECT_DROPDOWNS_SUCCESS:
        return {
          ...state,
          loading: false,
          projectLanguage: action.payload.data.result?.projectLanguage
            ? action.payload.data.result?.projectLanguage
            : [],
          projectClassificationSize: action.payload.data.result
            ?.projectClassificationSize
            ? action.payload.data.result?.projectClassificationSize
            : [],
          projectClassificationSector: action.payload.data.result
            ?.projectClassificationSector
            ? action.payload.data.result?.projectClassificationSector
            : [],
          projectClassificationConstructionType: action.payload.data.result
            ?.projectClassificationConstructionType
            ? action.payload.data.result?.projectClassificationConstructionType
            : [],
          projectClassificationBuisnessUnit: action.payload.data.result
            .projectClassificationBuisnessUnit
            ? action.payload.data.result.projectClassificationBuisnessUnit
            : [],
          projectManagementLevel: action.payload.data.result
            ? formattedPayloadToAllDropdown(
                action.payload.data.result.managementLevels
              )
            : [],
          projectToleranceState: action.payload.data.result
            ? formattedPayloadToAllDropdown(
                action.payload.data.result.toleranceStates
              )
            : [],
          projectScopeStatus: action.payload.data.result
            ? formattedPayloadToAllDropdown(
                action.payload.data.result.projectScopeStatus
              )
            : [],
          projectCurrency: action.payload.data.result
            ? formattedPayloadToAllDropdown(
                action.payload.data.result.currencies
              )
            : [],
          projectState: action.payload.data.result
            ? formattedPayloadToAllDropdown(action.payload.data.result.states)
            : [],
          projectType: action.payload.data.result
            ? formattedPayloadToAllDropdown(action.payload.data.result.types)
            : [],
          projectTemplate: action.payload.data.result
            ? formattedPayloadToAllDropdown(
                action.payload.data.result.templates
              )
            : [],
          filterProjectManagementLevel: action.payload.data.result
            ? formattedPayloadToFilterDropdown(
                action.payload.data.result.managementLevels
              )
            : [],
          filterProjectToleranceState: action.payload.data.result
            ? formattedPayloadToFilterDropdown(
                action.payload.data.result.toleranceStates
              )
            : [],
          filterProjectCurrency: action.payload.data.result
            ? formattedPayloadToFilterDropdown(
                action.payload.data.result.currencies
              )
            : [],
          filterProjectState: action.payload.data.result
            ? formattedPayloadToFilterDropdown(
                action.payload.data.result.states
              )
            : [],
          filterProjectType: action.payload.data.result
            ? formattedPayloadToFilterDropdown(action.payload.data.result.types)
            : [],
          filterProjectTemplate: action.payload.data.result
            ? formattedPayloadToFilterDropdown(
                action.payload.data.result.templates
              )
            : [],
          projectDropdownList: action.payload.data.result
            ? formattedPayloadToDropdownList(action.payload.data.result)
            : [],
          ledgerDropDowns: action.payload.data.result
            ? formattedLedgerPayloadToDropdown(
                action.payload.data.result.genaralLederNumber.sort(
                  (a: any, b: any) => {
                    const nameA = a.name; // ignore upper and lowercase
                    const nameB = b.name;
                    //  nameA = nameA.replace('%', '').padStart(2, 0);
                    //  nameB = nameB.replace('%', '').padStart(2, 0);
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  }
                )
              )
            : [],
        };
      case LOAD_PROJECT_DROPDOWNS_FAIL:
        return {
          ...state,
          loading: false,
          error: 'Error while fetching projectCurrency',
        };

      // ///Get All Project case////////
      case LOAD_PROJECT_DEFINITION:
        return { ...state, loading: true };
      case LOAD_PROJECT_DEFINITION_SUCCESS:
        return {
          ...state,
          loading: false,
          updateProjectList: false,
          nonFilterProjects: formattedPayloadToListPaneTable(action.payload),
          // loadMsg: action.payload.data.message,
        };

      // ///Filter Project case////////
      case LOAD_PROJECT_FILTER:
        return {
          ...state,
          loading: true,
          allProjects: [],
          loadMsg: 'loading',
          isDataLoaded: false,
        };
      case LOAD_PROJECT_FILTER_SUCCESS:
        return {
          ...state,
          loading: false,
          updateProjectList: false,
          allProjects: formattedPayloadToListPaneTable(action.payload),
          isDataLoaded: true,
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_PROJECT_FILTER_FAIL:
        return {
          ...state,
          allProjects: [],
          loading: false,
          isDataLoaded: false,
          error: 'Error while fetching Project',
          loadMsg: action.error.response.data.message,
        };

      // /// Project way points case////////
      case LOAD_PROJECT_WAYPOINTS:
        return { ...state, loading: true, wayPoints: [], loadMsg: 'loading' };
      case LOAD_PROJECT_WAYPOINTS_SUCCESS:
        return {
          ...state,
          loading: false,
          wayPoints: action.payload?.data?.result,
          // loadMsg: action.payload.data.message,
        };
      case LOAD_PROJECT_WAYPOINTS_FAIL:
        return {
          ...state,
          wayPoints: [],
          loading: false,
          error: 'Error while fetching Project',
        };

      // ///Get By id Project case////////
      case LOAD_PROJECT_DEFINITION_BY_ID:
        return {
          ...state,
          loading: true,
          isDetailsLoaded: false,
          isProjectLoaded: false,
          selectedProject: projectInitialFormData,
        };
      case LOAD_PROJECT_DEFINITION_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          isDetailsLoaded: true,
          isProjectLoaded: true,
          isEdit: true,
          isClear: false,
          selectedProject: action.payload.data.result,
          customerteamlist: action.payload.data.result
            ? formattedTeamlistToDropdown(
                action.payload.data.result.projectTeam.teamRoleList
              )
            : [],
          updatedProjectFromPP:
            isPlanning() || isCuProjectPlanning()
              ? action.payload.data.result
              : null,
        };
      case LOAD_PROJECT_DEFINITION_FAIL_BY_ID:
        return {
          ...state,
          loading: false,
          isDetailsLoaded: true,
          isProjectLoaded: false,
          isEdit: false,
          error: 'Error while fetching Project',
        };

      // ///Save Project DEFINITION ////////
      // case SAVE_PROJECT_DEFINITION:
      //     return { ...state, loading: true };
      // case SAVE_PROJECT_DEFINITION_SUCCESS:
      //     return {
      //         ...state,
      //         loading: false,
      //         updateProjectList: true,
      //         saveMsgType: 'success',
      //         saveMsg: action.payload.data.message
      //     };
      // case SAVE_PROJECT_DEFINITION_FAIL:
      //     return {
      //         ...state,
      //         alertMsg: action.error.response.data.message,
      //         alertMsgType: 'error',
      //         loading: false,
      //         error: 'Error while Saving Project Definition',
      //         saveMsgType: 'error',
      //         saveMsg: action.error.response.data.message
      //     };

      case CREATE_PROJECT_DEFINITION:
        return {
          ...state,
          showProgressBar: true,
          reloadListPane: false,
          projectID: null,
        };
      case CREATE_PROJECT_DEFINITION_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          isClear: false,
          showProgressBar: false,
          reloadListPane: true,
          projectID: action.payload.data.result,
        };
      case CREATE_PROJECT_DEFINITION_FAIL:
        return {
          ...state,
          showProgressBar: false,
          reloadListPane: false,
          projectID: null,
        };

      // ///Update Project DEFINITION ////////
      case UPDATE_PROJECT_DEFINITION:
        return {
          ...state,
          loading: true,
          showProgressBar: true,
          reloadListPane: false,
          updateProjectList: false,
        };
      case UPDATE_PROJECT_DEFINITION_SUCCESS:
        return {
          ...state,
          loading: false,
          updateProjectList: true,
          showProgressBar: false,
          reloadListPane: true,
          saveMsgType: 'success',
          alertMsgType: 'success',
          saveMsg: action.payload.data.message,
          alertMsg: action.payload.data.message,
        };
      case UPDATE_PROJECT_DEFINITION_FAIL:
        return {
          ...state,
          alertMsg: action.error.response.data.message,
          alertMsgType: 'error',
          reloadListPane: false,
          showProgressBar: false,
          loading: false,
          error: 'Error while Saving Project Definition',
          saveMsgType: 'error',
          saveMsg: action.error.response.data.message,
        };

      case LOAD_UPDATE_DETAILS_IS_LOADED:
        return {
          ...state,
          isDetailsLoaded: true,
        };
      // /////// GET User roles ////////
      case LOAD_USER_ROLES:
        UprinceLogger.log('LOAD_USER_ROLES');
        return {
          ...state,
          userRoles: [],
        };
      case LOAD_USER_ROLES_SUCCESS:
        UprinceLogger.log(
          'LOAD_USER_ROLES_SUCCESS',
          action.payload.data.result
        );
        return {
          ...state,
          userRoles: formatUserRoleResponse(action.payload.data.result),
        };
      case LOAD_USER_ROLES_FAIL:
        UprinceLogger.log('LOAD_USER_ROLES_FAIL');
        return {
          ...state,
          userRoles: [],
        };
      case DELETE_USER_ROLES:
        UprinceLogger.log('DELETE_USER_ROLES');
        return {
          ...state,
          deleteTeamState: false,
          showProgressBar: true,
        };
      case DELETE_USER_ROLES_SUCCESS:
        UprinceLogger.log(
          'DELETE_USER_ROLES_SUCCESS',
          action.payload.data.result
        );
        return {
          ...state,
          deleteTeamState: true,
          showProgressBar: false,
        };
      case DELETE_USER_ROLES_FAIL:
        UprinceLogger.log('DELETE_USER_ROLES_FAIL');
        return {
          ...state,
          deleteTeamState: false,
          showProgressBar: false,
        };

      case LOAD_PROJECT_HEADER_DETAILS:
        return {
          ...state,
          projectHeader: null,
        };
      case LOAD_PROJECT_HEADER_DETAILS_SUCCESS:
        return {
          ...state,
          projectHeader: action.payload.data.result,
        };
      case LOAD_PROJECT_HEADER_DETAILS_FAIL:
        return {
          ...state,
          projectHeader: null,
        };
      // eslint-disable-next-line no-case-declarations
      case SAVE_PROJECT_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value,
        };
      case REQUEST_ACCESS_ALL_PROJECTS_SUCCESS:
        return {
          ...state,
          requestAllProjectAccessSuccess: true,
        };
      case REQUEST_ACCESS_ALL_PROJECTS_FAIL:
        return {
          ...state,
          requestAllProjectAccessSuccess: true,
        };
      case CLEAR_PROJECT_FORM_DATA:
        return {
          ...state,
          isClear: true,
          isEdit: false,
        };
        break;

      default:
        return state;
    }
  }
};

export const saveFormData = (data: any) => {
  return {
    types: [
      CREATE_PROJECT_DEFINITION,
      CREATE_PROJECT_DEFINITION_SUCCESS,
      CREATE_PROJECT_DEFINITION_FAIL,
    ],
    payload: {
      request: {
        url: '/ProjectDefinition/Create',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateIsDetailsLoadedState = () => {
  return { type: LOAD_UPDATE_DETAILS_IS_LOADED };
};

export const getProjectManagementLevel = () => {
  return {
    type: LOAD_PROJECT_MANAGEMENT_LEVEL,
    payload: { request: { url: 'ProjectManagementLevel/Read' } },
  };
};

export const getProjectCurrency = () => {
  return {
    type: LOAD_PROJECT_CURRENCY,
    payload: { request: { url: 'Currency/Read' } },
  };
};

export const getProjectToleranceState = () => {
  return {
    type: LOAD_PROJECT_TOLERANCE_STATE,
    payload: { request: { url: 'ProjectToleranceState/Read' } },
  };
};

export const getProjectState = () => {
  return {
    type: LOAD_PROJECT_STATE,
    payload: { request: { url: 'ProjectState/Read' } },
  };
};

export const getProjectType = () => {
  return {
    type: LOAD_PROJECT_TYPE,
    payload: { request: { url: 'ProjectType/Read' } },
  };
};

export const getProjectTemplate = () => {
  return {
    type: LOAD_PROJECT_TEMPLATE,
    payload: { request: { url: 'ProjectTemplate/Read' } },
  };
};

export const getAllProjects = () => {
  return {
    type: LOAD_PROJECT_DEFINITION,
    payload: { request: { url: 'ProjectDefinition/Read' } },
  };
};

export const getAllProjectsWaypoints = () => {
  return {
    type: [LOAD_PROJECT_WAYPOINTS, LOAD_PROJECT_WAYPOINTS_SUCCESS, LOAD_PROJECT_WAYPOINTS_FAIL],
    payload: { request: { url: 'ProjectDefinition/Read', method: 'GET' } },
  };
};

export const getAllProjectDropdowns = () => {
  return {
    type: LOAD_PROJECT_DROPDOWNS,
    payload: { request: { url: 'ProjectDefinition/ReadProjectDropdowns' } },
  };
};

export const requestAccessAllProjects = () => {
  return {
    types: [
      REQUEST_ACCESS_ALL_PROJECTS,
      REQUEST_ACCESS_ALL_PROJECTS_SUCCESS,
      REQUEST_ACCESS_ALL_PROJECTS_FAIL,
    ],
    payload: {
      request: { url: 'ContractingUnitTenants/AssignAdminToProject' },
    },
  };
};

export const getProjectDefinition = (id: any) => {
  return {
    types: [
      LOAD_PROJECT_DEFINITION_BY_ID,
      LOAD_PROJECT_DEFINITION_BY_ID_SUCCESS,
      LOAD_PROJECT_DEFINITION_FAIL_BY_ID,
    ],
    payload: {
      request: {
        // url: `ProjectDefinition/Read/` + id,
        url: PROJECT_DEFINITION_READ_EP + id,
      },
    },
  };
};

export const getFilterProjectList = (data: {}) => {
  return {
    types: [
      LOAD_PROJECT_FILTER,
      LOAD_PROJECT_FILTER_SUCCESS,
      LOAD_PROJECT_FILTER_FAIL,
    ],
    payload: {
      request: {
        url: 'ContractingUnitTenants/GetProjectsByUserProjectFilter',
        // url: 'ProjectDefinition/ProjectFilter',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const saveProjectDefinition = (data: {}) => {
  return {
    type: SAVE_PROJECT_DEFINITION,
    payload: {
      request: {
        url: 'ProjectDefinition/Create',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const updateProjectDefinition = (data: {}) => {
  return {
    types: [
      UPDATE_PROJECT_DEFINITION,
      UPDATE_PROJECT_DEFINITION_SUCCESS,
      UPDATE_PROJECT_DEFINITION,
    ],
    payload: {
      request: {
        url: 'ProjectDefinition/Update',
        method: 'PUT',
        data: data,
      },
    },
  };
};

export const getUserRoles = (data: {}) => {
  return {
    types: [LOAD_USER_ROLES, LOAD_USER_ROLES_SUCCESS, LOAD_USER_ROLES_FAIL],
    payload: {
      request: {
        url: 'User/getRoles',
        method: 'GET',
        data: data,
      },
    },
  };
};

export const getProjectHeaderDetails = (id: any) => {
  return {
    types: [
      LOAD_PROJECT_HEADER_DETAILS,
      LOAD_PROJECT_HEADER_DETAILS_SUCCESS,
      LOAD_PROJECT_HEADER_DETAILS_FAIL,
    ],
    payload: {
      request: {
        // url: `ProjectDefinition/Read/` + id,
        url: PROJECT_HEADER_DETAILS_READ_EP + id,
      },
    },
  };
};

export const getReadLabourByPbsProduct = (id: any) => {
  return {
    types: [READ_LABOURS, READ_LABOURS_SUCCESS, READ_LABOURS_FAIL],
    payload: {
      request: {
        url: getLabourByProductIdURL(id),
        method: 'GET',
      },
    },
  };
};

export const clearFormData = () => {
  return { type: CLEAR_PROJECT_FORM_DATA };
};

export function saveProjectStateAttr(attr: string, value: any) {
  return {
    type: SAVE_PROJECT_STATE_ATTR,
    [attr]: value,
  };
}

const formatUserRoleResponse = (data: any[]) => {
  if (data && _.isArray(data)) {
    const formattedData = data.map((item: any) => {
      return {
        key: item.id,
        text: item.roleName,
      };
    });
    return formattedData;
  }
  return [];
};

export const deleteTeamMembers = (data: string[]) => {
  return {
    types: [
      DELETE_USER_ROLES,
      DELETE_USER_ROLES_SUCCESS,
      DELETE_USER_ROLES_FAIL,
    ],
    payload: {
      request: {
        url: 'ProjectTeam/DeleteTeamRole',
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const createTeamSite = (data: any) => {
  return {
    types: [CRATE_TEAM_SITE, CRATE_TEAM_SITE_SUCCESS, CRATE_TEAM_SITE_FAIL],
    payload: {
      request: {
        url: 'ProjectDefinition/CreateTeam',
        method: 'POST',
        data: data,
      },
    },
  };
};

export const getTeamInformation = (data : any) => {
  return {
    types: [GET_TEAM_INFORMATON, GET_TEAM_INFORMATON_SUCCESS, GET_TEAM_INFORMATON_FAIL],
    payload: {
      request: {
        url:'ProjectDefinition/GetTeamInformation',
        method: 'POST',
        data: data,
      }
    }
  }
}

const formattedPayloadToDropdown = (response: any) => {
  if (response.data.result) {
    const data = response.data.result.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    // console.log("map_data",data);
    return data;
  }
  return [];
};

const formattedPayloadToAllDropdown = (response: any) => {
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: item.isDefault,
      };
    });
    return data;
  }
  return [];
};

const formattedPayloadToListPaneTable = (response: any) => {
  if (response.data.result) {
    const data = response.data.result.map((item: any) => {
      return {
        id: item.id,
        value: item.projectDefinitionId,
        name: item.name,
        sequenceCode: item.sequenceCode,
        title: item.title,
        projectTypeName: item.projectTypeName,
        projectManagementLevelName: item.projectManagementLevelName,
        projectTemplateName: item.projectTemplateName
          ? item.projectTemplateName
          : '-',
        projectToleranceStateName: item.projectToleranceStateName,
        projectManagerId: item.projectManagerName
          ? item.projectManagerName
          : '-',
        customerId: item.customerName ? item.customerName : '-',
        projectStatus: item.projectStatusName ? item.projectStatusName : '-',
        sectorName: item.sectorName ? item.sectorName : '-',
        siteManagerName: item.siteManagerName ? item.siteManagerName : '-',
        ciawNumber: item.ciawNumber ? item.ciawNumber : '-',
        projectAddress: item.projectAddress ? item.projectAddress : '-',
        customerCompanyName: item.customerCompanyName
          ? item.customerCompanyName
          : '-',
      };
    });
    return data;
  }
  return [];
};

const formattedLedgerPayloadToDropdown = (response: any) => {
  // const t = i18n.t;
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
      };
    });
    return data;
  }
  return [];
};
const formattedTeamlistToDropdown = (response: any) => {
  // const t = i18n.t;
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.cabCompanyId,
        text: `${item.cabPersonName} (${item.companyName !== null ?item.companyName:''})`,
        customerContactPerson: item.cabPersonName,
        customerContactPersonId: item.cabPersonId,
        customerContactPersonEmailId: item.emailId,
        customerContactPersonEmail: item.email,
        roleName:item.roleName
      };
    });
    const filteredData = data.filter((item: any) => item.companyName !== null && item.roleName === "Customer Invoice Contact");
    return filteredData;
  }
  return [];
};
const formattedPayloadToDropdownList = (response: any) => {
  if (response) {
    const keys = Object.keys(response);
    const data = keys.map((item: any) => {
      return {
        key: item,
        text: item,
      };
    });
    return data;
  }
  return [];
};

const formattedPayloadToFilterDropdown = (response: any) => {
  // const t = i18n.t;
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
        isDefault: false,
      };
    });
    const defualtOption = [
      {
        key: '0',
        text: i18n.t('all'),
        isDefault: true,
      },
    ];
    Array.prototype.push.apply(defualtOption, data);
    return defualtOption;
  }
  return [];
};

export default projectReducer;
