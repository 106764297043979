import React, { useContext, useEffect, useState } from 'react';
import {
  getTheme,
  IconButton,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import { messageService } from '../../services/messageService';
import { useWindowSize } from '../../hooks/useResize';
import ModeSwitcherWithRadioButtons from '../ganttViewSwitcher/viewSwitcherWithRadioButtons';
import { ViewModeContext } from '../../components/projectVisualPlan/productTaxonomyListPane/component';
import { ViewMode } from '../ganttv2';

const theme = getTheme();
// Styles definition

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 5,
    fontSize: 11,
  },
};

const iconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
    cursor: 'pointer',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const cancelIcon: IIconProps = {
  iconName: 'Cancel',
  styles: { root: { fontSize: 11 } },
};
const minimizeIcon: IIconProps = {
  iconName: 'ChevronDownSmall',
  styles: { root: { fontSize: 11 } },
};
const maximizeIcon: IIconProps = {
  iconName: 'ChevronUpSmall',
  styles: { root: { fontSize: 11 } },
};
const collapseIcon: IIconProps = {
  iconName: 'DockLeft',
  styles: { root: { fontSize: 11 } },
};
const refreshIcon: IIconProps = {
  iconName: 'Refresh',
  styles: { root: { fontSize: 11 } },
};
const doubleColumnIcon: IIconProps = {
  iconName: 'DoubleColumn',
  styles: { root: { fontSize: 11 } },
};
const fullScreenIcon: IIconProps = {
  iconName: 'FullScreen',
  styles: { root: { fontSize: 11 } },
};
const addIcon: IIconProps = {
  iconName: 'Add',
  styles: { root: { fontSize: 11 } },
};
const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};
const stackStyles: IStackStyles = {
  root: {
    borderBottom: '1px solid rgb(237, 235, 233)',
    height: 26,
    //height:"4.9vh",
    //marginRight:-9
  },
};

const TitlePane = (props: {
  headerTitle: any;
  close?: boolean;
  collapse?: boolean;
  isCollapse?: boolean;
  type?: number;
  minimize?: boolean;
  isMinimize?: boolean;
  isRefresh?: boolean;
  isFullScreen?: boolean;
  isOldItem?: boolean;
  addProduct?: boolean;
  addUser?:boolean;
  addProductClick?: any;
  addWorkerClick?:any;
}) => {
  const [width, height] = useWindowSize();
  const [selectedMode, setSelectedViewMode] = React.useState<ViewMode>(
    ViewMode.Month
  );
  const [maxScreen,setMaxScreen] = useState(false)
  const { onViewModeChange, onViewListChange, isCheck } = useContext(ViewModeContext);

  useEffect(() => {
    resetViewSwitcher()
  },[])

  useEffect(()=>{},[maxScreen])

  const resetViewSwitcher = () => {
    onViewModeChange(ViewMode.Month);
    setSelectedViewMode(ViewMode.Month);
  }
  const hideDocumentPane = () => {
    messageService.sendMessage({
      hideDocumentPane: true,
      documentPaneType: props.type,
      // isNewPbs:true
    });
  };
  const collapseDocumentPane = () => {
    console.log('first')
    setMaxScreen(!maxScreen)
    messageService.sendMessage({
      collapseDocumentPane: true,
      documentPaneType: props.type ? props.type : 1,
      documentPaneFullScreen:!maxScreen
    });
  };
  const minimizeBottomPane = () => {
    messageService.sendMessage({
      minimizeBottomPane: true,
      isMinimize: !props.isMinimize,
    });
  };
  const refresh = () => {
    messageService.sendMessage({ refresh: true });
  };
  const fullScreen = () => {
    messageService.sendMessage({ fullScreen: true });
  };

  // const renderkList = () => {
  //   var titleList = props.list
  //     .map(function (el: any) {
  //
  //         return (
  //           <Stack.Item key={el} align="center" styles={stackItemStyles}>
  //             <span>{el}</span>
  //           </Stack.Item>
  //         );
  //
  //
  //     });
  //   return titleList;
  // };

  const onAddClick = () => {
    console.log('onClick')
    if(props.addProduct){
     return props.addProductClick();
    } else if(props.addUser){
      messageService.sendMessage({onAdd:true})
    }
  }

  if (props.close) {
    return (
      <div
        className={'title-pane'}
        style={{ backgroundColor: uPrinceTheme.palette.themePrimary }}
      >
        {/*<Stack horizontal horizontalAlign="space-between" styles={stackStyles}>*/}
        {/*<span style={itemStyles}>{props.headerTitle}</span>*/}
        {/*<span style={itemStyles}>3</span>*/}
        {/*</Stack>*/}
        <Stack
          horizontal
          horizontalAlign="space-between"
          disableShrink
          styles={stackStyles}
        >
          <Stack.Item align="center" styles={stackItemStyles}>
            <span>{props.headerTitle}</span>
          </Stack.Item>
          <Stack.Item align="center" styles={stackItemStyles}>
            {props.isRefresh && (
              <IconButton
                styles={iconButtonStyles}
                iconProps={refreshIcon}
                ariaLabel="Close popup modal"
                onClick={() => {
                  refresh();
                }}
              />
            )}
            {props.collapse && (
              <IconButton
                styles={iconButtonStyles}
                iconProps={props.isCollapse ? doubleColumnIcon : collapseIcon}
                ariaLabel="Close popup modal"
                onClick={() => {
                  collapseDocumentPane();
                }}
              />
            )}
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => {
                localStorage.removeItem('PBS')
                localStorage.removeItem('product')
                hideDocumentPane();
              }}
            />
          </Stack.Item>
        </Stack>
      </div>
    );
  } else {
    return (
      <div
        className={'title-pane'}
        style={{ backgroundColor: uPrinceTheme.palette.themePrimary }}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          disableShrink
          style={{ width: props.addUser ?  width - 280: props.isOldItem ? 'inherit' : width - 25 }}
          styles={stackStyles}
        >
          {/*{renderkList()}*/}
          <Stack.Item align="center" styles={stackItemStyles}>
            <span>{props.headerTitle} </span>
          </Stack.Item>
       
          {props.minimize && (
            <Stack.Item align="center" styles={stackItemStyles}>
              <IconButton
                styles={iconButtonStyles}
                iconProps={props.isMinimize ? maximizeIcon : minimizeIcon}
                ariaLabel="Close popup modal"
                onClick={() => {
                  minimizeBottomPane();
                }}
              />
            </Stack.Item>
          )}
          {props.isFullScreen && (
            <Stack.Item align="center" styles={stackItemStyles}>
              <IconButton
                styles={iconButtonStyles}
                iconProps={fullScreenIcon}
                onClick={() => {
                  fullScreen();
                }}
              />
            </Stack.Item>
          )}
          {/* Added add icon only for project planning using key addProducts  */}
          {(props.addProduct || props.addUser) && (
            <Stack.Item align="center">
              <IconButton
                styles={iconButtonStyles}
                iconProps={addIcon}
                onClick={() => {
                  onAddClick()
                }}
              ></IconButton>
            </Stack.Item>
          )}
        </Stack>
      </div>
    );
  }
};

export default TitlePane;
