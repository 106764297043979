import React, {useEffect} from 'react';
import {FontWeights, mergeStyleSets} from '@fluentui/react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './app/routes';
import UnauthenticatedRoutes from './app/unauthenticatedRoutes';
import {InteractionType, IPublicClientApplication} from "@azure/msal-browser";
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsalAuthentication} from "@azure/msal-react";
import './app/shared/main.css'

const boldStyle = {
  root: { fontWeight: FontWeights.semibold },
};

type AppProps = {
    pca: IPublicClientApplication
};

export const App= ({ pca }: AppProps) => {

  return (
      <MsalProvider instance={pca}>
          <AuthenticatedTemplate>
              <Router>
                  <div className={'routes_container'}>
                      <Routes />
                  </div>
              </Router>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
              <Router>
                  <div>
                      <UnauthenticatedRoutes />
                  </div>
              </Router>
          </UnauthenticatedTemplate>
      </MsalProvider>

  );
};
