import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PmolDocumentPane from './pmolContainer';
import { mergeStyles, Stack } from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import CommandBarRight from '../commandBar/commandBarRight';
import ProgressBar from '../../../shared/progressBar/progressBar';
import PmolBookMarkPaneComponent from '../../projectMolecule/bookMarkPane/pmolBookmarkPane';
import { PMOL_TYPE } from '../../../types/projectMolecule';

const DocumentPane = () => {

  const [toggleBookmark, setToggleBookmark] = useState<boolean>(true)

  const {
    selectedPmol,
    collapseBookmarkPane,
    showPmolProgressBar,
    // horizontalReSizer,
  } = useSelector((state: any) => state.projectDPlan);

  const contentStyle = mergeStyles({
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    selectors: {
      '@media(max-width: 1100px)': {
        width: '97.4%',
        height: 'calc(100vh)',
        position: 'absolute',
      },
    },
  });

  const _toggleBookmark = () => {
    setToggleBookmark(true)
  }

  const _toggleBookmarkOut = (): void => {
    setToggleBookmark(false)
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Stack
        className="project-list-header right-panel"
        style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
        styles={{ root: { width: '100%', paddingRight: 0 } }}
      >
        <TitlePane
          close={true}
          headerTitle={selectedPmol ? selectedPmol.title : ''}
          collapse={true}
          isCollapse={collapseBookmarkPane}
          type={1}
        />
        <CommandBarRight />
        <ProgressBar show={showPmolProgressBar} />
      </Stack>
      <div
        style={{
          height: 'calc(100% - 71px)',
          backgroundColor: 'white',
          display: 'flex',
        }}
      >
        <div
          style={{
            width: toggleBookmark ? 'calc(100% - 220px)' : 'calc(100% - 28px)',
          }}
        >
          <Stack
            className={`project-detail-list ${
              collapseBookmarkPane && window.innerWidth > 1440
                ? 'four-pane-full-inner-container'
                : 'four-pane-full-inner-container'
            } cpc  card-container`}
            styles={{
              root: {
                width: '100%',
                height: '100%',
                backgroundColor: 'rgb(255, 255, 255)',
                overflow: 'auto'
              },
            }}
          >
            {/*doc*/}
            <Stack
              className={contentStyle}
              horizontalAlign="start"
              verticalAlign="start"
              padding={0}
              gap={0}
              styles={{
                root: {
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgb(255, 255, 255)',
                },
              }}
            >
              <PmolDocumentPane projectId={selectedPmol?.projectSequenceCode} />
            </Stack>
          </Stack>
        </div>
        <PmolBookMarkPaneComponent 
          isEdit={true}
          isShowBookmark={toggleBookmark}
          currentActiveSection=""
          toggleBookmark={_toggleBookmark}
          toggleBookmarkOut={_toggleBookmarkOut}
          pmolType={PMOL_TYPE.REGULAR}
        />
      </div>
    </div>
  );
};

export default DocumentPane;
