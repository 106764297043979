import _ from 'lodash';
import { EXTERNAL_SYNC_STATUS_EP } from '../shared/endpoints';
import axios from 'axios';

export const LOAD_EXTERNAL_SYNC_STATUS = 'uprince/LOAD_EXTERNAL_SYNC_STATUS';
export const LOAD_EXTERNAL_SYNC_STATUS_SUCCESS =
  'uprince/LOAD_EXTERNAL_SYNC_STATUS_SUCCESS';
export const LOAD_EXTERNAL_SYNC_STATUS_FAIL =
  'uprince/LOAD_EXTERNAL_SYNC_STATUS_FAIL';

const defaultState: any = {
  isExternalSyncStatusLoaded: false,
  externalSyncStatus: null,
};

const source = axios.CancelToken.source();

const externalSyncReducer = (state = defaultState, action: any) => {
  if (action) {
    switch (action.type) {
      //  GET EXTERNAL SYNC STATUS
      case LOAD_EXTERNAL_SYNC_STATUS:
        return {
          ...state,
          loading: true,
          isExternalSyncStatusLoaded: false,
          externalSyncStatus: null,
        };
      case LOAD_EXTERNAL_SYNC_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          isExternalSyncStatusLoaded: true,
          externalSyncStatus: action.payload.data.result,
        };
      case LOAD_EXTERNAL_SYNC_STATUS_FAIL:
        return {
          ...state,
          loading: false,
          isExternalSyncStatusLoaded: true,
          error: 'Error while fetching the external sync status',
        };

      default:
        return state;
    }
  }
};

export const getExternalSyncStatus = () => {
  return {
    types: [
      LOAD_EXTERNAL_SYNC_STATUS,
      LOAD_EXTERNAL_SYNC_STATUS_SUCCESS,
      LOAD_EXTERNAL_SYNC_STATUS_FAIL,
    ],
    payload: {
      request: {
        url: EXTERNAL_SYNC_STATUS_EP,
      },
    },
  };
};

// export const getProjectDefinition = (id: any) => {
//   return {
//     types: [
//       LOAD_PROJECT_DEFINITION_BY_ID,
//       LOAD_PROJECT_DEFINITION_BY_ID_SUCCESS,
//       LOAD_PROJECT_DEFINITION_FAIL_BY_ID,
//     ],
//     payload: {
//       request: {
//         // url: `ProjectDefinition/Read/` + id,
//         url: PROJECT_DEFINITION_READ_EP + id,
//       },
//     },
//   };
// };

export default externalSyncReducer;
