import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  getTheme,
  // Icon,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  Separator,
  Stack,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { uPrinceTheme } from '../../../../theme';
import {
  ProjectBreakdown,
  ProjectBreakdownValidationMessage,
} from '../../../types/projectBreakdownStructure';
import TextEditor from '../../../shared/textEditor/textEditor';
import client from '../../../api';
import {
  formatDateTimeToISOString,
  PMOL_PBS_HEADER_SECTION_LIST,
} from '../../../shared/util';
import { useStore } from 'react-redux';
import PictureUploadComponent from '../../projectMolecule/extraWork/PictureUpload/PictureUploadComponent';
import { SAVE_PBS_COMPOSITION_IMAGES } from '../../../shared/endpoints';

const theme = getTheme();

const iconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.themePrimary,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

// Styles definition
const stackStyles: IStackStyles = {
  root: {
    padding: 0,
    marginBottom: 10,
  },
};

const classNames = mergeStyleSets({
  fullWidth: {
    width: '100%',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: {
      '@media(max-width: 1100px)': {
        textAlign: 'start',
      },
    },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  bigScreenTree: {
    marginBottom: 30,
    height: '30vh',
  },
  smallScreenTree: {
    marginBottom: 30,
    height: '56vh',
  },
  textEditor: {
    height:
      window.innerWidth <= 1280
        ? window.innerWidth <= 1150
          ? '60vh'
          : '50vh'
        : '30vh',
  },
});

const stackFooterBarItemStyles: IStackItemStyles = {
  root: {
    height: 45,
  },
};
const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const ProductDetail = (props: {
  isEdit: boolean;
  dropDownOptions: any;
  projectBreakdown: ProjectBreakdown;
  saveFormData: () => void;
  handleFormChange: (projectBreakdown: ProjectBreakdown, save: boolean) => void;
  validationMessages: ProjectBreakdownValidationMessage;
  readOnly: boolean;
  // productId:any
}) => {
  const { t } = useTranslation();
  const [copyImages, setCopyImages] = useState<any>([]);
  const [isDeletePCImage, setIsDeletePCImage] = useState(false);
  const [render, setRender] = useState(false);
  // const [productCompositionImages, setProdCompositionImages] = useState<any>(
  //   []
  // );
  const store = useStore();

  // const { formData } = useSelector((state: any) => state.projectBreakdown);

  const getProductCompositionImages = async () => {
    const getImageId = store.getState()?.projectBreakdown?.getImageId;
    await client.get('Pbs/GetPmolApprovedImagesByPbsId/' + getImageId).then(
      (res: any) => {
        if (
          res.data.result &&
          res.data.result.length > 0 &&
          res.data.result[0].picturesLinksDto
        ) {
          let newImage: any = [];
          res.data.result[0].picturesLinksDto?.forEach((item: any) => {
            newImage.push({
              id: item.id,
              image: item.link,
              link: item.link,
              type: item.type,
              //
              phase: item.phase,
              fileName: item.fileName,
              location: item.location,
              createdDate: item.createdDate,
              lat: item.lat,
              lon: item.lon,
              freeFormAddress: item.freeFormAddress,
            });
          });
          setCopyImages(newImage);
        } else {
          setCopyImages([]);
        }
      },

      (error: any) => {
        setCopyImages([]);
      }
    );
  };
  useEffect(() => {
    setRender(true);
  }, []);
  useEffect(() => {
    if (props.projectBreakdown.id != null) {
      getProductCompositionImages();
    }
  }, [props.projectBreakdown.id]);

  useEffect(() => {
    if (isDeletePCImage) {
      setIsDeletePCImage(false);
    }
  }, [isDeletePCImage]);

  const getFileName = (item: string) => {
    let decoded = decodeURIComponent(item);
    let fileName = decoded.substring(decoded.indexOf('M') + 1);

    return fileName;
  };

  const getShortFileName = (item: string) => {
    let shortFileName = getFileName(item).substring(
      0,
      getFileName(item).length
    );

    return shortFileName;
  };

  const onImageUpload = async (image: any, classifiedType?: string) => {
    let fetchedImageDetails = {
      id: null,
      image: image,
      link: image,
      type: '1',
      isApproved: false,
      isExtrawork: true,
      //
      phase: classifiedType,
      fileName: getShortFileName(image),
      location: null,
      createdDate: formatDateTimeToISOString(new Date()),
      pmolExtraWorkId: null,
    };
    let payload = {
      id: '',
      description: '',
      pbsProductId: props.projectBreakdown?.productId || null,
      pictureList: [fetchedImageDetails],
    };
    if (payload.pbsProductId) {
      try {
        const request = await client.post(SAVE_PBS_COMPOSITION_IMAGES, payload);
        if (request.status === 200) {
          getProductCompositionImages();
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <div className={'document-pane-card'}>
      <div className={'card-header'}>
        <Link href="#" id="pbs-product-detail">
          <Label>3. {t('productDetails')} </Label>
        </Link>
      </div>

      <div className={'card-body'}>
        <div className="proj-detail-content inner">
          <div className={`marginTop marginBottom `}>
            <div className={`${classNames.fullWidth}`}>
              <Label>{t('productPurpose')}</Label>
              {render && (
                <TextEditor
                  value={
                    props.projectBreakdown.productPurpose
                      ? props.projectBreakdown.productPurpose
                      : ''
                  }
                  onChange={(value: any) => {
                    if (value) {
                      props.projectBreakdown.productPurpose = value;
                    } else {
                      props.projectBreakdown.productPurpose = null;
                    }
                  }}
                  readOnly={props.readOnly}
                />
              )}
            </div>
          </div>

          <div className={`marginTop marginBottom `}>
            <div className={`${classNames.fullWidth}`}>
              <Label>{t('productComposition')}</Label>
              {render && (
                <TextEditor
                  value={
                    props.projectBreakdown.productComposition
                      ? props.projectBreakdown.productComposition
                      : ''
                  }
                  onChange={(value) => {
                    if (value) {
                      props.projectBreakdown.productComposition = value;
                    } else {
                      props.projectBreakdown.productComposition = null;
                    }
                  }}
                  readOnly={props.readOnly}
                />
              )}
            </div>
          </div>
          <div>
            <PictureUploadComponent
              uploadedImages={copyImages}
              onSaveSuccess={(res: any, classifiedType: string) =>
                onImageUpload(res, classifiedType)
              }
              isDragEnabled={true}
              updateDocument={(id: any, isDelete: boolean) => {
                getProductCompositionImages();
              }}
              pmolId={null}
              pbsId={props.projectBreakdown?.productId!!}
              sectionHeading={PMOL_PBS_HEADER_SECTION_LIST.PRODUCT}
              renameSuccess={() => {
                getProductCompositionImages();
              }}
              uploadURL={'pbs/UploadProductCompositionFile'}
              productId={undefined}
              isProduct={true}
            />
          </div>
          <div className={`marginTop marginBottom `}>
            <div className={`${classNames.fullWidth}`}>
              <Label>{t('productDerivation')}</Label>
              {render && (
                <TextEditor
                  value={
                    props.projectBreakdown.productDerivation
                      ? props.projectBreakdown.productDerivation
                      : ''
                  }
                  onChange={(value) => {
                    if (value) {
                      props.projectBreakdown.productDerivation = value;
                    } else {
                      props.projectBreakdown.productDerivation = null;
                    }
                  }}
                  readOnly={props.readOnly}
                />
              )}
            </div>
          </div>

          <div className={`marginTop marginBottom `}>
            <div className={`${classNames.fullWidth}`}>
              <Label>{t('productFormatPresentation')}</Label>
              {render && (
                <TextEditor
                  value={
                    props.projectBreakdown.productFormatPresentation
                      ? props.projectBreakdown.productFormatPresentation
                      : ''
                  }
                  onChange={(value) => {
                    if (value) {
                      props.projectBreakdown.productFormatPresentation = value;
                    } else {
                      props.projectBreakdown.productFormatPresentation = null;
                    }
                  }}
                  readOnly={props.readOnly}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={'card-footer'}>
        <Separator />
        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                props.saveFormData();
              }}
              readOnly={props.readOnly}
            >
              <Label className={classNames.actionButton}>{t('save')} </Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
    </div>
  );
};

export default ProductDetail;
