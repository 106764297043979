import { Text } from '@fluentui/react';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { walk } from '@nosferatu500/react-sortable-tree';
import { ExpandProvider } from '../../../hooks/expandContext';
import {
  formatVPurchaseTaxonomyBarDataWithoutRefresh,
  readVPMachineTaxonomyData,
  saveVPStateAttr,
  // updateProductTaxonomyTreeIndex,
} from '../../../reducers/visualPlanReducer';
import { Task, ViewMode } from '../../../shared/ganttv2';
import { Gantt } from '../../../shared/ganttv2/components/gantt/gantt';
import { ChartType } from '../../../shared/ganttv2/types/public-types';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { initTasks } from './helper';
import { ViewSwitcher } from './view-switcher';

export const ViewModeContext = React.createContext({
  onViewModeChange: (viewMode: ViewMode) => {},
  onViewListChange: (isCheck: boolean) => {},
  isCheck: true,
});
// Init
const MachineTaxonomyListPane = () => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.Month);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const reduxBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpMachineTaxonomyBarList
  );

  const listTasks: any[] = useSelector(
    (state: any) => state.vp.vpMachineTaxonomyList
  );

  const interactBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpMachineTaxonomyInteractBarList
  );

  const isDataLoaded: boolean = useSelector(
    (state: any) => state.vp.machineTaxanomyDataLoaded
  );

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.QuaterYear) {
    columnWidth = 350;
  }

  useEffect(() => {
    dispatch(
      readVPMachineTaxonomyData({
        fromDate: null,
        toDate: null,
      })
    );
  }, []);

  useEffect(() => {
    setDataLoaded(isDataLoaded);
  }, [isDataLoaded]);

  const handleTaskChange = (task: any) => {};

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {};

  const handleDblClick = (task: Task) => {};

  const handleSelect = (_task: Task, _isSelected: boolean) => {};

  const handleExpanderClick = (task: Task) => {
    // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const stringToArr = (string1: any) => {
    if (string1) {
      const x = string1.trim().split(',');
      x.shift();
      return x;
    }
    return [];
  };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  const sumNodeValues = (treeData: any) => {
    let indexss = '';
    const callback = ({ node }: any) => {
      indexss += ',' + node.id;
    };
    walk({
      treeData,
      getNodeKey,
      callback,
      ignoreCollapsed: true,
    });

    return indexss;
  };

  const onVisibilityChanged = (data: any) => {
    const treeTotal1 = sumNodeValues(data);
    if (stringToArr(treeTotal1).length <= interactBarTasks.length) {
      const newTasks = interactBarTasks.filter((item) =>
        stringToArr(treeTotal1).includes(item.id)
      );
      dispatch(saveVPStateAttr('vpMachineTaxonomyInteractBarList', newTasks));
    } else {
      const newTasks = reduxBarTasks.filter((item) =>
        stringToArr(treeTotal1).includes(item.id)
      );
      dispatch(saveVPStateAttr('vpMachineTaxonomyInteractBarList', newTasks));
    }
  };

  // const getTreeIndexList = (treeData:any[])=> {
  //     let total = 0;
  //     let modifiedArray:any[] = [];
  //     const callback = (props:any) => {
  //       //console.log("INDEX", props)
  //       modifiedArray.push({ pbsProductId: props.node.id, treeIndex: props.treeIndex });
  //       total += props.node.value;
  //     };

  //     walk({
  //       treeData,
  //       getNodeKey,
  //       callback,
  //       ignoreCollapsed: false,
  //     });
  //     dispatch(updateProductTaxonomyTreeIndex({pbsTreeIndex:modifiedArray}))
  //     return total;
  //   }

  return (
    <div style={{ height: '100%' }}>
      <ViewModeContext.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
        }}
      >
        <ExpandProvider>
          <ViewSwitcher />
          {!dataLoaded && <ProgressBar show={true} />}
          {dataLoaded && _.isEmpty(interactBarTasks) && (
            <Text style={{ marginLeft: 20, marginTop: 5 }}>{t('noData')}</Text>
          )}
          {dataLoaded && !_.isEmpty(interactBarTasks) && (
            <Gantt
              tasks={
                listTasks &&
                _.isArray(listTasks) &&
                !_.isEmpty(listTasks) &&
                dataLoaded &&
                !_.isEmpty(interactBarTasks) &&
                typeof interactBarTasks[0].start !== 'string'
                  ? interactBarTasks
                  : tasks
              }
              listTasks={
                listTasks &&
                _.isArray(listTasks) &&
                !_.isEmpty(listTasks) &&
                dataLoaded
                  ? listTasks
                  : tasks
              }
              searchQuery={''}
              searchMethod={({ node, searchQuery }: any) => {
                return (
                  searchQuery &&
                  node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                    -1
                );
              }}
              searchFocusOffset={0}
              searchFinishCallback={(matches) => {}}
              onlyExpandSearchedNodes={true}
              viewMode={view}
              onDateChange={handleTaskChange}
              onDelete={handleTaskDelete}
              onProgressChange={handleProgressChange}
              onDoubleClick={handleDblClick}
              onSelect={handleSelect}
              onExpanderClick={handleExpanderClick}
              listCellWidth={isChecked ? '155px' : ''}
              columnWidth={columnWidth}
              onVisibilityChanged={onVisibilityChanged}
              onVisibilityToggle={(data: any) => {}}
              ganttHeight={560}
              isInitialCollaps={true}
              canDrag={({ node, path, treeIndex }: any) => {
                return false;
              }}
              canDrop={({ node, nextParent }: any) => {
                return false;
              }}
              onMoveNode={(_path, _node) => {}}
              chartType={ChartType.PBS}
              onTreeDataChange={(treeData: any[]) => {
                dispatch(
                  saveVPStateAttr('vpMachineTaxonomyBarList', [
                    ...formatVPurchaseTaxonomyBarDataWithoutRefresh(
                      treeData
                    )[0],
                  ])
                );
                dispatch(
                  saveVPStateAttr('vpMachineTaxonomyInteractBarList', [
                    ...formatVPurchaseTaxonomyBarDataWithoutRefresh(
                      treeData
                    )[1],
                  ])
                );
              }}
              selectedScreen={''}
            />
          )}
        </ExpandProvider>
      </ViewModeContext.Provider>
    </div>
  );
};

export default MachineTaxonomyListPane;
