import React from 'react';
import {Calendar, DayOfWeek, FirstWeekOfYear} from "@fluentui/react";
import {formatDateTimeToISOString, getCalenderString, stringDateToDateObject} from "../../../../shared/util";
import {saveMyDayPlanningStateAttr} from "../../../../reducers/myDayPlanningReducer";
import {
    filterProjectDayPlanningProjectList,
    getProjectDayPlanningResourceList
} from "../../../../reducers/projectDayPlanningReducer";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getFilters} from "../../listPane/bottomListPane";

type Props = {

};

const ProjectPbCalendar: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {
        planboardPersonId,
        selectedDate,
    } = useSelector(
        (state: any) => state.mydPlan
    );
    const {
        selectedCu,
        selectedBu,
        ppbProjectSequenceCode,
        ppbPmolTypeId,
        ppbSiteManagerId,
    } = useSelector(
        (state: any) => state.projectDPlan
    );

    const onSelectDate = React.useCallback((date: Date, dateRangeArray: Date[] | undefined): void => {
        Promise.all([
            dispatch(saveMyDayPlanningStateAttr('selectedDate', date)), // Using SelectedDate from myDayPlanning(teamPlanBoard)
        ]).then(() => {
            dispatch(filterProjectDayPlanningProjectList({
                executionDate: formatDateTimeToISOString(date),
                contractingUnit: [selectedCu?.sequenceCode],
                buId: selectedBu,
                cabPersonId: planboardPersonId ?? null,
                projectSequenceCode: ppbProjectSequenceCode ? ppbProjectSequenceCode.value : null,
                pmolTypeId: ppbPmolTypeId ?? null,
                siteManagerId: ppbSiteManagerId ?? null
            }));

            dispatch(getProjectDayPlanningResourceList(getFilters(date, selectedCu?.sequenceCode, selectedBu, planboardPersonId)));
        });
    }, []);

    return (
        <Calendar
            showMonthPickerAsOverlay
            highlightSelectedMonth
            firstDayOfWeek={DayOfWeek.Monday}
            showGoToToday={false}
            onSelectDate={onSelectDate}
            value={stringDateToDateObject(selectedDate)}
            // Calendar uses English strings by default. For localized apps, you must override this prop.
            strings={getCalenderString()}
            showWeekNumbers={true}
            firstWeekOfYear={FirstWeekOfYear.FirstFourDayWeek}
            styles={{root: {height: '100%'}}}
        />
    );
};

export default ProjectPbCalendar;
