import React, { useState } from 'react';
import { FontIcon, mergeStyleSets } from '@fluentui/react';

const classNames = mergeStyleSets({
  wrapper: {
    height: 'calc(100vh - 14vh)!important',
    position: 'relative',
  },
  selected: {
    fontWeight: 'bold',
    color: '#854114!important',
  },
  hr: {
    marginBottom: 15,
    borderTop: '1px solid #d2d2d2',
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
  iconClass: {
    display: 'inline-block',
    fontFamily: 'FabricMDL2Icons',
    fontStyle: 'normal',
    fontWeight: 400,
    speak: 'none',
    margin: '4px 8px',
    cursor: 'pointer',
  },
  displayNone: { display: 'none' },
  collapsed: { transition: 'none!important', minWidth: '32px!important' },
  expand: { transition: 'none!important', minWidth: '255px!important' },
  container: {
    height: '100%',
  },
});

const BottomShortcutPane = () => {
  const [toggleOverlay, setToggleOverlay] = useState<boolean>(false);

  const onClickToggle = (): void => {
    setToggleOverlay((toggleOverlay) => !toggleOverlay);
  };

  return (
    <div className={classNames.container}>
      <div
        className={`projectDayPlanning  filter-panel-left ${
          classNames.wrapper
        } ${!toggleOverlay ? classNames.collapsed : classNames.expand}`}
      >
        <FontIcon
          iconName="GlobalNavButton"
          className={classNames.iconClass}
          onClick={onClickToggle}
        />
        <div
          className={`inner-container ${
            !toggleOverlay && classNames.displayNone
          }`}
        ></div>
      </div>
    </div>
  );
};

export default BottomShortcutPane;
