import React from 'react'
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import env from '../../../../env.json'
import { formatDateTimeToLocal } from '../../../shared/util';
import { useTranslation } from 'react-i18next';
import groupArray from 'group-array';
import _ from 'lodash';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    width: '100%'
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  },
  image: {
    width: '100%',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  table: {
    fontSize: 11,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 1,
    borderColor: '#020202',
  },
  tableNoBoarder: {
    fontSize: 11,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 0,
    borderColor: 'none',
  },
  tableBody: {
    display: 'flex', // error TS2322: Type '"table"' is not assignable to type '"none" | "flex" | undefined'.
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderBottomWidth: 0,
  },
  tableCol: {
    width: '60%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingLeft: 4,
    paddingRight: 1,
    fontSize: 10,
  },
  tableCol2: {
    width: '40%',
    textAlign: 'right',
    backgroundColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingRight: 4,
    paddingLeft: 1,
    fontSize: 10,
  },
  tableColLeft2: {
    width: '40%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingRight: 1,
    paddingLeft: 1,
    fontSize: 10,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
});

const itemTableWidth = {
  col: '54%',
  col2: '13%',
  col3: '7%',
  col4: '13%',
  col5: '13%',
};

const PDFPS = (props: {
  psData: any, 
  customerContactPerson: any,
  project: any
}) => {
  const {t} = useTranslation();

  const formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: 'EUR',
  });

  const Table = (props: any) => {
    return (
      <View style={props.borderWidth === 0 ? styles.tableNoBoarder : styles.table}>
        {props.children}
      </View>
    );
  };

  const TableRow = (props: any) => {
    if (props.style) {
      if (props.style.backgroundColor) {
        styles.tableRow.backgroundColor = props.style.backgroundColor;
      }
      if (props.style.border) {
        styles.tableRow.borderColor = props.style.borderColor;
        styles.tableRow.borderBottomWidth = props.style.borderBottomWidth ? props.style.borderBottomWidth : 2;
      } else {
        styles.tableRow.borderBottomWidth = 0;
      }
    }
    return (
      <View wrap={false} style={styles.tableRow}>
        {props.children}
      </View>
    );
  };

  const TableCol = (props: any) => {
    if (props.style) {
      if (props.style.backgroundColor) {
        styles.tableCol.backgroundColor = props.style.backgroundColor;
      }
      if (props.style.border) {
        if (props.style.isLastRow) {
          styles.tableCol.borderBottomWidth = 0;
          styles.tableCol.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
        } else {
          styles.tableCol.borderBottomWidth = 1;
          styles.tableCol.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
        }
      } else {
        styles.tableCol.borderColor = 'none';
        styles.tableCol.borderBottomWidth = 0;
        styles.tableCol.borderRightWidth = 0;
      }
  
      if (props.style.width) {
        styles.tableCol.width = props.style.width;
      } else {
        styles.tableCol.width = '30%';
      }
    }

    return (
      <View style={styles.tableCol}>
        {props.children}
      </View>
    );
  };
  
  const TableCol2 = (props: any) => {
    if (props.style) {
      if (props.style.backgroundColor) {
        styles.tableCol2.backgroundColor = props.style.backgroundColor;
      }
      if (props.style.border) {
        if (props.style.isLastRow) {
          styles.tableCol2.borderBottomWidth = 0;
          styles.tableCol2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
        } else {
          styles.tableCol2.borderBottomWidth = 1;
          styles.tableCol2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
        }
  
      } else {
        styles.tableCol.borderColor = 'none';
        styles.tableCol2.borderBottomWidth = 0;
        styles.tableCol2.borderRightWidth = 0;
      }
      if (props.style.width) {
        styles.tableCol2.width = props.style.width;
      } else {
        styles.tableCol2.width = '70%';
      }
    }
    return (
      <View style={styles.tableCol2}>
        {props.children}
      </View>
    );
  };
  
  const TableColLeft2 = (props: any) => {
    if (props.style) {
      if (props.style.backgroundColor) {
        styles.tableColLeft2.backgroundColor = props.style.backgroundColor;
      }
      if (props.style.border) {
        if (props.style.isLastRow) {
          styles.tableColLeft2.borderBottomWidth = 0;
          styles.tableColLeft2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
        } else {
          styles.tableColLeft2.borderBottomWidth = 1;
          styles.tableColLeft2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
        }
  
      } else {
        styles.tableCol.borderColor = 'none';
        styles.tableColLeft2.borderBottomWidth = 0;
        styles.tableColLeft2.borderRightWidth = 0;
      }
      if (props.style.width) {
        styles.tableColLeft2.width = props.style.width;
      } else {
        styles.tableColLeft2.width = '70%';
      }
    }
    return (
      <View style={styles.tableColLeft2}>
        {props.children}
      </View>
    );
  };

  const renderPsItem = (props: any) => {
    let data: any = getPsData(props);
    return data.map((item: any, index: any) => {
      return (
        <View key={index + 'psm'} style={{ paddingTop: 8 }}>
          <TableRow style={{ backgroundColor: '#ffffff', border: true, borderColor: '#020202', borderBottomWidth: 1 }}>
            <TableCol style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col }}><Text
              style={{ fontWeight: 'bold', fontSize: 10, fontFamily: 'Helvetica-Bold' }}> {item.product}</Text></TableCol>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col2,
            }}><Text>&nbsp;</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col3,
            }}><Text>&nbsp;</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col4,
            }}><Text>&nbsp;</Text></TableCol2>
            <TableCol2 style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col5 }}><Text style={{
              fontWeight: 'bold',
              fontSize: 10,
              fontFamily: 'Helvetica-Bold',
            }}>{getResourcesSubTotal(item.resource)}</Text></TableCol2>
          </TableRow>
          <View key={index + 'psmu'}>
            {renderPsResourceItem(item.resource)}
          </View>
        </View>
      );
    });
  };

  const getPsData = (props: any) => {
    let costListData = props.resources?.map((cost: any) => ({
      ...cost,
      resourceCategory: getResourceCategory(cost.cpcResourceTypeId),
    }));
    let products = groupArray(costListData, 'productTitle');
    let productMap = getCostMap(products);
    let array = Array.from(productMap, ([product, resource]) => ({ product, resource }));
    let array2 = array.map(item => {
      return {
        product: item.product,
        resource: groupArray(item.resource, 'resourceCategory'),
      };
    });
    const subResourceArray: any[] = array2.map((item: any) => {
      let materiData = getUniqueValues(item.resource.Materials);
      let toolsData = getUniqueValues(item.resource.Tools);
      let consumablesData = getUniqueValues(item.resource.Consumables);
      let laboursData = getUniqueValues(item.resource.Labours);
      let serviceData = getUniqueValues(item.resource.Service);
      let cbcData = getUniqueValues(item.resource.CBC);
      let allResources = _.concat(materiData, toolsData, consumablesData, laboursData, serviceData, cbcData);
  
      return allResources;
    });
    var merged = [].concat.apply([], subResourceArray);
    return getuniqueData(merged);
  
  };

  const getUniqueValues = (item: any) => {
    const uniqueValues = new Map();
    if (item !== null && item !== undefined) {
      item.forEach((e: any) => {
        const key = `${e.productTitle}_${e.cpcResourceType}_${e.cpcResourceNumber}`;
        if (uniqueValues.has(key)) {
          uniqueValues.get(key).soldQuantity += e.soldQuantity;
          uniqueValues.get(key).consumedQuantity += e.consumedQuantity;
          // uniqueValues.get(key).costToMou = parseFloat(uniqueValues.get(key).costToMou) + parseFloat(e.costToMou);
          uniqueValues.get(key).spToMou += e.spToMou;
          uniqueValues.get(key).totalCost = parseFloat(uniqueValues.get(key).totalCost) + parseFloat(e.totalCost);
        } else {
          uniqueValues.set(key, { ...e });
        }
      });
    }
    const aggregatedValues = Array.from(uniqueValues.values());
    const filteredValues = aggregatedValues.filter((value) => value.soldQuantity !== 0);
    return filteredValues;
  }

  const getuniqueData = (value: any) => {
    let costListData = value.map((cost: any) => ({
      ...cost,
      resourceCategory: getResourceCategory(cost.cpcResourceTypeId),
    }));
    let products = groupArray(costListData, 'productTitle');
    let productMap = getCostMap(products);
    let array = Array.from(productMap, ([product, resource]) => ({ product, resource }));
    let array2 = array.map(item => {
      return {
        product: item.product,
        resource: groupArray(item.resource, 'resourceCategory'),
      };
    });
  
    return array2
  };

  const getCostMap = (object: any) => {
    const mp = new Map();
    Object.keys(object).forEach((k) => {
      mp.set(k, object[k]);
    });
    return mp;
  };

  const getResourceCategory = (resourceId: string) => {
    const { t } = useTranslation();
    switch (resourceId) {
      case 'c46c3a26-39a5-42cc-n7k1-89655304eh6':
        return 'Materials';
      case 'c46c3a26-39a5-42cc-n9wn-89655304eh6':
        return 'Tools';
      case 'c46c3a26-39a5-42cc-m06g-89655304eh6':
        return 'Consumables';
      case 'c46c3a26-39a5-42cc-b07s-89655304eh6':
        return 'Labours';
      case 'cbc3a26-cbc-cbc-cbc-89655304cbc':
        return 'CBC';
      default:
        return 'Service';
        break;
    }
  };

  const renderPsResourceItem = (props: any) => {
    let productMap: any = Object.entries(props);
    //console.log("productsPsData",productMap);
    return productMap.map((item: any, index: any) => {
      return (
        <View key={index + 'ps'} style={{ paddingTop: 3 }}>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col }}><Text style={{
              paddingLeft: 10,
              fontStyle: 'bold',
              fontSize: 11,
            }}> {item[0] ? getResourceCategoryTranslateName(item[0]) : ''}</Text></TableCol>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col2,
            }}><Text>&nbsp;</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col2,
            }}><Text>&nbsp;</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col3,
            }}><Text>&nbsp;</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col4,
            }}><Text>&nbsp;</Text></TableCol2>
          </TableRow>
          <View key={index + 'pss'} style={{ paddingTop: 2 }}>
            {item[1] && renderPsResourceItemRow(item[1])}
          </View>
        </View>
      );
    });
  };

  const getResourceCategoryTranslateName = (resourceName: string) => {
    const { t } = useTranslation();
    switch (resourceName) {
      case 'Materials':
        return t('materials');
      case 'Tools':
        return t('tools');
      case 'Consumables':
        return t('consumables');
      case 'Labours':
        return t('otherLabours');
      case 'CBC':
        return t('cbc');
      case 'Service':
        return t('service');
      default:
        break;
    }
  };
  
  const renderPsResourceItemRow = (props: any) => {
    //console.log("productsPsData",productMap);
    return props.map((item: any, index: any) => {
      let toBeInvoiced = item ? item.spToMou * item.soldQuantity : 0;
      let formatter = new Intl.NumberFormat(window.navigator.language, {
        style: 'currency',
        currency: 'EUR',
      });
      return (
        <View key={index + 'psr'} style={{ paddingTop: 3 }}>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col }}><Text
              style={{ paddingLeft: 20 }}>{item.resourceCategory === 'Service' ? item.resourceCategory : item ? item.cpcTitle : ''}</Text></TableCol>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col2,
            }}><Text>{item ? item.soldQuantity : ''}</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col3,
            }}><Text>{item ? item.mou : ''}</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col4,
            }}><Text>{item ? formatter.format(item.spToMou) : ''}</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: itemTableWidth.col5,
            }}><Text>{item ? formatter.format(toBeInvoiced) : ''}</Text></TableCol2>
          </TableRow>
        </View>
      );
    });
  };

  const getResourcesSubTotal = (resource: any) => {
    let materials = resource && resource.hasOwnProperty('Materials') ? resource.Materials : [];
    let tools = resource && resource.hasOwnProperty('Tools') ? resource.Tools : [];
    let consumables = resource && resource.hasOwnProperty('Consumables') ? resource.Consumables : [];
    let labours = resource && resource.hasOwnProperty('Labours') ? resource.Labours : [];
    let services = resource && resource.hasOwnProperty('Service') ? resource.Service : [];
    let cbc = resource && resource.hasOwnProperty('CBC') ? resource.CBC : [];
    let allResources = _.concat(materials, tools, consumables, labours, services, cbc);
    let subTotal = allResources.reduce((n, { soldQuantity, spToMou }) => n + (soldQuantity * spToMou), 0);
    var formatter = new Intl.NumberFormat(window.navigator.language, {
      style: 'currency',
      currency: 'EUR',
    });
  
    return formatter.format(subTotal.toFixed(2));
  };

  return (
    <Document>
      <Page wrap style={styles.body}>
        <Text style={styles.header} fixed>
          {/*~ Created with react-pdf ~*/}
        </Text>

        <View style={{ width: '100%', flexDirection: 'row' }}>
          <View style={{ width: '50%', fontSize: 12 }}>
            <Text>{env.ORGANISATION_ADDRESS.ADDRESS_LINE_1}</Text>
            <Text>{env.ORGANISATION_ADDRESS.ADDRESS_LINE_2}</Text>
            <Text>{env.ORGANISATION_ADDRESS.ADDRESS_LINE_3}</Text>
            <Text>{env.ORGANISATION_ADDRESS.CONTACT_NO}</Text>
          </View>
          <View style={{ width: '50%' }}>
            <Image
              style={styles.image}
              src={env.IMG_PDF}
            />
          </View>
        </View>

        <Table>
          <TableRow>
            <TableCol style={{ backgroundColor: '#ffffff', border: true }}><Text> {t('dateForwardedinvoicing')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{props.psData.date ? formatDateTimeToLocal(props.psData.date) : ''}&nbsp;</Text></TableColLeft2>
          </TableRow>

          <TableRow>
            <TableCol
              style={{ backgroundColor: '#ffffff', border: true }}><Text> {t('customerOrganization')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{(props?.psData.customerName) ? props?.psData.customerName : ''}&nbsp;</Text></TableColLeft2>
          </TableRow>

          <TableRow>
            <TableCol
              style={{ backgroundColor: '#ffffff', border: true }}><Text>{t('customerContact')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{(props.customerContactPerson) ? props.customerContactPerson : ''}&nbsp;</Text></TableColLeft2>
          </TableRow>

          <TableRow>
            <TableCol style={{
              backgroundColor: '#ffffff',
              border: true,
            }}><Text>{t('customerContactperson')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{(props.customerContactPerson) ? '' : ''}&nbsp;</Text></TableColLeft2>
          </TableRow>

          <TableRow>
            <TableCol
              style={{ backgroundColor: '#ffffff', border: true }}><Text>{t('poCustomernumber')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{props.psData.purchaseOrderNumber}&nbsp;</Text></TableColLeft2>
          </TableRow>

          <TableRow>
            <TableCol style={{ backgroundColor: '#ffffff', border: true }}><Text>{t('projectTitle')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{props.project.title !== null ? props.project.title : ''}&nbsp;</Text></TableColLeft2>
          </TableRow>

          <TableRow>
            <TableCol
              style={{ backgroundColor: '#ffffff', border: true }}><Text> {t('claimStatetype')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{props.psData.projectType}&nbsp;</Text></TableColLeft2>
          </TableRow>


          <TableRow>
            <TableCol
              style={{ backgroundColor: '#ffffff', border: true }}><Text>{t('workPeriodfromUtai')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastCell: true,
            }}><Text>{formatDateTimeToLocal(props.psData.workPeriodFrom)} - {formatDateTimeToLocal(props.psData.workPeriodTo)}&nbsp;</Text></TableColLeft2>
          </TableRow>

          <TableRow>
            <TableCol
              style={{ backgroundColor: '#ffffff', border: true, isLastRow: true }}><Text> {t('comments')}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: true,
              isLastRow: true,
              isLastCell: true,
            }}><Text>{props.psData.projectScope}&nbsp;</Text></TableColLeft2>
          </TableRow>

        </Table>

        <Table borderWidth={0}>
          <View fixed style={{ marginBottom: 2 }}>
            <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
              <TableCol style={{
                backgroundColor: '#ffffff',
                border: false,
                width: itemTableWidth.col,
                isLastCell: true,
              }}><Text>{t('description')} </Text></TableCol>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: false,
                width: itemTableWidth.col2,
                isLastCell: true,
              }}><Text style={{ textAlign: 'center' }}>{t('quantities')}</Text></TableCol2>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: false,
                width: itemTableWidth.col3,
                isLastCell: true,
              }}><Text>{t('psMou')}</Text></TableCol2>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: false,
                width: itemTableWidth.col4,
                isLastCell: true,
              }}><Text>{t('unitPriceEuro')}</Text></TableCol2>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: false,
                width: itemTableWidth.col5,
                isLastCell: true,
              }}><Text>{t('toBeinvoiced')}</Text></TableCol2>
            </TableRow>
            <View key={'headerlhr'}
              style={{ backgroundColor: '#ffffff', borderColor: '#020202', borderTopWidth: 1, paddingTop: 8 }} />
          </View>
          {/*item start*/}
          {renderPsItem(props.psData)}
          {/*item end*/}
          <View key={'grandTotal'} style={{ paddingTop: 12 }}>
            <View key={'grandTotalhr'}
              style={{ backgroundColor: '#ffffff', borderColor: '#020202', borderTopWidth: 1, paddingTop: 8 }} />
            <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
              <TableCol style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col }}><Text style={{
                fontWeight: 'bold',
                fontSize: 10,
                fontFamily: 'Helvetica-Bold',
              }}>{t('grandTotal')}</Text></TableCol>
              <TableCol2 style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col2 }}><Text></Text></TableCol2>
              <TableCol2 style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col3 }}><Text></Text></TableCol2>
              <TableCol2 style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col4 }}><Text></Text></TableCol2>
              <TableCol2 style={{ backgroundColor: '#ffffff', border: false, width: itemTableWidth.col5 }}><Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 10,
                  fontFamily: 'Helvetica-Bold',
                }}>{(props.psData.totalAmount) ? formatter.format(props.psData.totalAmount) : ''}</Text></TableCol2>
            </TableRow>
          </View>
        </Table>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  )
}

export default PDFPS