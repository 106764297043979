import React, { Component } from 'react';
import MeNavBar from '../../shared/uprinceNavigation/meNavigation/component';
import { getContractingUnit, getProject } from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import ThAutomationMainLayout from './thAutomationMainLayout';

interface Props {
    currentUser: any
}

interface State {
    isContractUnit: boolean;
    isProject: boolean;
}
export class ThAutomation extends Component<Props, State> {
    render() {
        return (getProject() ?
                <UprinceNavigation
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/th-automation`}
                >
                    <ThAutomationMainLayout/>
                </UprinceNavigation> :
                <MeNavBar
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={'/me/th-automation'}
                >
                    <ThAutomationMainLayout/>
                </MeNavBar>
            )
    }
}

export default (ThAutomation);
