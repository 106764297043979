import { mergeStyles, Spinner, SpinnerSize, Text } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFlatDataFromTree,
  getNodeAtPath,
  walk,
} from '@nosferatu500/react-sortable-tree';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import { ExpandProvider } from '../../../hooks/expandContext';
// import { savePMOLStateAttr } from '../../../reducers/pmolReducer';
import {
  saveVPStateAttr,
  readVPPBSTaxonomyData,
  updateProductTaxonomyParent,
  formatVProductTaxonomyBarDataWithoutRefresh,
  updateProductTaxonomyTreeIndex,
  updateProjectItemsDate,
} from '../../../reducers/visualPlanReducer';
import { Gantt, Task, ViewMode } from '../../../shared/ganttv2';
import { ChartType } from '../../../shared/ganttv2/types/public-types';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { initTasks } from './helper';
import { ViewSwitcher } from './view-switcher';
import { getBuGroups } from '../../../types/myDayPlanning';
// import { getBuDropdownData } from '../../../reducers/projectDayPlanningReducer';
import { store } from '../../../../index';
// import { saveStateAttr } from '../../../reducers/uprinceReducer';
import { getById } from '../../../reducers/projectBreakdownReducer';

export const PBS_STATE = {
  PENDDING_DEVELOPMENT: 'd60aad0b-2e84-482b-ad25-618d80d49477',
  IN_DEVELOPMENT: '94282458-0b40-40a3-b0f9-c2e40344c8f1',
  IN_REVIEW: '7143ff01-d173-4a20-8c17-cacdfecdb84c',
  APPROVED: '7bcb4e8d-8e8c-487d-8170-6b91c89fc3da',
  HAND_OVER: '4010e768-3e06-4702-b337-ee367a82addb',
};

const spinnerStyle = mergeStyles({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  zIndex: 9999999,
  background: '#00000080',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow:
    'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
});

const customSpinnerStyles = {
  circle: {
    width: '60px', // Customize width
    height: '60px', // Customize height
  },
  label: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
  },
};

export const ViewModeContextLTP = React.createContext({
  onViewModeChange: (viewMode: ViewMode) => {},
  onViewListChange: (isCheck: boolean) => {},
  isCheck: true,
  onShowGanttChartChangeLTP: (showGanttChart: boolean) => {},
  showGanttChartLTP: true,
});
// Init
const ProductTreeListPaneComponent = (props: {
  showAdditionalFiltersForLTP?: boolean;
}) => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  const [showGanttChart, setShowGanttChart] = React.useState(true);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [tree, setTree] = React.useState<any>([]);
  const isTreeIndexUpdate = useRef(false);
  // const [searchText, setSearchText] = React.useState<any>();
  // const [refreshWithOutLoading, setRefreshWithOutLoading] =
  //   React.useState(false);
  //const [ searchString, setSearchString ]: any = React.useState('');
  // const [searchFocusIndex, setSearchFocusIndex]: any = React.useState(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDirty = useRef(false);
  const [isNoDataShow, setIsNoDataShow] = React.useState(false);

  // const reduxBarTasks: any[] = useSelector(
  //   (state: any) => state.vp.vpProducTaxonomyBarList
  // );

  let listTasks: any[] = useSelector(
    (state: any) => state.vp.vpProducTaxonomyList
  );

  const interactBarTasks: any[] = useSelector(
    (state: any) => state.vp.vpProducTaxonomyInteractBarList
  );

  // const projectLevelReloadRequired: any[] = useSelector(
  //   (state: any) => state.vp.projectLevelReloadRequired
  // );

  // const producttaxonomySearchText: any = useSelector(
  //   (state: any) => state.vp.producttaxonomySearchText
  // );

  // const pbsTaxonomyTreeHasChanged: any = useSelector(
  //   (state: any) => state.vp.pbsTaxonomyTreeHasChanged
  // );

  // const pbsTaxonomyReloaded: any = useSelector(
  //   (state: any) => state.vp.pbsTaxonomyReloaded
  // );

  const isDataLoaded: boolean = useSelector(
    (state: any) => state.vp.prodTaxanomyDataLoaded
  );

  // const isDocumentPaneShow: boolean = useSelector(
  //   (state: any) => state.vp.showvpProjectDocumentPane
  // );

  const selectedBuInCuProjectPlanning: any = useSelector(
    (state: any) => state.vp.selectedBuInCuProjectPlanning
  );

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  const vpProjectSelectedShortcutpaneItems: any = useSelector(
    (state: any) => state.vp.vpProjectSelectedShortcutpaneItems
  );

  let columnWidth = 60;

  if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.Month) {
    columnWidth = 320;
  } else if (view === ViewMode.QuaterYear) {
    columnWidth = 350;
  }

  // const filterData = {
  //   title: null,
  //   contractingUnit: store.getState().uprince?.contractingUinit,
  //   businessUnit: getBuGroups(),
  // };

  // No data display message show because data mapping takes time
  useEffect(() => {
    if (isDataLoaded) {
      setTimeout(() => {
        setIsNoDataShow(true);
      }, 3000);
    } else {
      setIsNoDataShow(false);
    }
  }, [isDataLoaded]);

  useEffect(() => {
    if (vpProjectSelectedShortcutpaneItems[0] == 5) {
      dispatch(
        readVPPBSTaxonomyData({
          fromDate: null,
          toDate: null,
        })
      );
    }
  }, [selectedBuInCuProjectPlanning]);

  useEffect(() => {
    setDataLoaded(isDataLoaded);
  }, [isDataLoaded]);

  const debounced = useDebouncedCallback(
    (value: any) => {
      dispatch(updateProductTaxonomyParent(value));
      dispatch(saveVPStateAttr('selectedProject', value?.project));
      dispatch(getById(value?.value?.childPbsSequenceId));
    },
    // delay in ms
    1000
  );

  const debouncedTreeIndex = useDebouncedCallback((treeData: any) => {
    if (isTreeIndexUpdate.current) {
      updateTreeIndexList(treeData);
      isTreeIndexUpdate.current = false;
    }
  }, 1000);

  const handleTaskChange = (task: any) => {
    dispatch(saveVPStateAttr('selectedProject', task.project));
    const isProject =
      _.isEmpty(task.pbsSequenceId) &&
      _.isEmpty(task.pmolSequenceId) &&
      _.isEmpty(task.parentId);
    isDirty.current = true;
    setTimeout(() => {
      isDirty.current = false;
    }, 2000);

    const ffData: any[] = getFlatDataFromTree({
      treeData: tree,
      getNodeKey: (node: any) => node.pbsSequenceId,
      ignoreCollapsed: false,
    });

    const finalresult = ffData.map((item: any) => {
      return item?.node;
    });

    let modifiedIndex = finalresult.findIndex((t) => t.id === task.id);
    if (modifiedIndex >= 0) {
      finalresult[modifiedIndex] = {
        ...finalresult[modifiedIndex],
        start: task.start,
        startDate: task.start.toISOString().replace('Z', ''),
        end: task.end,
        endDate: task.end.toISOString().replace('Z', ''),
      };
      dispatch(saveVPStateAttr('vpProducTaxonomyList', [...finalresult]));
    }

    const startDate =
      moment(task.start).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate =
      moment(task.end).subtract(1, 'days').format('YYYY-MM-DD') +
      'T00:00:00.000Z';
    //interactBarTasks.splice(task.index, 1, task);
    const payloadUpdate = {
      type: isProject ? 'Project' : 'PBS',
      sequenceId: isProject ? task.project : task.pbsSequenceId,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(updateProjectItemsDate(payloadUpdate));
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      // setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: Task) => {
    if (!isDirty.current) {
      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
      dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
      dispatch(saveVPStateAttr('slectedPBSForBor', null));
      dispatch(saveVPStateAttr('selectedPBS', task.pbsSequenceId));
    }
  };

  const handleSelect = (_task: Task, _isSelected: boolean) => {};

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  // const stringToArr = (string1: any) => {
  //     if (string1) {
  //         const x = string1.trim().split(',');
  //         x.shift();
  //         return x;
  //     }
  //     return [];
  // };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  // const sumNodeValues = (treeData: any) => {
  //     let indexss = '';
  //     const callback = ({ node }: any) => {
  //         indexss += ',' + node.id;
  //     };
  //     walk({
  //         treeData,
  //         getNodeKey,
  //         callback,
  //         ignoreCollapsed: true
  //     });

  //     return indexss;
  // };

  // const onVisibilityChanged = (data: any) => {
    // const treeTotal1 = sumNodeValues(data);
    // if (stringToArr(treeTotal1).length + 100 <= interactBarTasks.length) {
    //     const newTasks = interactBarTasks.filter((item) =>
    //         stringToArr(treeTotal1).includes(item.id)
    //     );
    //     dispatch(saveVPStateAttr('vpProducTaxonomyList', [...data]))
    //     dispatch(saveVPStateAttr('vpProducTaxonomyInteractBarList', newTasks));
    //     dispatch(saveVPStateAttr('vpProducTaxonomyBarList', [ ...formatVProductTaxonomyBarDataWithoutRefresh(newTasks, true)[0]]))
    // } else {
    //     const newTasks = reduxBarTasks.filter((item) =>
    //         stringToArr(treeTotal1).includes(item.id)
    //     );
    //     dispatch(saveVPStateAttr('vpProducTaxonomyList', [...data]))
    //     dispatch(saveVPStateAttr('vpProducTaxonomyInteractBarList', newTasks));
    //     dispatch(saveVPStateAttr('vpProducTaxonomyBarList', [ ...formatVProductTaxonomyBarDataWithoutRefresh(newTasks, true)[0]]))
    // }
  // };

  const updateTreeIndexList = async (treeData: any[]) => {
    try {
      const res: any = await dispatch(
        updateProductTaxonomyTreeIndex({
          pbsTreeIndex: getTreeIndexList(treeData),
        })
      );
      if (res.payload?.data?.message === 'Ok') {
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
    dispatch(
      updateProductTaxonomyTreeIndex({
        pbsTreeIndex: getTreeIndexList(treeData),
      })
    );
  };

  const getTreeIndexList = (treeData: any[]) => {
    let currentIndex = 0;
    // const updateTreeIndexes = (treeData: any) => {
    //     currentIndex = 0; // Reset index counter
    //     treeData?.forEach(assignTreeIndex);
    //     return treeData;
    // };
    // const assignTreeIndex = (node: any) => {
    //     if (node?.sequenceId?.length > 4) {
    //         // Update only the treeIndex while keeping the other properties intact
    //         node.treeIndex = currentIndex;
    //         currentIndex++;

    //         if (node?.children && node?.children?.length > 0) {
    //         node.children.forEach(assignTreeIndex);
    //         }
    //     }
    // };
    // updateTreeIndexes(treeData); // To Update the treeIndex for order change in same parent node.

    let modifiedArray: any[] = [];
    const callback = (props: any) => {
      if (props?.node?.sequenceId?.length > 4) {
        modifiedArray.push({
          pbsProductId: props.node.sequenceId,
          // treeIndex: treeData[0].isFilter ? props.node.treeIndex : props.treeIndex,
          treeIndex: currentIndex++,
        });
      }
    };

    walk({
      treeData,
      getNodeKey,
      callback,
      ignoreCollapsed: false,
    });
    //dispatch(updateProductTaxonomyTreeIndex({ pbsTreeIndex: modifiedArray }));
    return modifiedArray;
  };

  // const onvisibilityToggled = (data: any) => {
    // let modifiedIndex = listTasks.findIndex(t => t.id === data?.node.id)
    // if(modifiedIndex >= 0 ) {
    //     listTasks[modifiedIndex] = {...data?.node, expanded:!data?.node.expanded}
    //     dispatch(saveVPStateAttr('vpProducTaxonomyList', [...listTasks]))
    //     dispatch(saveVPStateAttr('vpProducTaxonomyBarList', [ ...formatVProductTaxonomyBarData(listTasks)[0] ]))
    //     dispatch(saveVPStateAttr('vpProducTaxonomyInteractBarList', [ ...formatVProductTaxonomyBarData(listTasks)[1] ]))
    // }
  // };

  return (
    <div style={{height: '100%'}}>
      <ViewModeContextLTP.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
          onShowGanttChartChangeLTP: (showGanttChart: boolean) => {
            setShowGanttChart(showGanttChart);
          },
          showGanttChartLTP: showGanttChart,
        }}
      >
        <ExpandProvider>
          <ViewSwitcher
            showAdditionalFiltersForLTP={props.showAdditionalFiltersForLTP}
          />

          {!dataLoaded && <ProgressBar show={true} />}
          {dataLoaded && _.isEmpty(interactBarTasks) && isNoDataShow && (
            <Text style={{ marginLeft: 20, marginTop: 5 }}>{t('noData')}</Text>
          )}
          {dataLoaded && !_.isEmpty(interactBarTasks) && (
            <>
              <Gantt
                tasks={
                  listTasks &&
                  _.isArray(listTasks) &&
                  !_.isEmpty(listTasks) &&
                  dataLoaded &&
                  !_.isEmpty(interactBarTasks) &&
                  typeof interactBarTasks[0].start !== 'string'
                    ? interactBarTasks
                    : tasks
                }
                listTasks={
                  listTasks &&
                  _.isArray(listTasks) &&
                  !_.isEmpty(listTasks) &&
                  dataLoaded
                    ? listTasks
                    : tasks
                }
                searchQuery={''}
                searchMethod={({ node, searchQuery }: any) => {
                  return (
                    searchQuery &&
                    node.title
                      .toLowerCase()
                      .indexOf(searchQuery.toLowerCase()) > -1
                  );
                }}
                selectedScreen={''}
                searchFocusOffset={0}
                searchFinishCallback={(matches) => {}}
                onlyExpandSearchedNodes={true}
                viewMode={view}
                onDateChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDblClick}
                onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                listCellWidth={isChecked ? '155px' : ''}
                columnWidth={columnWidth}
                onVisibilityChanged={(data: any) => {}}
                onVisibilityToggle={(data: any) => {}}
                ganttHeight={560}
                isInitialCollaps={true}
                canDrag={({ node, path, treeIndex }: any) => {
                  if (node?.isHidden) {
                    return false;
                  } else {
                    return true;
                  }
                }}
                canDrop={({
                  node,
                  prevPath,
                  prevParent,
                  prevTreeIndex,
                  nextPath,
                  nextParent,
                  nextTreeIndex,
                  treeData,
                }: any) => {
                  const comNode: any = getNodeAtPath({
                    treeData: tree,
                    path: nextPath,
                    getNodeKey: getNodeKey,
                  });
                  debounced({
                    value: {
                      parentPbsSequenceId: nextParent
                        ? nextParent.pbsSequenceId
                        : null,
                      childPbsSequenceId: node.pbsSequenceId,
                      OldPbsProductId: node.parentId,
                    },
                    project: node?.project,
                  });
                  isTreeIndexUpdate.current = true;
                  if (nextParent) {
                    if (_.inRange(nextParent.id, 1000, 1100)) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    if (!_.inRange(comNode?.node?.id, 1000, 1100)) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }}
                onMoveNode={(
                  treeData: any,
                  nodeOne: any,
                  nextParentNode: any,
                  prevPath: any,
                  prevTreeIndex: any,
                  nextPath: any,
                  nextTreeIndex: any,
                  nodeTwo: any
                ) => {
                  if (nextParentNode) {
                    nodeOne.parentId = nextParentNode.id;
                  } else {
                    nodeOne.parentId = null;
                  }

                  dispatch(saveVPStateAttr('pbsTaxonomyTreeHasChanged', true));
                  dispatch(saveVPStateAttr('expandPath', nextPath));

                  if (treeData[0]?.id !== '0000') {
                    if (isTreeIndexUpdate.current) {
                      setIsLoading(true);
                      debouncedTreeIndex(treeData);
                    }
                    setTree(treeData);
                  }
                }}
                chartType={ChartType.PBSTAXONOMY}
                onTreeDataChange={(treeData: any[]) => {
                  if (treeData[0]?.id !== '0000') {
                    // getTreeIndexList(treeData)
                    setTree(treeData);
                  }
                  dispatch(
                    saveVPStateAttr('vpProducTaxonomyBarList', [
                      ...formatVProductTaxonomyBarDataWithoutRefresh(
                        treeData
                      )[0],
                    ])
                  );
                  dispatch(
                    saveVPStateAttr('vpProducTaxonomyInteractBarList', [
                      ...formatVProductTaxonomyBarDataWithoutRefresh(
                        treeData
                      )[1],
                    ])
                  );
                }}
                showGanttChart={showGanttChart}
                showAdditionalFilters={props.showAdditionalFiltersForLTP}
              />
              {isLoading && (
                <div className={spinnerStyle}>
                  <Spinner
                    size={SpinnerSize.large}
                    styles={customSpinnerStyles}
                    label={t('updatingTreeIndex')}
                  />
                </div>
              )}
            </>
          )}
        </ExpandProvider>
      </ViewModeContextLTP.Provider>
    </div>
  );
};

export default ProductTreeListPaneComponent;
