import React, { Component } from 'react';
import Brand from '../../brand/brand';
// import ProjectBar from '../../projectBar/projectBar';
import ProjectNavBar from '../../navBar/projectNavBar';
import { getProject } from '../../util';
import CUNavBar from '../../navBar/cuNavBar';
import ProjectBarComponent from '../../projectBar/ProjectBarComponent';
import '../../../shared/main.css'

interface Props {
  currentUser: any;
  children: any;
  selectedNavigationIndex: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

class UprinceNavigation extends Component<Props, State> {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand />
        {/* <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/> */}
        <ProjectBarComponent contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        {getProject() ?
          <ProjectNavBar selectedKey={this.props.selectedNavigationIndex} currentUser={this.props.currentUser}/>
          :
          <CUNavBar selectedKey={this.props.selectedNavigationIndex}
                    contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}
                    currentUser={this.props.currentUser}/>
        }
        <div className={'routes_children'}>
          {this.props.children}
        </div>
      </div>
    );
  }
}


export default UprinceNavigation;
