import { getTheme, ITheme, Panel, PanelType } from '@fluentui/react';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Pane } from 'react-split-pane';
import history from '../../history';
import { messageService } from '../../services/messageService';
import { getContractingUnit } from '../../shared/util';
import '../style/style.css';
import CommandBarRight from './commandBar/commandBarRight';
import DocumentPane from './documentPane/container';
import DocumentPaneSection from '../../shared/mainUI/documentPaneSection/documentPaneSection';
import withMainUI from '../../shared/mainUI/withMainUI';
import ListPaneSection from './projectdefinitionListPaneSection';
import ShortCutPane from './shortCutPane/container';
import {
  clearFormData,
  getAllProjectDropdowns,
  getFilterProjectList,
  getProjectDefinition,
  getUserRoles,
  saveProjectStateAttr,
} from '../../reducers/projectReducer';
import {
  formData,
  Project,
  ProjectListItem,
  ProjectListPaneFilter,
  ProjectShortCutPaneItem,
} from '../../types/project';
import { BookMarkItem } from '../../types/template';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import {
  readCIAWSiteCodeById,
  saveCIAWStateAttr,
} from '../../reducers/ciawReducer';

export interface State {
  selectedShortCutItem: ProjectShortCutPaneItem;
  filter: ProjectListPaneFilter;
  formData: Project;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  showDetails: boolean;
  autoSave: boolean;
  isEdit: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  projectId: null | string;
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  showProgressBar: boolean;
  shortCutPaneFilters: ProjectShortCutPaneItem[];
  formData: Project;
  setUid: () => void;
  clearUid: () => void;
  resetIsChange: () => void;
  uid: string | null;
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  loading: boolean;
  handleShowDocumentPane: (collapseBookmarkPane?: boolean) => void;
  reSizer: number;
  showDetails: boolean;
  showPanel: boolean;
  handleScroll: () => void;
  currentActiveSection: string;
  animation: any;
  //readProjectShortCutPaneData:() => void;
  getFilterProjectList: (filter: ProjectListPaneFilter) => void;
  getAllProjectDropdowns: () => void;
  getProjectDefinition: (id: string) => void;
  clearFormData: () => void;
  shortcutPaneData: ProjectShortCutPaneItem[];

  projectList: ProjectListItem[];
  isPROJECTListLoaded: boolean;
  projectDropDowns: any;
  reloadListPane: boolean;
  collapseBookmarkPane: boolean;
  isNewProject: boolean;
  saveProjectStateAttr: (attr: string, value: any) => void;
  getUserRoles: () => void;
  selectedProject: any;
  userRoles: any;
  saveCIAWStateAttr: any;
  readCIAWSiteCodeById: any;
}

class ProjectMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  theme: ITheme = getTheme();
  subscription: any;

  bookmarkList: BookMarkItem[] = [
    {
      id: 1,
      link: 'header',
      label: this.props.t('projectDefinition'),
    },
    {
      id: 2,
      link: 'project-classification',
      label: this.props.t('projectClassification'),
    },
    { id: 3, link: 'project-address', label: this.props.t('address') },
    { id: 4, link: 'project-team', label: this.props.t('team') },
    { id: 5, link: 'project-finance', label: this.props.t('finance') },
    { id: 6, link: 'project-time', label: this.props.t('Date') },
    // {id: 7, link: 'project-kpis', label: this.props.t('kPIS') },
    // {id: 8, link: 'project-cost', label: this.props.t('projectCost') },
    { id: 7, link: 'history', label: this.props.t('history') },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: new ProjectListPaneFilter(),
      selectedShortCutItem: {
        key: null,
        id: null,
        name: null,
        type: null,
        value: null,
      },
      isEdit: false,
      autoSave: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      activeClass: 'grid-righter',
      bookmarkDivClass: 'bookmark-panel parent',
      showDetails: false,
      formData: formData,
      isDisableNewButton: false,
      isDrag: false,
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: screen.width,
      projectId: null,
    };
  }

  componentDidMount() {
    //this.handleUrlChange();
    this.props.getAllProjectDropdowns();
    if (this.props?.userRoles?.length < 0) {
      this.props.getUserRoles();
    }
    //this.props.readProjectShortCutPaneData()
    this.props.getFilterProjectList(new ProjectListPaneFilter());

    if (
      location.pathname.split('/').pop() === 'new' &&
      this.state.formData &&
      this.state.formData.id === null
    ) {
      this.createNew();
    }
    this.setState(
      {
        formData: this.props.formData,
      },
      this.props.handleShowDocumentPane
    );
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }

        if (data.data.isNewPROJECT) {
          this.createNew();
        }

        if (data.data.collapseDocumentPane) {
          this.setState({
            reSizer: this.props.reSizer === 300 ? screen.width / 2 : 300,
            isDrag: !this.state.isDrag,
            collapseBookmarkPane: !this.state.collapseBookmarkPane,
            resetLayout: !this.state.resetLayout,
          });
        }

        if (
          data &&
          data.data &&
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: true });
        } else if (
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          !data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: false });
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.reloadListPane !== prevProps.reloadListPane &&
      this.props.reloadListPane
    ) {
      this.reloadListPaneItem();
    }
    if (
      this.props.match.url !== prevProps.match.url &&
      !this.props.location?.state?.isDefinition
    ) {
      this.handleUrlChange(prevState.collapseBookmarkPane);
    }
    if (
      prevProps.isNewProject !== this.props.isNewProject &&
      this.props.isNewProject
    ) {
      this.createNew();
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
      });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.state.filter != this.props.location.state.projectFilter
    ) {
      if (this.props.location.state.projectFilter) {
        this.setState(
          {
            filter: this.props.location.state.projectFilter,
          },
          this.reloadListPaneItem
        );
      }
    }
    if (this.props.collapseBookmarkPane !== prevProps.collapseBookmarkPane) {
      this.setState({
        collapseBookmarkPane: this.props.collapseBookmarkPane,
      });
    }
  }

  handleUrlChange(collapseBookmarkPane?: boolean) {
    let id = this.props.match.params.featureId
      ? this.props.match.params.featureId
      : null;
    if (id && id !== 'new') {
      this.setState(
        {
          isEdit: !!this.props.match.params.featureId,
          isDisableNewButton: !!id,
        },
        () => {
          this.props.handleShowDocumentPane(collapseBookmarkPane);
        }
      );
      //this.props.getProjectDefinition(id)
    } else {
      this.setState(
        {
          isEdit: false,
          isDisableNewButton: false,
        },
        () => {
          this.props.handleShowDocumentPane();
        }
      );
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.props.saveCIAWStateAttr('isCiawSiteCodeModelOpen', false);
  }

  // 2. clear states in this method and save to state
  // 3. update redirect url
  createNew = (): void => {
    this.props.clearFormData();
    history.push(`/CU/${getContractingUnit()}/new`);
  };

  reloadListPaneItem = () => {
    //
    //this.props.resetIsChange();
    setTimeout(() => {
      this.props.getFilterProjectList(this.state.filter);
    }, 100);
  };

  renderShortCutPane = () => {
    return (
      <ShortCutPane
        projectId={this.state.projectId}
        shortCutPaneFilters={[]}
        filter={this.state.filter}
        handelShortCutPaneFilter={(selectedItem: ProjectShortCutPaneItem) =>
          this.handelShortCutPaneFilter(selectedItem)
        }
        toggleOverlay={this.state.collapseBookmarkPane}
        resetLayout={this.state.resetLayout}
      />
    );
  };

  getDocumentPaneView = () => {
    return (
      <DocumentPaneSection
        collapseBookmarkPane={this.state.collapseBookmarkPane}
        showProgress={this.props.showProgressBar}
        handleScroll={this.props.handleScroll}
        isEdit={this.props.isEdit}
        formData={this.props.selectedProject}
        bookmarkList={this.bookmarkList}
        currentActiveSection={this.props.currentActiveSection}
        renderCommandBar={() => <CommandBarRight />}
      >
        <DocumentPane formData={this.props.formData} />
      </DocumentPaneSection>
    );
  };

  private getListPaneView = () => {
    return (
      <ListPaneSection
        activeClass={this.state.activeClass}
        titlePaneHeader={
          this.state.selectedShortCutItem &&
          this.state.selectedShortCutItem.name
            ? this.state.selectedShortCutItem.name!!
            : 'All'
        }
        showProgress={this.props.showProgressBar}
        renderCommandBar={() => false}
        renderShotcutPane={() => this.renderShortCutPane()}
      >
        <div>{this.getDocumentPaneView()}</div>
        {/* <ListPane
         projectId={this.state.projectId}
         loadMsg={this.props.loadMsg}
         reloadListPaneItem={() => this.reloadListPaneItem()}
         filter={this.state.filter}
         listPaneItems={this.props.projectList}
         isDataLoaded={this.props.isPROJECTListLoaded}
         isChange={this.props.isChange}
         formData={this.state.formData}
         handelFilter={(filter: ProjectListPaneFilter) =>
           this.handleFilter(filter)
         }
         handleSelectedListPaneItem={(
           selectedListItemIds: [] | string[]
         ) => {
           //this.handleSelectedListPaneItem(selectedListItemIds);
         }}
         reSizer={this.state.reSizer}
        /> */}
      </ListPaneSection>
    );
  };

  handleFilter = (filter: ProjectListPaneFilter) => {
    if ((filter.title && filter.title.length >= 3) || filter.title === null) {
      this.setState(
        {
          filter: filter,
        },
        this.reloadListPaneItem
      );
    } else {
      this.setState({
        filter: filter,
      });
    }
  };

  handelShortCutPaneFilter = (selectedItem: ProjectShortCutPaneItem) => {
    this.setState({
      selectedShortCutItem: selectedItem,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className={'ms-Grid'}>
          <Pane className={'SplitPane'}>{this.getListPaneView()}</Pane>
          <Pane className={'SplitPane'}>
            <div
              className={`${this.props.animation(this.props.showDetails)}`}
              style={{
                display: this.props.showDetails ? 'block' : 'none',
                transition: 'all 0.9s linear',
              }}
            ></div>
          </Pane>
        </div>
        <Panel
          isOpen={this.props.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('corporateProductCatalogue')}
          className="custom-detail-panel"
          closeButtonAriaLabel={t('close')}
        >
          {this.getDocumentPaneView()}
        </Panel>

        <ConfirmationDialog
          hidden={true}
          title={t('areyousure')}
          subText={t('youwonTbeabletorevertthis')}
          onClickConfirmDialog={() => {}}
          onClickCancelDialog={() => {}}
          cancelButtonText={t('noIwonT')}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    history.push(`/projects/project/${this.state.projectId}/product/pbs`);
  };

  private hideDocumentPane = () => {
    if (getContractingUnit()) {
      this.props.saveProjectStateAttr('isNewProject', false);
      history.push(`/CU/${getContractingUnit()}`);
    }
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortcutPaneData: state.project?.projectShortCutPaneData,
    isPROJECTListLoaded: state.project.isDataLoaded,
    projectList: state.project.allProjects,
    projectDropDowns: state.project.projectDropDowns,
    reloadListPane: state.project.reloadListPane,
    formData: state.project.formData,
    isEdit: state.project.isEdit,
    showProgressBar: state.project.showProgressBar,
    isNewProject: state.project.isNewProject,
    loadMsg: state.project.loadMsg,
    selectedProject: state.project.selectedProject,
    userRoles: state.project.usrRoles,
  };
};

const mapDispatchToProps = {
  //readProjectShortCutPaneData,
  getFilterProjectList,
  getAllProjectDropdowns,
  getProjectDefinition,
  clearFormData,
  saveProjectStateAttr,
  getUserRoles,
  saveCIAWStateAttr,
  readCIAWSiteCodeById,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(withMainUI(ProjectMainLayout)))
);
