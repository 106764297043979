import React, { useEffect, useState } from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { ProgressStatement } from '../../../types/progressStatement';
import {
  IN_REVIEW_STATUS_ID,
  PENDING_DEVELOPMENT_STATUS_ID,
} from '../../../types/projectMolecule';
import { getProject } from '../../../shared/util';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { mergeStyleSets, Modal } from '@fluentui/react';
import { BlobProvider } from '@react-pdf/renderer';
import PDFPS from './pdf';

const styles = mergeStyleSets({
  modalContainer: {
    width: '80%',
    height: '80%',
  },
  scrollableContent: {
    height: '100%',
    overflow: 'hidden',
  },
});

const CommandBarRight = (props: {
  createNew: (create: boolean) => void;
  saveProductCatalogue: (save: boolean) => void;
  handleRemove: () => void;
  formData: ProgressStatement;
  isLoading: boolean;
  isDisableNewButton: boolean;
}) => {
  const { t } = useTranslation();
  const [state, setstate] = useState<boolean>(false);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const isPsFormDataLoaded: any = useSelector(
    (state: any) => state.ps.isPsFormDataLoaded
  );
  const newClick = () => {
    messageService.sendMessage({
      isNewPs: true,
    });
    props.createNew(true);
  };
  const saveFormData = () => {
    messageService.sendMessage({
      savePsFormData: true,
    });
  };
  const handleRemove = () => {
    props.handleRemove();
  };

  const handlePsApprove = async () => {
    setstate(true);
    if (getProject()) {
      messageService.sendMessage({
        approvePs: true,
      });
      triggerFlaskServiceWhenPsApprove();
    } else {
      messageService.sendMessage({
        approvePsCu: true,
      });
    }
  };

  const triggerFlaskServiceWhenPsApprove = async () => {
    let config: any = {
      method: 'post',
      url: 'http://172.201.98.220:8089/progressstatement',
      headers: {
        'X-Api-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ PS_id: `${props.formData?.progressStatementId}` }),
    };
    await axios
      .request(config)
      .then((res) => {
        console.log('res', res.status);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const printClick = () => {
    // if (isPsFormDataLoaded) {
    //   setTimeout(function() {
    //     messageService.sendMessage({
    //       pdfGenerate: true,
    //     });
    //   }, 700);
    // }
    setShowPdf(true);
  };

  useEffect(() => {
    setstate(
      getProject()
        ? props.formData.projectStatusId !== PENDING_DEVELOPMENT_STATUS_ID
        : props.formData.projectStatusId !== IN_REVIEW_STATUS_ID
    );
  }, [props.formData]);

  const handlePriceList = () => {
    messageService.sendMessage({
      openPriceList: true,
    });
  };

  const getItems = () => {
    return [
      {
        key: 'new',
        text: t('new'),
        iconProps: { iconName: 'Add' },
        onClick: () => {
          newClick();
        },
        disabled: props.isDisableNewButton,
      },
      {
        key: 'save',
        text: t('save'),
        iconProps: { iconName: 'Save' },
        onClick: () => {
          saveFormData();
        },
        disabled: !getProject() ? true : props.isLoading,
      },

      {
        key: 'priceList',
        onClick: () => {
          handlePriceList();
        },
        name: t('priceList'),
        iconProps: { iconName: 'Money' },
      },
      {
        key: 'Remove',
        onClick: () => {
          handleRemove();
        },
        name: t('remove'),
        iconProps: { iconName: 'Delete' },
        disabled: true,
      },
      {
        key: 'print',
        text: t('print'),
        disabled: !(isPsFormDataLoaded && props.formData.progressStatementId),
        iconProps: { iconName: 'Print' },
        onClick: () => printClick(),
      },

      {
        key: 'approve',
        text: t('approve'),
        iconProps: { iconName: 'ActivateOrders' },
        // disabled: (getProject()?props.formData.projectStatusId !== PENDING_DEVELOPMENT_STATUS_ID:props.formData.projectStatusId !== IN_REVIEW_STATUS_ID),
        disabled: state,
        onClick: () => handlePsApprove(),
      },
    ];
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />

      <Modal
        isBlocking={false}
        isOpen={showPdf}
        onDismiss={() => setShowPdf(false)}
        containerClassName={styles.modalContainer}
        scrollableContentClassName={styles.scrollableContent}
      >
        <BlobProvider
          document={
            <PDFPS
              psData={props.formData}
              customerContactPerson={props.formData.customerContactPerson}
              project={{ title: props.formData?.title }}
            />
          }
        >
          {({ url }) =>
            url ? (
              <iframe
                src={url}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="PDF Preview"
              />
            ) : (
              'Loading document...'
            )
          }
        </BlobProvider>
      </Modal>
    </div>
  );
};

export default CommandBarRight;
