import React, { Component } from 'react';
import Brand from '../../shared/brand/brand';
// import ProjectBar from '../../shared/projectBar/projectBar';
import TimeClockMainLayout from '../timeClock/timeClockMainLayout';
import CUNavBar from '../../shared/navBar/cuNavBar';
import { getContractingUnit } from '../../shared/util';
import ProjectBarComponent from '../../shared/projectBar/ProjectBarComponent';

export interface State {
  redirect: boolean
}

export default class CuTimeClock extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = { redirect: false };
  }

  render() {
    return (
      <div>
        <Brand/>
        {/* <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/> */}
        <ProjectBarComponent contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        <CUNavBar selectedKey={`/CU/${getContractingUnit()}/time_clock`} currentUser={this.props.currentUser}/>
        <TimeClockMainLayout/>
      </div>
    );
  }
}
