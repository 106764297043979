import _ from 'lodash';
import { DirectionalHint, IColumn, Link, TextField, TooltipHost } from '@fluentui/react';
import React, { useEffect, useMemo, useState } from 'react';
import i18n from '../../../i18n';
import { Icon } from '@fluentui/react/lib/Icon';
import { messageService } from '../../services/messageService';
import { AddressBookItem } from '../../types/addressBookItem';
import { ProjectCost } from '../../types/progressStatement';
import { ReadRisk } from '../../types/projectBreakdownStructure';
import { formatwithoutTime, getContractingUnit, getProject } from '../util';
import history from './../../history';
import { Text } from '@fluentui/react/lib/Text';
import { iconColors } from '../../../theme';
import DefaultNumberFormat from '../fluentFinalForm/defaultNumberFormat';
import moment from 'moment';

i18n;

const TableColumn = (item: any, index?: number, column?: IColumn) => {
  if (column && column.fieldName && column.fieldName === 'title') {
    return <Link onClick={(e) => {
      e.preventDefault();
      messageService.sendMessage({
        titleClick: true,
      });
      if (item.sequenceCode) {
        history.push(`/CU/${getContractingUnit()}/project/${item.sequenceCode}/project-planning`);
      }

    }}>{item[column.fieldName]}</Link>;
  } else if (column && column.fieldName) {
    const fieldContent = item[column.fieldName];
    return <span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{fieldContent}</span>;
  } else {
    return <span style={{ paddingLeft: 9 }}>-</span>;
  }
};

export const GridTableColumn = (item: any, index?: number, column?: IColumn, clickable?: boolean, type?: string) => {
  if (column && column.fieldName && column.fieldName === 'cabPerson') {
    return <TooltipHost
      content={item[column.fieldName]}
      // This is the important part!
      id={item[column.fieldName]}
      calloutProps={{ gapSpace: 0 }}
      directionalHint={DirectionalHint.rightCenter}
    >{item[column.fieldName]}</TooltipHost>;
  } else if (column && column.fieldName && (column.fieldName === 'cpcTitle' || column.fieldName === 'quantity') && clickable) {
    return <Link onClick={(e) => {
      e.preventDefault();
      messageService.sendMessage({
        pbsResourceEditItem: {
          item: item, feild: column.fieldName,
          type: type,
        },
      });

    }}>{item[column.fieldName]}</Link>;
  } else if (column && column.fieldName) {
    const fieldContent = item[column.fieldName];
    return <span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{fieldContent}</span>;
  } else {
    return <span style={{ paddingLeft: 9 }}>-</span>;
  }
};

export const EmpGridTableColumn = (item: any, index?: number, column?: IColumn) => {
  if (column && column.fieldName && column.fieldName === 'cabPerson') {
    return <Link onClick={(e) => {
      e.preventDefault();
      messageService.sendMessage({
        openEmpTimeClock: item?.cabPerson,
      });
    }}>{item[column.fieldName]}</Link>;

  } else if (column && column.fieldName && column.fieldName === 'startDateTime') {
    return <span>{item[column.fieldName] ? moment.utc(item[column.fieldName]).local().format('DD/MM HH:mm (ddd)') : "--:--"}</span>;

  } else if (column && column.fieldName && column.fieldName === 'endDateTime') {
    if (item[column.fieldName]) {
      return <span>{item[column.fieldName] ? moment.utc(item[column.fieldName]).format('DD/MM HH:mm (ddd)') : "--:--"}</span>;
    } else {
      return <Link onClick={(e) => {
        e.preventDefault();
        messageService.sendMessage({
          openEmpAddEndTime: item?.cabPerson,
        });
      }}>{item[column.fieldName] ? moment.utc(item[column.fieldName]).local().format('DD/MM HH:mm (ddd)') : i18n.t('addEndTime')}</Link>
    }

  } else if (column && column.fieldName) {
    const fieldContent = item[column.fieldName];
    return <span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{fieldContent}</span>;
  } else {
    return <span style={{ paddingLeft: 9 }}>-</span>;
  }
};

export const POResourceColumn = (resourceType: string, item: any, index?: number, column?: IColumn) => {
  var formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: 'EUR',
  });
  if (column && column.fieldName && (column.fieldName === 'pTitle' || column.fieldName === 'cTitle')) {
    return <Link onClick={(e) => {
      e.preventDefault();
      messageService.sendMessage({
        resourceItem: item,
        resourceType: resourceType,
      });

    }}>{item[column.fieldName]}</Link>;
  } else if (column && column.fieldName && (column.fieldName === 'pStartDate' || column.fieldName === 'cStartDate')) {
    const fieldContent = item[column.fieldName];
    let date = null;
    if (fieldContent) {
      date = formatwithoutTime(fieldContent);
    } else {
      date = '-';
    }
    return <span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{date}</span>;

  } else if (column && column.fieldName && (column.fieldName === 'pStopDate' || column.fieldName === 'cStopDate')) {
    const fieldContent = item[column.fieldName];
    let date = null;
    if (fieldContent) {
      date = formatwithoutTime(fieldContent);
    } else {
      date = '-';
    }
    return <span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{date}</span>;
  } else if (column && column.fieldName && (column.fieldName === 'pComments' || column.fieldName === 'cComments')) {
    let fieldContent = item[column.fieldName];
    if (column.fieldName === 'cComments' && !getProject()) {
      fieldContent = item['pComments'];
      if (!fieldContent) {
        fieldContent = item['cComments'];
      }
    }
    return fieldContent ? <div className={'mcomments'}><Icon
      iconName="Comment"
      style={{ color: iconColors.iconActiveColor }}
    /></div> : <div className={'mcomments'}><span style={{ paddingLeft: 9 }}>-</span></div>;
  } else if (column && column.fieldName && (column.fieldName === 'pDocuments' || column.fieldName === 'cDocuments')) {
    const fieldContent = item[column.fieldName];
    return fieldContent && fieldContent.length > 0 ? <Icon
      iconName="WordDocument"
      style={{ color: iconColors.iconActiveColor }}
    /> : <span style={{ paddingLeft: 9 }}>-</span>;
    ;
  } else if (column && column.fieldName && (column.fieldName === 'pMou' || column.fieldName === 'cMou')) {
    const fieldContent = item[column.fieldName];
    return <div className={'mmou'}><span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{fieldContent}</span></div>;
  } else if (column && column.fieldName && (column.fieldName === 'pQuantity' || column.fieldName === 'pQuantity')) {
    const fieldContent = item[column.fieldName];
    return <div style={{ textAlign: 'right' }} className={'pQuantity'}><span
      style={{ paddingLeft: 9, textAlign: 'right' }} onClick={() => {

      }}>{fieldContent}</span></div>;
  } else if (column && column.fieldName && (column.fieldName === 'cQuantity' || column.fieldName === 'cQuantity')) {
    const fieldContent = item[column.fieldName];
    return <div style={{ textAlign: 'right' }} className={'cQuantity'}><span
      style={{ paddingLeft: 9, textAlign: 'right' }} onClick={() => {

      }}>{fieldContent}</span></div>;
  } else if (column && column.fieldName && (column.fieldName === 'pUnitPrice' || column.fieldName === 'cUnitPrice')) {
    const fieldContent = item[column.fieldName];
    return fieldContent ?
      <div style={{ textAlign: 'right' }}><span style={{ paddingLeft: 9, textAlign: 'right' }} onClick={() => {

      }}>{formatter.format(fieldContent)}</span></div> :
      <div style={{ textAlign: 'right' }}><span style={{ paddingLeft: 9, textAlign: 'right' }}>-</span></div>;
  } else if (column && column.fieldName && (column.fieldName === 'pTotalPrice' || column.fieldName === 'cTotalPrice')) {
    const fieldContent = item[column.fieldName];
    return fieldContent ? <div style={{ textAlign: 'right' }}><span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{formatter.format(fieldContent)}</span></div> :
      <div style={{ textAlign: 'right' }}><span style={{ paddingLeft: 9, textAlign: 'right' }}>-</span></div>;
  } else if (column && column.fieldName) {
    const fieldContent = item[column.fieldName];
    return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
  } else {
    return <span style={{ paddingLeft: 9 }}>-</span>;
  }
};

//   export const POListColumn = (item: any, index?: number, column?: IColumn) => {
//     if (column && column.fieldName) {
//         const fieldContent = item[column.fieldName];
//         return fieldContent ?<span style={{ paddingLeft: 9 }} onClick={()=> {

//         }}>{fieldContent}</span>: <span style={{ paddingLeft: 9 }}>-</span>;
//       } else {
//         return <span style={{ paddingLeft: 9 }}>-</span>;
//       }
//   }


export const POProductColumn = (item: any, index?: number, column?: IColumn) => {
  var formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: 'EUR',
  });
  if (column && column.fieldName && column.fieldName === 'title') {
    return <Link onClick={(e) => {
      e.preventDefault();
      messageService.sendMessage({
        externalProduct: item,
      });

    }}>{item[column.fieldName]}</Link>;
  } else if (column && column.fieldName && (column.fieldName === 'pComment' || column.fieldName === 'cComment')) {
    let fieldContent = item[column.fieldName];
    if (column.fieldName === 'cComment' && !getProject()) {
      fieldContent = item['pComment'];
      if (!fieldContent) {
        fieldContent = item['cComment'];
      }
    }
    return fieldContent ? <div className={'mcomments'}><Icon
      iconName="Comment"
      style={{ color: iconColors.iconActiveColor }}
    /></div> : <div className={'mcomments'}><span style={{ paddingLeft: 9 }}>-</span></div>;
  } else if (column && column.fieldName && (column.fieldName === 'pDocuments' || column.fieldName === 'cDocuments')) {
    const fieldContent = item[column.fieldName];
    return fieldContent && fieldContent.length > 0 ? <Icon
      style={{ color: iconColors.iconActiveColor }}
      iconName="WordDocument"
    /> : <span style={{ paddingLeft: 9 }}>-</span>;
    ;
  } else if (column && column.fieldName && (column.fieldName === 'pTotalPrice' || column.fieldName === 'cTotalPrice')) {
    const fieldContent = item[column.fieldName];
    return fieldContent ? <div style={{ textAlign: 'right' }}><span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{formatter.format(fieldContent)}</span></div> :
      <div style={{ textAlign: 'right' }}><span style={{ paddingLeft: 9, textAlign: 'right' }}>-</span></div>;
  } else if (column && column.fieldName) {
    const fieldContent = item[column.fieldName];
    return fieldContent ? <span style={{ paddingLeft: 9 }} onClick={() => {

    }}>{fieldContent}</span> : <span style={{ paddingLeft: 9 }}>-</span>;
  } else {
    return <span style={{ paddingLeft: 9 }}>-</span>;
  }
};

export const CABTableColumn = (
  item: AddressBookItem,
  index?: number,
  column?: IColumn,
) => {
  if (column && column.fieldName) {
    // eslint-disable-next-line no-use-before-define
    const fieldContent = getCABFeildValueByName(item, column?.fieldName);
    return <span style={{ paddingLeft: 9 }}>{fieldContent}</span>;
  }
  return <span style={{ paddingLeft: 9 }}>-</span>;
};

export const RiskTableColumn = (
  item: ReadRisk,
  index?: number,
  column?: IColumn,
) => {
  if (column && column.fieldName) {
    // eslint-disable-next-line no-use-before-define
    const fieldContent = getRiskFeildValueByName(item, column?.fieldName);
    return <span style={{ paddingLeft: 9 }}>{fieldContent}</span>;
  }
  return <span style={{ paddingLeft: 9 }}>-</span>;
};

const getRiskFeildValueByName = (item: ReadRisk, feildName: string) => {
  let fieldContent = null;
  switch (feildName) {
    case 'sequenceCode':
      fieldContent = item ? item.sequenceCode : '-';
      return fieldContent;
    case 'title':
      fieldContent = item && item.title ? item.title : '-';
      return fieldContent;
    case 'owner':
      fieldContent = item && item.person ? item.person.fullName : '-';
      return fieldContent;
    case 'type':
      fieldContent = item && item.riskType ? item.riskType.text : '-';
      return fieldContent;
    case 'status':
      fieldContent = item && item.riskStatus ? item.riskStatus.text : '-';
      return fieldContent;
    default:
      return '-';
  }
};

const getCABFeildValueByName = (item: AddressBookItem, feildName: string) => {
  let fieldContent = null;
  switch (feildName) {
    case 'fullName':
      fieldContent =
        item && item.person && item.person.fullName
          ? item.person.fullName
          : '-';
      return fieldContent;
    case 'jobRole':
      fieldContent =
        item && item.personCompany && item.personCompany.jobTitle
          ? item.personCompany.jobTitle
          : '-';
      return fieldContent;
    case 'organisation':
      fieldContent =
        item && item.company && item.company.name ? item.company.name : '-';
      return fieldContent;
    case 'email':
      fieldContent =
        item && item.personCompany && item.personCompany.email
          ? item.personCompany.email
          : '-';
      return fieldContent;
    case 'mobile':
      fieldContent =
        item && item.personCompany && item.personCompany.mobileNumber
          ? item.personCompany.mobileNumber
          : '-';
      return fieldContent;
    case 'status':
      fieldContent = item && item.isSaved ? i18n.t('active') : i18n.t('draft');
      return fieldContent;
    default:
      return '-';
  }
};

const getResourcesSubTotal = (resource: any) => {
  let materials = resource && resource.hasOwnProperty('Materials') ? resource.Materials : [];
  let tools = resource && resource.hasOwnProperty('Tools') ? resource.Tools : [];
  let consumables = resource && resource.hasOwnProperty('Consumables') ? resource.Consumables : [];
  let labours = resource && resource.hasOwnProperty('Labours') ? resource.Labours : [];
  let service = resource && resource.hasOwnProperty('Service') ? resource.Service : [];
  let cbc = resource && resource.hasOwnProperty('CBC') ? resource.CBC : [];
  let allResources = _.concat(materials, tools, consumables, labours, service, cbc);
  let subTotal = allResources.reduce((n, { soldQuantity, spToMou }) => n + (soldQuantity * spToMou), 0);
  var formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(subTotal.toFixed(2));
};

const getMaterialsSubTotal = (resource: any) => {
  let materials = resource && resource.hasOwnProperty('Materials') ? resource.Materials : [];
  let subTotal = materials.reduce((n: number, { soldQuantity, spToMou }: any) => n + (soldQuantity * spToMou), 0);
  return subTotal.toFixed(2);
};

const getToolsSubTotal = (resource: any) => {
  let tools = resource && resource.hasOwnProperty('Tools') ? resource.Tools : [];
  let subTotal = tools.reduce((n: number, { soldQuantity, spToMou }: any) => n + (soldQuantity * spToMou), 0);
  return subTotal.toFixed(2);
};

const getConsumablsSubTotal = (resource: any) => {
  let consumables = resource && resource.hasOwnProperty('Consumables') ? resource.Consumables : [];
  let subTotal = consumables.reduce((n: number, { soldQuantity, spToMou }: any) => n + (soldQuantity * spToMou), 0);
  return subTotal.toFixed(2);

};

const getLaboursSubTotal = (resource: any) => {
  let labours = resource && resource.hasOwnProperty('Labours') ? resource.Labours : [];
  let subTotal = labours.reduce((n: number, { soldQuantity, spToMou }: any) => n + (soldQuantity * spToMou), 0);
  return subTotal.toFixed(2);
};

const getServiceSubTotal = (resource: any) => {
  let service = resource && resource.hasOwnProperty('Service') ? resource.Service : [];
  let subTotal = service.reduce((n: number, { soldQuantity, spToMou }: any) => n + (soldQuantity * spToMou), 0);
  return subTotal.toFixed(2);
};
const getCbcSubTotal = (resource: any) => {
  let cbc = resource && resource.hasOwnProperty('CBC') ? resource.CBC : [];
  let subTotal = cbc.reduce((n: number, { soldQuantity, spToMou }: any) => n + (soldQuantity * spToMou), 0);
  return subTotal.toFixed(2);
};

const getUniqueValues = (item: any) => {
  const uniqueValues = new Map();
  if (item != null && item != undefined) {
    item.forEach((e: any) => {
      const key = `${e.productTitle}_${e.cpcResourceType}_${e.cpcResourceNumber}`;
      if (uniqueValues.has(key)) {
        uniqueValues.get(key).soldQuantity += e.soldQuantity;
        uniqueValues.get(key).consumedQuantity += e.consumedQuantity;
        uniqueValues.get(key).comments = "cbccomments"
        // uniqueValues.get(key).costToMou = parseFloat(uniqueValues.get(key).costToMou) + parseFloat(e.costToMou);
        uniqueValues.get(key).spToMou += e.spToMou;
        uniqueValues.get(key).totalCost = parseFloat(uniqueValues.get(key).totalCost) + parseFloat(e.totalCost);
      } else {
        uniqueValues.set(key, { ...e });
      }
    });
  }
  // console.log(Array.from(uniqueValues.values()));
  return Array.from(uniqueValues.values());
}
const listItemStyle = {
  marginTop: 3,
};
const listItemQStyle = {
  marginTop: 3,
  listStyleType: 'none',
};
const listItemPStyle = {
  marginTop: 3,
  listStyleType: 'none',
  textAlign: 'right' as const,
};
const listItemSPStyle = {
  marginTop: 0,
  listStyleType: 'none',
  textAlign: 'right' as const,
};
const listItemEditStyle = {
  marginTop: 10,
  listStyleType: 'none',
};
const listItemPEditStyle = {
  marginTop: 10,
  listStyleType: 'none',
  textAlign: 'right' as const,
};
const resourceListStyle = {
  marginTop: 50,
  listStyleType: 'none',
};
const listItemTotalSalesStyle = {
  marginLeft: 20,

};
const editTextStyle = {
  paddingTop: 0,

};
const ulStyle = {
  paddingLeft: 0,
  marginTop: 0,
};
const borderStyle = { borderBottom: `0.25px solid #e5e5e5` };
const titleBorderStyle = { borderBottom: `0.25px solid #e5e5e5`, borderTop: `0.25px solid #e5e5e5` };
export const CostTableColumn = (
  item: any,
  index?: number,
  column?: IColumn,
  onChangeConsumedQuantity?: (index: number, rindex: number, type: number, value: any, resourceValue?: any) => void,
  onChangeMOUCost?: (index: number, rindex: number, type: number, value: any, resourceValue?: any) => void,
  onChangeTravelCost?: (index: number, rindex: number, type: number, value: any) => void,
  isEditable?: boolean,
  onChangeComments?: (index: number, rindex: number, value: any, resourceValue?: any) => void
) => {

  const consumableItems = useMemo(() => getUniqueValues(item.resource.Consumables), [item.resource.Consumables]);
  const cbcItems = useMemo(() => getUniqueValues(item.resource.CBC), [item.resource.CBC]);
  const materialItems = useMemo(() => getUniqueValues(item.resource.Materials), [item.resource.Materials]);
  const toolItems = useMemo(() => getUniqueValues(item.resource.Tools), [item.resource.Tools]);
  const labourItems = useMemo(() => getUniqueValues(item.resource.Labours), [item.resource.Labours]);
  const serviceItems = useMemo(() => getUniqueValues(item.resource.Service), [item.resource.Service]);


  var formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  //  formatter.format(subTotal.toFixed(2));
  if (column && column.fieldName && column.fieldName === 'title') {
    return (
      <div>
        <span style={{ fontWeight: 900, marginBottom: 20 }}>{item.product}</span>
        {item.resource &&
          item.resource.hasOwnProperty('CBC') &&
          item.resource?.CBC.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly value={`- ${i18n.t('cbc')}`} style={{ fontWeight: 600 }} />
              </div>
              <ul style={ulStyle}>
                {cbcItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.id} style={listItemStyle}><TextField borderless readOnly value={c.cpcTitle} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <div>
              {/* <span style={{fontWeight:600}}>{`- ${i18n.t('materials')}`}</span> */}
              <div style={titleBorderStyle}>
                <TextField borderless readOnly value={`- ${i18n.t('materials')}`} style={{ fontWeight: 600 }} />
              </div>
              <ul style={ulStyle}>
                {materialItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.id} style={listItemStyle}><TextField borderless readOnly value={c.cpcTitle} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <div>

              <div style={titleBorderStyle}>
                <TextField borderless readOnly value={`- ${i18n.t('tools')}`} style={{ fontWeight: 600 }} />
              </div>
              <ul style={ulStyle}>
                {toolItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.id} style={listItemStyle}><TextField borderless readOnly value={c.cpcTitle} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <div>

              <div style={titleBorderStyle}>
                <TextField borderless readOnly value={`- ${i18n.t('consumables')}`} style={{ fontWeight: 600 }} />
              </div>
              <ul style={ulStyle}>
                {consumableItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.cpcTitle} style={listItemStyle}><TextField borderless readOnly value={c.cpcTitle} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly value={`- ${i18n.t('otherLabours')}`} style={{ fontWeight: 600 }} />
              </div>
              <ul style={ulStyle}>
                {labourItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.id} style={listItemStyle}><TextField borderless readOnly value={c.cpcTitle} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Service') &&
          item.resource.Service.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly value={`- ${i18n.t('service')}`} style={{ fontWeight: 600 }} />
              </div>
              <ul style={ulStyle}>
                {serviceItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.id} style={listItemStyle}><TextField borderless readOnly value={i18n.t('service')} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}


        {/* <span style={{fontWeight:900,marginBottom:20}}>{'Total'}</span> */}
      </div>
    );
  } else if (column && column.fieldName && column.fieldName === 'cquantity') {
    return (
      <div className={'cquantity'}>
        <span>&nbsp;</span>
        {item.resource &&
          item.resource.hasOwnProperty('CBC') &&
          item.resource?.CBC.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {cbcItems?.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}>
                      {/* <TextField style={{ marginRight: 0, textAlign: 'right' }}  borderless readOnly value={"0.00"}/> */}
                      <TextField style={{ marginRight: 0, textAlign: 'right' }}
                        borderless readOnly value={l.consumedQuantity} />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {materialItems.map((m: ProjectCost, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={m.id} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(m.consumedQuantity)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {toolItems.map((t: any) => (
                  <div style={borderStyle}>
                    <li key={t.id} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(t.consumedQuantity)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {consumableItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.cpcTitle} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(c.consumedQuantity)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {labourItems.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(l.consumedQuantity)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Service') &&
          item.resource.Service.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {serviceItems.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(l.consumedQuantity)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

      </div>
    );

  } else if (column && column.fieldName && column.fieldName === 'costPerUnit') {
    return (
      <div>
        <span>&nbsp;</span>
        {item.resource &&
          item.resource.hasOwnProperty('CBC') &&
          item.resource?.CBC.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {cbcItems?.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}>
                      <TextField style={{ marginRight: 0, textAlign: 'right' }} borderless readOnly value={formatter.format(l.costToMou) === "0.00" ? "-" : formatter.format(l.costToMou)} />
                      {/*<TextField style={{ marginRight: 0, textAlign: 'right' }}*/}
                      {/*                                               borderless readOnly value={l.costToMou}/>*/}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {materialItems.map((m: any, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={m.id} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(m.costToMou) === "0.00" ? "-" : formatter.format(m.costToMou)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}


        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {toolItems.map((t: any) => (
                  <div style={borderStyle}>
                    <li key={t.id} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(t.costToMou) === "0.00" ? "-" : formatter.format(t.costToMou)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {consumableItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.cpcTitle} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(c.costToMou) === "0.00" ? "-" : formatter.format(c.costToMou)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {labourItems.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(l.costToMou) === "0.00" ? "-" : formatter.format(l.costToMou)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Service') &&
          item.resource.Service.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {serviceItems.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><TextField style={{ marginRight: 0, textAlign: 'right' }}
                      borderless readOnly value={formatter.format(l.costToMou) === "0.00" ? "-" : formatter.format(l.costToMou)} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}


      </div>


    );
  } else if (column && column.fieldName && column.fieldName === 'totalCost1') {
    return (
      <div className={'totalCost1'}>
        <span>&nbsp;</span>
        {item.resource &&
          item.resource.hasOwnProperty('CBC') &&
          item.resource?.CBC.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {cbcItems?.map((l: ProjectCost) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}>
                      <TextField borderless style={{ textAlign: 'right' }} readOnly value={"0.00"} />
                      {/*<TextField borderless style={{ textAlign: 'right' }} readOnly*/}
                      {/*           value={l.totalCost ? parseFloat((l.totalCost).toString()).toFixed(2) : parseFloat('0').toFixed(2)}/>*/}

                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {materialItems.map((m: ProjectCost, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={m.id} style={listItemQStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={m.totalCost ? formatter.format(m.totalCost) : '0.00'}
                    // value={m.totalCost ? parseFloat((m.totalCost).toString()).toFixed(2) : parseFloat('0').toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}


        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {toolItems.map((t: ProjectCost) => (
                  <div style={borderStyle}>
                    <li key={t.id} style={listItemQStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={t.totalCost ? formatter.format(t.totalCost) : '0.00'}
                    //  value={t.totalCost ? parseFloat((t.totalCost).toString()).toFixed(2) : parseFloat('0').toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {consumableItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.cpcTitle} style={listItemQStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={c.totalCost ? formatter.format(c.totalCost) : '0.00'}
                    //  value={c.totalCost ? parseFloat((c.totalCost).toString()).toFixed(2) : parseFloat('0').toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {labourItems.map((l: ProjectCost) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={l.totalCost ? formatter.format(l.totalCost) : '0.00'}
                    // value={l.totalCost ? parseFloat((l.totalCost).toString()).toFixed(2) : parseFloat('0').toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Service') &&
          item.resource.Service.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {serviceItems.map((l: ProjectCost) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={l.totalCost ? formatter.format(l.totalCost) : '0.00'}
                    //  value={l.totalCost ? parseFloat((l.totalCost).toString()).toFixed(2) : parseFloat('0').toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

      </div>


    );
  } else if (column && column.fieldName && column.fieldName === 'soldQuantity') {
    return (
      <div>
        <span>&nbsp;</span>
        {item.resource &&
          item.resource.hasOwnProperty('CBC') &&
          item.resource?.CBC.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {cbcItems?.map((l: ProjectCost, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={{ ...listItemQStyle, marginRight: 10 }}>
                      <DefaultNumberFormat
                        disabled={isEditable} value={l.soldQuantity?.toString()} onChange={(newValue?: string) => {
                          cbcItems[rindex].soldQuantity = newValue;
                          onChangeConsumedQuantity ? onChangeConsumedQuantity(index!!, rindex!!, 6, parseFloat(newValue && newValue != '' ? newValue : '0'), cbcItems) : null;
                        }}
                      />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              {/* <ul style={ulStyle}>
                            { item.resource.Labours.map((l: ProjectCost) => (
                                <div style={borderStyle}>
                                <li key={ l.id } style={ listItemQStyle}><TextField borderless disabled={isEditable} value={ l.totalCost?parseFloat(( l.totalCost).toString()).toFixed(2):parseFloat('0').toFixed(2) }/></li>
                                </div>
                            )) }
                        </ul> */}
              <ul style={ulStyle}>
                {materialItems.map((m: ProjectCost, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={m.id} style={{ ...listItemQStyle, marginRight: 10 }}><DefaultNumberFormat
                      disabled={isEditable} value={m.soldQuantity?.toString()} onChange={(newValue?: string) => {
                        materialItems[rindex].soldQuantity = newValue;
                        onChangeConsumedQuantity ? onChangeConsumedQuantity(index!!, rindex!!, 1, parseFloat(newValue && newValue != '' ? newValue : '0'), materialItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}


        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {toolItems.map((t: ProjectCost, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={t.id} style={{ ...listItemQStyle, marginRight: 10 }}><DefaultNumberFormat
                      disabled={isEditable} value={t.soldQuantity?.toString()} onChange={(newValue?: string) => {
                        toolItems[rindex].soldQuantity = newValue;
                        onChangeConsumedQuantity ? onChangeConsumedQuantity(index!!, rindex!!, 2, parseFloat(newValue && newValue != '' ? newValue : '0'), toolItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {consumableItems.map((c: ProjectCost, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={c.id} style={{ ...listItemQStyle, marginRight: 10 }}><DefaultNumberFormat
                      disabled={isEditable} value={c.soldQuantity?.toString()} onChange={(newValue?: string) => {
                        consumableItems[rindex].soldQuantity = newValue;
                        onChangeConsumedQuantity ? onChangeConsumedQuantity(index!!, rindex!!, 3, parseFloat(newValue && newValue != '' ? newValue : '0'), consumableItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {labourItems.map((l: ProjectCost, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={{ ...listItemQStyle, marginRight: 10 }}><DefaultNumberFormat
                      disabled={isEditable} value={l.soldQuantity?.toString()} onChange={(newValue?: string) => {
                        labourItems[rindex].soldQuantity = newValue;
                        onChangeConsumedQuantity ? onChangeConsumedQuantity(index!!, rindex!!, 4, parseFloat(newValue && newValue != '' ? newValue : '0'), labourItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Service') &&
          item.resource.Service.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {serviceItems.map((l: ProjectCost, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={{ ...listItemQStyle, marginRight: 10 }}><DefaultNumberFormat
                      disabled={isEditable} value={l.soldQuantity?.toString()} onChange={(newValue?: string) => {
                        serviceItems[rindex].soldQuantity = newValue;
                        onChangeConsumedQuantity ? onChangeConsumedQuantity(index!!, rindex!!, 5, parseFloat(newValue && newValue != '' ? newValue : '0'), serviceItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

      </div>


    );
  } else if (column && column.fieldName && column.fieldName === 'spMou') {
    return (
      <div>
        <span>&nbsp;</span>
        {item.resource &&
          item.resource.hasOwnProperty('CBC') &&
          item.resource?.CBC.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {cbcItems?.map((l: any, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><DefaultNumberFormat disabled={isEditable}
                      value={parseFloat(l?.spToMou).toFixed(2)}
                      onChange={(newValue?: string) => {
                        cbcItems[rindex].spToMou = newValue;
                        onChangeMOUCost ? onChangeMOUCost(index!!, rindex!!, 6, parseFloat(newValue && newValue != '' ? newValue : '0'),cbcItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {materialItems.map((m: any, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={m.id} style={listItemQStyle}><DefaultNumberFormat disabled={isEditable}
                      value={parseFloat(m.spToMou).toFixed(2)}
                      onChange={(newValue?: string) => {
                        materialItems[rindex].spToMou = newValue;
                        onChangeMOUCost ? onChangeMOUCost(index!!, rindex!!, 1, parseFloat(newValue && newValue != '' ? newValue : '0'),materialItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}


        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {toolItems.map((t: any, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={t.id} style={listItemQStyle}><DefaultNumberFormat disabled={isEditable}
                      value={parseFloat(t?.spToMou).toFixed(2)}
                      onChange={(newValue?: string) => {
                        toolItems[rindex].spToMou = newValue;
                        onChangeMOUCost ? onChangeMOUCost(index!!, rindex!!, 2, parseFloat(newValue && newValue != '' ? newValue : '0'),toolItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {consumableItems.map((c: any, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={c.cpcTitle} style={listItemQStyle}><DefaultNumberFormat disabled={isEditable}
                      value={parseFloat(c?.spToMou).toFixed(2)}
                      onChange={(newValue?: string) => {
                        consumableItems[rindex].spToMou = newValue;
                        onChangeMOUCost ? onChangeMOUCost(index!!, rindex!!, 3, parseFloat(newValue && newValue != '' ? newValue : '0'),consumableItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {labourItems.map((l: any, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><DefaultNumberFormat disabled={isEditable}
                      value={parseFloat(l?.spToMou).toFixed(2)}
                      onChange={(newValue?: string) => {
                        labourItems[rindex].spToMou = newValue;
                        onChangeMOUCost ? onChangeMOUCost(index!!, rindex!!, 4, parseFloat(newValue && newValue != '' ? newValue : '0'),labourItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Service') &&
          item.resource.Service.length > 0 && (
            <div style={{ marginTop: 0 }}>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly />
              </div>
              <ul style={ulStyle}>
                {serviceItems.map((l: any, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemQStyle}><DefaultNumberFormat disabled={isEditable}
                      value={parseFloat(l?.spToMou).toFixed(2)}
                      onChange={(newValue?: string) => {
                        serviceItems[rindex].spToMou = newValue;
                        onChangeMOUCost ? onChangeMOUCost(index!!, rindex!!, 5, parseFloat(newValue && newValue != '' ? newValue : '0'),serviceItems) : null;
                      }} /></li>
                  </div>
                ))}
              </ul>
            </div>
          )}
      </div>


    );
  } else if (column && column.fieldName && column.fieldName === 'travelCost') {
    return (
      <div>
        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <div>
              <ul style={{ marginTop: 50, listStyleType: 'none' }}>
                {materialItems.map((m: ProjectCost, rindex: number) => (
                  <li key={m.id}><TextField disabled={isEditable} value={m.travelCost?.toString()} underlined
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                      onChangeTravelCost ? onChangeTravelCost(index!!, rindex!!, 1, parseInt(newValue && newValue != '' ? newValue : '0')) : null;
                    }} /></li>
                ))}
              </ul>
            </div>
          )}


        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <div>
              <ul style={{ marginTop: 50, listStyleType: 'none' }}>
                {toolItems.map((t: ProjectCost, rindex: number) => (
                  <li key={t.id}><TextField disabled={isEditable} value={t.travelCost?.toString()} underlined
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                      onChangeTravelCost ? onChangeTravelCost(index!!, rindex!!, 2, parseInt(newValue && newValue != '' ? newValue : '0')) : null;
                    }} /></li>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <div>
              <ul style={{ marginTop: 50, listStyleType: 'none' }}>
                {consumableItems.map((c: any, rindex: number) => (
                  <li key={c.cpcTitle}><TextField disabled={isEditable} value={c.travelCost?.toString()} underlined
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                      onChangeTravelCost ? onChangeTravelCost(index!!, rindex!!, 3, parseInt(newValue && newValue != '' ? newValue : '0')) : null;
                    }} /></li>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <div>
              <ul style={{ marginTop: 0, listStyleType: 'none' }}>
                {labourItems.map((l: ProjectCost, rindex: number) => (
                  <li key={l.id}><TextField disabled={isEditable} value={l.travelCost?.toString()} underlined
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                      onChangeTravelCost ? onChangeTravelCost(index!!, rindex!!, 4, parseInt(newValue && newValue != '' ? newValue : '0')) : null;
                    }} /></li>
                ))}
              </ul>
            </div>
          )}
      </div>


    );
  } else if (column && column.fieldName && column.fieldName === 'totaleSales') {
    return (
      <div className={'totalCost1'}>
        {/* <span style={{marginLeft:40, fontWeight:900, textAlign:'right', display:'flex', flexDirection:'row'}}>{ getResourcesSubTotal(item.resource) }</span> */}
        <li key={item.product} style={{ ...listItemSPStyle }}>{<Text
          style={{ textAlign: 'right', marginRight: 8, fontWeight: 900 }}>
          {getResourcesSubTotal(item.resource)}
        </Text>}</li>
        {item.resource &&
          item.resource.hasOwnProperty('CBC') &&
          item.resource?.CBC.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly style={{ textAlign: 'right', fontWeight: 500 }}
                  value={formatter.format(getCbcSubTotal(item.resource))} />
              </div>
              <ul style={ulStyle}>
                {cbcItems?.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemPStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={formatter.format((l.soldQuantity!! * l.spToMou!!))}
                    //  value={parseFloat((l.soldQuantity!! * l.spToMou!!).toString()).toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly style={{ textAlign: 'right', fontWeight: 500 }}
                  value={formatter.format(getMaterialsSubTotal(item.resource))} />
              </div>
              <ul style={ulStyle}>
                {materialItems.map((m: any, rindex: number) => (
                  <div style={borderStyle}>
                    <li key={m.id} style={listItemQStyle}>{<TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={formatter.format((m.soldQuantity!! * m.spToMou!!))}
                    // value={parseFloat((m.soldQuantity!! * m.spToMou!!).toString()).toFixed(2)}
                    />}</li>
                  </div>
                ))}
              </ul>
            </div>
          )}


        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly style={{ textAlign: 'right', fontWeight: 500 }}
                  value={formatter.format(getToolsSubTotal(item.resource))} />
              </div>
              <ul style={ulStyle}>
                {toolItems.map((t: any) => (
                  <div style={borderStyle}>
                    <li key={t.id} style={listItemQStyle}>{<TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={formatter.format((t.soldQuantity!! * t.spToMou!!))}
                    // value={parseFloat((t.soldQuantity!! * t.spToMou!!).toString()).toFixed(2)}
                    />}</li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly style={{ textAlign: 'right', fontWeight: 500 }}
                  value={formatter.format(getConsumablsSubTotal(item.resource))} />
              </div>
              <ul style={ulStyle}>
                {consumableItems.map((c: any) => (
                  <div style={borderStyle}>
                    <li key={c.cpcTitle} style={listItemPStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={formatter.format((c.soldQuantity!! * c.spToMou!!))}
                    //  value={parseFloat((c.soldQuantity!! * c.spToMou!!).toString()).toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly style={{ textAlign: 'right', fontWeight: 500 }}
                  value={formatter.format(getLaboursSubTotal(item.resource))} />
              </div>
              <ul style={ulStyle}>
                {labourItems.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemPStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={formatter.format((l.soldQuantity!! * l.spToMou!!))}
                    //  value={parseFloat((l.soldQuantity!! * l.spToMou!!).toString()).toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Service') &&
          item.resource.Service.length > 0 && (
            <div>
              <div style={titleBorderStyle}>
                <TextField borderless readOnly style={{ textAlign: 'right', fontWeight: 500 }}
                  value={formatter.format(getServiceSubTotal(item.resource))} />
              </div>
              <ul style={ulStyle}>
                {serviceItems.map((l: any) => (
                  <div style={borderStyle}>
                    <li key={l.id} style={listItemPStyle}><TextField borderless style={{ textAlign: 'right' }} readOnly
                      value={formatter.format((l.soldQuantity!! * l.spToMou!!))}
                    //  value={parseFloat((l.soldQuantity!! * l.spToMou!!).toString()).toFixed(2)}
                    />
                    </li>
                  </div>
                ))}
              </ul>
            </div>
          )}
        {/* <div >
                    <div  style={listItemPStyle}><TextField borderless style={{textAlign:'right', fontWeight:800}} readOnly value={ getResourcesSubTotal(item.resource)}/></div>
                </div> */}



      </div>

    );
  } else if (column && column.fieldName && column.fieldName === 'comments') {
    return (
      <div>
        {item.resource &&
        item.resource.hasOwnProperty('CBC') &&
        item.resource?.CBC.length > 0 && (
          <div>
            <span>&nbsp;</span>
            <div style={titleBorderStyle}>
              <TextField borderless readOnly style={{ textAlign: 'right', fontWeight: 500 }}
                value={''} />
            </div>
            <ul style={ulStyle}>
              {cbcItems?.map((l: any, rindex: number) => (
                <div style={borderStyle}>
                  <li key={l.id} style={listItemPStyle}>
                    <TextField 
                      value={l?.comments ? l?.comments : ""}
                      placeholder={i18n.t('enterCommentsHere')}
                      onChange={(ev, newVal) => {
                        cbcItems[rindex].comments = newVal;
                        onChangeComments && onChangeComments(index!!, rindex!!, newVal, cbcItems)
                      }}
                    />
                  </li>
                </div>
              ))}
            </ul>
          </div>
        )}

        {item.resource &&
          item.resource.hasOwnProperty('Materials') &&
          item.resource.Materials.length > 0 && (
            <></>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Tools') &&
          item.resource.Tools.length > 0 && (
            <></>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Consumables') &&
          item.resource.Consumables.length > 0 && (
            <></>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Labours') &&
          item.resource.Labours.length > 0 && (
            <></>
          )}

        {item.resource &&
          item.resource.hasOwnProperty('Service') &&
          item.resource.Service.length > 0 && (
            <></>
          )}
      </div>
    )
  }
  // else if (column && column.fieldName && column.fieldName === 'costPerUnit') {
  //     return <div>
  //         <span onClick={ () => {
  //             test ? test() : null;
  //         } }>
  //             { '' }
  //         </span>
  //         { item.materials.length > 0 &&
  //     <div>
  //         <span></span>
  //         <ul style={ { marginTop: 50, listStyleType: 'none' } }>
  //             { item.materials.map((c: any) => <li key={ c.name }>{ c.cost }</li>) }
  //         </ul>
  //     </div>
  //         }

  //         { item.tools.length > 0 &&
  //     <div>

  //         <ul style={ { marginTop: 50, listStyleType: 'none' } }>
  //             { item.materials.map((c: any) => <li key={ c.name }>{ c.cost }</li>) }
  //         </ul>
  //     </div>
  //         }

  //     </div>;
  // } else if (column && column.fieldName && column.fieldName === 'totalCost1') {
  //     return <div>
  //         <span onClick={ () => {
  //             test ? test() : null;
  //         } }>
  //             { '' }
  //         </span>
  //         { item.materials.length > 0 &&
  //   <div>
  //       <span></span>
  //       <ul style={ { marginTop: 50, listStyleType: 'none' } }>
  //           { item.materials.map((c: any) => <li key={ c.name }>{ c.cost * c.consumed }</li>) }
  //       </ul>
  //   </div>
  //         }

  //         { item.tools.length > 0 &&
  //   <div>

  //       <ul style={ { marginTop: 50, listStyleType: 'none' } }>
  //           { item.tools.map((c: any) => <li key={ c.name }>{ c.cost * c.consumed }</li>) }
  //       </ul>
  //   </div>
  //         }

  //     </div>;
  // }
  return <span style={{ paddingLeft: 9 }}>-</span>;
};

export default TableColumn;

// else if (column && column.fieldName && column.fieldName === 'cquantity') {
//     return <div>
//         <span onClick={ () => {
//             onChangeConsumedQuantity ? onChangeConsumedQuantity(index!!,0,'k') : null;
//         } }>
//             { '' }
//         </span>
//         { item.materials.length > 0 &&
//   <div>
//       <span></span>
//       <ul style={ { marginTop: 50, listStyleType: 'none' } }>
//           { item.materials.map((c: any, rindex:number) => <li key={ c.name }>
//               <TextField value={ c.consumed }  underlined onChange={ ( event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,newValue?: string) => {
//                   onChangeConsumedQuantity ? onChangeConsumedQuantity(index!!, rindex!!, parseInt(newValue && newValue != ''?newValue:'0')) : null;
//               } } />
//           </li>) }
//       </ul>
//   </div>
//         }

//         { item.tools.length > 0 &&
//   <div>

//       <ul style={ { marginTop: 50, listStyleType: 'none' } }>
//           { item.tools.map((c: any) => <li key={ c.name }>{ c.consumed }</li>) }
//       </ul>
//   </div>
//         }

//     </div>;
// }
