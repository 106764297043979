import React, { Component } from 'react';
import { getContractingUnit, getProject } from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';
import WbsMainLayout from './wbsMainLayout';
import { FluentProvider } from '@fluentui/react-components';
import { uPrinceFluentLightTheme } from '../../../fluentTheme';

interface Props {
  currentUser: any;
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class WBS extends Component<Props, State> {
  render() {
    return (
      <UprinceNavigation
        currentUser={this.props.currentUser}
        selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/wbs`}
      >
        <FluentProvider theme={uPrinceFluentLightTheme}>
          <WbsMainLayout />
        </FluentProvider>
      </UprinceNavigation>
    )

  }
}

export default (WBS);
