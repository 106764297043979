import { Text } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeNodeAtPath,
  getFlatDataFromTree,
  getNodeAtPath,
  walk,
} from '@nosferatu500/react-sortable-tree';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import { ExpandProvider } from '../../../hooks/expandContext';
import { savePMOLStateAttr } from '../../../reducers/pmolReducer';
import {
  addMidTermLabourTeam,
  formatVPurchaseTaxonomyBarDataWithoutRefresh,
  readMidTermPlanning,
  readMTPOrganizationTaxanomyData,
  saveVPStateAttr,
  updateProductTaxonomyTreeIndex,
  updateProjectItemsDate,
} from '../../../reducers/visualPlanReducer';
import { Gantt, Task, ViewMode } from '../../../shared/ganttv2';
import { ChartType } from '../../../shared/ganttv2/types/public-types';
import ProgressBar from '../../../shared/progressBar/progressBar';
import { initTasks } from './helper';
import { ViewSwitcher } from './view-switcher';
import { getBuGroups } from '../../../types/myDayPlanning';
// import {getBuDropdownData} from '../../../reducers/projectDayPlanningReducer';
import { store } from '../../../../index';
import { OrganisationSettingListPaneFilter } from '../../../types/organisationSetting';
import { ORGANIZATION_TAXONOMY_LEVEL_TYPES } from '../../../types/cuMyCalendar';
import OrgTaxonomyModal from './orgTaxonomyModal';

export const ViewModeContext = React.createContext({
  onViewModeChange: (viewMode: ViewMode) => {},
  onViewListChange: (isCheck: boolean) => {},
  isCheck: true,
});
// Init
const MidTermTreeListPaneComponent = () => {
  const [view, setView] = React.useState<ViewMode>(ViewMode.QuaterYear);
  const [tasks, setTasks] = React.useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = React.useState(true);
  const [dataLoaded, setDataLoaded] = React.useState(false);
  const [tree, setTree] = React.useState<any>([]);
  const isTreeIndexUpdate = useRef(false);
  // const [searchText, setSearchText] = React.useState<any>();
  // const [refreshWithOutLoading, setRefreshWithOutLoading] = React.useState(false);
  //const [ searchString, setSearchString ]: any = React.useState('');
  // const [searchFocusIndex, setSearchFocusIndex]: any = React.useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDirty = useRef(false);
  const [isNoDataShow, setIsNoDataShow] = React.useState(false);

  // const reduxBarTasks: any[] = useSelector((state: any) => state.vp.midTermTaxonomyBarList);
  const listTasks: any[] = useSelector(
    (state: any) => state.vp.midTermPlanningTaxonomyList
  ); // midTermPlanningTaxonomyList vpProducTaxonomyList
  const interactBarTasks: any[] = useSelector(
    (state: any) => state.vp.midTermTaxonomyInteractBarList
  ); // midTermTaxonomyInteractBarList  vpProducTaxonomyInteractBarList
  const isDataLoaded: boolean = useSelector(
    (state: any) => state.vp.midTermDataLoaded
  );
  const selectedBuInCuProjectPlanning: any = useSelector(
    (state: any) => state.vp.selectedBuInCuProjectPlanning
  );
  const isLabourTeamAssigned: any = useSelector(
    (state: any) => state.vp.isLabourTeamAssigned
  );

  const onViewModeChange = (viewMode: ViewMode) => {
    setView(viewMode);
  };

  let columnWidth = 60;

  if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.Month) {
    columnWidth = 320;
  } else if (view === ViewMode.QuaterYear) {
    columnWidth = 350;
  }

  // const filterData = {
  //     title: null,
  //     contractingUnit: store.getState().uprince?.contractingUinit,
  //     businessUnit: getBuGroups(),
  // };

  // No data display message show because data mapping takes time
  useEffect(() => {
    if (isDataLoaded) {
      setTimeout(() => {
        setIsNoDataShow(true);
      }, 3000);
    } else {
      setIsNoDataShow(false);
    }
  }, [isDataLoaded]);

  useEffect(() => {
    dispatch(saveVPStateAttr('midTermDataLoaded', false));
    dispatch(savePMOLStateAttr('isPMOLOpenFromVP', true));
    dispatch(
      readMTPOrganizationTaxanomyData(new OrganisationSettingListPaneFilter())
    );
    dispatch(
      readMidTermPlanning({
        fromDate: null,
        toDate: null,
      })
    );
  }, []);

  useEffect(() => {
    if (isLabourTeamAssigned) {
      dispatch(
        readMTPOrganizationTaxanomyData(new OrganisationSettingListPaneFilter())
      );
    }
    return () => {
      dispatch(saveVPStateAttr('isLabourTeamAssigned', false));
    };
  }, [isLabourTeamAssigned]);

  useEffect(() => {
    if (!_.isEmpty(selectedBuInCuProjectPlanning)) {
      dispatch(
        readMidTermPlanning({
          fromDate: null,
          toDate: null,
        })
      );
    }
  }, [selectedBuInCuProjectPlanning]);

  useEffect(() => {
    setDataLoaded(isDataLoaded);
  }, [isDataLoaded]);

  const debounced = useDebouncedCallback((value: any) => {
    // console.log('debounced value >', value);
    let filterCabPersonId;
    if (
      value.node.organizationTaxonomyLevelId ===
      ORGANIZATION_TAXONOMY_LEVEL_TYPES.TEAM
    ) {
      filterCabPersonId = value?.node?.children?.map((item: any) => {
        return item.personId;
      });
    } else {
      filterCabPersonId = [value?.node?.personId];
    }

    dispatch(
      addMidTermLabourTeam({
        cabPersonId: filterCabPersonId,
        cpcId: value.nextParent.cpcId,
        cu: value.nextParent.cu,
        pbsProduct: value.nextParent.parentId,
        project: value.nextParent.project,
      })
    );
    // dispatch(saveVPStateAttr('selectedProject', value?.project));
    // dispatch(getById(value?.value?.childPbsSequenceId));
  }, 1000);

  // const debouncedTreeIndex = useDebouncedCallback(
  //     (treeData: any) => {
  //         if (isTreeIndexUpdate.current) {
  //             updateTreeIndexList(treeData);
  //             isTreeIndexUpdate.current = false;
  //         }
  //     }, 1000,);

  const handleTaskChange = (task: any) => {
    dispatch(saveVPStateAttr('selectedProject', task.project));
    console.log('update node', task);
    const isProject =
      _.isEmpty(task.pbsSequenceId) &&
      _.isEmpty(task.pmolSequenceId) &&
      _.isEmpty(task.parentId);
    isDirty.current = true;
    setTimeout(() => {
      isDirty.current = false;
    }, 2000);

    const ffData: any[] = getFlatDataFromTree({
      treeData: tree,
      getNodeKey: (node: any) => node.pbsSequenceId,
      ignoreCollapsed: false,
    });

    const finalresult = ffData.map((item: any) => {
      return item?.node;
    });

    let modifiedIndex = finalresult.findIndex((t) => t.id === task.id);
    if (modifiedIndex >= 0) {
      finalresult[modifiedIndex] = {
        ...finalresult[modifiedIndex],
        start: task.start,
        startDate: task.start.toISOString().replace('Z', ''),
        end: task.end,
        endDate: task.end.toISOString().replace('Z', ''),
      };
      dispatch(
        saveVPStateAttr('midTermPlanningTaxonomyList', [...finalresult])
      );
    }

    const startDate =
      moment(task.start).format('YYYY-MM-DD') + 'T00:00:00.000Z';
    const endDate =
      moment(task.end).subtract(1, 'days').format('YYYY-MM-DD') +
      'T00:00:00.000Z';
    //interactBarTasks.splice(task.index, 1, task);
    const payloadUpdate = {
      type: isProject ? 'Project' : 'PBS',
      sequenceId: isProject ? task.project : task.pbsSequenceId,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(updateProjectItemsDate(payloadUpdate));
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm('Are you sure about ' + task.name + ' ?');
    if (conf) {
      // setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: Task) => {
    if (!isDirty.current) {
      dispatch(saveVPStateAttr('showvpProjectDocumentPane', true));
      dispatch(saveVPStateAttr('selectedVpProjectBarType', 'pbs'));
      dispatch(saveVPStateAttr('slectedPBSForBor', null));
      dispatch(saveVPStateAttr('selectedPBS', task.pbsSequenceId));
    }
  };

  const handleSelect = (_task: Task, _isSelected: boolean) => {};

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  // const stringToArr = (string1: any) => {
  //     if (string1) {
  //         const x = string1.trim().split(',');
  //         x.shift();
  //         return x;
  //     }
  //     return [];
  // };

  const getNodeKey = ({ treeIndex }: any) => treeIndex;

  // const sumNodeValues = (treeData: any) => {
  //     let indexss = '';
  //     const callback = ({node}: any) => {
  //         indexss += ',' + node.id;
  //     };
  //     walk({
  //         treeData,
  //         getNodeKey,
  //         callback,
  //         ignoreCollapsed: true
  //     });

  //     return indexss;
  // };

  // const updateTreeIndexList = (treeData: any[]) => {
  //     Promise.all([dispatch(updateProductTaxonomyTreeIndex({pbsTreeIndex: getTreeIndexList(treeData)}))]).then((res: any) => {
  //         if (res[0].payload.data.status) {
  //             // dispatch(readMidTermPlanning({
  //             //     fromDate: null,
  //             //     toDate: null,
  //             // }));
  //             // dispatch(readMTPOrganizationTaxanomyData(new OrganisationSettingListPaneFilter()));
  //         }
  //     })
  // };

  // const getTreeIndexList = (treeData: any[]) => {
  //     // console.log('treeDatanice', treeData);
  //     let modifiedArray: any[] = [];
  //     const callback = (props: any) => {
  //         if (props.node?.id?.length > 4) {
  //             modifiedArray.push({
  //                 pbsProductId: props.node.id,
  //                 treeIndex: treeData[0].isFilter ? props.node.treeIndex : props.treeIndex,
  //             });
  //         }
  //     };

  //     walk({
  //         treeData,
  //         getNodeKey,
  //         callback,
  //         ignoreCollapsed: false,
  //     });
  //     //dispatch(updateProductTaxonomyTreeIndex({ pbsTreeIndex: modifiedArray }));
  //     console.log('');
  //     return modifiedArray;
  // };

  return (
    <div style={{ height: '100%' }}>
      <ViewModeContext.Provider
        value={{
          onViewModeChange: (viewMode: ViewMode) => onViewModeChange(viewMode),
          onViewListChange: (isCheck: boolean) => setIsChecked(isCheck),
          isCheck: isChecked,
        }}
      >
        <ExpandProvider>
          <ViewSwitcher />
          {!dataLoaded && <ProgressBar show={true} />}
          {dataLoaded && _.isEmpty(interactBarTasks) && isNoDataShow && (
            <Text style={{ marginLeft: 20, marginTop: 5 }}>{t('noData')}</Text>
          )}
          {dataLoaded && !_.isEmpty(interactBarTasks) && (
            <>
              <Gantt
                tasks={
                  listTasks &&
                  _.isArray(listTasks) &&
                  !_.isEmpty(listTasks) &&
                  dataLoaded &&
                  !_.isEmpty(interactBarTasks) &&
                  typeof interactBarTasks[0].start !== 'string'
                    ? interactBarTasks
                    : tasks
                }
                listTasks={
                  listTasks &&
                  _.isArray(listTasks) &&
                  !_.isEmpty(listTasks) &&
                  dataLoaded
                    ? listTasks
                    : tasks
                }
                searchQuery={''}
                searchMethod={({ node, searchQuery }: any) => {
                  return (
                    searchQuery &&
                    node.title
                      .toLowerCase()
                      .indexOf(searchQuery.toLowerCase()) > -1
                  );
                }}
                searchFocusOffset={0}
                searchFinishCallback={(matches) => {}}
                onlyExpandSearchedNodes={true}
                viewMode={view}
                onDateChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDblClick}
                onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                listCellWidth={isChecked ? '155px' : ''}
                columnWidth={columnWidth}
                selectedScreen={'midTermPlanning'}
                // onVisibilityChanged={onVisibilityChanged}
                onVisibilityToggle={() => {}}
                ganttHeight={560}
                isInitialCollaps={true}
                canDrag={({ node, path, treeIndex }: any) => {
                  return false;
                }}
                canDrop={({
                  node,
                  prevPath,
                  prevParent,
                  prevTreeIndex,
                  nextPath,
                  nextParent,
                  nextTreeIndex,
                  treeData,
                }: any) => {
                  const comNode: any = getNodeAtPath({
                    treeData: tree,
                    path: nextPath,
                    getNodeKey: getNodeKey,
                  });

                  if (nextParent?.type === 'PbsLabour') {
                    debounced({
                      node: node,
                      nextParent: nextParent,
                    });
                    isTreeIndexUpdate.current = true;
                    if (nextParent) {
                      return !_.inRange(nextParent.id, 1000, 1100);
                    } else {
                      return !_.inRange(comNode?.node?.id, 1000, 1100);
                    }
                  } else {
                    return false;
                  }
                }}
                onMoveNode={(
                  treeData: any,
                  nodeOne: any,
                  nextParentNode: any,
                  prevPath: any,
                  prevTreeIndex: any,
                  nextPath: any,
                  nextTreeIndex: any,
                  nodeTwo: any
                ) => {
                  if (nextParentNode) {
                    nodeOne.parentId = nextParentNode.id;
                  } else {
                    nodeOne.parentId = null;
                  }

                  // console.log('onMoveNode treeData >', treeData);
                  // console.log('onMoveNode nodeOne >', nodeOne);
                  // console.log('onMoveNode nextParentNode >', nextParentNode);
                  // console.log('onMoveNode nextPath >', nextPath);
                  dispatch(saveVPStateAttr('pbsTaxonomyTreeHasChanged', true));
                  dispatch(saveVPStateAttr('expandPath', nextPath));

                  if (
                    nodeOne.organizationTaxonomyLevelId ===
                    ORGANIZATION_TAXONOMY_LEVEL_TYPES.TEAM
                  ) {
                    const teamNodeOne = {
                      ...nextParentNode,
                      children: nodeOne.children.map((child: any) => {
                        return {
                          ...child,
                          children: undefined,
                        };
                      }),
                    };

                    const treeWithTeam = changeNodeAtPath({
                      treeData: treeData,
                      path: nextPath,
                      newNode: teamNodeOne,
                      getNodeKey: ({ treeIndex }) => treeIndex,
                    });

                    dispatch(
                      saveVPStateAttr(
                        'midTermPlanningTaxonomyList',
                        treeWithTeam
                      )
                    );
                  } else if (
                    nodeOne.organizationTaxonomyLevelId ===
                    ORGANIZATION_TAXONOMY_LEVEL_TYPES.PERSON
                  ) {
                    const labourNodeOne = { ...nodeOne, children: undefined };

                    const treeWithLabour = changeNodeAtPath({
                      treeData: treeData,
                      path: nextPath,
                      newNode: labourNodeOne,
                      getNodeKey: ({ treeIndex }) => treeIndex,
                    });

                    dispatch(
                      saveVPStateAttr(
                        'midTermPlanningTaxonomyList',
                        treeWithLabour
                      )
                    );
                  } else {
                    dispatch(
                      saveVPStateAttr('midTermPlanningTaxonomyList', treeData)
                    );
                  }

                  // if (treeData[0]?.id !== '0000') {
                  //     if (isTreeIndexUpdate.current) {
                  //         debouncedTreeIndex(treeData);
                  //     }
                  //     dispatch(saveVPStateAttr('midTermPlanningTaxonomyList', treeData));
                  //     setTree(treeData);
                  // }
                }}
                chartType={ChartType.PBSTAXONOMY}
                onTreeDataChange={(treeData: any[]) => {
                  if (treeData[0]?.id !== '0000') {
                    // getTreeIndexList(treeData)
                    setTree(treeData);
                  }
                  dispatch(
                    saveVPStateAttr('midTermTaxonomyBarList', [
                      ...formatVPurchaseTaxonomyBarDataWithoutRefresh(
                        treeData
                      )[0],
                    ])
                  );
                  dispatch(
                    saveVPStateAttr('midTermTaxonomyInteractBarList', [
                      ...formatVPurchaseTaxonomyBarDataWithoutRefresh(
                        treeData
                      )[1],
                    ])
                  );
                }}
              />
            </>
          )}
        </ExpandProvider>
      </ViewModeContext.Provider>
      <OrgTaxonomyModal />
    </div>
  );
};

export default MidTermTreeListPaneComponent;
