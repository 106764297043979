import React from 'react';
import { useTranslation } from 'react-i18next';
import { DirectionalHint, IconButton, Link, mergeStyleSets, Stack, Text, TooltipHost } from '@fluentui/react';
import { Depths } from '@fluentui/theme';
import { uPrinceTheme } from '../../../../theme';
import { PMOL_TYPE } from '../../../types/projectMolecule';

interface BookMarkPaneProps {
  isShowBookmark: boolean;
  currentActiveSection: string;
  toggleBookmark: () => void;
  toggleBookmarkOut: () => void;
  isEdit: boolean;
  pmolType?: PMOL_TYPE;
  pbsFormData?: any;
  list: any[]
}

const PbsBookMarkPaneComponent: React.FC<BookMarkPaneProps> = ({
  isShowBookmark,
  currentActiveSection,
  toggleBookmark,
  toggleBookmarkOut,
  isEdit,
  pmolType,
  pbsFormData,
  list
}) => {
  const { t } = useTranslation();

  const collapseDocumentPane = () => {
    toggleBookmarkOut();
  };

  const GlobalNavButton = () => (
    <IconButton onClick={toggleBookmark} iconProps={{ iconName: 'GlobalNavButton' }} title={t('openBookmark')} ariaLabel={t('openBookmark')} style={{ width: 32, height: 32 }} />
  );

  // Define styles for reusable parts
  const classStyles = mergeStyleSets({
    topHeaderStyle: {
      fontWeight: 600,
      borderLeft: '0px !important',
      color: uPrinceTheme.palette.themePrimary
    },
    container: {
      height: 'calc(100% - 12px)',
      width: 'calc(100% - 12px)',
      padding: 6,
      overflow: 'hidden',
    },
    regularFont: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    bookmarkTitleBar: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 8px',
    },
    bookmarkContent: {
      height: 'calc(100% - 34px)',
      overflow: 'auto',
    },
    bookmarkWith: {
      height: '100%',
      width: '220px',
    },
    bookmarkWithout: {
      height: '100%',
      width: '28px',
    },
    containerSub: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      padding: '4px 0',
    },
  });

  // A reusable function for rendering links
  const renderLink = (id: string, label: string, isSub: boolean, seqNo: any) => (
    <div style={{paddingTop: 6}}>
      <Link
        className={`${classStyles.topHeaderStyle} ${currentActiveSection === id ? 'selected' : ''}`}
        style={{
          borderLeft: currentActiveSection === id ? `4px solid ${uPrinceTheme.palette.themePrimary}` : '',
          fontWeight: !isSub ? 600 : 'normal',
          fontSize: !isSub ? 15 : 14,
          marginLeft: !isSub ? 0 : '6px'
        }}
        href={`#${id}`}
      >
        {`${seqNo}. ${t(label.toLocaleLowerCase())}`}
      </Link><br/>
    </div>
  );

  // Function to render dynamic bookmark list
  const renderBookmarkList = (bookmarkList: any[]) => {
    return bookmarkList.map((item) => {
      if (item.isSubHeader) {
        return (
          <div key={item.id}>
            {renderLink(item.link, item.label, true, item.id)}
          </div>
        );
      } else {
        return (
          <div key={item.id}>
            {renderLink(item.link, item.label, false, item.id)}
            {/* Render sub-headers recursively if they exist */}
            {item.subsections && item.subsections.length > 0 && (
              <div style={{ marginLeft: 16 }}>
                {renderBookmarkList(item.subsections)}
              </div>
            )}
          </div>
        );
      }
    });
  };

  // Render the appropriate bookmark content
  return (
    <div className={isShowBookmark ? classStyles.bookmarkWith : classStyles.bookmarkWithout} style={{ width: isShowBookmark ? '220px' : '28px', height: '100%' }}>
      {isShowBookmark ? (
        <div className={classStyles.container}>
          <div className={classStyles.bookmarkTitleBar}>
            <Text className={classStyles.regularFont}>{t('bookmark')}</Text>
            <i title="Close" className="ms-Icon ms-Icon--ChromeClose" aria-hidden="true" onClick={collapseDocumentPane}></i>
          </div>
          <div className={`inner-container inner-container-pmol ${classStyles.bookmarkContent}`}>
            {renderBookmarkList(list)}
          </div>
          <Stack className="bookmark-panel-responsive" verticalFill onClick={toggleBookmark} gap={30} styles={{ root: { boxShadow: Depths.depth8 } }}>
            <TooltipHost content={t('bookmark')} id="bmark" calloutProps={{ gapSpace: 0 }} directionalHint={DirectionalHint.rightCenter}>
              <i className="ms-Icon ms-Icon--SearchBookmark" aria-labelledby="bmark" aria-hidden="true"></i>
            </TooltipHost>
          </Stack>
        </div>
      ) : (
        <div className={classStyles.containerSub}>
          <GlobalNavButton />
        </div>
      )}
    </div>
  );
};

export default PbsBookMarkPaneComponent;
