import React, { Component } from 'react';
import { connect } from 'react-redux';
import Brand from '../../shared/brand/brand';
// import ProjectBar from '../../shared/projectBar/projectBar';
import InvoiceMainLayout from './invoiceMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';
import CUNavBar from '../../shared/navBar/cuNavBar';
import ProjectNavBar from '../../shared/navBar/projectNavBar';
import ProjectBarComponent from '../../shared/projectBar/ProjectBarComponent';

interface Props {
  currentUser: any;
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class Invoice extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isContractUnit: getContractingUnit() ? true : false,
      isProject: getProject() ? true : false,
    };
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        {/* <ProjectBar
          contractingUnit={
            this.props.currentUser && this.props.currentUser.contractingUnits[0]
              ? this.props.currentUser.contractingUnits[0]
              : null
          }
        /> */}
        <ProjectBarComponent 
          contractingUnit={
            this.props.currentUser && this.props.currentUser.contractingUnits[0]
              ? this.props.currentUser.contractingUnits[0]
              : null
          }
        />
        {this.state.isProject ? (
          <ProjectNavBar
            selectedKey={`/CU/${getContractingUnit()}/project/${getProject()}/invoice`}
            currentUser={this.props.currentUser}
          />
        ) : (
          <CUNavBar
            selectedKey={`/CU/${getContractingUnit()}/invoice`}
            contractingUnit={
              this.props.currentUser &&
              this.props.currentUser.contractingUnits[0]
                ? this.props.currentUser.contractingUnits[0]
                : null
            }
            currentUser={this.props.currentUser}
          />
        )}
        { /* {!this.state.isContractUnit &&
          <NavBar  selectedKey={4} contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0]?this.props.currentUser.contractingUnits[0]:null}/>
        } */}
        <InvoiceMainLayout/>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
