import { Image, Label, Link } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import HistoryBar from '../../../shared/historyBar/historyBar';
import ProjectHeader from '../../../shared/projectHeader/projectHeader';
import { Cost, ProgressStatement, ProgressStatementValidationMessage } from '../../../types/progressStatement';
import ProjectInformation from './projectInformation/projectInformation';
import CostTable from './costTable/component';
import CustomerInformation from './customerInformation/customerInformation';
import InvoiceComments from './invoiceComments/invoiceComments';
import ENV from '../../../../env.json'


// Tokens definition

// const theme = getTheme();

export const DocumentPaneComponent = (props: {
  projectHeader: any;
  dropDownOptions: any;
  isEdit: boolean;
  formData: ProgressStatement;
  saveFormData: () => void;
  handleFormChange: (progressStatement: ProgressStatement, save: boolean) => void;
  validationMessages: ProgressStatementValidationMessage;
  // eslint-disable-next-line react/no-unused-prop-types
  isLoading: boolean;
  projectCostData: Cost[],
  openCostModal: () => void,
  createPSResource: (data: any) => void,
  isExpand: boolean,
  ledgerDropDowns: { key: string, text: string }[],
  contractingUnitsdropdown:{key: string, text: string }[],
  customerInformationdropdown: { key: string, text: string ,customerContactPerson:string,customerContactPersonId: string,
    customerContactPersonEmailId: string,
    customerContactPersonEmail: string, }[];
  setGeneralLedger: (id: any) => void,
  setContractunit: (id: any) => void,
  generalLedger: string | null,
  contractunit:string | null
  customerName :string | null
  customerContactPerson:string | null
  customerContactPersonEmail:string | null
  setCustomerName:(id:any)=>void,
  setcontactPersonName:(id:any)=>void,
  setcontactPersonEmail:(id:any)=>void,
  setResourceData: (data: any) => void,
  onQualityFilterPersonChanged: any;
}) => {
  const { t } = useTranslation();


  return (
    <div style={{ width: '100%' }} id={'ProjectBreakdownForm'}>
      <div className="proj-detail-block">
        <ProjectHeader projectDetails={props.projectHeader}/>
      </div>

      <div className="proj-detail-block" id="1">
        <ProjectInformation
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          formData={props.formData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(progressStatement: ProgressStatement, save: boolean) =>
            props.handleFormChange(progressStatement, save)
          }
          validationMessages={props.validationMessages}
          ledgerDropDowns={props.ledgerDropDowns}
          contractingUnitsdropdown={props.contractingUnitsdropdown}
          setGeneralLedger={(id) => props.setGeneralLedger(id)}
          setContractunit={(id) => props.setContractunit(id)}
          generalLedger={props.generalLedger}
          contractunit={props.contractunit}
          onQualityFilterPersonChanged={
            props.onQualityFilterPersonChanged
          }
          projectDetails={props.projectHeader}
        />
      </div>

      <div className="proj-detail-block" id="2">
        <CustomerInformation
          dropDownOptions={props.dropDownOptions}
          isEdit={props.isEdit}
          formData={props.formData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(progressStatement: ProgressStatement, save: boolean) =>
            props.handleFormChange(progressStatement, save)
          }
          validationMessages={props.validationMessages}
          customerInformationdropdown= {props.customerInformationdropdown}
          customerName={props.customerName}
          customerContactPerson={props.customerContactPerson}
          customerContactPersonEmail={props.customerContactPersonEmail}
          setCustomerName={(id) => props.setCustomerName(id)}
          setcontactPersonName={(id) => props.setcontactPersonName(id)}
          setcontactPersonEmail={(id) => props.setcontactPersonEmail(id)}
        />
      </div>

      <div className="proj-detail-block" id="3">
        <InvoiceComments
          isDisabled={props.dropDownOptions.isApproved}
          isEdit={props.isEdit}
          formData={props.formData}
          saveFormData={() => props.saveFormData()}
          handleFormChange={(progressStatement: ProgressStatement, save: boolean) =>
            props.handleFormChange(progressStatement, save)
          }
          validationMessages={props.validationMessages}
        />
      </div>


      <div className="proj-detail-block" id="4">
        <CostTable
          projectCostData={props.projectCostData}
          openCostModal={props.openCostModal}
          createPSResource={(data: any) => props.createPSResource(data)}
          isExpand={props.isExpand}
          formData={props.formData}
          isApproved={props.dropDownOptions.isApproved}
          setResourceData={(data: any) => props.setResourceData(data)}
        />
      </div>


      {props.isEdit &&
      props.formData.historyLog &&
      props.formData.historyLog.createdByUser && (
        <div className="proj-detail-block" id="5">
          <div className="document-pane-card">
            <div className="marginTop marginBottom">
              <Link href="#" id="history">
                <Label>5. {t('history')} </Label>
              </Link>

              <HistoryBar
                createdByUser={
                  props.formData.historyLog.createdByUser
                    ? props.formData.historyLog.createdByUser
                    : ''
                }
                updatedByUser={
                  props.formData.historyLog.updatedByUser
                    ? props.formData.historyLog.updatedByUser
                    : ''
                }
                createdDateTime={
                  props.formData.historyLog.createdDateTime
                    ? props.formData.historyLog.createdDateTime
                    : ''
                }
                updatedDateTime={
                  props.formData.historyLog.updatedDateTime
                    ? props.formData.historyLog.updatedDateTime
                    : ''
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentPaneComponent);
