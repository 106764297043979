import { READ_PBS_RELATIONS_EP } from '../shared/endpoints';

export const LOAD_SHORTCUT_PANE_FILTER_LIST =
  'uprince/LOAD_PBS_SHORTCUT_PANE_FILTER_LIST';
export const LOAD_SHORTCUT_PANE_FILTER_LIST_SUCCESS =
  'uprince/LOAD_PBS_SHORTCUT_PANE_FILTER_LIST_SUCCESS';
export const LOAD_SHORTCUT_PANE_FILTER_LIST_FAIL =
  'uprice/LOAD_PBS_SHORTCUT_PANE_FILTER_LIST_FAIL';

export const LOAD_LIST_PANE_LIST = 'uprince/LOAD_PBS_LIST_PANE_LIST';
export const LOAD_LIST_PANE_LIST_SUCCESS =
  'uprince/LOAD_PBS_LIST_PANE_LIST_SUCCESS';
export const LOAD_LIST_PANE_LIST_FAIL = 'uprice/LOAD_PBS_LIST_PANE_LIST_FAIL';

export const LOAD_RISK_DROPDOWN = 'uprince/LOAD_RISK_DROPDOWN';
export const LOAD_RISK_DROPDOWN_SUCCESS = 'uprince/LOAD_RISK_DROPDOWN_SUCCESS';
export const LOAD_RISK_DROPDOWN_FAIL = 'uprice/LOAD_RISK_DROPDOWN_FAIL';

export const GET_PBS_BY_ID = 'uprince/GET_PBS_BY_ID';
export const GET_PBS_BY_ID_SUCCESS = 'uprince/GET_PBS_BY_ID_SUCCESS';
export const GET_PBS_BY_ID_FAIL = 'uprice/GET_PBS_BY_ID_FAIL';

export const GET_PBS_BY_ID_FOR_TREESTRUCTURE =
  'uprince/GET_PBS_BY_ID_FOR_TREESTRUCTURE';
export const GET_PBS_BY_ID_FOR_TREESTRUCTURE_SUCCESS =
  'uprince/GET_PBS_BY_ID_FOR_TREESTRUCTURE_SUCCESS';
export const GET_PBS_BY_ID_FOR_TREESTRUCTURE_FAIL =
  'uprice/GET_PBS_BY_ID_FOR_TREESTRUCTURE_FAIL';

export const GET_PBS_EXTERNAL_SYNC_LOG = 'uprince/GET_PBS_EXTERNAL_SYNC_LOG';
export const GET_PBS_EXTERNAL_SYNC_LOG_SUCCESS =
  'uprince/GET_PBS_EXTERNAL_SYNC_LOG_SUCCESS';
export const GET_PBS_EXTERNAL_SYNC_LOG_FAIL =
  'uprice/GET_PBS_EXTERNAL_SYNC_LOG_FAIL';

export const SAVE_PBS = 'uprince/SAVE_PBS';
export const SAVE_PBS_SUCCESS = 'uprince/SAVE_PBS_SUCCESS';
export const SAVE_PBS_FAIL = 'uprice/SAVE_PBS_FAIL';

export const CREATE_PBS_FOLDER = 'uprince/CREATE_PBS_FOLDER';
export const CREATE_PBS_FOLDER_SUCCESS = 'uprince/CREATE_PBS_FOLDER_SUCCESS';
export const CREATE_PBS_FOLDER_FAIL = 'uprince/CREATE_PBS_FOLDER_FAIL';

export const SET_PBS_UID = 'uprice/SET_PBS_UID';
export const CLEAR_PBS_UID = 'uprice/CLEAR_PBS_UID';
export const CLEAR_PBS_FORM_DATA = 'uprice/CLEAR_PBS_FORM_DATA';
export const CLEAR_PBS_INS_DATA = 'uprice/CLEAR_PBS_INS_DATA';
export const RESET_IS_CHANGE = 'uprice/RESET_IS_CHANGE';
export const RESET_RISK_GRID_LIST = 'uprice/RESET_RISK_GRID_LIST';

export const SAVE_PBS_RISK = 'uprince/SAVE_PBS_RISK';
export const SAVE_PBS_RISK_SUCCESS = 'uprince/SAVE_PBS_RISK_SUCCESS';
export const SAVE_PBS_RISK_FAIL = 'uprice/SAVE_PBS_RISK_FAIL';

export const SAVE_PBS_INSTRUCTION = 'uprince/SAVE_PBS_INSTRUCTION';
export const SAVE_PBS_INSTRUCTION_SUCCESS =
  'uprince/SAVE_PBS_INSTRUCTION_SUCCESS';
export const SAVE_PBS_INSTRUCTION_FAIL = 'uprice/SAVE_PBS_INSTRUCTION_FAIL';

export const CREATE_RISK = 'uprince/CREATE_RISK';
export const CREATE_RISK_SUCCESS = 'uprince/CREATE_RISK_SUCCESS';
export const CREATE_RISK_FAIL = 'uprice/CREATE_RISK_FAIL';

export const READ_RISK = 'uprince/READ_RISK';
export const READ_RISK_SUCCESS = 'uprince/READ_RISK_SUCCESS';
export const READ_RISK_FAIL = 'uprice/READ_RISK_FAIL';

export const DELETE_RISK = 'uprince/DELETE_RISK';
export const DELETE_RISK_SUCCESS = 'uprince/DELETE_RISK_SUCCESS';
export const DELETE_RISK_FAIL = 'uprice/DELETE_RISK_FAIL';

export const LOAD_DROPDOWN_DATA = 'uprince/LOAD_DROPDOWN_DATA';
export const LOAD_DROPDOWN_DATA_SUCCESS = 'uprince/LOAD_DROPDOWN_DATA_SUCCESS';
export const LOAD_DROPDOWN_DATA_FAIL = 'uprice/LOAD_DROPDOWN_DATA_FAIL';

export const LOAD_QUALITY = 'uprince/LOAD_QUALITY';
export const LOAD_QUALITY_SUCCESS = 'uprince/LOAD_QUALITY_SUCCESS';
export const LOAD_QUALITY_FAIL = 'uprice/LOAD_QUALITY_FAIL';

export const CBC_EXCEL_UPLOAD = 'uprince/CBC_EXCEL_UPLOAD';
export const CBC_EXCEL_UPLOAD_SUCCESS = 'uprince/CBC_EXCEL_UPLOAD_SUCCESS';
export const CBC_EXCEL_UPLOAD_FAIL = 'uprice/CBC_EXCEL_UPLOAD_FAIL';

export const CBC_PDF_UPLOAD = 'uprince/CBC_PDF_UPLOAD';
export const CBC_PDF_UPLOAD_SUCCESS = 'uprince/CBC_PDF_UPLOAD_SUCCESS';
export const CBC_PDF_UPLOAD_FAIL = 'uprice/CBC_PDF_UPLOAD_FAIL';

export const CREATE_QUALITY = 'uprince/CREATE_QUALITY';
export const CREATE_QUALITY_SUCCESS = 'uprince/CREATE_QUALITY_SUCCESS';
export const CREATE_QUALITY_FAIL = 'uprice/CREATE_QUALITY_FAIL';

export const SAVE_PBS_QUALITY = 'uprince/SAVE_PBS_QUALITY';
export const SAVE_PBS_QUALITY_SUCCESS = 'uprince/SAVE_PBS_QUALITY_SUCCESS';
export const SAVE_PBS_QUALITY_FAIL = 'uprice/SAVE_PBS_QUALITY_FAIL';

export const DELETE_QUALITY = 'uprince/DELETE_QUALITY';
export const DELETE_QUALITY_SUCCESS = 'uprince/DELETE_QUALITY_SUCCESS';
export const DELETE_QUALITY_FAIL = 'uprice/DELETE_QUALITY_FAIL';

export const LOAD_MATERIALS = 'uprince/LOAD_MATERIALS';
export const LOAD_MATERIALS_SUCCESS = 'uprince/LOAD_MATERIALS_SUCCESS';
export const LOAD_MATERIALS_FAIL = 'uprice/LOAD_MATERIALS_FAIL';

export const CREATE_RESOURCE = 'uprince/CREATE_RESOURCE';
export const CREATE_RESOURCE_SUCCESS = 'uprince/CREATE_RESOURCE_SUCCESS';
export const CREATE_RESOURCE_FAIL = 'uprice/CREATE_RESOURCE_FAIL';

export const CREATE_TOOLS = 'uprince/CREATE_TOOLS';
export const CREATE_TOOLS_SUCCESS = 'uprince/CREATE_TOOLS_SUCCESS';
export const CREATE_TOOLS_FAIL = 'uprice/CREATE_TOOLS_FAIL';

export const READ_TOOLS = 'uprince/READ_TOOLS';
export const READ_TOOLS_SUCCESS = 'uprince/READ_TOOLS_SUCCESS';
export const READ_TOOLS_FAIL = 'uprice/READ_TOOLS_FAIL';

export const DELETE_MATERIALS = 'uprince/DELETE_MATERIALS';
export const DELETE_MATERIALS_SUCCESS = 'uprince/DELETE_MATERIALS_SUCCESS';
export const DELETE_MATERIALS_FAIL = 'uprice/DELETE_MATERIALS_FAIL';

export const DELETE_TOOLS = 'uprince/DELETE_TOOLS';
export const DELETE_TOOLS_SUCCESS = 'uprince/DELETE_TOOLS_SUCCESS';
export const DELETE_TOOLS_FAIL = 'uprice/DELETE_TOOLS_FAIL';

export const CREATE_LABOUR = 'uprince/CREATE_LABOUR';
export const CREATE_LABOUR_SUCCESS = 'uprince/CREATE_LABOUR_SUCCESS';
export const CREATE_LABOUR_FAIL = 'uprice/CREATE_LABOUR_FAIL';

export const READ_LABOURS = 'uprince/READ_LABOURS';
export const READ_LABOURS_SUCCESS = 'uprince/READ_LABOURS_SUCCESS';
export const READ_LABOURS_FAIL = 'uprice/READ_LABOURS_FAIL';

export const DELETE_LABOURS = 'uprince/DELETE_LABOURS';
export const DELETE_LABOURS_SUCCESS = 'uprince/DELETE_LABOURS_SUCCESS';
export const DELETE_LABOURS_FAIL = 'uprice/DELETE_LABOURS_FAIL';

export const READ_CONSUMABLE = 'uprince/READ_CONSUMABLE';
export const READ_CONSUMABLE_SUCCESS = 'uprince/READ_CONSUMABLE_SUCCESS';
export const READ_CONSUMABLE_FAIL = 'uprice/READ_CONSUMABLE_FAIL';

export const CREATE_CONSUMABLE = 'uprince/CREATE_CONSUMABLE';
export const CREATE_CONSUMABLE_SUCCESS = 'uprince/CREATE_CONSUMABLE_SUCCESS';
export const CREATE_CONSUMABLE_FAIL = 'uprice/CREATE_CONSUMABLE_FAIL';

export const DELETE_CONSUMABLE = 'uprince/DELETE_CONSUMABLE';
export const DELETE_CONSUMABLE_SUCCESS = 'uprince/DELETE_CONSUMABLE_SUCCESS';
export const DELETE_CONSUMABLE_FAIL = 'uprice/DELETE_CONSUMABLE_FAIL';

export const LOAD_INSTRUCTION_DROPDOWN = 'uprince/LOAD_INSTRUCTION_DROPDOWN';
export const LOAD_INSTRUCTION_DROPDOWN_SUCCESS =
  'uprince/LOAD_INSTRUCTION_DROPDOWN_SUCCESS';
export const LOAD_INSTRUCTION_DROPDOWN_FAIL =
  'uprice/LOAD_INSTRUCTION_DROPDOWN_FAIL';

export const LOAD_TECH_INSTRUCTION = 'uprince/LOAD_TECH_INSTRUCTION';
export const LOAD_TECH_INSTRUCTION_SUCCESS =
  'uprince/LOAD_TECH_INSTRUCTION_SUCCESS';
export const LOAD_TECH_INSTRUCTION_FAIL = 'uprice/LOAD_TECH_INSTRUCTION_FAIL';

export const UPLOAD_INSTRUCTION_DOC = 'uprince/UPLOAD_INSTRUCTION_DOC';
export const UPLOAD_INSTRUCTION_DOC_SUCCESS =
  'uprince/UPLOAD_INSTRUCTION_DOC_SUCCESS';
export const UPLOAD_INSTRUCTION_DOC_FAIL = 'uprice/UPLOAD_INSTRUCTION_DOC_FAIL';

export const LOAD_TAXONOMY = 'uprince/LOAD_TAXONOMY';
export const LOAD_TAXONOMY_SUCCESS = 'uprince/LOAD_TAXONOMY_SUCCESS';
export const LOAD_TAXONOMY_FAIL = 'uprice/LOAD_TAXONOMY_FAIL';

export const CREATE_TECH_INSTRUCTION = 'uprince/CREATE_TECH_INSTRUCTION';
export const CREATE_TECH_INSTRUCTION_SUCCESS =
  'uprince/CREATE_TECH_INSTRUCTION_SUCCESS';
export const CREATE_TECH_INSTRUCTION_FAIL =
  'uprice/CREATE_TECH_INSTRUCTION_FAIL';

export const LOAD_COMPETENCE = 'uprince/LOAD_COMPETENCE';
export const LOAD_COMPETENCE_SUCCESS = 'uprince/LOAD_COMPETENCE_SUCCESS';
export const LOAD_COMPETENCE_FAIL = 'uprice/LOAD_COMPETENCE_FAIL';

export const LOAD_COMPETENCE_PRODUCT = 'uprince/LOAD_COMPETENCE_PRODUCT';
export const LOAD_COMPETENCE_SUCCESS_PRODUCT =
  'uprince/LOAD_COMPETENCE_SUCCESS_PRODUCT';
export const LOAD_COMPETENCE_FAIL_PRODUCT =
  'uprice/LOAD_COMPETENCE_FAIL_PRODUCT';

export const LOAD_MILESTONE = 'uprince/LOAD_MILESTONE';
export const LOAD_MILESTONE_SUCCESS = 'uprince/LOAD_MILESTONE_SUCCESS';
export const LOAD_MILESTONE_FAIL = 'uprice/LOAD_MILESTONE_FAIL';

export const LOAD_COMPETENCE_DROPDOWN = 'uprince/LOAD_COMPETENCE_DROPDOWN';
export const LOAD_COMPETENCE_DROPDOWN_SUCCESS =
  'uprince/LOAD_COMPETENCE_DROPDOWN_SUCCESS';
export const LOAD_COMPETENCE_DROPDOWN_FAIL =
  'uprice/LOAD_COMPETENCE_DROPDOWN_FAIL';

export const CREATE_COMPETENCE = 'uprince/CREATE_COMPETENCE';
export const CREATE_COMPETENCE_SUCCESS = 'uprince/CREATE_COMPETENCE_SUCCESS';
export const CREATE_COMPETENCE_FAIL = 'uprice/CREATE_COMPETENCE_FAIL';

export const CREATE_COMPETENCE_PRODUCT = 'uprince/CREATE_COMPETENCE_PRODUCT';
export const CREATE_COMPETENCE_SUCCESS_PRODUCT =
  'uprince/CREATE_COMPETENCE_SUCCESS_PRODUCT';
export const CREATE_COMPETENCE_FAIL_PRODUCT =
  'uprice/CREATE_COMPETENCE_FAIL_PRODUCT';

export const DELETE_COMPETENCE = 'uprince/DELETE_COMPETENCE';
export const DELETE_COMPETENCE_SUCCESS = 'uprince/DELETE_COMPETENCE_SUCCESS';
export const DELETE_COMPETENCE_FAIL = 'uprice/DELETE_COMPETENCE_FAIL';

export const DELETE_COMPETENCE_PRODUCT = 'uprince/DELETE_COMPETENCE_PRODUCT';
export const DELETE_COMPETENCE_SUCCESS_PRODUCT =
  'uprince/DELETE_COMPETENCE_SUCCESS_PRODUCT';
export const DELETE_COMPETENCE_FAIL_PRODUCT =
  'uprice/DELETE_COMPETENCE_FAIL_PRODUCT';

export const CREATE_MILESTONE = 'uprince/CREATE_MILESTONE';
export const CREATE_MILESTONE_SUCCESS = 'uprince/CREATE_MILESTONE_SUCCESS';
export const CREATE_MILESTONE_FAIL = 'uprice/CREATE_MILESTONE_FAIL';

export const DELETE_MILESTONE = 'uprince/DELETE_MILESTONE';
export const DELETE_MILESTONE_SUCCESS = 'uprince/DELETE_MILESTONE_SUCCESS';
export const DELETE_MILESTONE_FAIL = 'uprice/DELETE_MILESTONE_FAIL';

export const DELETE_TECH_INSTRUCTION = 'uprince/DELETE_TECH_INSTRUCTION';
export const DELETE_TECH_INSTRUCTION_SUCCESS =
  'uprince/DELETE_TECH_INSTRUCTION_SUCCESS';
export const DELETE_TECH_INSTRUCTION_FAIL =
  'uprice/DELETE_TECH_INSTRUCTION_FAIL';

export const DELETE_PBS = 'uprince/DELETE_PBS';
export const DELETE_PBS_SUCCESS = 'uprince/DELETE_PBS_SUCCESS';
export const DELETE_PBS_FAIL = 'uprice/DELETE_PBS_FAIL';

export const READ_PBS_RELATIONS = 'uprince/READ_PBS_RELATIONS';
export const READ_PBS_RELATIONS_SUCCESS = 'uprince/READ_PBS_RELATIONS_SUCCESS';
export const READ_PBS_RELATIONS_FAIL = 'uprice/READ_PBS_RELATIONS_FAIL';

export const READ_CPC_RELATIONS = 'uprince/READ_CPC_RELATIONS';
export const READ_CPC_RELATIONS_SUCCESS = 'uprince/READ_CPC_RELATIONS_SUCCESS';
export const READ_CPC_RELATIONS_FAIL = 'uprice/READ_CPC_RELATIONS_FAIL';

export const RESET_FORM_DATA = 'uprice/RESET_PBS';

export const CREATE_SAFTY_INSTRUCTION = 'uprince/CREATE_SAFTY_INSTRUCTION';
export const CREATE_SAFTY_INSTRUCTION_SUCCESS =
  'uprince/CREATE_SAFTY_INSTRUCTION_SUCCESS';
export const CREATE_SAFTY_INSTRUCTION_FAIL =
  'uprice/CREATE_SAFTY_INSTRUCTION_FAIL';

export const LOAD_SAFTY_INSTRUCTION = 'uprince/LOAD_SAFTY_INSTRUCTION';
export const LOAD_SAFTY_INSTRUCTION_SUCCESS =
  'uprince/LOAD_SAFTY_INSTRUCTION_SUCCESS';
export const LOAD_SAFTY_INSTRUCTION_FAIL = 'uprice/LOAD_SAFTY_INSTRUCTION_FAIL';

export const DELETE_SAFTY_INSTRUCTION = 'uprince/DELETE_SAFTY_INSTRUCTION';
export const DELETE_SAFTY_INSTRUCTION_SUCCESS =
  'uprince/DELETE_SAFTY_INSTRUCTION_SUCCESS';
export const DELETE_SAFTY_INSTRUCTION_FAIL =
  'uprice/DELETE_SAFTY_INSTRUCTION_FAIL';

export const CREATE_ENV_INSTRUCTION = 'uprince/CREATE_ENV_INSTRUCTION';
export const CREATE_ENV_INSTRUCTION_SUCCESS =
  'uprince/CREATE_ENV_INSTRUCTION_SUCCESS';
export const CREATE_ENV_INSTRUCTION_FAIL = 'uprice/CREATE_ENV_INSTRUCTION_FAIL';

export const LOAD_ENV_INSTRUCTION = 'uprince/LOAD_ENV_INSTRUCTION';
export const LOAD_ENV_INSTRUCTION_SUCCESS =
  'uprince/LOAD_ENV_INSTRUCTION_SUCCESS';
export const LOAD_ENV_INSTRUCTION_FAIL = 'uprice/LOAD_ENV_INSTRUCTION_FAIL';

export const DELETE_ENV_INSTRUCTION = 'uprince/DELETE_ENV_INSTRUCTION';
export const DELETE_ENV_INSTRUCTION_SUCCESS =
  'uprince/DELETE_ENV_INSTRUCTION_SUCCESS';
export const DELETE_ENV_INSTRUCTION_FAIL = 'uprice/DELETE_ENV_INSTRUCTION_FAIL';

export const CREATE_HELTH_INSTRUCTION = 'uprince/CREATE_HELTH_INSTRUCTION';
export const CREATE_HELTH_INSTRUCTION_SUCCESS =
  'uprince/CREATE_HELTH_INSTRUCTION_SUCCESS';
export const CREATE_HELTH_INSTRUCTION_FAIL =
  'uprice/CREATE_HELTH_INSTRUCTION_FAIL';

export const LOAD_HELTH_INSTRUCTION = 'uprince/LOAD_HELTH_INSTRUCTION';
export const LOAD_HELTH_INSTRUCTION_SUCCESS =
  'uprince/LOAD_HELTH_INSTRUCTION_SUCCESS';
export const LOAD_HELTH_INSTRUCTION_FAIL = 'uprice/LOAD_HELTH_INSTRUCTION_FAIL';

export const DELETE_HELTH_INSTRUCTION = 'uprince/DELETE_HELTH_INSTRUCTION';
export const DELETE_HELTH_INSTRUCTION_SUCCESS =
  'uprince/DELETE_HELTH_INSTRUCTION_SUCCESS';
export const DELETE_HELTH_INSTRUCTION_FAIL =
  'uprice/DELETE_HELTH_INSTRUCTION_FAIL';

export const READ_TAXONOMY_LEVEL = 'uprince/READ_TAXONOMY_LEVEL';
export const READ_TAXONOMY_LEVEL_SUCCESS =
  'uprince/READ_TAXONOMY_LEVEL_SUCCESS';
export const READ_TAXONOMY_LEVEL_FAIL = 'uprice/READ_TAXONOMY_LEVEL_FAIL';

export const CREATE_TAXONOMY_NODE = 'uprince/CREATE_TAXONOMY_NODE';
export const CREATE_TAXONOMY_NODE_SUCCESS =
  'uprince/CREATE_TAXONOMY_NODE_SUCCESS';
export const CREATE_TAXONOMY_NODE_FAIL = 'uprice/CREATE_TAXONOMY_NODE_FAIL';

export const CLONE_PBS = 'uprince/CLONE_PBS';
export const CLONE_PBS_SUCCESS = 'uprince/CLONE_PBS_SUCCESS';
export const CLONE_PBS_FAIL = 'uprice/CLONE_PBS_FAIL';

export const CREATE_PBS_SERVICE = 'uprince/CREATE_PBS_SERVICE';
export const CREATE_PBS_SERVICE_SUCCESS = 'uprince/CREATE_PBS_SERVICE_SUCCESS';
export const CREATE_PBS_SERVICE_FAIL = 'uprice/CREATE_PBS_SERVICE_FAIL';

export const READ_PBS_SERVICE = 'uprince/READ_PBS_SERVICE';
export const READ_PBS_SERVICE_SUCCESS = 'uprince/READ_PBS_SERVICE_SUCCESS';
export const READ_PBS_SERVICE_FAIL = 'uprice/READ_PBS_SERVICE_FAIL';

export const SAVE_PBS_SCOPE_OF_WORK = 'uprince/SAVE_PBS_SCOPE_OF_WORK';
export const SAVE_PBS_SCOPE_OF_WORK_SUCCESS =
  'uprince/SAVE_PBS_SCOPE_OF_WORK_SUCCESS';
export const SAVE_PBS_SCOPE_OF_WORK_FAIL = 'uprice/SAVE_PBS_SCOPE_OF_WORK_FAIL';

export const READ_PBS_LABOUR = 'uprince/READ_PBS_LABOUR';
export const READ_PBS_LABOUR_SUCCESS = 'uprince/READ_PBS_LABOUR_SUCCESS';
export const READ_PBS_LABOUR_FAIL = 'uprice/READ_PBS_LABOUR_FAIL';

export const READ_PBS_VP_RESOURCES = 'uprince/READ_PBS_VP_RESOURCES';
export const READ_PBS_VP_RESOURCES_SUCCESS =
  'uprince/READ_PBS_VP_RESOURCES_SUCCESS';
export const READ_PBS_VP_RESOURCES_FAIL = 'uprice/READ_PBS_VP_RESOURCES_FAIL';

export const CREATE_PMOL_WITH_RESOURCE = 'uprince/CREATE_PMOL_WITH_RESOURCE';
export const CREATE_PMOL_WITH_RESOURCE_SUCCESS =
  'uprince/CREATE_PMOL_WITH_RESOURCE_SUCCESS';
export const CREATE_PMOL_WITH_RESOURCE_FAIL =
  'uprice/CREATE_PMOL_WITH_RESOURCE_FAIL';

export const SAVE_PBS_STATE_ATTR = 'uprice/SAVE_PBS_STATE_ATTR';

export const READ_CBC_CONTRACTOR = 'uprince/READ_CBC_CONTRACTOR';
export const READ_CBC_CONTRACTOR_SUCCESS =
  'uprince/READ_CBC_CONTRACTOR_SUCCESS';
export const READ_CBC_CONTRACTOR_FAIL = 'uprice/READ_CBC_CONTRACTOR_FAIL';

export const SAVE_CBC_TO_PBS = 'uprince/SAVE_CBC_TO_PBS';
export const SAVE_CBC_TO_PBS_SUCCESS = 'uprince/SAVE_CBC_TO_PBS_SUCCESS';
export const SAVE_CBC_TO_PBS_FAIL = 'uprice/SAVE_CBC_TO_PBS_FAIL';

export const REMOVE_CBC_TO_PBS = 'uprince/REMOVE_CBC_TO_PBS';
export const REMOVE_CBC_TO_PBS_SUCCESS = 'uprince/REMOVE_CBC_TO_PBS_SUCCESS';
export const REMOVE_CBC_TO_PBS_FAIL = 'uprice/REMOVE_CBC_TO_PBS_FAIL';

export const READ_CBC_TO_PBS = 'uprince/SAVE_CBC_TO_PBS';
export const READ_CBC_TO_PBS_SUCCESS = 'uprince/SAVE_CBC_TO_PBS_SUCCESS';
export const READ_CBC_TO_PBS_FAIL = 'uprice/SAVE_CBC_TO_PBS_FAIL';

export const READ_DELIVERABLES_TO_PBS = 'uprince/READ_DELIVERABLES_TO_PBS';
export const READ_DELIVERABLES_TO_PBS_SUCCESS =
  'uprince/READ_DELIVERABLES_TO_PBS_SUCCESS';
export const READ_DELIVERABLES_TO_PBS_FAIL =
  'uprice/READ_DELIVERABLES_TO_PBS_FAIL';

export const UPDATE_SELECTED_ITEMS = "UPDATE_SELECTED_ITEMS";
