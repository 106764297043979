import { mergeStyles, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import BookMarkPaneComponent from '../../bookMarkPane/bookMarkPane';
import CollapseBookMarkPane from '../../collapseBookMarkPane/bookMarkPane';
import ProgressBar from '../../progressBar/progressBar';
import TitlePane from '../../titlePane/titlePane';
import { BookMarkItem } from '../../../types/template';
import { PMOL_TYPE } from '../../../types/projectMolecule';
import PbsBookMarkPaneComponent from '../../../components/projectVisualPlan/bookMarkPane/pbsBookMarkPane';

const contentStyle = mergeStyles({
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  selectors: {
    '@media(max-width: 1100px)': {
      width: '97.4%',
      height: 'calc(100vh)',
      position: 'absolute',
    },
  },
});

const listStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

const certStyle = mergeStyles({
  flex: '1 1 auto',
  width: '100%',
  height: 'auto',
  paddingBottom: '30px',
  marginLeft: '0',
});

// const PMOLBookMarkPane = require('../../../components/projectMolecule/bookMarkPane/container').default;

const DocumentPaneSection = (props: {
  collapseBookmarkPane: boolean;
  showProgress: boolean;
  handleScroll: () => void;
  isEdit: boolean;
  bookmarkList: BookMarkItem[];
  currentActiveSection: string;
  children: any;
  renderCommandBar: any;
  formData: any;
  type?: number;
  isPMOL?: boolean;
  isRefresh?: boolean;
  renderAlert?: any;
  isCertification?: boolean;
}) => {
  // const [bookmarkDivClass, setBookmarkDivClass] = useState(
  //   'bookmark-panel parent',
  // );

  const [toggleBookmark, setToggleBookmark] = useState<boolean>(true);

  const _toggleBookmark = (): void => {
    // setBookmarkDivClass('bookmark-panel parent panel-on');
    setToggleBookmark(true);
  };

  const _toggleBookmarkOut = (): void => {
    // setBookmarkDivClass('bookmark-panel parent');
    setToggleBookmark(false);
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Stack
        className="project-list-header right-panel"
        style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
        styles={{ root: { width: '100%', paddingRight: 0 } }}
      >
        <TitlePane
          close={true}
          isRefresh={props.isRefresh}
          headerTitle={props.formData ? props.formData.title : ''}
          collapse={true}
          isCollapse={props.collapseBookmarkPane}
          type={props.type}
        />
        {props.renderCommandBar()}
        <ProgressBar show={props.showProgress} />
        {props?.renderAlert ? props?.renderAlert() : false}
      </Stack>

      <div
        style={{
          height: 'calc(100% - 71px)',
          backgroundColor: 'white',
          display: 'flex',
        }}
      >
        <div
          style={{
            width: toggleBookmark ? 'calc(100% - 220px)' : 'calc(100% - 28px)',
            height: '100%',
          }}
        >
          <Stack
            className={`project-detail-list ${
              props.collapseBookmarkPane && screen.width > 1440
                ? 'full-inner-container'
                : 'inner-container'
            } cpc card-container`}
            style={{
              width: '100%',
              height: '100%',
              overflow: 'auto',
              padding: '0 0 1rem 1rem',
              minHeight: 'unset !important',
            }}
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
          >
            {/* doc*/}
            <Stack
              className={contentStyle}
              horizontalAlign="start"
              verticalAlign="start"
              styles={{
                root: {
                  width: '100%',
                  height: '100%',
                },
              }}
              verticalFill
            >
              <Stack
                className={props.isCertification ? certStyle : listStyle}
                styles={{
                  root: {
                    width: '100%',
                    height: 'auto',
                  },
                }}
                verticalFill
                gap={50}
                horizontalAlign="start"
                verticalAlign="start"
                // onScroll={ props.handleScroll }
                onScroll={() => {}}
              >
                {props.children}
              </Stack>
            </Stack>
          </Stack>
        </div>
        <PbsBookMarkPaneComponent
          isEdit={props.isEdit}
          // divClass={bookmarkDivClass}
          currentActiveSection={props.currentActiveSection}
          list={props.bookmarkList}
          toggleBookmark={_toggleBookmark}
          toggleBookmarkOut={_toggleBookmarkOut}
          isShowBookmark={toggleBookmark}
        />
      </div>
    </div>
  );
};

export default DocumentPaneSection;
