import React, { Component } from 'react';
import Brand from '../shared/brand/brand';
// import ProjectBar from '../shared/projectBar/projectBar';
import NavBar from '../shared/navBar/navBar';
import ProjectMainLayout from './cuProjects/projectMainLayout';
import history from '../../../src/app/history';
import ProjectBarComponent from '../shared/projectBar/ProjectBarComponent';

export interface State {
  redirect: boolean
}

export default class Project extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = { redirect: false };
  }

  onIconClick = () => {
    if (this.props.contractingUnit) {
      history.push(`/CU/${this.props.contractingUnit.title}`);
    }
  };

  render() {
    return (
      <div>
        <Brand/>
        {/* <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/> */}
        <ProjectBarComponent contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        <NavBar selectedKey={0} currentUser={this.props.currentUser}/>
        <ProjectMainLayout/>
      </div>
    );
  }
}
