import React, { Component } from 'react';
import { connect } from 'react-redux';
import Brand from '../../shared/brand/brand';
// import ProjectBar from '../../shared/projectBar/projectBar';
import ProjectNavBar from '../../shared/navBar/projectNavBar';
import PsMainLayout from './psMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';
import CUNavBar from '../../shared/navBar/cuNavBar';
import ProjectBarComponent from '../../shared/projectBar/ProjectBarComponent';


interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

export class ProgressStatement extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isContractUnit: getContractingUnit() ? true : false,
      isProject: getProject() ? true : false,
    };
  }


  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        {/* <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/> */}
        <ProjectBarComponent contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        {this.state.isProject ?
          <ProjectNavBar selectedKey={`/CU/${getContractingUnit()}/project/${getProject()}/progress-statement`}
                         currentUser={this.props.currentUser}/>
          :
          <CUNavBar selectedKey={`/CU/${getContractingUnit()}/progress-statement`}
                    contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}
                    currentUser={this.props.currentUser}/>
        }
        <PsMainLayout/>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressStatement);
