import React, { Component } from 'react';
import Brand from '../../brand/brand';
import NavBar from '../../navBar/navBar';
// import ProjectBar from '../../projectBar/projectBar';
import ProjectBarComponent from '../../projectBar/ProjectBarComponent';

interface Props {
  currentUser: any;
  children: any;
  selectedNavigationIndex: string
}

interface State {
  isContractUnit: boolean;
  isProject: boolean;
}

class UprinceOrganizationNavigation extends Component<Props, State> {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        {/* <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/> */}
        <ProjectBarComponent contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        <NavBar selectedKey={this.props.selectedNavigationIndex} currentUser={this.props.currentUser}/>
        {
          this.props.children
        }
      </div>
    );
  }
}

export default UprinceOrganizationNavigation;
