import React, {useEffect, useState} from 'react';
import SplitPane, {Pane} from 'react-split-pane';
import {DocumentPaneType} from '../../types/dayPlanning';
import {mergeStyleSets, Panel, PanelType, Stack} from '@fluentui/react';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import {useTranslation} from 'react-i18next';
import TitlePane from '../../shared/titlePane/titlePane';
import CommandBarLeft from './commandBar/commandBarLeft';
import ProgressBar from '../../shared/progressBar/progressBar';
import {useDispatch, useSelector, useStore} from 'react-redux';
import TopListPane from './listPane/topListPane/topListPane';
import {saveProjectDayPlanningStateAttr} from '../../reducers/projectDayPlanningReducer';
import DocumentPane from './documentPane/documentPane';
import {messageService} from '../../services/messageService';
import BottomShortcutPane from './shortCutPane/bottomShortcutPane';
import BottomListPane from './listPane/bottomListPane';
import {DpViewMode} from '../../types/myDayPlanning';
import {readPMOLDropDownData} from "../../reducers/pmolReducer";
import {getContractingUnits} from '../../reducers/cuProjectReducer';
import {saveMyDayPlanningStateAttr} from "../../reducers/myDayPlanningReducer";
import PlanBoardShortcutPane from "./shortCutPane/planboardShortcutPane";

const classnames = mergeStyleSets({
    myDayPlanContainer: {
      position:'relative', 
      height: '100%', 
      overflow: 'auto', 
      width: '100%'
    }
})

const ProjectDayPlanningMainLayout = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [totalScreenHeight, setTotalScreenHeight] = useState(window.innerHeight - 50 - 45 - 40)
    const [bottomPaneHeight, setBottomPaneHeight] = useState<number>(120)
    const [totalScreenWidth, setTotalScreenWidth] = useState<number>(window.innerWidth)
    const [leftPanelHeight, setLeftPanelHeight] = useState<number>()
    const [rightPanelWidth, setRightPanelWidth] = useState<number>()
    const [showPanel, setShowPanel]: any = useState(false);
    const [showDetails, setShowDetails]: any = useState(false);
    const [topReSizer, setTopReSizer]: any = useState(screen.width);
    const [bottomReSizer, setBottomReSizer]: any = useState(screen.width);
    const [documentPaneType, setDocumentPaneType]: any = useState("");
    const [isExpand, setIsExpand] = useState(false);
    const [isMinimize, setIsMinimize] = useState(true);

    const {
        showProgressBar,
        // horizontalReSizer,
        selectedPmol,
        // collapseBookmarkPane,
        isShortCutPaneCollapse
    } = useSelector(
        (state: any) => state.projectDPlan
    );

    useEffect(() => {
        dispatch(saveProjectDayPlanningStateAttr('isShortCutPaneCollapse', true));
        dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', screen.height - 330));
        dispatch(readPMOLDropDownData());
        dispatch(getContractingUnits());
        dispatch(saveMyDayPlanningStateAttr('selectedDate', new Date()));
        window.addEventListener('resize', handleResize);
        window.addEventListener('resizeWidth', handleResizeWidth);

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.hideDocumentPane) {

                    if (data.data.documentPaneType === 1) {
                        _hideDocPane(true)
                    } else {
                        _hideDocPane(false)
                    }
                }

                if (data.data.collapseDocumentPane) {
                    setIsExpand((prevCheck) => !prevCheck);
                }

                if (data.data.minimizeBottomPane) {
                    dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', data.data.isMinimize ? screen.height - 330 : screen.height / 2 - 140));
                    setIsMinimize(data.data.isMinimize);
                    if (data.data.isMinimize) {
                        setBottomPaneHeight(120)
                      } else {
                        setBottomPaneHeight(310)
                      }
                }
            }
        });

        return () => {
            subscription.unsubscribe();
            dispatch(saveProjectDayPlanningStateAttr('selectedPmol', null));
            const projectPlanboardTimeZoom = localStorage.getItem('projectPlanboardTimeZoom');
            dispatch(saveProjectDayPlanningStateAttr('projectDpViewMode', projectPlanboardTimeZoom && projectPlanboardTimeZoom!='' ? parseFloat(projectPlanboardTimeZoom) : DpViewMode.QuarterHour));
            dispatch(saveProjectDayPlanningStateAttr('isShortCutPaneCollapse', true));
            setTopReSizer(screen.width);
            window.removeEventListener('resize', handleResize);
            window.addEventListener('resizeWidth', handleResizeWidth);
        }
    }, []);


    useEffect(() => {
        if (selectedPmol) {
            setShowDetails(true);
            setDocumentPaneType(DocumentPaneType.PMOL);
            setTopReSizer(screen.width / 2)
        }
    }, [selectedPmol]);

    useEffect(() => {
        if (isExpand) {
            if (showDetails) {
                dispatch(saveProjectDayPlanningStateAttr('collapseBookmarkPane', true));
                setTopReSizer(screen.width / 2);
            }
        } else {
            if (showDetails) {
                dispatch(saveProjectDayPlanningStateAttr('collapseBookmarkPane', false));
                setTopReSizer(300);
            }
        }
    }, [isExpand])

    const handleResize = () => {
        // window.innerHeight - CUNavBarHeight - ProjectWayPointSectionHeight - Project Level bar Height
        setTotalScreenHeight(window.innerHeight - 50 - 45 - 40) // Recalculate the height based on the new window height
    }

    const handleResizeWidth = () => {
        setTotalScreenWidth(window.innerWidth)
    }

    const _hidePanel = () => {
        setShowPanel(false)
    };
    const _hideDocPane = (isTop?: boolean) => {
        if (isTop) {
            setTopReSizer(screen.width)
        } else {
            setBottomReSizer(screen.width)
        }
        setShowDetails(false);
        setDocumentPaneType(null);
        setTopReSizer(screen.width);
        dispatch(saveProjectDayPlanningStateAttr('selectedPmol', null))
    };

    const getDocumentPaneView = (isTopPane?: boolean) => {
        if (isTopPane) {
            return <div style={{height: '100%'}}>
                <DocumentPane />
            </div>
        } else {
            return <div> &nbsp; </div>
        }

    };

    const renderShortCutPane = (isFirst: boolean, isTopPane?: boolean) => {
        if (isTopPane) {
            return <div style={{ height: '100%' }}>
                <PlanBoardShortcutPane type={"project"}/>
            </div>
        } else {
            return <div style={{ height: '100%' }}><BottomShortcutPane/></div>
        }

    };

    const getListPaneView = (isTopPane?: boolean) => {
        if (isTopPane) {
            return <div style={{width: '100%', height: '100%'}}>
                <TitlePane
                    headerTitle={"All"}
                    type={1}
                    isFullScreen={true}
                />
                <div style={{
                    width: '100%',
                    height: 'calc(100% - 25px)',
                }}>
                    <Stack horizontal style={{height: '100%'}}>
                        {renderShortCutPane(true, true)}
                        <div style={{width: isShortCutPaneCollapse ? 'calc(100% - 32px)' : 'calc(100% - 255px)', height: '100%'}}>
                            <Stack
                                className="project-list-header right-panel ppb"
                                style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingRight: 0,
                                    // position: 'absolute',
                                    height: 25,
                                }}
                                styles={{root: {width: '100%', paddingRight: 0}}}
                            >
                                <CommandBarLeft disable={false} isTopPane={true}/>
                                <ProgressBar show={showProgressBar}/>
                            </Stack>

                            <Stack style={{alignSelf: 'center', width: '100%', height: 'calc(100% - 44px)'}}>
                                <TopListPane/>
                            </Stack>
                        </div>
                    </Stack>
                </div>
            </div>
        } else {
            return <div style={{width: '100%', height: '100%'}}>
                <TitlePane
                    headerTitle={"All"}
                    type={1}
                    minimize={true}
                    isMinimize={isMinimize}
                />
                <div style={{width: '100%', height: '100%'}}>
                    <Stack horizontal style={{height: '100%'}}>
                        {renderShortCutPane(false)}

                        <div style={{width: '100%', height: '100%'}}>
                            <Stack
                                className="project-list-header right-panel"
                                style={{
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    paddingRight: 0,
                                    height: 25
                                }}
                                styles={{root: {width: '100%', paddingRight: 0}}}
                            >
                                <CommandBarLeft disable={false} isTopPane={false}/>
                                <ProgressBar show={showProgressBar}/>
                            </Stack>

                            <div style={{height: 'calc(100% - 72px)'}}>
                                <BottomListPane />
                            </div>
                        </div>
                    </Stack>
                </div>
            </div>
        }
    };

    return (
        <div className={classnames.myDayPlanContainer}>
            <SplitPane
                onChange={(size: any) => {
                    const topPane = size
                    const bottomPaneSize = totalScreenHeight - topPane
                    setBottomPaneHeight(bottomPaneSize)
                    dispatch(saveProjectDayPlanningStateAttr('horizontalReSizer', size));
                }}
                maxSize={'100%'}
                minSize={40}
                // style={{position: 'inherit'}}
                split="horizontal"
                pane1Style={{
                    height: totalScreenHeight - bottomPaneHeight
                }}
                pane2Style={{
                    height: bottomPaneHeight,
                }}
                size={'100%'}
            >
                <div style={{width: '100%', height: '100%'}}>
                    <SplitPane className={'SplitPane zIndex999'}
                        minSize={(totalScreenWidth / 3)}
                        maxSize={(totalScreenWidth / 3) * 2}
                        defaultSize={
                            showDetails && documentPaneType === DocumentPaneType.PMOL ? totalScreenWidth / 2 : totalScreenWidth
                        }
                        split="vertical"
                        onChange={(size: any) => {
                            setTopReSizer(size)
                        }}
                        size={topReSizer}
                        style={{
                            
                        }}
                    >
                        <Pane className={''} style={{width: '100%', height: '100%', overflow: 'hidden'}}>{getListPaneView(true)} </Pane>
                        <Pane className={''} style={{
                            width: '100%',
                            height: '100%',
                            zIndex: 10
                        }}> {documentPaneType === DocumentPaneType.PMOL && getDocumentPaneView(true)}</Pane>
                    </SplitPane>
                    <div></div>
                </div>
                <div style={{width: '100%', height: '100%'}}>
                    <div id={"bottomPane"} style={{height: '100%'}}>
                        <SplitPane
                            className={'SplitPane'}
                            minSize={(screen.width / 3)}
                            maxSize={(screen.width / 3) * 2}
                            defaultSize={
                                showDetails && (documentPaneType === DocumentPaneType.CU_HR || documentPaneType === DocumentPaneType.CPC) ? screen.width / 2 : screen.width
                            }
                            split="vertical"
                            onChange={(size: any) => {
                                setBottomReSizer(size)
                            }}
                            size={'100%'}
                        >
                            <Pane className={''} style={{width: '100%', height: '100%'}}>{getListPaneView()}</Pane>
                            <Pane className={''} style={{width: '100%', height: '100%'}}>{getDocumentPaneView()}</Pane>
                        </SplitPane>
                    </div>
                </div>
            </SplitPane>
            <Panel
                isOpen={showPanel}
                type={PanelType.smallFixedFar}
                onDismiss={_hidePanel}
                isLightDismiss={true}
                headerText={t('corporateProductCatalogue')}
                className="custom-detail-panel"
                closeButtonAriaLabel={t('close')}
            >
                {getDocumentPaneView()}
            </Panel>

            <ConfirmationDialog
                hidden={true}
                title={t('areyousure')}
                subText={t('youwonTbeabletorevertthis')}
                onClickConfirmDialog={() => {
                }}
                onClickCancelDialog={() => {
                }}
                cancelButtonText={t('noIwonT')}
                confirmButtonText={t('yesDeleteit')}
            />
        </div>
    )
};


export default ProjectDayPlanningMainLayout
