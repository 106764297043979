import { getTheme, ITheme, Panel, PanelType } from '@fluentui/react';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SplitPane, { Pane } from 'react-split-pane';
import history from '../../history';
import {
  filterCorporateScheduleListPaneData,
  readByCorporateScheduleId,
} from '../../reducers/corporateScheduleReducer';
import {
  clearFormData,
  filterHumanResourceListPaneData,
  readByHumanResourceId,
  readHumanResourceDropDownData,
} from '../../reducers/humanResourceReducer';
import { messageService } from '../../services/messageService';
import ConfirmationDialog from '../../shared/confirmationDialog/confirmationDialog';
import DocumentPaneSection from '../../shared/mainUI/documentPaneSection/documentPaneSection';
import ListPaneSection from '../../shared/mainUI/listPaneSection/component';
import withMainUI from '../../shared/mainUI/withMainUI';
import { getContractingUnit } from '../../shared/util';
import { CorporateScheduleListPaneFilter } from '../../types/corporateSchedule';
import {
  formData,
  HumanResource,
  HumanResourceListItem,
  HumanResourceListPaneFilter,
  HumanResourceShortCutPaneItem,
  WorkSchedule,
} from '../../types/humanResource';
import { BookMarkItem } from '../../types/template';
import '../style/style.css';
import ShortCutPaneComponent from '../timeClock/shortcutPane/component';
import CommandBarLeft from './commandBar/commandBarLeft';
import CommandBarRight from './commandBar/commandBarRight';
import DocumentPane from './documentPane/container';
import ListPane from './listPane/container';
import { resizerStyle } from '../../shared/customStyle/commonStyles';
import AddCustomModal from '../projectVisualPlan/addCustomModal';

type ImportType = 'syndi' | 'vicky' | 'fluvius' | 'brutélé';
interface OptionSubject<T> {
  key?: string;
  text?: string;
  data?: {value:T};
}
export interface State {
  selectedShortCutItem: HumanResourceShortCutPaneItem;
  filter: HumanResourceListPaneFilter;
  formData: HumanResource;
  loading: boolean;
  showPanel: boolean;
  width: number;
  activeClass: string;
  bookmarkDivClass: string;
  showDetails: boolean;
  autoSave: boolean;
  isEdit: boolean;
  isDisableNewButton: boolean;
  isDrag: boolean;
  resetLayout: boolean;
  collapseBookmarkPane: boolean;
  reSizer: number;
  projectId: null | string;
  coporateScheduleTime: WorkSchedule[];

  filterByWorkers: string | null;
  resetFilter: boolean;
  filterShiftDay: number | string | Date | null;
  filterStartDateTime: string | Date | null;
  filterEndDateTime: string | Date | null;
  filterEmployee: string | null;
  filterTimeClockState: string | null;
  sortingOrder: string | null;
  sortingAttribute: string | null;
  showTimeClockDetails: boolean;
  certifications: boolean;
  selectedCertificateFromListPane: any;
  //
  isCertificationSaved: boolean;
  workerModal:boolean;
  option:OptionSubject<ImportType>
}

interface Props extends WithTranslation {
  match: any;
  resetFormData: () => void;
  showProgressBar: boolean;
  shortCutPaneFilters: HumanResourceShortCutPaneItem[];
  formData: HumanResource;
  setUid: () => void;
  clearUid: () => void;
  resetIsChange: () => void;
  uid: string | null;
  autoSave: boolean;
  isChange: boolean;
  isEdit: boolean;
  isDataLoaded?: boolean;
  loadMsg: string;
  location: any;
  loading: boolean;
  handleShowDocumentPane: (collapseBookmarkPane?: boolean) => void;
  reSizer: number;
  showDetails: boolean;
  showPanel: boolean;
  handleScroll: () => void;
  currentActiveSection: string;
  animation: any;
  readHumanResourceShortCutPaneData: () => void;
  filterHumanResourceListPaneData: (filter: HumanResourceListPaneFilter) => void;
  readHumanResourceDropDownData: () => void;
  readByHumanResourceId: (id: string) => void;
  clearFormData: () => void;
  shortcutPaneData: HumanResourceShortCutPaneItem[];
  hrList: HumanResourceListItem[];
  isHRListLoaded: boolean;
  hrDropDowns: any;
  reloadListPane: boolean;
  collapseBookmarkPane: boolean;
  filterCorporateScheduleListPaneData: (filter: CorporateScheduleListPaneFilter) => any;
  readByCorporateScheduleId: (id: string) => any;
}

class HumanResourceMainLayout extends React.Component<Props, State> {
  _isMounted = false;
  theme: ITheme = getTheme();
  subscription: any;

  bookmarkList: BookMarkItem[] = [
    {
      id: 1,
      link: 'labour',
      label: this.props.t('labour'),
    },
    {
      id: 2,
      link: 'competencies',
      label: this.props.t('competencies'),
    },
    {
      id: 3,
      link: 'certifications',
      label: this.props.t('certifications'),
    },
    {
      id: 4,
      link: 'labourHistory',
      label: this.props.t('labourHistory'),
    },
    {
      id: 5,
      link: 'workSchedule',
      label: this.props.t('workSchedule'),
    },
    {
      id: 6,
      link: 'history',
      label: this.props.t('history'),
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: new HumanResourceListPaneFilter(),
      selectedShortCutItem: {
        key: null,
        id: null,
        name: null,
        type: null,
        value: null,
      },
      isEdit: false,
      autoSave: false,
      loading: false,
      width: window.innerWidth,
      showPanel: false,
      activeClass: 'grid-righter column-filter-activated',
      bookmarkDivClass: 'bookmark-panel parent',
      showDetails: false,
      formData: formData,
      isDisableNewButton: false,
      isDrag: false,
      option:{
        key:'0',
        data:{
          value:'syndi'
        }
      },
      resetLayout: false,
      collapseBookmarkPane: false,
      reSizer: screen.width,
      projectId: null,
      coporateScheduleTime: [],
      filterByWorkers: null,
      resetFilter: false,
      filterShiftDay: 0,
      filterStartDateTime: null,
      filterEndDateTime: null,
      filterEmployee: null,
      filterTimeClockState: null,
      sortingAttribute: null,
      sortingOrder: null,
      showTimeClockDetails: false,
      certifications: false,
      //
      selectedCertificateFromListPane: [],
      isCertificationSaved: false,
      workerModal:false,

    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const certificationsParam = queryParams.get('certifications');
    const certifications = certificationsParam === 'true';
    this.setState({ certifications });
    
    this.props.filterCorporateScheduleListPaneData(new CorporateScheduleListPaneFilter()).then((response: any) => {
      this.props.readByCorporateScheduleId(response.payload.data.result[0]?.sequenceId).then((response: any) => {
        this.setState({
          coporateScheduleTime: response?.payload?.data?.result?.corporateSheduleTime,
        });
      });
    });

    this.handleUrlChange();
    this.props.filterHumanResourceListPaneData(new HumanResourceListPaneFilter());

    this.setState(
      {
        formData: this.props.formData,
      },
      this.props.handleShowDocumentPane,
    );
    
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if(data?.data?.onAdd){
        this.setState({workerModal:true})
      }
      if (data) {
        if (data.data.hideDocumentPane) {
          this.hideDocumentPane();
        }
        if (data.data.isNewHR) {
          this.createNew();
        }
        if (
          data &&
          data.data &&
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: true });
        } else if (
          data.data.hasOwnProperty('isLoadingPMOLData') &&
          !data.data.isLoadingPMOLData
        ) {
          this.setState({ isDisableNewButton: false });
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // if (this.props.reloadListPane !== prevProps.reloadListPane && this.props.reloadListPane) {
    //   this.reloadListPaneItem();
    // }
    if (this.props.match.url !== prevProps.match.url) {
      this.handleUrlChange(prevState.collapseBookmarkPane);
    }
    if (this.props.formData !== prevProps.formData) {
      this.setState({
        formData: this.props.formData,
      });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state?.hrFilter !== prevProps.location.state?.hrFilter
    ) {
      if (this.props.location.state.hrFilter) {
        const status = this.props.location.state.hrFilter;

        if (status === 'active') {
          this.setState(
            {
              filter: { ...this.state.filter, active: true, nonActive: false },
            },
            this.reloadListPaneItem,
          );
        } else if (status === 'nonActive') {
          this.setState(
            {
              filter: { ...this.state.filter, active: false, nonActive: true },
            },
            this.reloadListPaneItem,
          );
        } else {
          this.setState(
            {
              filter: { ...this.state.filter, active: false, nonActive: false },
            },
            this.reloadListPaneItem,
          );
        }
      }
    }
    if (this.props.collapseBookmarkPane !== prevProps.collapseBookmarkPane) {
      this.setState({
        collapseBookmarkPane: this.props.collapseBookmarkPane,
      });
    }
  }

  handleUrlChange(collapseBookmarkPane?: boolean) {
    let id = this.props.match.params.featureId ? this.props.match.params.featureId : null;
    let isNew = location.pathname.split('/').pop() === 'new';

    if (id && id !== 'new') {
      this.setState(
        {
          isEdit: !!this.props.match.params.featureId,
          isDisableNewButton: !!id,
        }, () => {
          this.props.handleShowDocumentPane(collapseBookmarkPane);
        });
      this.props.readByHumanResourceId(id);
    } else {
      this.setState(
        {
          isEdit: false,
          isDisableNewButton: false,
        }, () => {
          this.props.handleShowDocumentPane();
        });
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  createNew = (): void => {
    this.props.clearFormData();
    let formData: any = {
      id: null,
      sequenceId: null,
      title: null,
      name: null,
      personId: null,
      azureOid: null,
      organizationTaxonomyId: null,
      personName: null,
      role: null,
    };
    this.setState({
      formData: formData,
    });
    history.push(`/CU/${getContractingUnit()}/human-resource/new`);
  };

  reloadListPaneItem = () => {
    setTimeout(() => {
      this.props.filterHumanResourceListPaneData(this.state.filter);
    }, 100);
  };

  renderShortCutPane = () => {
    return (
      <ShortCutPaneComponent />
    );
  };

  _isCertSavedTrue = () => {
    this.setState({
      isCertificationSaved: true
    })
  }

  getDocumentPaneView = (isCertification: boolean) => {
    return (
      <DocumentPaneSection
        collapseBookmarkPane={this.state.collapseBookmarkPane}
        showProgress={this.props.showProgressBar}
        handleScroll={this.props.handleScroll}
        isEdit={this.props.isEdit}
        formData={this.state.formData}
        bookmarkList={this.bookmarkList}
        currentActiveSection={this.props.currentActiveSection}
        renderCommandBar={() => <CommandBarRight />}
        isCertification={isCertification}
      >
        <DocumentPane
          formData={this.props.formData}
          coporateScheduleTime={this.state.coporateScheduleTime}
          selectedCertificateFromListPane={this.state.selectedCertificateFromListPane}
          triggerCertificationSavedToTrue={this._isCertSavedTrue}
          isCertification={isCertification}
        />
      </DocumentPaneSection>
    );
  };

  _selectedCertificateFromListPane = (selected: any) => {
    this.setState({
      selectedCertificateFromListPane: selected
    })
  }

  _isCertSavedFalse = () => {
    this.setState({
      isCertificationSaved: false
    })
  }

  private getListPaneView = (isCertification: boolean) => {
    return (
      <ListPaneSection
        activeClass={this.state.activeClass}
        titlePaneHeader={
          (this.state.selectedShortCutItem && this.state.selectedShortCutItem.name)
            ? this.state.selectedShortCutItem.name!!
            : 'All'
        }
        showProgress={this.props.showProgressBar}
        renderCommandBar={() => <CommandBarLeft disable={false} />}
        renderShotcutPane={() => this.renderShortCutPane()}
        addUser={true}
      >
        <ListPane
          projectId={this.state.projectId}
          loadMsg={this.props.loadMsg}
          reloadListPaneItem={() => this.reloadListPaneItem()}
          filter={this.state.filter}
          listPaneItems={this.props.hrList}
          isDataLoaded={this.props.isHRListLoaded}
          isChange={this.props.isChange}
          formData={this.state.formData}
          handelFilter={(filter: HumanResourceListPaneFilter) =>
            this.handleFilter(filter)
          }
          handleSelectedListPaneItem={(
            selectedListItemIds: [] | string[],
          ) => {
            //this.handleSelectedListPaneItem(selectedListItemIds);
          }}
          reSizer={this.state.reSizer}
          selectedCertificationFromList={(selected: any) => this._selectedCertificateFromListPane(selected)}
          isCertificationSaved={this.state.isCertificationSaved}
          triggerCertificationSavedToFalse={this._isCertSavedFalse}
          isCertification={isCertification}
        />
      </ListPaneSection>
    );
  };

  handleFilter = (filter: HumanResourceListPaneFilter) => {
    if ((filter.title && filter.title.length >= 3) || filter.title === null) {
      this.setState({
        filter: filter,
      }, this.reloadListPaneItem);
    } else {
      this.setState({
        filter: filter,
      });
    }
  };

  handelShortCutPaneFilter = (selectedItem: HumanResourceShortCutPaneItem) => {
    this.setState({
      selectedShortCutItem: selectedItem,
    });
  };

  onProjectModalClose = (type:any) => {
    this.setState({workerModal:false,option:type})
   }

  render() {
    const { t } = this.props;
    const isCertificationModule = localStorage.getItem('selectedFilterMenu') === 'true'

    return (
      <div style={{height: '100%'}}>
        <div style={{height: '100%'}}>
          <SplitPane
            className={'SplitPane hrsplitpane'}
            split='vertical'
            minSize={300}
            maxSize={screen.width / 2}
            defaultSize={
              this.props.showDetails ? screen.width / 2 : screen.width
            }
            resizerStyle={resizerStyle}
            style={{ height: 'calc(100% - 136px)', width: '100%' }}
            size={this.props.reSizer}
            onResizerDoubleClick={() => {
              this.setState({
                reSizer: this.props.reSizer === 300 ? screen.width / 2 : 300,
                isDrag: !this.state.isDrag,
                collapseBookmarkPane: !this.state.collapseBookmarkPane,
                resetLayout: !this.state.resetLayout,
              });
            }}
            onDragFinished={(newSize: number) => {
              this.setState({
                reSizer: newSize,
                collapseBookmarkPane: newSize < screen.width / 2,
              });
            }}
            onChange={(newSize: number) => {
              if (newSize === screen.width / 2) {
                this.setState({
                  // resetLayout: true,
                  collapseBookmarkPane: false,
                });
              }
            }}
            pane2Style={{
              width: '49%'
            }}
          >
            <Pane className={'SplitPane'} style={{height: '100%'}}>{this.getListPaneView(isCertificationModule)}</Pane>
            <Pane className={'SplitPane'} style={{borderLeft: '1px solid #bebebe', zIndex: '1', height: '100%'}}>
              {this.props.showDetails && (
                <div
                  // className={`${this.props.animation(this.props.showDetails)}`}
                  style={{
                    display: this.props.showDetails ? 'block' : 'none',
                    transition: 'all 0.9s linear',
                    height: '100%'
                  }}
                >
                  {!this.props.showPanel && (
                    <div style={{height: '100%'}}>{this.getDocumentPaneView(isCertificationModule)}</div>
                  )}
                </div>
              )}
            </Pane>
          </SplitPane>
        </div>
        <Panel
          isOpen={this.props.showPanel}
          type={PanelType.smallFixedFar}
          onDismiss={this._hidePanel}
          isLightDismiss={true}
          headerText={t('corporateProductCatalogue')}
          className='custom-detail-panel'
          closeButtonAriaLabel={t('close')}
        >
          {this.getDocumentPaneView(isCertificationModule)}
        </Panel>
        {this.state.workerModal ?
        <AddCustomModal  optionType={this.state.option}  from='worker' isOpen={this.state.workerModal} onCloseModel={(type:string) => {this.onProjectModalClose(type) }}></AddCustomModal>
        : <></>}
        <ConfirmationDialog
          hidden={true}
          title={t('areyousure')}
          subText={t('youwonTbeabletorevertthis')}
          onClickConfirmDialog={() => {
          }}
          onClickCancelDialog={() => {
          }}
          cancelButtonText={t('noIwonT')}
          confirmButtonText={t('yesDeleteit')}
        />
      </div>
    );
  }

  private _hidePanel = () => {
    this.setState({ showPanel: false, showDetails: false });
    history.push(`/projects/project/${this.state.projectId}/product/pbs`);
  };

  private hideDocumentPane = () => {
    getContractingUnit() && history.push(`/CU/${getContractingUnit()}/human-resource`);
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    shortcutPaneData: state.hr?.hrShortCutPaneData,
    isHRListLoaded: state.hr.isHRListLoaded,
    hrList: state.hr.hrList,
    hrDropDowns: state.hr.hrDropDowns,
    reloadListPane: state.hr.reloadListPane,
    formData: state.hr.formData,
    isEdit: state.hr.isEdit,
    showProgressBar: state.hr.showProgressBar,
  };
};

const mapDispatchToProps = {
  filterHumanResourceListPaneData,
  readHumanResourceDropDownData,
  readByHumanResourceId,
  clearFormData,
  filterCorporateScheduleListPaneData,
  readByCorporateScheduleId,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(withMainUI((HumanResourceMainLayout))),
  ));
