import * as React from 'react';
import { CommandBarButton, IButtonStyles, Text } from '@fluentui/react';
import {
  ArrowSquareUpRightRegular,
  ArrowSyncCheckmarkRegular,
  ArrowSyncDismissRegular,
} from '@fluentui/react-icons';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../theme';
import { Link, OverflowSet } from 'office-ui-fabric-react';
import { getExternalSyncStatus } from '../../reducers/externalSyncReducer';

export const SyncStatusDropdown: React.FunctionComponent = (props) => {
  const dispatch = useDispatch();

  const { isExternalSyncStatusLoaded, externalSyncStatus } = useSelector(
    (state: any) => state.externalSync
  );

  React.useEffect(() => {
    dispatch(getExternalSyncStatus());
  }, []);

  const onRenderOverflowButton = (
    overflowItems: any[] | undefined
  ): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
      root: {
        minWidth: 0,
        padding: '0 4px',
        alignSelf: 'stretch',
        height: 40,
        width: 40,
        fontSize: 40,
        background: uPrinceTheme.palette.themePrimary,
      },
      menuIcon: {
        fontSize: 25,
        color: '#ffffff',
      },
    };
    return (
      <CommandBarButton
        ariaLabel="More items"
        role="menuitem"
        styles={buttonStyles}
        // menuIconProps={{ iconName: 'Sync' }}
        onRenderMenuIcon={() =>
          externalSyncStatus?.errorsInLastSync +
            externalSyncStatus?.warningsInLastSync >
          0 ? (
            <ArrowSyncDismissRegular className="syncHoverIcon" />
          ) : (
            <ArrowSyncCheckmarkRegular className="syncHoverIcon" />
          )
        }
        menuProps={{ items: overflowItems! }}
      />
    );
  };

  return (
    <OverflowSet
      aria-label="Custom Example"
      role="menubar"
      className="syncIcon"
      overflowItems={[
        {
          key: 'externalSyncStatus',
          name: 'External Sync Status',
          icon: 'MoveToFolder',
          onRender: (
            item: any,
            dismissMenu: (ev?: any, dismissAll?: boolean) => void
          ) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 15,
                }}
              >
                {isExternalSyncStatusLoaded && (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={{ marginTop: 2 }}>
                        Last Sync :{' '}
                        {externalSyncStatus?.lastSync
                          ? moment(externalSyncStatus?.lastSync).format(
                              'YYYY-MM-DD HH:mm'
                            )
                          : '-'}
                      </Text>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={{ marginTop: 5 }}>
                        Errors in Last Sync :{' '}
                        {externalSyncStatus?.errorsInLastSync}
                      </Text>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={{ marginTop: 5 }}>
                        Warnings in Last Sync :{' '}
                        {externalSyncStatus?.warningsInLastSync}
                      </Text>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 10,
                      }}
                    >
                      <Link
                        href={externalSyncStatus?.platformBaseUrl}
                        target="_blank"
                        style={{
                          display: 'flex',
                          gap: 4,
                          alignItems: 'center',
                        }}
                      >
                        <ArrowSquareUpRightRegular fontSize={16} />
                        Visit Platform
                      </Link>
                    </div>
                  </>
                )}
              </div>
            );
          },
        },
      ]}
      onRenderOverflowButton={onRenderOverflowButton}
      onRenderItem={() => {}}
    />
  );
};
