import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PmolDocumentPane from './pmolContainer';
import {mergeStyles, Spinner, SpinnerSize, Stack} from '@fluentui/react';
import TitlePane from '../../../shared/titlePane/titlePane';
import CommandBarRight from '../commandBar/commandBarRight';
import ProgressBar from '../../../shared/progressBar/progressBar';
import {useTranslation} from 'react-i18next';
import {PMOL_TYPE} from '../../../types/projectMolecule';
import PmolBookMarkPaneComponent from '../../projectMolecule/bookMarkPane/pmolBookmarkPane';

const DocumentPane = (props: { horizontalReSizer: any, collapseBookmarkPane: any, isShowDocPane: boolean }) => {
  const { t } = useTranslation();
  const [toggleBookmark, setToggleBookmark] = useState<boolean>(true)

  const [show, setShow] = useState<boolean>(false);

  const contentStyle = mergeStyles({
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    selectors: {
      '@media(max-width: 1100px)': {
        width: '97.4%',
        height: 'calc(100vh)',
        position: 'absolute',
      },
    },
  });

  useEffect(() => {
    if (props.isShowDocPane) {
      setTimeout(() => {
        setShow(true)
      }, 100);
    } else {
      setShow(false)
    }
  }, [props.isShowDocPane])

  const {
    selectedMyDpPmol,
    collapseBookmarkPane,
  } = useSelector(
    (state: any) => state.mydPlan
  );

  const { showProgressBar } = useSelector(
    (state: any) => state.pmol
  );

  const _toggleBookmark = (): void => {
    setToggleBookmark(true)
  };

  const _toggleBookmarkOut = (): void => {
    setToggleBookmark(false)
  };

  return <div style={{height: '100%'}}>
    <Stack
      className="project-list-header right-panel"
      style={{ backgroundColor: 'rgb(255, 255, 255)', paddingRight: 0 }}
      styles={{ root: { width: '100%', paddingRight: 0 } }}
    >
      <TitlePane
        close={true}
        headerTitle={selectedMyDpPmol ? selectedMyDpPmol.title : ""}
        collapse={true}
        isCollapse={props.collapseBookmarkPane}
        type={1}
      />
      <CommandBarRight />
      <ProgressBar show={showProgressBar} />
    </Stack>
    <div 
      style={{ 
        height: 'calc(100% - 71px)', 
        // overflow: 'auto', 
        backgroundColor: 'white',
        display: 'flex'
      }}
    >
      <div style={{width: toggleBookmark ? 'calc(100% - 220px)' : 'calc(100% - 28px)'}}>
        <Stack
          className={`project-detail-list ${(props.collapseBookmarkPane && window.innerWidth > 1440) ? 'four-pane-full-inner-container' : 'four-pane-full-inner-container'} cpc  card-container`}
          styles={{
            root: {
              width: '100%',
              height: '100%',
              overflow: 'auto'
            },
          }}
        >
          <Stack
            className={contentStyle}
            horizontalAlign="start"
            verticalAlign="start"
            styles={{
              root: {
                width: '100%',
                height: '100%',
              },
            }}
            verticalFill
          >
            {show ?  
              <PmolDocumentPane projectId={selectedMyDpPmol?.projectSequenceCode} />
            : <div style={{width: '100%', height: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute'}}>
                <Spinner size={SpinnerSize.large}/>
              </div>}
          </Stack>
        </Stack>
      </div>
      <PmolBookMarkPaneComponent 
        isEdit={true}
        isShowBookmark={toggleBookmark}
        currentActiveSection=""
        toggleBookmark={_toggleBookmark}
        toggleBookmarkOut={_toggleBookmarkOut}
        pmolType={PMOL_TYPE.REGULAR}
      />
    </div>
  </div>
};

export default DocumentPane;
