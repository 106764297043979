import React, {Component} from 'react';
import MeNavBar from '../../shared/uprinceNavigation/meNavigation/component';
import ProjectDayPlanningMainLayout from './projectDayPlanningMainLayout';
import {getContractingUnit, getProject} from '../../shared/util';
import UprinceNavigation from '../../shared/uprinceNavigation/cuProjectNavigation/component';

interface Props {
    currentUser: any
}

interface State {
    isContractUnit: boolean;
    isProject: boolean;
}


export class ProjectDayPlanningNew extends Component<Props, State> {
    render() {
        return  (
            getProject() ?
                <UprinceNavigation
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={`/CU/${getContractingUnit()}/project/${getProject()}/project-day-planning`}
                >
                    <ProjectDayPlanningMainLayout/>
                </UprinceNavigation> :
                <MeNavBar
                    currentUser={this.props.currentUser}
                    selectedNavigationIndex={'/me/project-day-planning'}
                >
                    <ProjectDayPlanningMainLayout/>
                </MeNavBar>
        )

    }
}

export default (ProjectDayPlanningNew);
