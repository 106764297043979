import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import Home from './components/home';
import { connect } from 'react-redux';
import Project from './components/project/project';
import history from './history';
import QRCode from './components/qrcode';
import PrivateRoute from './privateRoute';
import NotFoundPage from './components/notFound';
import TimeClock from './components/timeClock ';
import AddressBook from './components/addressBook/addressBook';
import { ProductCatalogue } from './components/productCatalogue/productCatalogue';
import { ProjectBreakdownStructure } from './components/ProjectBreakdownStructure/projectBreakdownStructure';
import { CUProductCatalogue } from './components/cuProjects/cpuProductCatalog';
import { BillOfResources } from './components/billOfResources/billOfResources';
import ContractingUnit from './components/contractingUnit/contractingUnit';
import CuTimeClock from './components/cuProjects/cuTimeClock ';
import CuQrcode from './components/cuProjects/cuQrcode';
import { ProjectMolecule } from './components/projectMolecule/projectMolecule';
import ProjectDefinition from './components/project/projectDefinition';
import PermissionDeniedPage from './components/noAccessPage';
import { ProgressStatement } from './components/progressStatement/progressStatement';
import { Invoice } from './components/invoice/invoice';
import { Language } from './components/language/language';
import { PurchaseOrder } from './components/purchaseOrder/purchaseOrder';
import { Warehouse } from './components/warehouse/warehouse';
import { dropdownTranslation } from './components/dropdownTranslation/dropdownTranslation';
import { Stock } from './components/stock/stock';
import { WorkFlow } from './components/workFlow/workFlow';
import VisualPlan from './components/visualPlan/visualPlan';
import { MyDayPlanning } from './components/dayPlanning/myDayPlaning';
import { OrganisationSetting } from './components/organisationSetting/organisationSetting';
import { Competency } from './components/competency/competency';
import { Certification } from './components/certification/certification';
import { CorporateSchedule } from './components/corporateSchedule/corporateSchedule';
import { HumanResource } from './components/humanResource/humanResource';
import { DayPlanning } from './components/dayPlanning/dayPlanning';
import ProjectVisualPlan from './components/projectVisualPlan/visualPlan';
import { MileStones } from './components/mileStones/mileStones';
import { Contract } from './components/contract/contract';
import { StandardMail } from './components/standardMail/standardMail';
import { RiskRegister } from './components/riskRegister/riskRegister';
import { Quality } from './components/quality/quality';
import { InstructionRegister } from './components/InstructionRegister/instructionRegister';
import CustomLoading from './shared/loading/customLoading';
import { uPrinceTheme } from '../theme';
import { getThemeColor } from './reducers/uprinceReducer';
import MyDayPlanningNew from './components/myDayPlanning/myDayPlanning';
import { ProjectDayPlanningNew } from './components/projectDayPlanning/projectDayPlanning';
import { Ciaw } from './components/ciaw/ciaw';
import MyProjectVisualPlan from './components/myProjectVisualPlan/visualPlan';
import ThAutomation from './components/thAutomation/thAutomation';
import { PROJECT_PLANNING_ROUTE } from './types/types';
import CuMyCalendar from './components/cuMyCalendar/cuMyCalendar';
import cuProjectVisualPlan from './components/cuProjectVisualPlan/cuVisualPlan';
import WBS from './components/wbs/wbs';
import WbsCreate from './components/wbsCreate/wbsCreate';
import WbsTask from './components/wbsTask/wbsTask';
import WbsCuTask from './components/wbsCuTask/WbsCuTask';
import { CuInstructionRegister } from './components/cuInstructionRegister/cuInstructionRegister';
import WbsIssue from './components/wbsIssue/WbsIssue';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import './shared/main.css'

class Routes extends React.Component<any, any> {
  _isMounted = false;

  constructor(props: {}) {
    super(props);
    let user: any;
    user = localStorage.getItem('user') || null;
    // localStorage.setItem('user_logged', 'false');
    this.state = {
      user_logged: localStorage.getItem('user_logged'),
      user: JSON.parse(user) || null,
      languageSelected:
        localStorage.getItem('languageSelected') === 'true',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if(!this.props.organization){
        this.props.getThemeColor()
    }
    const favicon:any = document.getElementById('favicon');
    favicon.href = localStorage.getItem('logoUrl');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <main className={'routes_main_container'}>
        <DndProvider key={uuidv4()} backend={HTML5Backend}>
        <Router history={history}>
          <Switch>

            <Route
              path="/"
              exact
              render={ (props) =>
                  this.props.currentUser?<Home
                      currentUser={ this.props.currentUser }
                      location={ props.location }
                  />:<div><CustomLoading loadingColor={ uPrinceTheme.palette.themePrimary}/></div>
              }
            />
            <Route
              path="/login"
              exact
              render={ (props) =>
                  this.props.currentUser?<Home
                      currentUser={ this.props.currentUser }
                      location={ props.location }
                  />:<div><CustomLoading loadingColor={ uPrinceTheme.palette.themePrimary}/></div>
              }
            />

            {/* <PrivateRoute
              path="/me/visualPlan"
              exact
              roles={['Admin', 'User']}
              component={MyVisualPlan}
              currentUser={this.props.currentUser}
            /> */}

            <PrivateRoute
              path="/me/project-planning"
              exact
              roles={['Admin', 'User']}
              component={MyProjectVisualPlan}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/me/day-planning"
              exact
              roles={['Admin', 'User']}
              component={MyDayPlanning}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/me/day-planning-new"
              exact
              roles={['Admin', 'User']}
              component={MyDayPlanningNew}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path={`/me/${PROJECT_PLANNING_ROUTE}`}
              exact
              roles={['Admin', 'User']}
              component={ProjectDayPlanningNew}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId"
              exact
              roles={['Admin', 'User']}
              component={Project}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/new"
              exact
              roles={['Admin', 'User']}
              component={Project}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/product-catalogue"
              exact
              roles={['Admin', 'User']}
              component={CUProductCatalogue}
              currentUser={this.props.currentUser}
            />

            {/* <PrivateRoute
                          path="/organisation"
                          roles={ [ 'Admin' ] }
                          component={ Organization }
                          currentUser={ this.props.currentUser }
                      />
                      <PrivateRoute
                          path="/project"
                          exact
                          roles={ [ 'Admin', 'User' ] }
                          component={ Project }
                          currentUser={ this.props.currentUser }
                      /> */}

            <PrivateRoute
              path="/qr-code"
              component={QRCode}
              roles={['Admin', 'User']}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/qr-code/:id"
              component={QRCode}
              exact
              roles={['Admin', 'User']}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/qr-code"
              component={CuQrcode}
              roles={['Admin', 'User']}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/qr-code/:id"
              component={CuQrcode}
              exact
              roles={['Admin', 'User']}
              currentUser={this.props.currentUser}
            />
            { /* <Route path="/qr/new" component={NewQRCode} exact /> */}

            {/* <PrivateRoute
                          path="/project/:id"
                          exact
                          roles={ [ 'Admin', 'User' ] }
                          component={ Project }
                          currentUser={ this.props.currentUser }
                      /> */}

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Project}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/project-definition"
              exact
              roles={['Admin', 'User']}
              component={ProjectDefinition}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/product-catalogue"
              exact
              roles={['Admin', 'User']}
              component={ProductCatalogue}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/time_clock"
              exact
              roles={['Admin', 'User']}
              component={TimeClock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/time_clock/:id"
              exact
              roles={['Admin', 'User']}
              component={TimeClock}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/time_clock"
              exact
              roles={['Admin', 'User']}
              component={CuTimeClock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/time_clock/:id"
              exact
              roles={['Admin', 'User']}
              component={CuTimeClock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/human-resource"
              exact
              roles={['Admin', 'User']}
              component={HumanResource}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/human-resource/new"
              exact
              roles={['Admin', 'User']}
              component={HumanResource}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/human-resource/:featureId"
              exact
              roles={['Admin', 'User']}
              component={HumanResource}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/address-book"
              exact
              roles={['Admin', 'User']}
              component={AddressBook}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/address-book/:id"
              exact
              roles={['Admin', 'User']}
              component={AddressBook}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/address-book/new"
              exact
              roles={['Admin', 'User']}
              component={AddressBook}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/product-catalogue"
              exact
              roles={['Admin', 'User']}
              component={ProductCatalogue}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/product-catalogue/new"
              exact
              roles={['Admin', 'User']}
              component={ProductCatalogue}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/product-catalogue/new"
              exact
              roles={['Admin', 'User']}
              component={CUProductCatalogue}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/product-catalogue/:cpcId"
              exact
              roles={['Admin', 'User']}
              component={CUProductCatalogue}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:projectid/product-catalogue/new"
              exact
              roles={['Admin', 'User']}
              component={ProductCatalogue}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:projectid/product-catalogue/:cpcId"
              exact
              roles={['Admin', 'User']}
              component={ProductCatalogue}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/product-catalogue/:cpcId"
              exact
              roles={['Admin', 'User']}
              component={ProductCatalogue}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/project/:id/product/pbs"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/product/pbs"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/project/:id/product/pbs/new"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/product/pbs/new"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/project/:id/product/pbs/:pbsId"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/product/pbs/:pbsId"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/bill-Of-resources"
              exact
              roles={['Admin', 'User']}
              component={BillOfResources}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/bill-Of-resources"
              exact
              roles={['Admin', 'User']}
              component={BillOfResources}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/bill-Of-resources/new"
              exact
              roles={['Admin', 'User']}
              component={BillOfResources}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/bill-Of-resources/new"
              exact
              roles={['Admin', 'User']}
              component={BillOfResources}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/bill-Of-resources/:borId"
              exact
              roles={['Admin', 'User']}
              component={BillOfResources}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/bill-Of-resources/:borId"
              exact
              roles={['Admin', 'User']}
              component={BillOfResources}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/contracting-unit"
              exact
              roles={['Admin', 'User']}
              component={ContractingUnit}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/project-molecule"
              exact
              roles={['Admin', 'User']}
              component={ProjectMolecule}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/project-molecule/new"
              exact
              roles={['Admin', 'User']}
              component={ProjectMolecule}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/project-molecule/:pmolId"
              exact
              roles={['Admin', 'User']}
              component={ProjectMolecule}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/progress-statement"
              exact
              roles={['Admin', 'User', 'PM']}
              component={ProgressStatement}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/progress-statement"
              exact
              roles={['Admin', 'User']}
              component={ProgressStatement}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/progress-statement/:psId"
              exact
              roles={['Admin', 'User']}
              component={ProgressStatement}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/progress-statement/new"
              exact
              roles={['Admin', 'User']}
              component={ProgressStatement}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/progress-statement/:psId"
              exact
              roles={['Admin', 'User']}
              component={ProgressStatement}
              currentUser={this.props.currentUser}
            />


            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/invoice"
              exact
              roles={['Admin', 'User', 'PM']}
              component={Invoice}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/invoice"
              exact
              roles={['Admin', 'User', 'PM']}
              component={Invoice}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/invoice/new"
              exact
              roles={['Admin', 'User']}
              component={Invoice}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/invoice/:invoiceId"
              exact
              roles={['Admin', 'User']}
              component={Invoice}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/invoice/:invoiceId"
              exact
              roles={['Admin', 'User']}
              component={Invoice}
              currentUser={this.props.currentUser}
            />


            <PrivateRoute
              path="/translations-dashboard"
              exact
              roles={['Admin', 'User']}
              component={Language}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/translations-dashboard/new"
              exact
              roles={['Admin', 'User']}
              component={Language}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/translations-dashboard/:lang"
              exact
              roles={['Admin', 'User']}
              component={Language}
              currentUser={this.props.currentUser}
            />

            { /* <PrivateRoute
                          path="/CU/:contratingUnitId/template"
                          exact
                          roles={ [ 'Admin', 'User' ] }
                          component={ Template }
                          currentUser={ this.props.currentUser }
                      />

                      <PrivateRoute
                          path="/CU/:contratingUnitId/template/:id"
                          exact
                          roles={ [ 'Admin', 'User' ] }
                          component={ Template }
                          currentUser={ this.props.currentUser }
                      /> */}

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/purchase-order"
              exact
              roles={['Admin', 'User']}
              component={PurchaseOrder}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/purchase-order/new"
              exact
              roles={['Admin', 'User']}
              component={PurchaseOrder}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/purchase-order/:featureId"
              exact
              roles={['Admin', 'User']}
              component={PurchaseOrder}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/purchase-order"
              exact
              roles={['Admin', 'User']}
              component={PurchaseOrder}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/purchase-order/new"
              exact
              roles={['Admin', 'User']}
              component={PurchaseOrder}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/purchase-order/:featureId"
              exact
              roles={['Admin', 'User']}
              component={PurchaseOrder}
              currentUser={this.props.currentUser}
            />


            <PrivateRoute
              path="/dropdown-translations-dashboard"
              exact
              roles={['Admin', 'User']}
              component={dropdownTranslation}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/dropdown-translations-dashboard/new"
              exact
              roles={['Admin', 'User']}
              component={dropdownTranslation}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/dropdown-translations-dashboard/:Trans"
              exact
              roles={['Admin', 'User']}
              component={dropdownTranslation}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock-workflow"
              exact
              roles={['Admin', 'User']}
              component={WorkFlow}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock-workflow/new"
              exact
              roles={['Admin', 'User']}
              component={WorkFlow}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock-workflow/:featureId"
              exact
              roles={['Admin', 'User']}
              component={WorkFlow}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/stock-workflow"
              exact
              roles={['Admin', 'User']}
              component={WorkFlow}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/stock-workflow/new"
              exact
              roles={['Admin', 'User']}
              component={WorkFlow}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/stock-workflow/:featureId"
              exact
              roles={['Admin', 'User']}
              component={WorkFlow}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/stock"
              exact
              roles={['Admin', 'User']}
              component={Stock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/stock/new"
              exact
              roles={['Admin', 'User']}
              component={Stock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock"
              exact
              roles={['Admin', 'User']}
              component={Stock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock/new"
              exact
              roles={['Admin', 'User']}
              component={Stock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Stock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/stock/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Stock}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock-warehouse"
              exact
              roles={['Admin', 'User']}
              component={Warehouse}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock-warehouse/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Warehouse}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/stock-warehouse/new"
              exact
              roles={['Admin', 'User']}
              component={Warehouse}
              currentUser={this.props.currentUser}
            />


            <PrivateRoute
              path="/CU/:contratingUnitId/stock-warehouse"
              exact
              roles={['Admin', 'User']}
              component={Warehouse}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/quality"
              exact
              roles={['Admin', 'User']}
              component={Quality}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/quality/new"
              exact
              roles={['Admin', 'User']}
              component={Quality}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/quality/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Quality}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/quality/:qualityId/new"
              exact
              roles={['Admin', 'User']}
              component={Quality}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/instruction-register"
              exact
              component={InstructionRegister}
              roles={['Admin', 'User']}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/instruction-register/new"
              exact
              component={InstructionRegister}
              roles={['Admin', 'User']}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/instruction-register/:featureId"
              exact
              roles={['Admin', 'User']}
              component={InstructionRegister}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/cu-instruction-register"
              exact
              component={CuInstructionRegister}
              roles={['Admin', 'User']}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/cu-instruction-register/new"
              exact
              component={CuInstructionRegister}
              roles={['Admin', 'User']}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/cu-instruction-register/:featureId"
              exact
              roles={['Admin', 'User']}
              component={CuInstructionRegister}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/risk-register"
              exact
              roles={['Admin', 'User']}
              component={RiskRegister}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/risk-register/new"
              exact
              roles={['Admin', 'User']}
              component={RiskRegister}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/risk-register/:featureId"
              exact
              roles={['Admin', 'User']}
              component={RiskRegister}
              currentUser={this.props.currentUser}
            />


            <PrivateRoute
              path="/CU/:contratingUnitId/stock-warehouse/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Warehouse}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/stock-warehouse/new"
              exact
              roles={['Admin', 'User']}
              component={Warehouse}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/visual-plan"
              exact
              roles={['Admin', 'User']}
              component={VisualPlan}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/project-planning"
              exact
              roles={['Admin', 'User']}
              component={ProjectVisualPlan}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/visual-plan/:featureId"
              exact
              roles={['Admin', 'User']}
              component={VisualPlan}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/visual-plan/new"
              exact
              roles={['Admin', 'User']}
              component={VisualPlan}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/organisation-settings"
              exact
              roles={['Admin', 'User']}
              component={OrganisationSetting}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-settings/new"
              exact
              roles={['Admin', 'User']}
              component={OrganisationSetting}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-settings/org/:featureId"
              exact
              roles={['Admin', 'User']}
              component={OrganisationSetting}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-settings/competencies"
              exact
              roles={['Admin', 'User']}
              component={Competency}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/organisation-settings/competencies/new"
              exact
              roles={['Admin', 'User']}
              component={Competency}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/organisation-settings/competencies/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Competency}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/organisation-settings/certifications"
              exact
              roles={['Admin', 'User']}
              component={Certification}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-settings/certifications/new"
              exact
              roles={['Admin', 'User']}
              component={Certification}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-settings/certifications/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Certification}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-settings/corporate-schedule"
              exact
              roles={['Admin', 'User']}
              component={CorporateSchedule}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-settings/corporate-schedule/new"
              exact
              roles={['Admin', 'User']}
              component={CorporateSchedule}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-settings/corporate-schedule/:featureId"
              exact
              roles={['Admin', 'User']}
              component={CorporateSchedule}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-cbc"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-cbc/:cbcId"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/organisation-cbc/new"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/organisation-cbc"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/organisation-cbc/:cbcId"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/organisation-cbc/new"
              exact
              roles={['Admin', 'User']}
              component={ProjectBreakdownStructure}
              currentUser={this.props.currentUser}
            />


            <PrivateRoute
              path="/CU/:contratingUnitId/day-planning"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/day-planning/new"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/day-planning/pmol/:featureId"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/day-planning/cpc/:featureId"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/day-planning/hr/:featureId"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />


            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/day-planning"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/day-planning/new"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/day-planning/pmol/:featureId"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/day-planning/cpc/:featureId"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/day-planning/hr/:featureId"
              exact
              roles={['Admin', 'User']}
              component={DayPlanning}
              currentUser={this.props.currentUser}
            />


            {/* <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/day-planning-new"
              exact
              roles={['Admin', 'User']}
              component={MyDayPlanningNew}
              currentUser={this.props.currentUser}
            /> */}

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/milestone"
              exact
              roles={['Admin', 'User']}
              component={MileStones}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/milestone/new"
              exact
              roles={['Admin', 'User']}
              component={MileStones}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/milestone/:featureId"
              exact
              roles={['Admin', 'User']}
              component={MileStones}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/milestone/:featureId"
              exact
              roles={['Admin', 'User']}
              component={MileStones}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/contract"
              exact
              roles={['Admin', 'User']}
              component={Contract}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/contract/new"
              exact
              roles={['Admin', 'User']}
              component={Contract}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/contract/:featureId"
              exact
              roles={['Admin', 'User']}
              component={Contract}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/contract/new"
              exact
              roles={['Admin', 'User']}
              component={Contract}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/standard-mail"
              exact
              roles={['Admin', 'User']}
              component={StandardMail}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/standard-mail/new"
              exact
              roles={['Admin', 'User']}
              component={StandardMail}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/standard-mail/:featureId"
              exact
              roles={['Admin', 'User']}
              component={StandardMail}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/day-planning-new"
              exact
              roles={['Admin', 'User']}
              component={MyDayPlanningNew}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path={`/CU/:contratingUnitId/project/:id/${PROJECT_PLANNING_ROUTE}`}
              exact
              roles={['Admin', 'User']}
              component={ProjectDayPlanningNew}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
                path="/CU/:contratingUnitId/ciaw"
                exact
                roles={['Admin', 'User']}
                component={Ciaw}
                currentUser={this.props.currentUser}
            />

            <PrivateRoute
                path="/CU/:contratingUnitId/ciaw/:featureId"
                exact
                roles={['Admin', 'User']}
                component={Ciaw}
                currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/me/th-automation"
              exact
              roles={['Admin', 'User']}
              component={ThAutomation}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/cu-project-planning"
              exact
              roles={['Admin', 'User']}
              component={cuProjectVisualPlan}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/cu-my-calendar"
              exact
              roles={['Admin', 'User']}
              component={CuMyCalendar}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/wbs"
              exact
              roles={['Admin', 'User']}
              component={WBS}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/wbs-create"
              exact
              roles={['Admin', 'User']}
              component={WbsCreate}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/wbs-create/new"
              exact
              roles={['Admin', 'User']}
              component={WbsCreate}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/wbs-create/:featureId"
              exact
              roles={['Admin', 'User']}
              component={WbsCreate}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/wbs-create/:featureId/template"
              exact
              roles={['Admin', 'User']}
              component={WbsCreate}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/wbs-create/:featureId/template/:templateId"
              exact
              roles={['Admin', 'User']}
              component={WbsCreate}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/wbs/task"
              exact
              roles={['Admin', 'User']}
              component={WbsTask}
              currentUser={this.props.currentUser}
            />


            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/wbs/task/:featureId"
              exact
              roles={['Admin', 'User']}
              component={WbsTask}
              currentUser={this.props.currentUser}
            />
            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/wbs/task/new"
              exact
              roles={['Admin', 'User']}
              component={WbsTask}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/wbs-cu-task"
              exact
              roles={['Admin', 'User']}
              component={WbsCuTask}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/wbs-cu-task/:featureId"
              exact
              roles={['Admin', 'User']}
              component={WbsCuTask}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/wbs/issue"
              exact
              roles={['Admin', 'User']}
              component={WbsIssue}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/wbs/issue/:featureId"
              exact
              roles={['Admin', 'User']}
              component={WbsIssue}
              currentUser={this.props.currentUser}
            />

            <PrivateRoute
              path="/CU/:contratingUnitId/project/:id/wbs/issue/new"
              exact
              roles={['Admin', 'User']}
              component={WbsIssue}
              currentUser={this.props.currentUser}
            />

{/*
            <Route
              path="/CU/:contratingUnitId/project/:id/lot-invitation/:featureId/:invitationId"
              exact
              component={LotInvitation}
            />

            <Route
              path="/CU/:contratingUnitId/project/:id/lot-subscribe/:featureId/:invitationId"
              exact
              component={LotSubcribe}
            />

            <Route
              path="/CU/:contratingUnitId/project/:id/download-documents/:featureId/:invitationId"
              exact
              component={LotDownload}
            /> */}
            <PrivateRoute roles={['Admin', 'User']}
                          path="/permission-denied"
                          component={PermissionDeniedPage}
                          currentUser={this.props.currentUser}/>
            <Route
              path="/permission--denied"
              component={PermissionDeniedPage}/>
            <Route path="*" component={NotFoundPage}/>

          </Switch>
        </Router>
        </DndProvider>
      </main>
    );
  }
}

// const RouteComponent = () => {
//   return <Route component={Routes} />;
// };

const mapStateToProps = (state: any) => {  //state: any, ownProps: any
    return {
        organization: state.uprince.organization,
        currentUser: state.uprince.currentUser,
    };
};

const mapDispatchToProps = {
    getThemeColor,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
