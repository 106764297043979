import React, { useEffect, useState } from 'react';
import { CommandBar } from '@fluentui/react/lib/CommandBar';
import { useTranslation } from 'react-i18next';
import { messageService } from '../../../services/messageService';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { ArrowSquareUpRightRegular } from '@fluentui/react-icons';
import {
  APPROVED_STATUS_ID,
  HANDED_OVER_STATUS_ID,
  IN_REVIEW_STATUS_ID,
} from '../../../types/projectMolecule';
import { PBS_TYPE } from '../../../types/projectBreakdownStructure';
import {
  clearFormData,
  clearUid,
  deleteProduct,
  getPbsExternalSyncLogs,
  readPbsRelations,
  savePbsStateAttr,
} from '../../../reducers/projectBreakdownReducer';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';
import {
  EXTERNAL_DATA_SYNC_SOURCE_TYPE,
  isPlanning,
} from '../../../shared/util';
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';
import SquareMetersModal from '../../ProjectBreakdownStructure/squareMeters/squareMetersModal';
import DeleteConfirmationDialogWithData from '../../../shared/confirmationDialog/deleteConfirmationDialogWithData';
import history from '../../../history';
import client from '../../../api';
import { v4 as uuidv4 } from 'uuid';
import { getContractingUnit, getProject } from '../../../shared/util';
import {
  saveCIAWProductStateAttr,
  readCIAWSiteproductCodeById,
} from '../../../reducers/ciawReducer';
import CIAWSiteCodeModal from '../../../components/projectVisualPlan/documentPane/ciawModel/component';
import Axios from 'axios';
import {
  GET_FOLDERS_FROM_TEAMS,
  GET_GRAPH_API_TOKEN,
  GET_PMOL_PRODUCT_HISTORY_INTERVENTIONS,
} from '../../../shared/endpoints';
import {
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  mergeStyleSets,
  Modal,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../../theme';
import '../../../components/style/style.css';
import { Link } from 'office-ui-fabric-react';

const theme = getTheme();

const contentStyles = mergeStyleSets({
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  modalContainer: {
    width: '80%',
    height: '75%',
  },
  previewContent: {
    width: '100%',
    textAlign: 'center',
  },
  logCodeBlock: {
    background: '#f4f4f4',
    padding: 10,
    borderRadius: 5,
    height: 'calc(100% - 100px)',
    margin: 0,
    overflowY: 'auto',
  },
  platformLinkContainer: {
    display: 'flex',
  },
  platformLink: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    marginTop: 10,
    marginLeft: 10,
  },
});
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: { color: theme.palette.neutralDark },
};

const styles = mergeStyleSets({
  iframe: {
    width: '100%',
    height: '100%',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
});

const CommandBarRight = (props: {
  type: string;
  disable: boolean;
  vpProjectSelectedShortcutpaneType?: string;
  documentPaneType?: number;
  createNew?: (create: boolean, type?: PBS_TYPE) => void;
}) => {
  const store = useStore();
  const isApprovedPmol =
    store.getState()?.pmol?.formData?.statusId == APPROVED_STATUS_ID ||
    store.getState()?.pmol?.formData?.statusId == HANDED_OVER_STATUS_ID;
  const isInReviewPmol =
    store.getState()?.pmol?.formData?.statusId == IN_REVIEW_STATUS_ID;
  const isLoading = store.getState()?.pmol?.showProgressBar;

  const [isConfirmDialogHidden, setIsConfirmDialogHidden] =
    React.useState(true);
  const [isDocumentDisabled, setIsDocumentDisabled] = useState<boolean>(true);
  const [isExternalSyncLogVisible, setIsExternalSyncLogVisible] =
    useState<boolean>(false);
  const [isExternalSyncLogDisabled, setIsExternalSyncLogDisabled] =
    useState<boolean>(false);
  const [isExternalSyncLogModalShow, setIsExternalSyncLogModalShow] =
    useState<boolean>(false);

  // History of intervention
  const [historyDataRetrieve, sethistoryDataretrieve] = useState<any>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disableConditionForHistory, setDisableConditionForHistory] =
    useState(true);

  const updatedVPPBS = store.getState()?.projectBreakdown?.updatedVPPBS;
  const pbsRelations = store.getState()?.projectBreakdown?.pbsRelations;

  const [folderDetails, setFolderDetails] = useState<any>(null);

  const { isPbsSqModalOpen, formData, pbsExternalSyncLogs } = useSelector(
    (state: any) => state.projectBreakdown
  );
  const selectedProject = useSelector(
    (state: any) => state.project.selectedProject
  );
  const { externalSyncStatus } = useSelector(
    (state: any) => state.externalSync
  );

  const squareMeters = () => {
    dispatch(savePbsStateAttr('isPbsSqModalOpen', !isPbsSqModalOpen));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (formData && formData?.id) {
      fetchCurrentFolderDetails(formData);
      retrieveHistoryData(formData);
    } else {
      setIsDocumentDisabled(true);
      setFolderDetails(null);
    }
  }, [formData, formData?.id]);

  useEffect(() => {
    if (
      formData?.parentId ||
      !formData?.id ||
      selectedProject?.externalDataSyncSourceType !==
        EXTERNAL_DATA_SYNC_SOURCE_TYPE.ULTRANETTER
    ) {
      return setIsExternalSyncLogVisible(false);
    }

    setIsExternalSyncLogVisible(true);
    dispatch(getPbsExternalSyncLogs(formData?.id));
  }, [formData, formData?.id, formData?.parentId, selectedProject]);

  useEffect(() => {
    if (pbsExternalSyncLogs?.length > 0) {
      return setIsExternalSyncLogDisabled(false);
    }

    setIsExternalSyncLogDisabled(true);
  }, [pbsExternalSyncLogs]);

  const retrieveHistoryData = async (data: any) => {
    const payload = {
      id: null,
      PbsId: data?.productId ? data?.productId : null,
    };
    if (payload.PbsId) {
      try {
        setLoading(true);
        const response = await client.post(
          GET_PMOL_PRODUCT_HISTORY_INTERVENTIONS,
          payload
        );
        if (response.status === 200) {
          sethistoryDataretrieve(response?.data?.result);
          setDisableConditionForHistory(false);
        }
      } catch (error) {
        console.log('error', error);
        setDisableConditionForHistory(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchCurrentFolderDetails = async (formData: any) => {
    const data = {
      pbsGuid: formData?.id,
      Instruction: '',
      contentTypeId: '',
      creationDate: '',
    };
    let config: any = {
      method: 'post',
      maxBodyLength: Infinity,
      url: GET_FOLDERS_FROM_TEAMS,
      headers: {
        'X-API-Key': 'fcbbdac5-d0ad-45cb-8b45-dd8550785eb6',
        // 'Project': getProject(),
      },
      data: data,
    };
    await client
      .request(config)
      .then((res) => {
        if (res?.data) {
          getGraphApiToken(res?.data?.result?.[0]);
        }
      })
      .catch((err) => console.log('err', err));
  };

  const getGraphApiToken = async (product: any) => {
    if (product?.driveId && product?.driveItemId) {
      try {
        const response = await client.get(GET_GRAPH_API_TOKEN);
        if (response.data) {
          callGraphApiToRetrieveUrl(product, response.data.result.access_token);
        }
      } catch (error) {
        console.error('Error getting Graph API token:', error);
      }
    }
  };

  const callGraphApiToRetrieveUrl = async (product: any, token: any) => {
    const base_graph_api_url = 'https://graph.microsoft.com';
    if (token) {
      if (product?.driveId && product?.driveItemId) {
        await Axios({
          url: `${base_graph_api_url}/v1.0/drives/${product?.driveId}/items/${product?.driveItemId}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setFolderDetails(res.data);
            setIsDocumentDisabled(false);
          })
          .catch((err) => {
            console.log('err', err);
            setFolderDetails(null);
          });
      }
    }
  };

  const openShareLinkURL = () => {
    if (folderDetails) {
      window.open(folderDetails?.webUrl, '_blank');
    }
  };

  const handlePBSRemove = () => {
    if (updatedVPPBS && updatedVPPBS.id) {
      setIsConfirmDialogHidden(true);
      Promise.all([dispatch(deleteProduct([updatedVPPBS.id]))]).then(() => {
        if (isPlanning()) {
          dispatch(saveVPStateAttr('showvpProjectDocumentPane', false));
        } else {
          messageService.sendMessage({
            hideDocumentPane: true,
            documentPaneType: props.documentPaneType,
          });
        }
      });
    }
  };

  const { t } = useTranslation();
  const saveFormData = () => {
    console.log(props);
    if (props.type === 'pmol') {
      messageService.sendMessage({ savePmolFormData: true });
    }

    if (props.type === 'bor') {
      messageService.sendMessage({ saveFormDataBor: true });
    }

    if (props.type === 'pbs') {
      messageService.sendMessage({ saveFormDataPBS: true });
    }
    if (props.type === 'milestone') {
      messageService.sendMessage({ saveMSTONEData: true });
    }

    if (props.type === 'pr') {
      messageService.sendMessage({ savePOData: true });
    }
  };

  const handleRegular = () => {
    messageService.sendMessage({ isNewPbs: true, pbsType: PBS_TYPE.REGULAR });
  };
  const handleSubContract = () => {
    messageService.sendMessage({ isNewPbs: true, pbsType: PBS_TYPE.SUB });
  };

  const commandBarButtons = [
    {
      key: 'new',
      text: t('new'),
      iconProps: { iconName: 'Add' },
      split: true,
      subMenuProps: {
        items: [
          {
            key: 'regular',
            text: t('clientName'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
              if (props.type === 'pbs') {
                handleRegular();
              }
            },
          },
          {
            key: 'subContract',
            text: t('subContract'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
              if (props.type === 'pbs') {
                handleSubContract();
              }
            },
          },
        ],
      },
      disabled: false,
      onClick: () => {
        if (props.type === 'pbs') {
          handleRegular();
        }
      },
    },
    {
      key: 'save',
      text: t('save'),
      iconProps: { iconName: 'Save' },
      onClick: () => {
        saveFormData();
      },
      disabled: isApprovedPmol || isLoading,
    },
    {
      key: 'saveAndClose',
      text: t('saveAndClose'),
      iconProps: { iconName: 'SaveAndClose' },
      onClick: () => {
        saveFormData();
        dispatch(saveVPStateAttr('showvpProjectDocumentPane', false));
      },
      disabled: isApprovedPmol || isLoading,
    },
    {
      key: 'Remove',
      onClick: () => {
        setIsConfirmDialogHidden(false);
        dispatch(readPbsRelations(updatedVPPBS?.productId));
      },
      name: t('remove'),
      iconProps: { iconName: 'Delete' },
      disabled: props.type != 'pbs', // Currently disabled for PMOL
    },
    {
      key: 'approve',
      text: t('approve'),
      iconProps: { iconName: 'ActivateOrders' },
      onClick: () => {
        messageService.sendMessage({ approvePmol: true });
      },
      disabled: !isInReviewPmol || isLoading,
    },
    {
      key: 'export',
      text: t('export'),
      iconProps: { iconName: 'Download' },
      split: true,
      subMenuProps: {
        items: [
          {
            key: 'exportNl',
            text: t('exportDutch'),
            iconProps: { iconName: 'Download' },
            onClick: () => {
              messageService.sendMessage({ exportPmolNL: true });
            },
          },
          {
            key: 'exportEn',
            text: t('exportEnglish'),
            iconProps: { iconName: 'Download' },
            onClick: () => {
              messageService.sendMessage({ exportPmolEN: true });
            },
          },
        ],
      },
      onClick: () => {
        messageService.sendMessage({ exportPmolNL: true });
      },
    },
    {
      key: 'SquareMeters',
      onClick: () => {
        squareMeters();
      },
      name: updatedVPPBS?.pbsScopeOfWork?.quantity
        ? updatedVPPBS?.pbsScopeOfWork?.quantity + ' m' + '²'
        : '' + 'm' + '²',
      iconProps: { iconName: 'Section' },
      disabled: !(
        updatedVPPBS?.productId && updatedVPPBS?.pbsType == PBS_TYPE.REGULAR
      ),
    },
    {
      key: 'CreatePS',
      text: t('createPS'),
      iconProps: { iconName: 'Add' },
      onClick: () => {
        autoCreatePsHeader();
      },
    },
    {
      key: 'ciaw',
      onClick: () => {
        dispatch(
          saveCIAWProductStateAttr('isCiawSiteCodeProductModelOpen', true)
        );
        dispatch(readCIAWSiteproductCodeById(updatedVPPBS?.id));
      },
      name: t('ciaw'),
      iconProps: { iconName: 'connectContacts' },
      disabled: false,
    },
    {
      key: 'document',
      onClick: () => openShareLinkURL(),
      iconProps: { iconName: 'FabricFolder' },
      name: t('documents'),
      disabled: isDocumentDisabled,
    },
    {
      key: 'syncLogs',
      onClick: () => setIsExternalSyncLogModalShow(true),
      iconProps: { iconName: 'Sync' },
      name: t('Logs'),
      disabled: isExternalSyncLogDisabled,
    },
    {
      key: 'history',
      onClick: () => {
        setShowPreview(true);
      },
      iconProps: { iconName: 'ClockIcon' },
      name: t('Work Order History'),
      disabled: disableConditionForHistory,
    },
  ];

  const autoCreatePsHeader = () => {
    const payload = {
      PbsProductId: updatedVPPBS?.productId,
      PbsProductName: updatedVPPBS?.name,
      ProjectStatusId: 'd60aad0b-2e84-482b-ad25-618d80d49477',
      ProjectTypeId: '5bb656-f708-4a0d-9973-3d834ffe757d01',
      GeneralLedgerId: '4ab98714-4087-45d4-beff-2d63c756682f2',
      PSId: uuidv4() + '-' + Date.now(),
    };

    client.post('ProgressStatement/AutoCreatePsHeader', payload).then(
      (response: any) => {
        if (
          response.data.result != null &&
          response.data.result != undefined &&
          response.data.result != 'No relevant record is found!!!'
        ) {
          window.open(
            `/CU/${getContractingUnit()}/project/${getProject()}/progress-statement/${
              response.data.result
            }`
          );
        } else {
          alert(response.data.result);
        }
      },

      (error: any) => {}
    );
  };

  const getItems = () => {
    const mappedButtons = commandBarButtons.filter((button) =>
      isExternalSyncLogVisible ? true : button.key !== 'syncLogs'
    );
    switch (props.type) {
      case 'pmol':
        return mappedButtons.slice(0, 6);
      case 'bor':
        return mappedButtons.slice(1, 2);
      case 'pbs':
        return mappedButtons.filter(
          (button) => button.key !== 'approve' && button.key !== 'export'
        );
      default:
        return mappedButtons.filter(
          (item) => item.key === 'save' || item.key === 'saveAndClose'
        );
    }
  };

  return (
    <div>
      <CommandBar
        items={getItems()}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />

      <DeleteConfirmationDialogWithData
        hidden={isConfirmDialogHidden}
        title={t('areyousure')}
        subText={t("You won't be able to revert this!")}
        onClickConfirmDialog={() => {
          handlePBSRemove();
        }}
        onClickCancelDialog={() => {
          setIsConfirmDialogHidden(true);
        }}
        cancelButtonText={t("No, I won't!")}
        confirmButtonText={t('Yes, delete it!')}
        relationsData={pbsRelations}
      />

      <SquareMetersModal />
      <CIAWSiteCodeModal />

      <Modal
        titleAriaId={''}
        isOpen={showPreview}
        onDismiss={() => {
          setShowPreview(false);
        }}
        isBlocking={true}
        containerClassName={contentStyles.modalContainer}
        className="custom-preview-modal-for-certifications"
      >
        <div className={contentStyles.header}>
          <span>{t('preview')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setShowPreview(false);
            }}
          />
        </div>
        {loading ? (
          <div className={styles.centered}>
            <Spinner size={SpinnerSize.large} />
          </div>
        ) : (
          <iframe
            src={historyDataRetrieve}
            title="PDF Preview"
            className={styles.iframe}
          />
        )}
      </Modal>

      <Modal
        titleAriaId={''}
        isOpen={isExternalSyncLogModalShow}
        onDismiss={() => {
          setIsExternalSyncLogModalShow(false);
        }}
        isBlocking={true}
        containerClassName={contentStyles.modalContainer}
        className="custom-preview-modal-for-certifications"
      >
        <div className={contentStyles.header}>
          <span>{t('Sync Logs')}</span>
          <IconButton
            styles={cancelIconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={() => {
              setIsExternalSyncLogModalShow(false);
            }}
          />
        </div>

        {pbsExternalSyncLogs && (
          <pre className={contentStyles.logCodeBlock}>
            {JSON.stringify(pbsExternalSyncLogs, null, 2)}
          </pre>
        )}

        {externalSyncStatus?.platformBaseUrl && (
          <div className={contentStyles.platformLinkContainer}>
            <Link
              href={`${externalSyncStatus?.platformBaseUrl}/${formData?.id}`}
              target="_blank"
              className={contentStyles.platformLink}
            >
              <ArrowSquareUpRightRegular fontSize={16} />
              Visit Source
            </Link>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CommandBarRight;
