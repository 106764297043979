import React, {Component} from 'react';
import {connect} from 'react-redux';
import history from '../../../history';
import ListPaneComponent from './component';
import {withRouter} from 'react-router';
import {WithTranslation, withTranslation} from 'react-i18next';
import {readDayPlanningDropDownData, setSelectedGridType,} from '../../../reducers/dayPlanningReducer';
import {DPLANDropDowns, SelectedGridType,} from '../../../types/dayPlanning';
import {getContractingUnit, getProject,} from '../../../shared/util';
import { readCompetancyTaxonomyList } from '../../../reducers/competencyReducer';

export interface State {
    redirect: boolean;
    isChange: boolean;
    loading: boolean;
    resourceList: any;
}

export interface Props extends WithTranslation {
    isDataLoaded?: boolean;
    isChange: boolean;
    reSizer: boolean;
    loadMsg: string;
    reloadListPane: boolean,
    dplanDropDowns: DPLANDropDowns;
    readDayPlanningDropDownData: () => void;
    resourceList: any;
    selectedGridType: SelectedGridType;
    setSelectedGridType: any;
    mydPlanResourceType: any;
    horizontalReSizer: any;
    competencyTaxonomyList: any
    readCompetancyTaxonomyList: any
}

class ResourceListPane extends Component<Props, State> {

    subscription: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            redirect: false,
            isChange: false,
            loading: false,
            resourceList: null
        };
    }

    componentWillUnmount() {

    }

    componentDidMount() {
        this.props.readCompetancyTaxonomyList();
        this.props.setSelectedGridType(SelectedGridType.DAY)
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    handleListPaneItemClick = (id: string) => {
        //this.props.getById(id);
        if (id) {
            if (getProject()) {
                history.push(`/CU/${getContractingUnit()}/project/${getProject()}/day-planning/hr/` + id);
            } else {
                history.push(`/CU/${getContractingUnit()}/day-planning/hr/` + id);
            }
        }
    };

    render() {
        return (
            <div style={{height: 'auto'}}>
                <ListPaneComponent 
                    horizontalReSizer={this.props.horizontalReSizer}
                    competencyTaxonomyList={this.props.competencyTaxonomyList}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        isChange: state.dplan.isChange,
        dplanDropDowns: state.dplan.dplanDropDowns,
        resourceList: state.dplan.resourceList,
        isDataLoaded: state.dplan.isResourceDataLoaded,
        reloadListPane: state.dplan.reloadListPane,
        selectedGridType: state.dplan.selectedGridType,
        competencyTaxonomyList: state.comp.competencyTaxonomyList
    };
};

const mapDispatchToProps = {
    readDayPlanningDropDownData,
    setSelectedGridType,
    readCompetancyTaxonomyList
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResourceListPane))
);
