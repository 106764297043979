import React from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Image, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
// import pdfLogo from './../../../../images/pdfLogo.png';
import { EXPORT_EN, PmolPDFData } from '../../../types/projectMolecule';
import moment from 'moment';
import { PrimaryButton } from '@fluentui/react';
import i18n from '../../../../i18n';
import ENV from '../../../../env.json'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
  },
  image: { width: '100%' },
  signatureImage: { width: '15%' },
  signatureItem: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footer: {
    position: 'absolute',
    fontSize: 11,
    bottom: 40,
    left: 10,
    right: 0,
  },
  table: {
    fontSize: 11,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#020202',
  },
  tableNoBoarder: {
    fontSize: 11,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderWidth: 0,
    borderColor: 'none',
  },
  tableBody: {
    display: 'flex', // error TS2322: Type '"table"' is not assignable to type '"none" | "flex" | undefined'.
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
  tableCol: {
    width: '60%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingLeft: 4,
    paddingRight: 1,
    fontSize: 10,
  },
  tableCol2: {
    width: '40%',
    textAlign: 'right',
    backgroundColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingRight: 4,
    paddingLeft: 1,
    fontSize: 10,
  },
  tableColLeft2: {
    width: '40%',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderRightColor: '#020202',
    borderBottomWidth: 0,
    borderRightWidth: 0,
    paddingRight: 1,
    paddingLeft: 1,
    fontSize: 10,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
});

const signatureTableWidth = {
  col: '40%',
  col2: '60%',
};
const itemTableWidth = {
  col: '15%',
  col2: '65%',
  col3: '10%',
  col4: '10%',
};
const labourTableWidth = {
  col: '20%',
  col2: '40%',
  col3: '20%',
  col4: '20%',
};
const backgroundColor = {
  color1: '#ffffff',
  color2: '#ffffff',
};

const formatDateTimeToLocal = (date: moment.MomentInput) => {
  const formatted = moment.utc(date).local().format('DD/MM/YYYY');
  return formatted;
};


const PdfGenerate = (props: { pdfData: PmolPDFData, customer: any, project: any, language: any }) => {
  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: 'EUR',
  });

  const lebelNames = {
    customerName: props.language === EXPORT_EN ? 'Customer Name' : 'Naam klant',
    customerAddress: props.language === EXPORT_EN ? 'Address' : 'Adres',
    customerContact: props.language === EXPORT_EN ? 'Customer Contact' : 'Klant Contact',
    customerContactDetails: props.language === EXPORT_EN ? 'Customer Contact Details' : 'Email',
    project: props.language === EXPORT_EN ? 'Project' : 'Projectnr',
    title: props.language === EXPORT_EN ? 'Work Order' : 'Opdracht',
    work: props.language === EXPORT_EN ? 'Work' : 'Uitgevoerde werken',
    labour: props.language === EXPORT_EN ? 'Labour' : 'Prestaties',
    date: props.language === EXPORT_EN ? 'Date' : 'Datum',
    technician: props.language === EXPORT_EN ? 'Technician' : 'Naam technieker',
    from: props.language === EXPORT_EN ? 'From' : 'Van',
    till: props.language === EXPORT_EN ? 'Till' : 'Tot',
    material: props.language === EXPORT_EN ? 'Material' : 'Verbruikte materialen',
    resourceNumber: props.language === EXPORT_EN ? 'Art.nr' : 'Art.nr',
    description: props.language === EXPORT_EN ? 'Description' : 'Omschrijving',
    quantity: props.language === EXPORT_EN ? 'Quantity' : 'Aantal',
    mou: props.language === EXPORT_EN ? 'MOU' : 'Eenheid',
    remarks: props.language === EXPORT_EN ? 'Remarks' : 'Opmerking',
    signatureCustomer: props.language === EXPORT_EN ? 'Signature Customer' : 'Handtekening klant',
    footerGoddeeris: props.language === EXPORT_EN ? 'Footer Goddeeris' : 'Footer Goddeeris',
  };

  return (
    <Document>
      <Page wrap style={styles.body}>
        <Text style={styles.header} fixed>
          { /* ~ Created with react-pdf ~*/}
        </Text>

        <View style={{ width: '100%', flexDirection: 'row' }}>
          <View style={{ width: '50%', fontSize: 12 }}>
            <Text>{ENV.ORGANISATION_ADDRESS.ADDRESS_LINE_1}</Text>
            <Text>{ENV.ORGANISATION_ADDRESS.ADDRESS_LINE_2}</Text>
            <Text>{ENV.ORGANISATION_ADDRESS.ADDRESS_LINE_3}</Text>
            <Text>{ENV.ORGANISATION_ADDRESS.CONTACT_NO}</Text>
          </View>
          <View style={{ width: '50%' }}>
            <Image
              style={styles.image}
              src={ENV.IMG_PDF}
            />
          </View>
        </View>

        <Table borderWidth={0}>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol
              style={{ backgroundColor: '#ffffff', border: false }}><Text> {lebelNames.customerName}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: false,
              isLastCell: true,
            }}><Text>{props.pdfData.customerName}&nbsp;</Text></TableColLeft2>
          </TableRow>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{ backgroundColor: '#ffffff', border: false }}><Text> {lebelNames.customerContact}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: false,
              isLastCell: true,
            }}><Text>{props.pdfData.customerContact}&nbsp;</Text></TableColLeft2>
          </TableRow>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{
              backgroundColor: '#ffffff',
              border: false,
            }}><Text> {lebelNames.customerContactDetails}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: false,
              isLastCell: true,
            }}><Text>{props.pdfData.contactPersonEmail}&nbsp;</Text></TableColLeft2>
          </TableRow>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol
              style={{ backgroundColor: '#ffffff', border: false }}><Text> {lebelNames.project}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: false,
              isLastCell: true,
            }}><Text>{props.pdfData.project}&nbsp;</Text></TableColLeft2>
          </TableRow>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{ backgroundColor: '#ffffff', border: false }}><Text> {lebelNames.title}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: false,
              isLastCell: true,
            }}><Text>{props.pdfData.title}&nbsp;</Text></TableColLeft2>
          </TableRow>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{ backgroundColor: '#ffffff', border: false }}><Text> {lebelNames.customerAddress}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: false,
              isLastCell: true,
            }}><Text>{props.pdfData.customerAddress}&nbsp;</Text></TableColLeft2>
          </TableRow>
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{ backgroundColor: '#ffffff', border: false }}><Text> {lebelNames.work}:</Text></TableCol>
            <TableColLeft2 style={{
              backgroundColor: '#ffffff',
              border: false,
              isLastCell: true,
            }}><Text>{props.pdfData.work}&nbsp;</Text></TableColLeft2>
          </TableRow>
        </Table>

        <View style={{ marginTop: 10 }}>
          <Text style={{
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'Helvetica-Bold',
            textDecoration: 'underline',
          }}> {lebelNames.labour}</Text>
        </View>
        <Table borderWidth={0}>
          <View fixed style={{ marginBottom: 0 }}>
            <TableRow style={{ backgroundColor: '#ffffff', border: false, sideBorder: true, topBorder: true }}>
              <TableCol style={{
                backgroundColor: '#ffffff',
                border: true,
                width: labourTableWidth.col,
                isLastCell: false,
                textAlign: 'center',
              }}><Text>{lebelNames.date} </Text></TableCol>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: true,
                width: labourTableWidth.col2,
                isLastCell: false,
                textAlign: 'center',
              }}><Text>{lebelNames.technician} </Text></TableCol2>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: true,
                width: labourTableWidth.col3,
                isLastCell: false,
                textAlign: 'center',
              }}><Text>{lebelNames.from} </Text></TableCol2>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: true,
                width: labourTableWidth.col4,
                isLastCell: true,
                textAlign: 'center',
              }}><Text>{lebelNames.till} </Text></TableCol2>
            </TableRow>
          </View>
          {renderLabourItem(props.pdfData)}
        </Table>


        <View style={{ marginTop: 10 }}>
          <Text style={{
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'Helvetica-Bold',
            textDecoration: 'underline',
          }}> {lebelNames.material}</Text>
        </View>
        <Table borderWidth={0}>
          <View fixed style={{ marginBottom: 0 }}>
            <TableRow style={{ backgroundColor: '#ffffff', border: false, sideBorder: true, topBorder: true }}>
              <TableCol style={{
                backgroundColor: '#ffffff',
                border: true,
                width: itemTableWidth.col,
                isLastCell: false,
                textAlign: 'center',
              }}><Text>{lebelNames.resourceNumber} </Text></TableCol>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: true,
                width: itemTableWidth.col2,
                isLastCell: false,
                textAlign: 'center',
              }}><Text>{lebelNames.description} </Text></TableCol2>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: true,
                width: itemTableWidth.col3,
                isLastCell: false,
                textAlign: 'center',
              }}><Text>{lebelNames.quantity} </Text></TableCol2>
              <TableCol2 style={{
                backgroundColor: '#ffffff',
                border: true,
                width: itemTableWidth.col4,
                isLastCell: true,
                textAlign: 'center',
              }}><Text>{lebelNames.mou} </Text></TableCol2>
            </TableRow>
          </View>
          {renderMaterialItem(props.pdfData)}
        </Table>
        <View style={{ marginTop: 50 }}>
          <Text style={{
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'Helvetica-Bold',
            textDecoration: 'underline',
          }}> {lebelNames.remarks}</Text>
        </View>
        <View style={{ marginTop: 50 }}>
          <Text style={{
            fontWeight: 'bold',
            fontSize: 10,
            fontFamily: 'Helvetica-Bold',
            textDecoration: 'underline',
          }}> {lebelNames.signatureCustomer}</Text>
        </View>

        <View style={{ marginTop: 10 }}>
          <Table borderWidth={0}>
            {renderSignatureItem(props.pdfData)}
          </Table>
        </View>


        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed/>
      </Page>
    </Document>
  );
};


const renderLabourItem = (pdfData: PmolPDFData) => {
  if (pdfData.labour) {
    const lastIndex = pdfData.labour.length - 1;
    return pdfData.labour.map((item: any, index: any) => {
      return (
        <View key={index + 'Labour'}>
          <TableRow
            style={{ backgroundColor: '#ffffff', border: false, sideBorder: true, isLastRow: lastIndex === index }}>
            <TableCol style={{
              backgroundColor: '#ffffff',
              border: true,
              width: labourTableWidth.col,
              isLastCell: false,
              isLastRow: lastIndex === index,
            }}><Text>{item.date}&nbsp; </Text></TableCol>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: true,
              width: labourTableWidth.col2,
              isLastCell: false,
              isLastRow: lastIndex === index,
              textAlign: 'left',
            }}><Text>{item.technician}&nbsp;</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: true,
              width: labourTableWidth.col3,
              isLastCell: false,
              isLastRow: lastIndex === index,
            }}><Text>{item.from}&nbsp;</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: true,
              width: labourTableWidth.col4,
              isLastCell: true,
              isLastRow: lastIndex === index,
            }}><Text>{item.till}&nbsp;</Text></TableCol2>
          </TableRow>
        </View>
      );
    });
  }
};

const renderMaterialItem = (pdfData: PmolPDFData) => {
  if (pdfData.material) {
    const lastIndex = pdfData.material.length - 1;
    return pdfData.material.map((item: any, index: any) => {
      return (
        <View key={index + 'Labour'}>
          <TableRow
            style={{ backgroundColor: '#ffffff', border: false, sideBorder: true, isLastRow: lastIndex === index }}>
            <TableCol style={{
              backgroundColor: '#ffffff',
              border: true,
              width: itemTableWidth.col,
              isLastCell: false,
              isLastRow: lastIndex === index,
            }}><Text>{item.resourceNumber}&nbsp;{item.description?.length > 65 ? '\n' : ''} </Text></TableCol>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: true,
              width: itemTableWidth.col2,
              isLastCell: false,
              isLastRow: lastIndex === index,
              textAlign: 'left',
            }}><Text>{item.description}&nbsp;</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: true,
              width: itemTableWidth.col3,
              isLastCell: false,
              isLastRow: lastIndex === index,
            }}><Text>{item.quantity}&nbsp;{item.description?.length > 65 ? '\n \n' : ''}</Text></TableCol2>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: true,
              width: itemTableWidth.col4,
              isLastCell: true,
              isLastRow: lastIndex === index,
              textAlign: 'center',
            }}><Text>{item.mou}&nbsp;{item.description?.length > 65 ? '\n \n' : ''}</Text></TableCol2>
          </TableRow>
        </View>
      );
    });
  }
};


const renderSignatureItem = (pdfData: PmolPDFData) => {
  // console.log("pdfData.signatureCustomer",pdfData.signatureCustomer);
  if (pdfData.signatureCustomer && pdfData.signatureCustomer.length > 0) {
    return pdfData.signatureCustomer.map((item: any, index: any) => {
      return (
        <View style={styles.signatureItem} key={index + 'signatureCustomer'}>
          { /* <Text style={{width:'30%',fontSize:10}}> {item.name}</Text>*/}
          <TableRow style={{ backgroundColor: '#ffffff', border: false }}>
            <TableCol style={{
              backgroundColor: '#ffffff',
              border: false,
              width: signatureTableWidth.col,
              isLastCell: false,
            }}><Text>{item.name} </Text></TableCol>
            <TableCol2 style={{
              backgroundColor: '#ffffff',
              border: false,
              width: signatureTableWidth.col2,
              isLastCell: false,
              textAlign: 'center',
            }}><Image
              style={styles.signatureImage}
              src={item.link}/></TableCol2>
          </TableRow>
        </View>
      );
    });
  }
};


const Table = (props: any) => {
  return (
    <View style={props.borderWidth === 0 ? styles.tableNoBoarder : styles.table}>
      {props.children}
    </View>
  );
};
const TableRow = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableRow.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      styles.tableRow.borderColor = props.style.borderColor;
      styles.tableRow.borderBottomWidth = props.style.borderBottomWidth ? props.style.borderBottomWidth : 2;
    } else {
      styles.tableRow.borderBottomWidth = 0;
    }
    if (props.style.topBorder) {
      styles.tableRow.borderTopWidth = props.style.borderBottomWidth ? props.style.borderBottomWidth : 1;
    } else {
      styles.tableRow.borderTopWidth = 0;
    }
    if (props.style.isLastRow) {
      styles.tableRow.borderBottomWidth = props.style.borderBottomWidth ? props.style.borderBottomWidth : 1;
    } else {
      styles.tableRow.borderBottomWidth = 0;
    }
    if (props.style.sideBorder) {
      styles.tableRow.borderRightWidth = props.style.borderBottomWidth ? props.style.borderBottomWidth : 1;
      styles.tableRow.borderLeftWidth = props.style.borderBottomWidth ? props.style.borderBottomWidth : 1;
    } else {
      styles.tableRow.borderRightWidth = 0;
      styles.tableRow.borderLeftWidth = 0;
    }
  }
  return (
    <View break wrap={false} style={styles.tableRow}>
      {props.children}
    </View>
  );
};
const TableCol = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableCol.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      if (props.style.isLastRow) {
        styles.tableCol.borderBottomWidth = 0;
        styles.tableCol.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      } else {
        styles.tableCol.borderBottomWidth = 1;
        styles.tableCol.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      }
    } else {
      styles.tableCol.borderColor = 'none';
      styles.tableCol.borderBottomWidth = 0;
      styles.tableCol.borderRightWidth = 0;
    }

    if (props.style.width) {
      styles.tableCol.width = props.style.width;
    } else {
      styles.tableCol.width = '30%';
    }
  }
  return (
    <View style={styles.tableCol}>
      {props.children}
    </View>
  );
};

const TableCol2 = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableCol2.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      if (props.style.isLastRow) {
        styles.tableCol2.borderBottomWidth = 0;
        styles.tableCol2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      } else {
        styles.tableCol2.borderBottomWidth = 1;
        styles.tableCol2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      }
    } else {
      styles.tableCol.borderColor = 'none';
      styles.tableCol2.borderBottomWidth = 0;
      styles.tableCol2.borderRightWidth = 0;
    }
    if (props.style.width) {
      styles.tableCol2.width = props.style.width;
    } else {
      styles.tableCol2.width = '70%';
    }
    if (props.style?.textAlign) {
      styles.tableCol2.textAlign = props.style.textAlign;
    } else {
      styles.tableCol2.textAlign = 'right';
    }
  }
  return (
    <View style={styles.tableCol2}>
      {props.children}
    </View>
  );
};

const TableColLeft2 = (props: any) => {
  if (props.style) {
    if (props.style.backgroundColor) {
      styles.tableColLeft2.backgroundColor = props.style.backgroundColor;
    }
    if (props.style.border) {
      if (props.style.isLastRow) {
        styles.tableColLeft2.borderBottomWidth = 0;
        styles.tableColLeft2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      } else {
        styles.tableColLeft2.borderBottomWidth = 1;
        styles.tableColLeft2.borderRightWidth = (props.style.isLastCell) ? 0 : 1;
      }
    } else {
      styles.tableCol.borderColor = 'none';
      styles.tableColLeft2.borderBottomWidth = 0;
      styles.tableColLeft2.borderRightWidth = 0;
    }
    if (props.style.width) {
      styles.tableColLeft2.width = props.style.width;
    } else {
      styles.tableColLeft2.width = '70%';
    }
  }
  return (
    <View style={styles.tableColLeft2}>
      {props.children}
    </View>
  );
};

const ref = React.createRef<HTMLDivElement>();

const PdfExport = (props: { pdfData: PmolPDFData, customer: any, project: any, language: any }) => (
  <div className="App">
    <div style={{ float: 'right' }}>
      <PDFDownloadLink
        document={<PdfGenerate language={props.language} pdfData={props.pdfData} customer={props.customer}
                               project={props.project}/>}
        fileName={props.pdfData.title + moment().format('YYYYMMDDHHMMSS')}>
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
          <PrimaryButton text={i18n.t('download')}/>)}
      </PDFDownloadLink>
    </div>

    { /* <br/>*/}
    { /* <PdfGenerate/>*/}
    <PDFViewer style={{ width: '100%', height: '80vh' }}>
      <PdfGenerate language={props.language} pdfData={props.pdfData} customer={props.customer} project={props.project}/>
    </PDFViewer>
  </div>
);
export default PdfExport;


