import React, {useEffect, useState} from 'react';
import SplitPane, {Pane} from 'react-split-pane';
import {Panel, PanelType, Stack} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import TitlePane from '../../shared/titlePane/titlePane';
import CommandBarLeft from './commandBar/commandBarLeft';
import ProgressBar from '../../shared/progressBar/progressBar';
import {useDispatch, useSelector} from 'react-redux';
import ListPaneComponent from './listPane/component';
import DocumentPane from './documentPane/component';
import {messageService} from '../../services/messageService';
import {ciawCancelDropDown, readCIAWDropdownData, saveCIAWStateAttr} from '../../reducers/ciawReducer';
import ShortCutPaneComponent from '../timeClock/shortcutPane/component';


const CiawMainLayout=()=>{
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [showDetails, setShowDetails]: any = useState(false);
  const [topReSizer, setTopReSizer]: any = useState(screen.width);
  const [isExpand, setIsExpand] = useState(false);
  const [isMinimize, setIsMinimize] = useState(true);

  const {
    horizontalReSizer,
    selectedPmol
  } = useSelector(
    (state: any) => state.projectDPlan
  );

  const {
    selectedCiaw,
    showProgressBar,
    collapseBookmarkPane
  } = useSelector(
    (state: any) => state.ciaw
  );

  useEffect(()=>{
    dispatch(readCIAWDropdownData());
    dispatch(ciawCancelDropDown());

    const subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.hideDocumentPane) {
          if (data.data.documentPaneType === 1) {
            _hideDocPane(true)
          }else{
            _hideDocPane(false)
          }
        }

        if (data.data.collapseDocumentPane) {
          setIsExpand((prevCheck) => !prevCheck);
        }

        if (data.data.minimizeBottomPane) {
          setIsMinimize(data.data.isMinimize);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
      dispatch(saveCIAWStateAttr('selectedCiaw',null));
      dispatch(saveCIAWStateAttr('showProgressBar',false));
      setTopReSizer(screen.width);
      _hidePanel();
    }
  },[]);


  useEffect(()=>{
   if(selectedCiaw && selectedCiaw[0] && selectedCiaw[0].id){
    setShowDetails(true);
    setTopReSizer(screen.width/2)
   }
  },[selectedCiaw]);

  useEffect(()=>{
    if(isExpand){
      if(showDetails) {
        dispatch(saveCIAWStateAttr('collapseBookmarkPane', true));
        setTopReSizer(screen.width / 2);
      }
    }else{
      if(showDetails){
        dispatch(saveCIAWStateAttr('collapseBookmarkPane',false));
        setTopReSizer(300);
      }
    }
  },[isExpand]);



  const _hidePanel=()=>{
      setShowPanel(false)
  };

  const _hideDocPane=(isTop?:boolean)=>{
    if(isTop){
      setTopReSizer(screen.width)
    }else{

    }
    setShowDetails(false);
    setTopReSizer(screen.width);
  };

  const getDocumentPaneView = () => {
    return <div style={{height: '100%'}}>
      <DocumentPane />
    </div>
  };

  const renderShortCutPane=()=>{
    return <div>
      {/* <TopShortcutPane/> */}
        <ShortCutPaneComponent />
    </div>
  };

 const topPaneResizer = () => {
    if(!collapseBookmarkPane) {
      return topReSizer===screen.width?'100%':'50%'
    } else {
      return '20%'
    }
  }

  const getListPaneView=()=>{
      return <div className={'grid-righter column-filter-activated'} style={{width:'100%'}}>
        <div style={{width:'100%', height: screen.height}}>
          <Stack horizontal>
            {renderShortCutPane()}
            <div style={{width:'100%'}}>
              <Stack
                className="project-list-header right-panel"
                style={{
                  backgroundColor: 'rgb(255, 255, 255)',
                  paddingRight: 0
                }}
                styles={{ root: { width: '100%', paddingRight: 0 } }}
              >
                <TitlePane
                    headerTitle={"ciaw"}
                />
                <CommandBarLeft disable={false} />

              </Stack>
              <ProgressBar show={showProgressBar} />

              <Stack style={{ alignSelf: 'center',width:'100%' }}>
               <ListPaneComponent/>
              </Stack>
            </div>
          </Stack>
        </div>
      </div>
  };
  return <div className={'tedtmainui'} style={{height:"calc(100vh - 130px)",}}>
    <SplitPane
      onChange={(size: any) => {
        dispatch(saveCIAWStateAttr('horizontalReSizer', size));
      }}
      minSize={300}
      maxSize={screen.width / 2}
      defaultSize={showDetails ? screen.width / 2 : screen.width}
      style={{ position: 'inherit' }}
      split="vertical"
      size={topPaneResizer()} //screen.width / 2}
      className='hrsplitpane'
    >
      <Pane className={'SplitPane'} style={{ width: '100%', height: '100%' }} >{getListPaneView()} </Pane>
      <Pane className={'SplitPane'} style={{ width: '100%', height: '100%', zIndex: 1 }}>
        {showDetails && <div
          style={{
            display: showDetails ? 'block' : 'none',
            transition: 'all 0.9s linear',
            borderLeft: '1px solid #bebebe'
          }}>
          {!showPanel && getDocumentPaneView()}
        </div>}
      </Pane>
    </SplitPane>

    <Panel
      isOpen={showPanel}
      type={PanelType.smallFixedFar}
      onDismiss={_hidePanel}
      isLightDismiss={true}
      headerText={t('corporateProductCatalogue')}
      className="custom-detail-panel"
      closeButtonAriaLabel={t('close')}
    >
      {getDocumentPaneView()}
    </Panel>

  </div>
};

export default CiawMainLayout
