import { DocumentAnalysisClient, AzureKeyCredential } from "@azure/ai-form-recognizer";

const endpoint = "https://centralindia.api.cognitive.microsoft.com/";
const apiKey = "7d44cc2614f74361ab4768bc8d33f595";
const modelId = "Vicky_ocr_fix";

const client = new DocumentAnalysisClient(endpoint, new AzureKeyCredential(apiKey));

export const analyzeCustomDocument = async (file:any) => {
  try {
    const poller = await client.beginAnalyzeDocument(modelId, file);
    const result = await poller.pollUntilDone();
    return result;
  } catch (error) {
    console.error("Error analyzing document:", error);
    throw error;
  }
};

