import React, { Component } from 'react';
import { connect } from 'react-redux';
import Brand from '../../shared/brand/brand';
// import ProjectBar from '../../shared/projectBar/projectBar';
import NavBar from '../../shared/navBar/navBar';
import ProjectNavBar from '../../shared/navBar/projectNavBar';
import ProductCatalogueMainLayout from './productCatalogueMainLayout';
import { getContractingUnit, getProject } from '../../shared/util';
import ProjectBarComponent from '../../shared/projectBar/ProjectBarComponent';

interface Props {
  currentUser: any
}

interface State {
  isContractUnit: boolean
}

export class ProductCatalogue extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isContractUnit: getContractingUnit() ? true : false };
  }

  componentDidMount() {

  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Brand/>
        {/* <ProjectBar
          contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/> */}
        <ProjectBarComponent contractingUnit={this.props.currentUser && this.props.currentUser.contractingUnits[0] ? this.props.currentUser.contractingUnits[0] : null}/>
        {this.state.isContractUnit &&
        <ProjectNavBar selectedKey={`/CU/${getContractingUnit()}/project/${getProject()}/product-catalogue`}
                       currentUser={this.props.currentUser}/>
        }
        {!this.state.isContractUnit &&
        <NavBar selectedKey={`/product-catalogue`} currentUser={this.props.currentUser}/>
        }

        <ProductCatalogueMainLayout/>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCatalogue);
