import React, {useEffect, useRef} from 'react';
import {Field, Form} from 'react-final-form';
import {Dropdown, mergeStyleSets} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import {
    filterMyDayPlanningResourceListPaneData,
    saveMyDayPlanningStateAttr,
} from '../../../reducers/myDayPlanningReducer';
import {useDispatch, useSelector} from 'react-redux';
import { formatDateTimeToISOString, getProject, getTimeZone } from '../../../shared/util';
import client from '../../../api';
import _ from 'lodash';
import {
    getProjectDayPlanningResourceList,
    saveProjectDayPlanningStateAttr,
} from '../../../reducers/projectDayPlanningReducer';
import {LOAD_LABOUR_LIST_MY_ENV_EP} from '../../../shared/endpoints';
import moment from 'moment/moment';
import {GridView} from '../../../types/myDayPlanning';
import CustomDropdownWithPlaceholder from '../../../shared/customDropdown/customDropdownWithPlaceholder';

const classNames = mergeStyleSets({
    wrapper: {
        display: 'block',
        maxWidth: '200px',
        minWidth: '180px',
        fontFamily: 'Segoe UI',
    },
    aligment: {
        margin: '2px 0 0 5px',
    },
    fullWidth: {width: '100%'},
    halfWidth: {width: '49%'},
    btnWrapper: {
        display: 'block',
        maxWidth: '200px',
    },
});

const PersonFilters = (props: {
    type: string;
    competencyTaxonomyList?: any
}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const formRef: any = useRef();
    const currentDate = moment();
    const competency = props.competencyTaxonomyList?.filter((e: any) => e?.parentCompetenciesId !== null)

    const options = [
        {key: '0', text: t('all')},
        {key: '1', text: t('unPlanned')},
        {key: '2', text: t('planned')},
    ];

    const {
        selectedDate,
        selectedWeek,
        view,
        reloadBottomListPane,
    } = useSelector(
        (state: any) => state.mydPlan,
    );

    const {
        selectedCu,
        selectedBu,
        buDropDown,
        isAddMultipleMembersSuccess,
    } = useSelector(
        (state: any) => state.projectDPlan,
    );

    const {
        userInfo,
        userCu,
    } = useSelector(
        (state: any) => state.uprince,
    );

    const labourResourceOptions = (inputValue: any) =>
        new Promise((resolve) => {
            resolve(getLabourItemByName(inputValue));
        });

    const competenciesOption = (inputvalue: any) => {
        return new Promise((resolve) => {
            resolve(getCompetencyItemByName(inputvalue));
        });
    }

    const getCompetencyItemByName = async (name: string) => {
        if (name) {
            const filterByInputValue = await competency?.filter((comps: any) => comps?.title?.toLowerCase().includes(name.toLowerCase())) 
            return formatCompetencyResponse(filterByInputValue)
        }
    }

    const getLabourItemByName = async (name: string) => {
        let filter = {
            cu: userCu?.sequenceCode,
            title: name,
        };

        if (name) {
            const response = await client.post(LOAD_LABOUR_LIST_MY_ENV_EP, filter);
            return formatLabourResponse(response);
        }

        return []
    };

    const formatCompetencyResponse = (result: any) => {
        let data: { value: string; label: string }[] = [];
        if (_.isArray(result)) {
            data = result.map((e: any) => {
                return {
                    value: e.competenciesId,
                    label: e.title
                }
            })
        }

        return data
    }

    const formatLabourResponse = (response: any) => {
        let data: { value: string; label: string }[] = [];
        if (response && response.data && _.isArray(response.data.result)) {
            data = response.data.result.map((item: any) => {
                return {
                    value: item.id,
                    label: item.title,
                };
            });
        }
        return data;
    };

    const dateSelector = (dateType: any): any => {
        if (view === 'week') {
            if (dateType === 'startDate') {
                return selectedWeek?.startDate ? formatDateTimeToISOString(selectedWeek.startDate) : moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD');
            }
            if (dateType === 'endDate') {
                return selectedWeek?.endDate ? formatDateTimeToISOString(selectedWeek.endDate) : moment(new Date()).endOf('isoWeek').format('YYYY-MM-DD');
            }
        } else {
            return selectedDate ? formatDateTimeToISOString(selectedDate) : formatDateTimeToISOString(new Date());
        }
    };

    useEffect(() => {
        formRef.current.reset();

        return () => {
            dispatch(saveProjectDayPlanningStateAttr('ppbProjectSequenceCode', null));
            dispatch(saveProjectDayPlanningStateAttr('ppbPmolTypeId', null));
            dispatch(saveProjectDayPlanningStateAttr('ppbSiteManagerId', null));
            dispatch(saveMyDayPlanningStateAttr('planboardPersonId', null));
            dispatch(saveMyDayPlanningStateAttr('planboardPersonName', null));
        };
    }, [selectedDate, selectedBu, selectedCu]);

    useEffect(() => {
        if (reloadBottomListPane || isAddMultipleMembersSuccess) {
            // dispatch(filterMyDayPlanningResourceListPaneData(getFilterDate()));
            formRef.current.submit();

            return () => {
                dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', false));
                dispatch(saveProjectDayPlanningStateAttr('isAddMultipleMembersSuccess', false));
            }
        }
    }, [reloadBottomListPane, isAddMultipleMembersSuccess]);

    const initialValues = () => {
        return {
            startDate: dateSelector('startDate'),
            endDate: dateSelector('endDate'),
            localDate: moment(currentDate).format('YYYY-MM-DD'),
            offSet: getTimeZone(),
            type: view,
            contractingUnit: getProject() ? selectedCu?.sequenceCode : userInfo ? userInfo?.contractingUnit?.sequnceCode : [],
            // buId: getProject() ? selectedBu : buDropDown ? buDropDown[0]?.key : null,
            buId: selectedBu,
            isPlanned: "0",
            competenciesId: null
        };
    };

    const onSubmit = async (values: any) => {
        const filterData = {
            ...values,
            startDate: moment(values.startDate).format('YYYY-MM-DD'),
            endDate: moment(values.endDate).format('YYYY-MM-DD'),
            cabPersonId: null,
            personName: values?.cabPersonName?.text ?? null,
            isPlanned: values?.isPlanned,
            preferedCpc: values?.labourResource?.value ?? null,
            buId: selectedBu,
            competenciesId: values?.competenciesId?.value ?? null
        };
        if (props.type == 'teamPlanBoard') {
            dispatch(filterMyDayPlanningResourceListPaneData(filterData));
        } else if (props.type === 'projectPlanBoard') {
            dispatch(getProjectDayPlanningResourceList(filterData));
        }
    };

    return <Form
        onSubmit={onSubmit}
        initialValues={initialValues()}
        mutators={{
            setEmployeeName: (args, state) => {
                const field = state.fields['cabPersonName'];
                field.change(args[0]);
            },
            setLabourResourceItem: (args, state) => {
                const field = state.fields['labourResource'];
                field.change(args[0]);
            },
        }}
        render={({handleSubmit, form, values}) => {
            // console.log('values', values);
            formRef.current = form;
            return (
                <form
                    style={{
                        display: 'flex',
                        gap: 10,
                        padding: 5,
                        marginLeft: 8,
                    }}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <div className={`${classNames.wrapper}`}>
                        <Field name='cabPersonName'>
                            {({input}) => (
                                <PeoplePicker
                                    label=''
                                    placeHolder={t('name')}
                                    isDisabled={false}
                                    onChange={(items: any) => {
                                        if (items && _.isArray(items) && items.length === 0) {
                                            input.onChange(null);
                                        }
                                        if (!items) {
                                            input.onChange(null);
                                        }
                                        formRef.current.submit();
                                        return items ? items : [];
                                    }}
                                    onPersonSelected={(item: any) => {
                                        input.onChange(item);
                                        formRef.current.submit();
                                        return item;
                                    }}
                                    selectedItem={values.cabPersonName
                                        ? [{key: values.cabPersonName.key, text: values.cabPersonName.text}]
                                        : []
                                    }
                                />
                            )}
                        </Field>
                    </div>

                    <div className={`${classNames.wrapper}`}>
                        <Field name='isPlanned'>
                            {({input}) => (
                                <Dropdown
                                    placeholder={t('planned/unplanned')}
                                    onChange={(ev, item) => {
                                        if (item) {
                                            input.onChange(item?.key);
                                            formRef.current.submit();
                                        } else {
                                            input.onChange(null);
                                        }
                                    }}
                                    selectedKey={values?.isPlanned}
                                    disabled={false}
                                    options={options}
                                />
                            )}
                        </Field>

                    </div>

                    <div style={{zIndex: 10, width: 200, height: 0}} className={`${classNames.wrapper}`}>
                        <Field name='labourResource'>
                            {({input}) => (
                                <CustomDropdownWithPlaceholder
                                    key={`${selectedDate}${selectedBu}${selectedCu}`}
                                    promiseOptions={labourResourceOptions}
                                    placeHolder={t('labourResourceItem')}
                                    onChange={(values: any) => {
                                        if (values) {
                                            input.onChange(values);
                                        } else {
                                            input.onChange(undefined);
                                        }
                                        formRef.current.submit();
                                    }}
                                />
                            )}
                        </Field>
                        {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                    </div>

                    <div style={{width: 200}} className={`${classNames.wrapper}`}>
                        <Field name='competenciesId'>
                            {({input}) => (
                                <CustomDropdownWithPlaceholder
                                    // key={`${selectedDate}${selectedBu}${selectedCu}`}
                                    promiseOptions={competenciesOption}
                                    placeHolder={t('competence')}
                                    onChange={(values: any) => {
                                        if (values) {
                                            input.onChange(values);
                                        } else {
                                            input.onChange(undefined);
                                        }
                                        formRef.current.submit();
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                </form>
            );
        }}
    />;
};

export default PersonFilters;
