import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon, Pivot, PivotItem, TooltipHost } from '@fluentui/react';
import { iconColors, uPrinceTheme } from '../../../theme';
import { getContractingUnit, getProject } from '../util';
import history from '../../history';
import './projectBar.css';
import { saveMyDayPlanningStateAttr } from '../../reducers/myDayPlanningReducer';

const pivotStyle = { root: { float: 'left' }, icon: { color: iconColors.waypointIconColor } };

const styles = {
  hoverWaypoint: {
    alignSelf: 'center',
    color: uPrinceTheme.palette.themeDarker,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '22px',
    marginRight: '15px',
    transition: 'all 0.2s',
    marginLeft: '10px',
    '&:hover': { background: uPrinceTheme.palette.themeSecondary },
  },
  Waypoint: {
    alignSelf: 'center',
    color: uPrinceTheme.palette.themePrimary,
    fontSize: '22px',
    cursor: 'pointer',
    textDecoration: 'none',
    marginRight: '15px',
    marginLeft: '10px',
    transition: 'all 0.2s',
    '&:hover': { background: uPrinceTheme.palette.themeSecondary },
  },
};

const ProjectBarComponent = (props: {
  contractingUnit?: {
    id: string | any,
    name: string | any,
    title: string | any
  } | null | undefined
}) => {
  const contractingUnit: string | null = getContractingUnit();
  const allProjects = useSelector((state: any) => state.project.allProjects)
  const validatedAllProjects = allProjects?.slice(0, 9)

  const [cu, setCu] = useState<string | null>(null)
  const [hoverPoi, setHoverPoi] = useState<boolean>(false)
  
  useEffect(() => {
    if (props.contractingUnit) {
      setCu(props.contractingUnit?.title)
    } else {
      if (contractingUnit) {
        setCu(contractingUnit)
      } else {
        setCu(null)
      }
    }
  }, [props.contractingUnit])

  const onIconClick = () => {
    if (getContractingUnit()) {
      history.push(`/CU/${getContractingUnit()}`);
    } else {
      history.push(`/contracting-unit`)
    }
  };

  const handleRedirect = (url: string) => {
    history.replace(url)
  }

  return (
    <div className='projectBar' style={{ height: 44 }}>
      <Icon
        iconName="POISolid"
        onClick={onIconClick}
        onMouseEnter={() => setHoverPoi(true)}
        onMouseLeave={() => setHoverPoi(false)}
        style={{ ...(hoverPoi ? styles.hoverWaypoint : styles.Waypoint) }}
      />
      {cu && 
        <Pivot
          styles={pivotStyle}
          selectedKey={getProject() ? `/CU/${cu}/project/${getProject()}/project-planning` : null}
          onLinkClick={(item: PivotItem | undefined) => {
            if (item && item?.props && item?.props?.itemKey) {
              handleRedirect(item.props.itemKey);
              saveMyDayPlanningStateAttr('selectedDate', undefined)
            }
          }}
        >
          {validatedAllProjects?.map((each: any, index: any) => {
            const url = `/CU/${cu}/project/${each.sequenceCode}/project-planning`
            return (
              <PivotItem
                key={each.sequenceCode}
                itemKey={url}
                headerText={each.sequenceCode}
                itemIcon="FabricFolderFill"
                onRenderItemLink={() => {
                  return  <TooltipHost
                            content={<span>{each.name}<br></br>{`${each.projectManagerId && each.projectManagerId} ${each.customerCompanyName && (each.customerCompanyName)}`}</span>}
                          >
                            {
                              <div style={{display: 'flex'}}>
                                <Icon iconName="FabricFolderFill" style={{ paddingRight: "4px", color: iconColors.waypointIconColor }} />
                                {each.sequenceCode}
                              </div>
                            }
                          </TooltipHost>
                }}
              />
            )
          })}
        </Pivot>
      }
    </div>
  )
}

export default ProjectBarComponent