import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { ICommandBarItemProps, Panel, PanelType, ScrollablePane, ScrollbarVisibility, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { SelectedGridType } from '../../../types/dayPlanning';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import '../../../shared/kanban/kanban.css';
import CustomToolbar from '../../../shared/kanban/customToolbar';
import CustomWeek from './customWeek';
import { filterDayPlanningTeamsData, saveDayPlanningStateAttr } from '../../../reducers/dayPlanningReducer';
import * as TdDropBox from '../../../shared/dnd/dropBox';
import { AcceptType } from '../../../types/myDayPlanning';
import EventBody from '../../projectDayPlanning/listPane/eventBody';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import {
    formatDateTimeToISOString,
    formatEndDateTimeToISOString,
    getTimeZone,
    handleCalendarEndTime,
    tooltipAccessor,
} from '../../../shared/util';
import { updateMyDayPlanningPmol } from '../../../reducers/myDayPlanningReducer';
import EventWrapper from '../../myDayPlanning/listPane/eventWrapper';
import MyCalendarNewProject from './newProject';
import { updatePlanBoardsPmolStatus } from '../../../reducers/projectDayPlanningReducer';
import CreatePmolModal from './component/newPmol';

moment.locale('en-GB');
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar as any);

export const classNames = mergeStyleSets({
    wrapper: {
        height: 'calc(100vh - 25vh)!important',
        position: 'relative',
    },
    fullScreenWrapper: {
        height: 'calc(100vh - 25vh)!important',
        position: 'relative',
    }
});

const PmolListPaneComponent = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const store = useStore();

    const {
        dayPlanningDate,
        dayPlanningWeek,
        teamList,
        selectedGridType,
        loadMsg,
        isDPLANListLoaded,
        dPlanFullScreen,
        reloadDPListPane
    } = useSelector((state: any) => state.dplan);

    const {userInfo} = useSelector((state: any) => state.uprince);

    const [teamListState, setTeamListState]: any = useState(null);
    const [pmolList, setPmolList]: any = useState(null);
    // prevState
    const [dayPlanningDateState, setDayPlanningDateState] = useState<any>(dayPlanningDate);
    const [selectedGridTypeState, setSelectedGridTypeState] = useState<SelectedGridType>(SelectedGridType.DAY);
    const [dayPlanningWeekState, setDayPlanningWeekState] = useState<any>(dayPlanningWeek); //data: {startDate, endDate, toDate}

    useEffect(() => {
        dispatch(saveDayPlanningStateAttr("selectedGridType", SelectedGridType.DAY));
        reloadMyCalListPane();

        return () => {

        }
    }, [])

    useEffect(() => {
        getTeam(teamList);
    }, [teamList]);

    // dayPlanningDate
    useEffect(() => {
        if (dayPlanningDate) {
            if (moment(dayPlanningDateState).format('YYYY/MM/DD') != moment(dayPlanningDate).format('YYYY/MM/DD')) {
                Promise.all([
                    reloadMyCalListPane()
                ]).then(() => {
                    setDayPlanningDateState(dayPlanningDate);
                });
            }
        }
    }, [dayPlanningDate]);

    // dayPlanningWeek
    useEffect(() => {
        if (dayPlanningWeekState) {
            if (moment(dayPlanningWeekState.startDate).format('YYYY/MM/DD') != moment(dayPlanningWeek.startDate).format('YYYY/MM/DD')) {
                Promise.all([
                    reloadMyCalListPane()
                ]).then(() => {
                    setDayPlanningWeekState(dayPlanningWeek);
                });
            }
        }
    }, [dayPlanningWeek]);

    // selectedGridType
    useEffect(() => {
        if (selectedGridTypeState != selectedGridType) {
            if (selectedGridType) {
                Promise.all([
                    reloadMyCalListPane()
                ]).then(() => {
                    setSelectedGridTypeState(selectedGridType);
                });
            }
        }
    }, [selectedGridType]);

    useEffect(() => {
        if (reloadDPListPane) {
            reloadMyCalListPane();   //TODO: handle initial reload
        }
    }, [reloadDPListPane]);

    const reloadMyCalListPane = () => {
        dispatch(filterDayPlanningTeamsData(getFilterData()));

        return null;
    }

    const formatTimeForPmol = (date: string, time: any) => {
        let formatted = moment(date).format('YYYY/MM/DD');
        let dateonly = (formatted === 'Invalid date') ? '' : formatted.toString();
        if (dateonly && time) {
            let t = time ? time : '';
            let dateTime = dateonly + ' ' + t;
            return moment(dateTime).toDate();
        } else {
            return moment.utc(date).local().toDate();
        }
    };

    const getFilterData = () => {
        return {
            startDate: selectedGridType === SelectedGridType.DAY
              ? moment(formatDateTimeToISOString(dayPlanningDate)).format('YYYY-MM-DD')
              : dayPlanningWeek?.startDate,
            endDate: selectedGridType === SelectedGridType.DAY
              ? moment(formatEndDateTimeToISOString(dayPlanningDate)).format('YYYY-MM-DD')
              : dayPlanningWeek.endDate,
            localDate: moment().format('YYYY-MM-DD'),
            offSet: getTimeZone(),
            type: selectedGridType,
            // weekEndDate: currentDate.clone().endOf('isoWeek').format('YYYY-MM-DD')
        };
    };

    const getTeam = (teamList: any) => {
        if (teamList && teamList.length > 0) {
            let teams = teamList.map((team: any, index: any) => {
                // console.log('team >',team);
                let teamName = team?.team?.map((emp: any) => {
                    return emp.name;
                });
                return {
                    resourceId: 1 + index,
                    resourceTitle: teamName?.join(','),
                    team: team.team,
                    teamId: team.teamId,
                    teamTitle: team.teamTitle
                };
            });
            setTeamListState(teams);
            getPmol(teamList);
        } else {
            setTeamListState(null);
            setPmolList([]);
        }
    };

    const getPmol = (teamList: any) => {
        let pmolList: any[] = [];
        teamList.map((team: any, index: any) => {
            if (team && team?.pmol && team.pmol.length > 0) {
                team?.pmol?.map((pmol: any) => {
                    pmolList.push({
                        id: pmol.id,
                        teamId: pmol?.teamId,
                        projectMoleculeId: pmol.projectMoleculeId,
                        projectSequenceCode: pmol.projectSequenceCode,
                        contractingUinit: pmol?.contractingUinit,
                        title: pmol.title,
                        vehicles: pmol.pomlVehical,
                        start: formatTimeForPmol(pmol.executionDate, pmol.executionStartTime),
                        end: formatTimeForPmol(pmol.executionDate, pmol.executionEndTime),
                        resourceId: 1 + index,
                        typeId: pmol.typeId,
                        pmolVehical: pmol?.pomlVehical,
                        pmolTool: pmol?.pomlTool,
                        statusId: pmol?.statusId,
                        projectTitle: pmol?.projectTitle,
                        forecast: pmol?.forecast,
                        executionStartTime: pmol?.executionStartTime,
                        executionEndTime: pmol?.executionEndTime,
                        productTaxonomy: pmol?.productTaxonomy,
                        productId: pmol?.productId,
                        comment: pmol?.comment,
                        // competencies: pmol?.competencies
                    });
                });
            }
            return pmolList;
        });
        setPmolList(pmolList);
    };

    const displayMessage = () => {
        return (
          <div style={{paddingTop: 36, paddingLeft: 20}}>
              <Text>{t(loadMsg)}</Text>
          </div>
        );
    };

    const handlePmolStatusChange = (data: any) => {
        Promise.all([
            dispatch(updatePlanBoardsPmolStatus(data))
        ]).then(() => {
            reloadMyCalListPane()
        });
    };

    const CustomEvent = ({event}: any) => {
        const _overflowItems: ICommandBarItemProps[] = [
            {
                key: 'openPbsKey',
                text: t('openPbs'),
                onClick: () => onClickPbs(event),
                iconProps: {iconName: 'MoveToFolder'}
            }
        ];

        return <TdDropBox.DropBox
          item={event}
          day={dayPlanningDateState}
          type={[AcceptType.TEAM, AcceptType.VEHICLE, AcceptType.TOOL]}
          isPmol={true}
        >
            <EventBody
              pmol={event}
              overflowItems={_overflowItems}
              isMyCal={true}
              handlePmolStatusChange={(data: any) => handlePmolStatusChange(data)}
            />
        </TdDropBox.DropBox>
    };

    const onSelectDateFromToolBar=(date:any)=>{
        dispatch(saveDayPlanningStateAttr('dayPlanningDate', date))
    }
    const renderCalendar = () => {
        // return <div style={{width: 'auto', height: `${dPlanFullScreen ? 'calc(100vh - 50px)' : 'calc(100vh - 295px)'}`}}>
        return <div style={{height:"100%", width:"100%"}}>
            <DragAndDropCalendar
              events={pmolList ? pmolList : []}
              localizer={localizer}
              view={selectedGridType}
              views={{day: true, week: CustomWeek}}
              onView={(view) => {
                  dispatch(saveDayPlanningStateAttr('selectedGridType', view));
              }}
              step={30}
              date={selectedGridType === SelectedGridType.DAY
                ? dayPlanningDateState
                : selectedGridType === SelectedGridType.WEEK ? dayPlanningWeekState?.toDate : moment().toDate()}
              onNavigate={(date) => {
                  dispatch(saveDayPlanningStateAttr('dayPlanningDate', date))
              }}
              resources={teamListState}
              resourceIdAccessor={(resources: any) => resources.resourceId}
              resourceTitleAccessor={(resources: any) => resources.resourceTitle}
              components={{
                  event: CustomEvent,
                  eventWrapper: EventWrapper,
                  resourceHeader: resourceHeader,
                 // toolbar: CustomToolbar,
                  toolbar: (props) => CustomToolbar({
                      ...props,
                      showAllButton: true,
                      selectedDate: store.getState()?.dplan?.dayPlanningDate,
                      onSelectDateFromToolBar,
                  }),
              }}
              onEventDrop={moveEvent}
              onEventResize={resizeEvent}
              onSelectSlot={selectSlotEvent}
              resizable={true}
              formats={{
                  eventTimeRangeFormat: ({start, end}, culture: any, localizer: any) =>
                    localizer.format(start, 'HH:mm', culture) +
                    ' - ' +
                    localizer.format(end, 'HH:mm', culture),
                  timeGutterFormat: (date, culture: any, localizer: any) =>
                    localizer.format(date, 'HH:mm', culture),
              }}
              dayLayoutAlgorithm="no-overlap"
              tooltipAccessor={tooltipAccessor}
              selectable
            />
        </div>
    }

    const resourceHeader = ({label}: any) => (
      <div key={JSON.stringify(label)} className='find me class' style={{width: '100%', minHeight: 40}}>
          {addLineBrake(label)}
      </div>
    );

    const addLineBrake = (htmlString: any) => {
        const array = htmlString.split(',');
        return array.map((text: any) => {
            return <React.Fragment>{text}<br/></React.Fragment>;
        });
    };

    const onClickPbs = (event: any) => {
        if (event && event.productId) {
            const openUrl = `/CU/${event.contractingUinit}/project/${event.projectSequenceCode}/product/pbs/${event.productId}`;
            window.open(openUrl);
        }
    };

    const resizeEvent = ({event, start, end}: any) => {
        let list = teamListState.find((item: any) => item.resourceId === event.resourceId);

        let data = {
            teamId: event?.teamId,
            team: list?.team,
            id: event?.id,
            executionDate: moment(event?.start).format('YYYY/MM/DD'),
            executionStartTime: moment(start).format('HH:mm'),
            executionEndTime: moment(end).format('HH:mm'),
            projectSequenceCode: event?.projectSequenceCode,
            contractingUnit: event?.contractingUinit
        }

        Promise.all([
            updateEvent(event, start, end, event?.resourceId)
        ]).then(() => {
            Promise.all([
                dispatch(updateMyDayPlanningPmol(data))
            ]).then(() => {
                dispatch(saveDayPlanningStateAttr("reloadListPane", true));
                reloadMyCalListPane();
            });
        });
    };

    const moveEvent = ({event, start, end, resourceId, isAllDay: droppedOnAllDaySlot = false}: any) => {
        if (resourceId) {
            let list = teamListState.find((item: any) => item.resourceId === resourceId);
            let data = {
                teamId: event?.teamId,
                team: list?.team,
                id: event?.id,
                executionDate: moment(event?.start).format('YYYY/MM/DD'),
                executionStartTime: moment(start).format('HH:mm'),
                executionEndTime: handleCalendarEndTime(event?.start, end)[0],
                projectSequenceCode: event?.projectSequenceCode,
                contractingUnit: event?.contractingUinit
            };

            Promise.all([
                updateEvent(event, start, handleCalendarEndTime(event?.start, end)[1], resourceId)
            ]).then(() => {
                Promise.all([
                    dispatch(updateMyDayPlanningPmol(data))
                ]).then(() => {
                    dispatch(saveDayPlanningStateAttr("reloadListPane", true));
                    reloadMyCalListPane();
                });
            });
        }
    };

    const selectSlotEvent = ({start, end, resourceId, slots, action}: any) => {
        let resourceSelector
        if (teamListState) {
            resourceSelector = teamListState.find((item: any) => item.resourceId === resourceId);
        } else {
            resourceSelector = {
                resourceId: 0,
                resourceTitle: userInfo?.userName,
                team: [{id: userInfo?.userId, name: userInfo?.userName}],
                teamId: null,
                teamTitle: null,
            }
        }
        dispatch(saveDayPlanningStateAttr("myCalendarSlotData", {start, end, resourceId, slots, action}));
        dispatch(saveDayPlanningStateAttr("resourceSelectorData", resourceSelector));
    };

    const updateEvent = (event: any, start: any, end: any, resourceId: any) => {
        const idx = pmolList.indexOf(event);
        const updatedEvent = {...event, start, end, resourceId};

        const nextEvents = [...pmolList];
        nextEvents.splice(idx, 1, updatedEvent);

        setPmolList(nextEvents)

        return null;
    };

    return (
      // <div className={`wrapper-holder ${dPlanFullScreen ? classNames.fullScreenWrapper : classNames.wrapper}`}>
      <div>
          {/* don't remove the ScrollablePane */}
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{marginLeft: 255, marginTop: 80}}>
              {renderCalendar()}
              {/*{isDPLANListLoaded && displayMessage()}*/}
          </ScrollablePane>
          <Panel
            isOpen={dPlanFullScreen}
            type={PanelType.custom}
            customWidth="100vw"
            onDismiss={() => {
                dispatch(saveDayPlanningStateAttr('dPlanFullScreen', false))
            }}
            isLightDismiss={true}
            headerText={t('myCalendar')}
            className="custom-detail-panel pdp"
            closeButtonAriaLabel={t('close')}
          >
              {renderCalendar()}
          </Panel>

          <MyCalendarNewProject/>
          <CreatePmolModal fCode={"day-planning"}/>
      </div>
    );
};

export default PmolListPaneComponent
