import _ from 'lodash';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  Fabric,
  IColumn,
  IDetailsFooterProps,
  IDetailsListProps,
  IDetailsRowStyles, ITag,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType, TagPicker,
  Text,
  TextField,
} from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect, useDispatch, useSelector} from 'react-redux';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import TableColumn from '../../../shared/tableColumn/tableColumn';
import {ProjectListItem, ProjectListPaneFilter} from '../../../types/project';
import client from '../../../api';

export const classNames = mergeStyleSets({
  wrapper: {
    height: '100%!important',
    position: 'relative',
    '[class^="ms-DetailsList"]': {
      width: 'calc(100vw + 160px)',
    },
  },
  filter: {
    paddingBottom: 20,
    maxWidth: 100,
  },
  header: { margin: 0 },
  row: { display: 'inline-block' },
});

const ListPaneComponent = (props: {
  filter: ProjectListPaneFilter;
  selection: any;
  columns: IColumn[];
  listPaneItems: ProjectListItem[];
  handleListPaneItemClick: (id: string) => void;
  handleFilterChange: (filter: ProjectListPaneFilter) => void;
  isDataLoaded?: boolean;
  loadMsg: string;
  resetSelection: () => void;
  dropDownOptions: any;
  key: string
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<ProjectListPaneFilter>(props.filter);
  const { projectType, projectManagementLevel, projectTemplate, projectState, projectClassificationConstructionType, projectClassificationSector }: any = useSelector(
    (state: any) => state.project,
  );
  const dispatch = useDispatch();

  const [requester, setRequester] = useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [siteManagerName, setSiteManagerName] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);

  const projectTolerenceDropDown: any = useSelector(
    (state: any) => state.project.projectToleranceState,
  );
  const getDropdownWithDefualt = (options: any) => {
    if (options) {
      const defualtOption = [
        {
          key: '0',
          text: t('all'),
          isDefault: true,
        },
      ];
      Array.prototype.push.apply(defualtOption, options);
      return defualtOption;
    }
    return [];
  };

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  const _onRenderRow: IDetailsListProps['onRenderRow'] = (row) => {
    // eslint-disable-next-line no-undef
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (row) {
      return (
        <span
          style={{ textDecoration: 'none' }}
          onClick={() => {
            // chnage item id name
            props.handleListPaneItemClick(row.item.sequenceCode);
          }}
        >
                    <DetailsRow {...row} styles={customStyles}/>
                </span>
      );
    }
    return null;
  };

  const onFilterCompanyChanged = (
    filterText: string,
  ): ITag[] | Promise<ITag[]> => {
    if (filterText) {
      return getCompanyByName(filterText);
    } else {
      return [];
    }
  };

  const getCompanyByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabOrganizationList/${name}`,
    );
    return formatCompanyResponse(response, name);
  };

  const formatCompanyResponse = (response: any, name: any) => {
    let data: { key: string; name: string; isNewItem: boolean }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: any) => {
        return {
          ...item,
          key: item.id,
          isNewItem: false,
        };
      });
    } else {
      data = [];
    }
    return data;
  };

  const onChangeTags = (items: any) => {
    console.log('dd', items[0]?.id);
    if (items) {
      props.filter.customerCompanyId = items[0]?.id;
    } else
      props.filter.customerCompanyId = null;

    props.handleFilterChange(filter);
    setSelectedCompany(items);
  };

  const _onRenderDetailsFooter = (
    detailsFooterProps: IDetailsFooterProps | undefined,
  ) => {
    return (
      <DetailsRow
        className="footer-as-filter"
        {...detailsFooterProps}
        columns={detailsFooterProps?.columns}
        item={{}}
        itemIndex={-1}
        groupNestingDepth={detailsFooterProps?.groupNestingDepth}
        selection={detailsFooterProps?.selection}
        onRenderItemColumn={_renderFilterItemColumn}
        // onRenderCheck={_onRenderCheckForFooterRow}
      />
    );
  };


  const _renderFilterItemColumn = (
    item?: ProjectListPaneFilter,
    index?: number,
    column?: IColumn,
  ) => {
    switch (column?.key) {
      case 'column1': {
        return (
          <div>
            <TextField
              value={filter?.title ? filter?.title.toString() : ''}
              onChange={(event, value) => {
                if (value) {
                  props.filter.title = value;
                } else {
                  props.filter.title = null;
                }

                props.handleFilterChange(filter);
              }}
            />
          </div>
        );
      }
      case 'column2': {
        return (
          <div>
            <Dropdown
              placeholder={t('type')}
              options={getDropdownWithDefualt(projectType)}
              selectedKey={props.filter?.projectTypeId ? props.filter?.projectTypeId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.projectTypeId =
                    value.key !== '0' ? value.key.toString() : null;
                } else {
                  props.filter.projectTypeId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
      }
      case 'column3': {
        return (
          <div>
            <Dropdown
              placeholder={t('filterManagementLevel')}
              options={getDropdownWithDefualt(projectManagementLevel)}
              selectedKey={props.filter?.managementLevelId ? props.filter?.managementLevelId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.managementLevelId =
                    value.key !== '0' ? value.key.toString() : null;
                } else {
                  props.filter.managementLevelId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
      }
      case 'column4': {
        return (
          <div>
            <Dropdown
              placeholder={t('templateId')}
              options={getDropdownWithDefualt(projectTemplate)}
              selectedKey={props.filter?.templateId ? props.filter?.templateId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.templateId =
                    value.key !== '0' ? value.key.toString() : null;
                } else {
                  props.filter.templateId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
      }
      case 'column5': {
        return (
          <div>
            <Dropdown
              placeholder={t('toleranceState')}
              options={getDropdownWithDefualt(projectTolerenceDropDown)}
              selectedKey={props.filter?.toleranceStateId ? props.filter?.toleranceStateId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.toleranceStateId =
                    value.key !== '0' ? value.key.toString() : null;
                } else {
                  props.filter.toleranceStateId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
      }
      case 'column6': {
        return (
          <div className="project_filter_pp">
            <PeoplePicker
              label={null}
              isDisabled={false}
              selectedItem={requester}
              onChange={(items: any) => {
                if (items && _.isArray(items) && items.length === 0) {
                  setRequester([]);
                  props.filter.projectManagerId = null;
                  props.handleFilterChange(props.filter);
                } else {
                  setRequester([]);
                  props.filter.projectManagerId = null;
                  props.handleFilterChange(props.filter);
                }
              }}
              onPersonSelected={(items: any) => {
                if (items) {
                  props.filter.projectManagerId = items.cabPersonId;
                  setRequester([{ key: items.key, text: items.text }]);
                } else {
                  props.filter.projectManagerId = null;
                  setRequester([]);
                }
                props.handleFilterChange(props.filter);
                return items;
              }}

            />
          </div>
        );
      }
      case 'column7': {
        return (
          <div>
            <Dropdown
              options={getDropdownWithDefualt(projectState)}
              selectedKey={props.filter?.projectStatus ? props.filter?.projectStatus : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.projectStatus =
                    value.key !== '0' ? value.key.toString() : null;
                } else {
                  props.filter.projectStatus = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
      }
      case 'column8': {
        return (
          <div>
            <Dropdown
              options={getDropdownWithDefualt(projectClassificationConstructionType)}
              selectedKey={props.filter?.toleranceStateId ? props.filter?.toleranceStateId : '0'}
              onChange={(event, value) => {
                //     if (value) {
                //         props.filter.toleranceStateId =
                // value.key !== '0' ? value.key.toString() : null;
                //     } else {
                //         props.filter.toleranceStateId = null;
                //     }
                //     props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
      }
      case 'column9': {
        return (
          <div>
            <Dropdown
              options={getDropdownWithDefualt(projectClassificationSector)}
              selectedKey={props.filter?.projectClassificationSectorId ? props.filter?.projectClassificationSectorId : '0'}
              onChange={(event, value) => {
                if (value) {
                  props.filter.projectClassificationSectorId =
                    value.key !== '0' ? value.key.toString() : null;
                } else {
                  props.filter.projectClassificationSectorId = null;
                }
                props.handleFilterChange(props.filter);
              }}
            />
          </div>
        );
      }
      case 'column10': {
        return (
          <div>
            <div className="project_filter_pp">
              <PeoplePicker
                label={null}
                isDisabled={false}
                selectedItem={customer}
                onChange={(items: any) => {
                  if (items && _.isArray(items) && items.length === 0) {
                    setCustomer([]);
                    props.filter.customerId = null;
                    props.handleFilterChange(props.filter);
                  } else {
                    setCustomer([]);
                    props.filter.customerId = null;
                    props.handleFilterChange(props.filter);
                  }
                }}
                onPersonSelected={(items: any) => {
                  if (items) {
                    props.filter.customerId = items.cabPersonId;
                    setCustomer([{ key: items.key, text: items.text }]);
                  } else {
                    props.filter.customerId = null;
                    setCustomer([]);
                  }
                  props.handleFilterChange(props.filter);
                  return items;
                }}

              />
            </div>
          </div>
        );
      }
        case 'column11': {
            return (
              <div>
                  <div className="project_filter_pp">
                      <PeoplePicker
                        label={null}
                        isDisabled={false}
                        selectedItem={siteManagerName}
                        onChange={(items: any) => {
                            if (items && _.isArray(items) && items.length === 0) {
                                setSiteManagerName([]);
                                props.filter.siteManagerId = null;
                                props.handleFilterChange(props.filter);
                            } else {
                                setSiteManagerName([]);
                                props.filter.siteManagerId = null;
                                props.handleFilterChange(props.filter);
                            }
                        }}
                        onPersonSelected={(items: any) => {
                            if (items) {
                                props.filter.siteManagerId = items.cabPersonId;
                                setSiteManagerName([{key: items.key, text: items.text}]);
                            } else {
                                props.filter.siteManagerId = null;
                                setSiteManagerName([]);
                            }
                            props.handleFilterChange(props.filter);
                            return items;
                        }}

                      />
                  </div>
              </div>
            );
        }
        case 'column12': {
            return (
              <div>
                  <TextField
                    value={filter?.ciawNumber ? filter?.ciawNumber.toString() : ''}
                    onChange={(event, value) => {
                        if (value) {
                            props.filter.ciawNumber = value;
                        } else {
                            props.filter.ciawNumber = null;
                        }

                        props.handleFilterChange(filter);
                    }}
                  />
              </div>
            );
        }
        case 'column13': {
            return (
              <div>
                  <TextField
                    value={filter?.projectAddress ? filter?.projectAddress.toString() : ''}
                    onChange={(event, value) => {
                        if (value) {
                            props.filter.projectAddress = value;
                        } else {
                            props.filter.projectAddress = null;
                        }

                        props.handleFilterChange(filter);
                    }}
                  />
              </div>
            );
        }
        case 'column14': {
          return (
            <div>
              <TagPicker
                selectedItems={selectedCompany ? selectedCompany : []}
                removeButtonAriaLabel='Remove'
                onResolveSuggestions={onFilterCompanyChanged as any}
                // onItemSelected={onCompanyItemSelected}
                // onChange={props.onRemoveCompanySuggestion}
                // pickerSuggestionsProps={tagSearchSuggestionProps}
                // onRenderSuggestionsItem={onRenderSuggestionsItemTagPicker}
                onChange={onChangeTags}
                itemLimit={1}
                disabled={false}
                inputProps={{
                  onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
                    console.log(),
                  onFocus: (ev: React.FocusEvent<HTMLInputElement>) =>
                    console.log(),
                  'aria-label': 'Tag Picker',
                }}
              />
            </div>
          );
        }
      default: {
        break;
      }
    }
  };


  const displayMessage = () => {
    return (
      <div style={{ paddingTop: 36, paddingLeft: 20 }}>
        <Text>{t(props.loadMsg)}</Text>
      </div>
    );
  };
  return (
    <Fabric style={{height: '100%'}}>
      <div className={`wrapper-holder ${classNames.wrapper}`}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <ShimmeredDetailsList
            items={props.listPaneItems}
            columns={props.columns}
            setKey={props.key}
            enableShimmer={!props.isDataLoaded}
            layoutMode={DetailsListLayoutMode.justified}
            selectionPreservedOnEmptyClick={true}
            checkboxVisibility={1}
            onRenderRow={_onRenderRow}
            onRenderDetailsFooter={_onRenderDetailsFooter}
            onRenderItemColumn={(
              item: ProjectListItem,
              index?: number,
              column?: IColumn,
            ) =>
              TableColumn(
                item,
                index,
                column,
              )
            }
            selection={props.selection}
            selectionMode={SelectionMode.single}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (defaultRender !== undefined) {
                return (
                  <Sticky
                    stickyPosition={StickyPositionType.Header}
                    isScrollSynced={true}
                    stickyBackgroundColor="transparent"
                  >
                    <div>{defaultRender(headerProps)}</div>
                  </Sticky>
                );
              }
              return <span></span>;
            }}
          />
          {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
      </div>
    </Fabric>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);
