import groupArray from 'group-array';

export const getVPPOFromBarTask = (task: any) => {
  return {
    id: task.id,
    parentId: task.parentId,
    title: task.title,
    startDate: task.start,
    endDate: task.end,
    status: null,
    type: task.type,
    linkId: task.linkId,
    project: task.project,
    borId: null,
    ccpcId: task.cpcId,
    poId: task.poId,
    children: [
      null,
    ],
  };
};

export const getCPCTeams = (teams: any, cpcId: string) => {
  const groupTeamArray: any = groupArray(teams, 'cpcId');
  if (groupTeamArray.hasOwnProperty(cpcId)) {
    return groupTeamArray[cpcId];
  }
  return [];
};

export const getPersonIDArray = (team: any) => {
  const ids = team.map((member: any) => {
    return member.personId;
  });

  return ids;
};

export const frenchMonthMap: { [key: string]: number } = {
  janvier: 0,
  fevrier: 1,
  mars: 2,
  avril: 3,
  mai: 4,
  juin: 5,
  juillet: 6,
  aout: 7,
  septembre: 8,
  octobre: 9,
  novembre: 10,
  decembre: 11,
};

export const dateNormalizer = (value:string) => {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const calFrenchDate = (value:any) => {
  const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  console.log(normalizedValue)
  const match = normalizedValue.match(/(\d{1,2}) (\w+) (\d{4})/);
  console.log(match)
  if(match){
  const [, day, month, year] = match
  const date = new Date(Number(year), frenchMonthMap[month.toLowerCase()], Number(day));
  console.log(date)
 return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
}}

export const convertToDateString = (d:any) => {
  const [date,month,year] = d.split('/')
  return `${year}-${month}-${date}`
}
export const  convertDateStringToYYYYMMDD = (date:any) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}/${month}/${year}`;
};
